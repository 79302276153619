import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { Col, Form, Row } from 'react-bootstrap';

import edit from '../assets/img/icons/PenFullBlue.svg';
import threeDots from '../assets/img/icons/threeDots.svg';
import cleanCloseIcon from '../assets/img/icons/ordClearBlueX.svg';

import { useGetMethod, usePostMethod } from '../Hooks/useFetch';
import { formatToRcSelect, loader, message, validateEmptyString } from '../helpers/helpers';

import { OrdAccordion } from '../OrderingModule/OrderingComponents/OrdAccordion';
import { OrdGenericTemplate } from '../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdModal from '../OrderingModule/OrderingComponents/OrdModal';
import OrdTable from '../OrderingModule/OrderingComponents/OrdTable';

import { ordCustomSelect } from '../components/Layouts/react-select-custom';
import tableStyles from '../components/Layouts/tableStyle.module.scss';

export const ServicesCombos = () => {
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const idAccount = store.loginReducer.currentAccount.id;
  const [trigger, setTrigger] = useState(1);
  const [data, setData] = useState({
    eaccount: idAccount,
    services: [],
    name: '',
    suggestions: '',
  });

  const [filters, setFilters] = useState({
    name: '',
    eaccount: idAccount,
  });

  const { results: timeResults, trigger: getTime } = useGetMethod();
  const { results: servicesResults, trigger: getServices } = useGetMethod();
  const { results: servicesCombos, load: comboLoader, trigger: getServicesCombo } = useGetMethod();

  const { load: createLoader, trigger: CreateNewServiceCombo } = usePostMethod();
  const { load: editLoader, trigger: EditServiceCombo } = usePostMethod();
  const { load: updateComboLoader, trigger: updateCombo } = usePostMethod();

  useEffect(() => {
    getServicesCombo({
      url: '/medical/serviceCombos/',
      objFilters: filters,
      token: token,
    });
  }, [filters, getServicesCombo, token, trigger]);

  useEffect(() => {
    getServices({
      url: '/medical/generals/services/',
      token: token,
    });
  }, [getServices, token, trigger]);

  useEffect(() => {
    getTime({
      url: '/medical/generals/times/',
      objFilters: { timTag: ' minute,hour' },
      token: token,
    });
  }, [getTime, token, trigger]);

  const body = data => {
    let tempList = [];
    if (Array.isArray(data)) {
      data.forEach((item, index) => {
        tempList.push(
          <tr key={index + 'servicesCombos'} className={``}>
            <td className='col-1 px-1 text-secondary'>
              {item.srvId} - {item.srvName}
            </td>
          </tr>,
        );
      });
      return tempList;
    }
  };
  const footer = data => {
    let x = (
      <div className='pt-4'>
        <b className='text-secondary'>Recomendaciones</b>
        <hr style={{ margin: '0px' }} />
        <label className='text-secondary'>{data ? data : '-'}</label>
      </div>
    );
    return x;
  };
  //   -------------- INHABILITE METHOD --------------------------------
  const handleSubmit = data => {
    let statusEs = data?.staValue === 'enabled' ? 'habilitado' : 'deshabilitado';
    updateCombo({
      url: '/medical/serviceCombos/',
      token: token,
      method: 'PUT',
      body: data,
      doAfterSuccess: () => {
        setTrigger(trigger + 1);
      },
      succesAction: () =>
        message(
          'success',
          `<span class=${tableStyles.ordClearBlueText}>Proceso completado </span>`,
          `Combo ${data?.name} ${statusEs} exitosamente`,
        ),
    });
  };
  // ------------------------- MODAL ----------------------------
  const [showComboModal, setShowComboModal] = useState({
    show: false,
    isEditing: false,
  });
  const openNewCombo = () => {
    setShowComboModal({ ...showComboModal, show: true, isEditing: false });
  };
  const openEditCombo = item => {
    setShowComboModal({ ...showComboModal, show: true, isEditing: true });
    setData({
      ...data,
      name: item.name,
      suggestions: item.suggestions,
      services: item.services,
      id: item.id,
    });
  };

  const closeComboModal = () => {
    setShowComboModal({ ...showComboModal, show: false, isEditing: false });
    setServiceComboRow({
      tWait: '',
      tmId: '',
      srvId: '',
      srvName: '',
    });
    setData({ ...data, services: [], name: '', suggestions: '' });
  };

  const createCombo = () => {
    CreateNewServiceCombo({
      url: '/medical/serviceCombos/',
      token: token,
      method: 'POST',
      body: data,
      doAfterSuccess: () => {
        setTrigger(trigger + 1);
        closeComboModal();
      },
      succesAction: () =>
        message(
          'success',
          `<span class=${tableStyles.ordClearBlueText}>Proceso completado </span>`,
          `Combo ${data?.name} creado exitosamente`,
        ),
    });
  };

  const EditCombo = () => {
    EditServiceCombo({
      url: '/medical/serviceCombos/',
      token: token,
      method: 'PUT',
      body: data,
      doAfterSuccess: () => {
        setTrigger(trigger + 1);
        closeComboModal();
      },
      succesAction: () =>
        message(
          'success',
          `<span class=${tableStyles.ordClearBlueText}>Proceso completado </span>`,
          `Combo ${data?.name} editado exitosamente`,
        ),
    });
  };

  const validationRequest = () => {
    let validations = validateEmptyString([
      { field: data.name, message: 'El nombre del combo es obligatorio' },
    ]);
    let servicesValidation =
      data.services.length > 1
        ? true
        : message(
            'info',
            'Valide la cantidad de servicios',
            'Para crear un combo de servicios debe seleccionar como mínimo (2) servicios.',
          );

    if (validations.valid && servicesValidation) {
      showComboModal?.isEditing ? EditCombo() : createCombo();
    }
  };

  const headers = [
    {
      title: 'Código',
      className: 'px-2 text-center col-2',
    },
    {
      title: 'Servicio',
      className: 'px-2 text-start col-5',
    },
    {
      title: 'Tiempo de espera',
      className: 'px-2 text-start col-3',
    },
    {
      className: 'px-2 text-end ',
    },
  ];
  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className='text-center px-2'>{item?.srvId}</td>
            <td className='text-start px-2'>{item?.srvName}</td>
            <td className='text-start px-2'>
              {item?.tWait} - {item?.tmName}
            </td>
            <td className='text-start px-2'>
              <button className='px-1 mr-2' onClick={() => editItem(item)}>
                <img alt='edit' src={edit} />
              </button>
              <button className='px-1' onClick={() => deleteItem(item)}>
                <img alt='delete' src={cleanCloseIcon} />
              </button>
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const timeOptions = formatToRcSelect(timeResults?.results, 'tim_id', 'tim_name', '', '', '');
  const servicesOption = formatToRcSelect(servicesResults?.results, 'id', 'name', '', '', '');

  const [serviceComboRow, setServiceComboRow] = useState({
    tWait: '',
    tmId: '',
    srvId: '',
    srvName: '',
  });

  const addService = () => {
    let validations = validateEmptyString([
      { field: serviceComboRow.srvName, message: 'El nombre del servicio es obligatorio' },
      { field: serviceComboRow.tWait, message: 'El tiempo de espera es obligatorio' },
      { field: serviceComboRow.srvId, message: 'El servicio es obligatorio' },
      { field: serviceComboRow.tmId, message: 'El tiempo de espera es obligatorio' },
    ]);

    let serviceValidation = data.services.find(services => services.srvId === serviceComboRow.srvId)
      ? message('info', 'Servicio repetido', 'El servicio elegido ya se encuentra seleccionado')
      : false;

    if (validations.valid && serviceValidation === false) {
      data?.services?.push(serviceComboRow);
      setServiceComboRow({
        tWait: '',
        tmId: '',
        srvId: '',
        srvName: '',
      });
      setTrigger(trigger + 1);
    }
  };
  const deleteItem = item => {
    let id = item?.srvId;
    let founded = data.services.length > 0 && data.services.filter(x => x.srvId !== id);
    setData({ ...data, services: founded });
  };
  const editItem = item => {
    let id = item?.srvId;
    let founded = data.services.length > 0 && data.services.filter(x => x.srvId !== id);
    setData({ ...data, services: founded });
    setServiceComboRow({
      tWait: item.tWait,
      tmId: item.tmId,
      srvId: item.srvId,
      srvName: item.srvName,
      tmName: item.tmName,
    });
  };

  return (
    <>
      {comboLoader && loader}
      {updateComboLoader && loader}
      {createLoader && loader}
      {editLoader && loader}

      <OrdModal
        title={showComboModal.isEditing ? 'Editar combo de servicios' : 'Nuevo combo de servicios'}
        show={showComboModal.show}
        btnYesName={showComboModal.isEditing ? 'Editar' : 'Crear'}
        btnNoName={'Cancelar'}
        size={700}
        onHide={() => closeComboModal()}
        btnYesEvent={() => validationRequest()}
        btnNoEvent={() => closeComboModal()}
        sizeBody={`${tableStyles.modalHeight}`}
      >
        <div>
          <div>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                <b> &nbsp;Nombre del combo</b>
              </Form.Label>
              <Form.Control
                className={`ord-roundInput `}
                type='text'
                placeholder='Escriba...'
                value={data.name}
                onChange={e => {
                  setData({ ...data, name: e.target.value });
                }}
              />
            </Form.Group>
          </div>

          <Row className='d-flex justify-content-between'>
            <Col xs={5}>
              <Form.Group className='mb-3 text-start' controlId='fName'>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <b> &nbsp;Servicios</b>
                </Form.Label>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  options={servicesOption}
                  className='text-secondary '
                  placeholder={'Seleccione...'}
                  value={servicesOption.find(x => x.value === serviceComboRow.srvId)}
                  styles={ordCustomSelect}
                  onChange={e => {
                    setServiceComboRow({ ...serviceComboRow, srvId: e.value, srvName: e.label });
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group className='mb-3 text-start ' controlId='fName'>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <b> &nbsp;Tiempo de espera</b>
                </Form.Label>
                <div className='d-flex'>
                  <Col
                    xs={4}
                    style={{
                      marginRight: '10px',
                    }}
                  >
                    <Form.Control
                      className={`ord-roundInput `}
                      type='number'
                      placeholder='Escriba...'
                      value={serviceComboRow.tWait}
                      onChange={e => {
                        setServiceComboRow({ ...serviceComboRow, tWait: e.target.value });
                      }}
                    />
                  </Col>

                  <Col xs={8}>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      options={timeOptions}
                      className='text-secondary '
                      placeholder={'Seleccione...'}
                      value={timeOptions.find(x => x.value === serviceComboRow.tmId)}
                      styles={ordCustomSelect}
                      onChange={e => {
                        setServiceComboRow({ ...serviceComboRow, tmId: e.value, tmName: e.label });
                      }}
                    />
                  </Col>
                </div>
              </Form.Group>
            </Col>
            <Col xs={1} className='d-flex '>
              <div className=' align-self-end'>
                <button
                  className='addCheckButton mx-2'
                  style={{ marginBottom: '1rem' }}
                  onClick={() => {
                    addService();
                  }}
                />
              </div>
            </Col>
          </Row>

          <div className={`d-flex justify-content-center`}>
            {data?.services?.length > 0 ? (
              <OrdTable
                headers={headers}
                hasChildren={true}
                shadow={true}
                oneHeadDarkBlue={false}
                lowerCase={false}
              >
                {formatData(data.services)}
              </OrdTable>
            ) : (
              <b className={`${tableStyles.ordClearGrayText} ${tableStyles.f18} text-center my-4`}>
                Agregue servicios
              </b>
            )}
          </div>
          <div>
            <Form.Group className='mb-3' controlId='fName'>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                <b className='text-start'>&nbsp;Observaciones</b>
              </Form.Label>
              <textarea
                value={data?.suggestions}
                placeholder={'Escribir...'}
                rows='40'
                cols='50'
                style={{ height: '4rem' }}
                className={`text-secondary ord-roundInput w-100 ${tableStyles.outlineNone}`}
                key={trigger + 'observations'}
                onChange={e =>
                  setData({
                    ...data,
                    suggestions: e.target.value,
                  })
                }
              />
            </Form.Group>
          </div>
        </div>
      </OrdModal>
      <OrdGenericTemplate
        className='w-90'
        showBottomBorder={false}
        title={'Configuración de combos de servicios'}
        titleSize={12}
        buttonNewLabel={'Nuevo combo'}
        buttonNewAction={() => {
          openNewCombo();
        }}
        searchWithNew={{
          colSize: '12',
          WSize: '90',
          buttonNewLabel: 'Nuevo combo',
          onChange: e => {
            setFilters({ ...filters, name: e.target.value });
          },
          buttonNewAction: () => openNewCombo(),
          onSubmit: e => {
            e.preventDefault();
            setTrigger(trigger + 1);
          },
        }}
      >
        <div className='w-90 mx-1'>
          {servicesCombos?.results?.length > 0 &&
            servicesCombos.results.map(item => {
              return (
                <OrdAccordion
                  key={item?.id}
                  triggerSrc={threeDots}
                  title={item?.name}
                  editClickEvent={() => openEditCombo(item)}
                  enableClickEvent={() =>
                    Swal.fire({
                      icon: 'warning',
                      title:
                        item?.status?.staValue === 'enabled'
                          ? 'Deshabilitar combo de servicios'
                          : 'Habilitar combo de servicios',
                      text:
                        item?.status?.staValue === 'enabled'
                          ? '¿Desea deshabilitar la combo de servicios ?'
                          : '¿Desea habilitar la combo de servicios ?',
                      confirmButtonColor: '#3085d6',
                      confirmButtonText:
                        item?.status?.staValue === 'enabled' ? 'Si, deshabilitar' : 'Si, habilitar',
                      cancelButtonColor: '#d33',
                      cancelButtonText: 'Cancelar',
                      showCancelButton: true,
                    }).then(result => {
                      if (result.isConfirmed) {
                        let newStatus =
                          item?.status?.staValue === 'enabled' ? 'disabled' : 'enabled';
                        handleSubmit({
                          id: item.id,
                          status: newStatus,
                          name: item.name,
                          eaccount: idAccount,
                          services: item.services,
                          suggestions: item.suggestions,
                        });
                      }
                    })
                  }
                  isEnabled={item?.status?.staValue === 'enabled' ? true : false}
                  status={item?.status?.staDescription}
                  fontcolor={item?.status?.fontColor}
                  backgroundColor={item?.status?.bgColor}
                  body={body(item?.services)}
                  footer={footer(item?.suggestions)}
                />
              );
            })}
        </div>
      </OrdGenericTemplate>
    </>
  );
};
