import { forwardRef, useEffect, useState } from 'react';
import { $getRoot, $insertNodes } from 'lexical';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { BeautifulMentionsPlugin, createBeautifulMentionNode } from 'lexical-beautiful-mentions';
//
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { ListItemNode, ListNode } from '@lexical/list';
//
import ToolbarPlugin, { LETTER_VARIABLES } from './Plugins/Toolbar';
//
import editorTheme from './theme';
import './LetterEditor.scss';

export const LABELS = {
  '[DD]': 'Día actual',
  '[MM]': 'Mes actual',
  '[AAAA]': 'Año actual',
  '[CLIENTE]': 'Cliente',
  '[TABLA_FACTURAS]': 'Tabla facturas',
  '[NOMBRE_FIRMA]': 'Nombre',
  '[CARGO_FIRMA]': 'Cargo',
};

const CustomMentionComponent = forwardRef(function CustomMentionComponent({ value, ...rest }, ref) {
  return (
    <span {...rest} ref={ref}>
      {LABELS[value]}
    </span>
  );
});

export default function LetterEditor({
  onGetResult = () => null,
  name = '',
  defaultValue = '',
  readOnly = false,
}) {
  const [textValue, setTextValue] = useState('');

  useEffect(() => {
    if (defaultValue) setTextValue(defaultValue);
  }, [defaultValue]);

  const initializeEditorState = editor => {
    if (textValue) {
      const parser = new DOMParser();
      const dom = parser.parseFromString(textValue, 'text/html');
      const nodes = $generateNodesFromDOM(editor, dom);
      $getRoot().select();
      $insertNodes(nodes);
    }
  };

  const initialConfig = {
    namespace: 'LetterEditor',
    theme: editorTheme,
    onError: error => console.error(error),
    editorState: initializeEditorState,
    editable: !readOnly,
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      ...createBeautifulMentionNode(CustomMentionComponent),
    ],
  };

  const onChange = (_, editor) => {
    editor.update(() => {
      const raw = $generateHtmlFromNodes(editor, null);
      onGetResult({ [name]: raw });
    });
  };

  const render = () => {
    return (
      <div key={textValue}>
        <LexicalComposer initialConfig={initialConfig}>
          {!readOnly && <ToolbarPlugin />}
          <RichTextPlugin
            contentEditable={<ContentEditable className='editor' />}
            placeholder={!readOnly ? <div className='editor-placeholder'>Escribe...</div> : ''}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <ListPlugin />
          <OnChangePlugin onChange={onChange} ignoreSelectionChange />
          <MyCustomAutoFocusPlugin />
          <BeautifulMentionsPlugin items={LETTER_VARIABLES} triggers={['@']} />
        </LexicalComposer>
      </div>
    );
  };

  return render();
}

function MyCustomAutoFocusPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    // Focus the editor when the effect fires!
    editor.focus();
  }, [editor]);

  return null;
}
