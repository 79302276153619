import { useState } from 'react';

//styles
import tableStyles from '../../components/Layouts/tableStyle.module.scss';

//components
import { Col, Row } from 'react-bootstrap';
import { OrdGenericTemplate } from './../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import { OrdersPage, SubscriptionsPage } from './../TabsProviders/';
import { useLocation } from 'react-router';

const SupplierOrders = () => {
  const location = useLocation();
  const [newOrdenActive, setNewOrdenActive] = useState(false);
  const locationDataSubscription = location?.state?.data?.subscription;
  const [tabActive, setTabActive] = useState(
    locationDataSubscription ? 'SubscriptionsPage' : 'OrdersPage',
  );

  return (
    <OrdGenericTemplate className=' mt-4 mx-2' title={'Órdenes de proveedores'} titleSize={12}>
      <div
        className='asistencialDateColorIcon'
        style={{ width: '94%' }}
        onClick={() => {
          newOrdenActive && setNewOrdenActive(false);
        }}
      >
        <Row className={tableStyles.rowTabStyle}>
          <Col
            xs={2}
            className={`${tabActive === 'OrdersPage' ? tableStyles.tabActiveAdmition : tableStyles.tabInactiveAdmition}`}
            onClick={() => setTabActive('OrdersPage')}
          >
            <span>Órdenes</span>
          </Col>

          <Col
            xs={2}
            className={`${tabActive === 'SubscriptionsPage' ? tableStyles.tabActiveAdmition : tableStyles.tabInactiveAdmition}`}
            onClick={() => setTabActive('SubscriptionsPage')}
          >
            <span>Abonos</span>
          </Col>

          <Col xs={8} style={{ borderBottom: '1px solid #00B4CC' }}></Col>
        </Row>

        {tabActive === 'OrdersPage' && <OrdersPage />}

        {tabActive === 'SubscriptionsPage' && <SubscriptionsPage />}
      </div>
    </OrdGenericTemplate>
  );
};

export default SupplierOrders;
