import { useEffect, useState } from 'react';
import { Card, Col, Form, ListGroup, Row } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import TabContainer from 'react-bootstrap/TabContainer';
import TabContent from 'react-bootstrap/TabContent';
import TabPane from 'react-bootstrap/TabPane';
import Tooltip from 'react-bootstrap/Tooltip';
import Pagination from 'react-js-pagination';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useGetMethod } from '../../Hooks/useFetch';
import stylePagination from '../../OrderingModule/OrderingComponents/ordComponentStyles.module.scss';
import approved from '../../assets/img/icons/app_aprovado.svg';
import assign from '../../assets/img/icons/app_asignarCita.svg';
import styles from '../../components/Layouts/tableStyle.module.scss';
import { loader } from '../../helpers/helpers';

export const OrderingPatientTab = ({ patient }) => {
  const store = useSelector(state => state);
  const infoPatient = patient?.results;
  const location = useLocation();
  const [profile] = useState(1);
  let history = useHistory();
  const idPatient = location.state.idPatient;
  const idAccount = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const [triggerConsult, setTriggerConsult] = useState(1);
  const [triggerProcedures, setTriggerProcedures] = useState(1);
  const [triggerMedicines, setTriggerMedicines] = useState(1);
  const [activeTab, setActiveTab] = useState(1);

  const [filterConsults, setFilterConsult] = useState({
    patient: idPatient,
    typeList: 'cons',
    startDate: '',
    endDate: '',
    search: '',
    page: 1,
    perpage: 10,
    eaccount: idAccount,
  });

  const [filterProcedures, setFilterProcedures] = useState({
    patient: idPatient,
    typeList: 'proc',
    startDate: '',
    endDate: '',
    search: '',
    page: 1,
    perpage: 10,
    eaccount: idAccount,
  });

  const [filterMedicines, setFilterMedicines] = useState({
    patient: idPatient,
    startDate: '',
    endDate: '',
    search: '',
    page: 1,
    perpage: 10,
    eaccount: idAccount,
  });

  const renderTooltipAssign = props => (
    <Tooltip {...props}>
      <div>
        <span>Asignar Cita</span>
      </div>
    </Tooltip>
  );

  const renderTooltipApproved = props => (
    <Tooltip {...props}>
      <div>
        <span>Gestionado</span>
      </div>
    </Tooltip>
  );

  const {
    results: consultsPatient,
    load: loadConsultsPatient,
    trigger: getConsultsPatient,
  } = useGetMethod();

  const {
    results: proceduresPatient,
    load: loadProceduresPatient,
    trigger: getProceduresPatient,
  } = useGetMethod();

  const {
    results: medicinePatient,
    load: loadMedicinePatient,
    trigger: getMedicinePatient,
  } = useGetMethod();

  useEffect(() => {
    getConsultsPatient({
      url: `/medical/patient/service/`,
      token: token,
      objFilters: filterConsults,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerConsult]);

  useEffect(() => {
    getProceduresPatient({
      url: `/medical/patient/service/`,
      token: token,
      objFilters: filterProcedures,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerProcedures]);

  useEffect(() => {
    getMedicinePatient({
      url: `/medical/patient/medicine/`,
      token: token,
      objFilters: filterMedicines,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerMedicines]);

  const formDataMedicines = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <Card key={index} className={styles.app_container_card_medicine}>
            <div key={index} className={styles.app_card_medicine}>
              <div className={styles.app_container_medicine}>
                <span className={styles.app_title_info_card}>Fecha</span>
                <span className={styles.app_subtitle_info_card} style={{ paddingRight: '16px' }}>
                  {item?.date ? item?.date : '-'}
                </span>
              </div>
            </div>

            <div>
              {item.medicines.map((item, index) => {
                return (
                  <div key={index} className={styles.app_card_medicine}>
                    <div className={styles.app_container_medicine}>
                      <span className={styles.app_title_info_card_medicine}>Medicamento</span>
                      <span className={styles.app_subtitle_info_card_medicine}>
                        {item?.medicine ? item?.medicine : '-'}
                      </span>
                    </div>
                    <div className={styles.app_container_medicine}>
                      <span className={styles.app_title_info_card_medicine}>Cantidad</span>
                      <span className={styles.app_subtitle_info_card_medicine}>
                        {item?.dosis ? item?.dosis : '-'}
                      </span>
                    </div>
                    <div className={styles.app_container_medicine}>
                      <span className={styles.app_title_info_card_medicine}>Frecuencia</span>
                      <span className={styles.app_subtitle_info_card_medicine}>
                        {item?.frequency ? item?.frequency : '-'}
                      </span>
                    </div>
                    <div className={styles.app_container_medicine_time}>
                      <span className={styles.app_title_info_card_medicine}>Tiempo</span>
                      <span className={styles.app_subtitle_info_card_medicine}>
                        {item?.period ? item?.period : '-'}
                      </span>
                    </div>
                    <div className={styles.app_container_medicine}>
                      <span className={styles.app_title_info_card_medicine}>Presentación</span>
                      <span className={styles.app_subtitle_info_card_medicine}>
                        {item?.presentation ? item?.presentation : '-'}
                      </span>
                    </div>
                    <div className={styles.app_container_medicine}>
                      <span className={styles.app_title_info_card_medicine}>Concentración</span>
                      <span className={styles.app_subtitle_info_card_medicine}>
                        {item?.concentration ? item?.concentration : '-'}
                      </span>
                    </div>
                    <div className={styles.app_container_medicine}>
                      <span className={styles.app_title_info_card_medicine}>Vía</span>
                      <span className={styles.app_subtitle_info_card_medicine}>
                        {item?.route ? item?.route : '-'}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </Card>,
        );
      });
    }
    return tempList;
  };

  const formDataConsult = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <Card className={styles.app_card_custom} key={index}>
            <div key={index} className={styles.app_container_cards}>
              <div className={styles.app_container_info_card}>
                <span className={styles.app_title_info_card}>Fecha</span>
                <span className={styles.app_subtitle_info_card}>{item.orderDate}</span>
              </div>
              <div className={styles.app_container_info_card}>
                <span className={styles.app_title_info_card}>Cups</span>
                <span className={styles.app_subtitle_info_card}>{item.cup}</span>
              </div>
              <div className={styles.app_container_w}>
                <span className={styles.app_title_info_card}>{item?.serviceType}</span>
                <span className={styles.app_subtitle_info_card}>{item.offeredService}</span>
              </div>
              <div className={styles.app_container_info_card}>
                <span className={styles.app_title_info_card}>Ojo</span>
                <span className={styles.app_subtitle_info_card}>{item.bodyOrgan}</span>
              </div>
              <div className={styles.app_container_w}>
                <span className={styles.app_title_info_card}>Médico</span>
                <span className={styles.app_subtitle_info_card}>{item.fullnameMedical}</span>
              </div>
              <div className={styles.app_container_info_card}>
                <span className={styles.app_title_info_card}>Fecha sugerida</span>
                <span className={styles.app_subtitle_info_card}>{item.dateSuggested}</span>
              </div>
              <div className={styles.app_container_icon}>
                {item.appAssign === 0 ? (
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltipAssign}
                  >
                    <div>
                      <img
                        alt={'icon'}
                        style={{ width: '25px' }}
                        src={assign}
                        className={`cursorPointer`}
                        onClick={() =>
                          history.push({
                            pathname: '/citas/RegistroCita',
                            state: { infoPatient: infoPatient, procedure: item },
                          })
                        }
                      />
                    </div>
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltipApproved}
                  >
                    <div>
                      <img
                        alt={'icon'}
                        style={{ width: '30px' }}
                        src={approved}
                        className={`cursorPointer`}
                      />
                    </div>
                  </OverlayTrigger>
                )}
              </div>
            </div>
          </Card>,
        );
      });
    }
    return tempList;
  };
  //  ANCHOR CARD
  const formDataProcedures = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <Card className={styles.app_card_custom} key={index}>
            <div key={index} className={styles.app_container_cards}>
              <div className={styles.app_container_info_card}>
                <span className={styles.app_title_info_card}>Fecha</span>
                <span className={styles.app_subtitle_info_card}>{item.orderDate}</span>
              </div>
              <div className={styles.app_container_info_card}>
                <span className={styles.app_title_info_card}>Cups</span>
                <span className={styles.app_subtitle_info_card}>{item.cup}</span>
              </div>
              <div className={styles.app_container_w}>
                <span className={styles.app_title_info_card}>Procedimiento</span>
                <span className={styles.app_subtitle_info_card}>{item.offeredService}</span>
              </div>
              <div className={styles.app_container_info_card}>
                <span className={styles.app_title_info_card}>Ojo</span>
                <span className={styles.app_subtitle_info_card}>{item.bodyOrgan}</span>
              </div>
              <div className={styles.app_container_w}>
                <span className={styles.app_title_info_card}>Observaciones</span>
                <span className={styles.app_subtitle_info_card}>{item.observation}</span>
              </div>
              <div className={styles.app_container_w}>
                <span className={styles.app_title_info_card}>Médico</span>
                <span className={styles.app_subtitle_info_card}>{item.fullnameMedical}</span>
              </div>
              <div className={styles.app_container_icon}>
                {item.appAssign === 0 ? (
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltipAssign}
                  >
                    <div>
                      <img
                        alt={'icon'}
                        style={{ width: '25px' }}
                        src={assign}
                        className={`cursorPointer`}
                        onClick={() =>
                          history.push({
                            pathname: '/citas/RegistroCita',
                            state: { infoPatient: infoPatient, procedure: item },
                          })
                        }
                      />
                    </div>
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltipApproved}
                  >
                    <div>
                      <img
                        alt={'icon'}
                        style={{ width: '30px' }}
                        src={approved}
                        className={`cursorPointer`}
                      />
                    </div>
                  </OverlayTrigger>
                )}
              </div>
            </div>
          </Card>,
        );
      });
    }
    return tempList;
  };

  return (
    <>
      {(loadConsultsPatient || loadProceduresPatient || loadMedicinePatient) && loader}
      <div>
        <TabContainer id='list-group-tabs-example' defaultActiveKey={profile}>
          <Row>
            <div className='col-lg-2'>
              <ListGroup className={styles.app_list_group}>
                <ListGroup.Item
                  action
                  className={activeTab === 1 ? styles.app_list_group_active : styles.app_list_item}
                  eventKey={1}
                  onClick={() => setActiveTab(1)}
                >
                  Consultas y ayudas dx
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  className={activeTab === 2 ? styles.app_list_group_active : styles.app_list_item}
                  eventKey={2}
                  onClick={() => setActiveTab(2)}
                >
                  Medicamentos
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  className={activeTab === 3 ? styles.app_list_group_active : styles.app_list_item}
                  eventKey={3}
                  onClick={() => setActiveTab(3)}
                >
                  Procedimientos y/o cirugías
                </ListGroup.Item>
              </ListGroup>
            </div>
            <div className='col-lg-10'>
              <TabContent>
                {/* Consult */}
                <TabPane eventKey={1}>
                  <div className='col-11'>
                    <Row className={styles.app_container_options}>
                      <Col xs={6}>
                        <Form.Group>
                          <form
                            onSubmit={e => {
                              e.preventDefault();
                              setTriggerConsult(triggerConsult + 1);
                            }}
                          >
                            <Form.Control
                              type='text'
                              placeholder='Escribe aquí para buscar'
                              onChange={e => {
                                e.preventDefault();
                                setFilterConsult({
                                  ...filterConsults,
                                  search: e.target.value,
                                });
                              }}
                              className={`${styles.app_background_out_image} ${styles.app_roundSearch} col-8 pl-2`}
                            />
                          </form>
                        </Form.Group>
                      </Col>
                    </Row>
                    <div key={triggerConsult + 1} className={styles.app_container_card_scroll}>
                      {formDataConsult(consultsPatient.results)}
                    </div>
                    <div
                      className={`${stylePagination.wrapper}`}
                      style={stylePagination.endPagination}
                    >
                      <p className={stylePagination.paginationText}>
                        Pag. {consultsPatient ? filterConsults.page : ''}
                        {' de '}
                        {Math.ceil(consultsPatient.rowTotal / filterConsults.perpage)
                          ? Math.ceil(consultsPatient.rowTotal / filterConsults.perpage)
                          : ''}{' '}
                        ({consultsPatient.rowTotal} encontrados)
                      </p>
                      <Pagination
                        activePage={filterConsults.page}
                        itemsCountPerPage={filterConsults.perpage}
                        totalItemsCount={consultsPatient.rowTotal}
                        pageRangeDisplayed={5}
                        onChange={e => {
                          setFilterConsult({ ...filterConsults, page: e });
                          setTriggerConsult(triggerConsult + 1);
                        }}
                        itemClassPrev={stylePagination.itemClassPrev}
                        itemClassNext={stylePagination.itemClassNext}
                        itemClassFirst={stylePagination.itemClassFirst}
                        itemClassLast={stylePagination.itemClassLast}
                        itemClass={stylePagination.itemClass}
                      />
                    </div>
                  </div>
                </TabPane>
                {/* Medicines */}
                <TabPane eventKey={2}>
                  <div className='col-11'>
                    <Row className={styles.app_container_options}>
                      <Col xs={6}>
                        <Form.Group>
                          <form
                            onSubmit={e => {
                              e.preventDefault();
                              setTriggerMedicines(triggerMedicines + 1);
                            }}
                          >
                            <Form.Control
                              type='text'
                              placeholder='Escribe aquí para buscar'
                              onChange={e => {
                                e.preventDefault();
                                setFilterMedicines({
                                  ...filterMedicines,
                                  search: e.target.value,
                                });
                              }}
                              className={`${styles.app_background_out_image} ${styles.app_roundSearch} col-8 pl-2`}
                            />
                          </form>
                        </Form.Group>
                      </Col>
                      <Col xs={3}>
                        <Form.Group controlId='startDate'>
                          <Form.Control
                            className={styles.app_roundInput}
                            name='startDate'
                            placeholder='Desde'
                            type='date'
                            onChange={e => {
                              setFilterMedicines({
                                ...filterMedicines,
                                startDate: e.target.value,
                              });
                              setTriggerMedicines(triggerMedicines + 1);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={3}>
                        <Form.Group controlId='endDate'>
                          <Form.Control
                            className={styles.app_roundInput}
                            type='date'
                            name='endDate'
                            onChange={e => {
                              setFilterMedicines({
                                ...filterMedicines,
                                endDate: e.target.value,
                              });
                              setTriggerMedicines(triggerMedicines + 1);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <div key={triggerMedicines + 1} className={styles.app_container_card_scroll}>
                      {formDataMedicines(medicinePatient.results)}
                    </div>
                    <div
                      className={`${stylePagination.wrapper}`}
                      style={stylePagination.endPagination}
                    >
                      <p className={stylePagination.paginationText}>
                        Pag. {medicinePatient ? filterMedicines.page : ''}
                        {' de '}
                        {Math.ceil(medicinePatient.rowTotal / filterMedicines.perpage)
                          ? Math.ceil(medicinePatient.rowTotal / filterMedicines.perpage)
                          : ''}{' '}
                        ({medicinePatient.rowTotal} encontrados)
                      </p>
                      <Pagination
                        activePage={filterMedicines.page}
                        itemsCountPerPage={filterMedicines.perpage}
                        totalItemsCount={medicinePatient.rowTotal}
                        pageRangeDisplayed={5}
                        onChange={e => {
                          setFilterMedicines({ ...filterMedicines, page: e });
                          setTriggerMedicines(triggerMedicines + 1);
                        }}
                        itemClassPrev={stylePagination.itemClassPrev}
                        itemClassNext={stylePagination.itemClassNext}
                        itemClassFirst={stylePagination.itemClassFirst}
                        itemClassLast={stylePagination.itemClassLast}
                        itemClass={stylePagination.itemClass}
                      />
                    </div>
                  </div>
                </TabPane>
                {/* Procedures */}
                <TabPane eventKey={3}>
                  <div className='col-11'>
                    <Row className={styles.app_container_options}>
                      <Col xs={6}>
                        <Form.Group>
                          <form
                            onSubmit={e => {
                              e.preventDefault();
                              setTriggerProcedures(triggerProcedures + 1);
                            }}
                          >
                            <Form.Control
                              type='text'
                              placeholder='Escribe aquí para buscar'
                              onChange={e => {
                                e.preventDefault();
                                setFilterProcedures({
                                  ...filterProcedures,
                                  search: e.target.value,
                                });
                              }}
                              className={`${styles.app_background_out_image} ${styles.app_roundSearch} col-8 pl-2`}
                            />
                          </form>
                        </Form.Group>
                      </Col>
                      <Col xs={3}>
                        <Form.Group controlId='startDate'>
                          <Form.Control
                            type='date'
                            name='startDate'
                            onChange={e => {
                              setFilterProcedures({
                                ...filterProcedures,
                                startDate: e.target.value,
                              });
                              setTriggerProcedures(triggerProcedures + 1);
                            }}
                            className={styles.app_roundInput}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={3}>
                        <Form.Group controlId='endDate'>
                          <Form.Control
                            type='date'
                            name='endDate'
                            onChange={e => {
                              setFilterProcedures({
                                ...filterProcedures,
                                endDate: e.target.value,
                              });
                              setTriggerProcedures(triggerProcedures + 1);
                            }}
                            className={styles.app_roundInput}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className={styles.app_container_card_scroll}>
                      {formDataProcedures(proceduresPatient.results)}
                    </div>
                    <div
                      className={`${stylePagination.wrapper}`}
                      style={stylePagination.endPagination}
                    >
                      <p className={stylePagination.paginationText}>
                        Pag. {proceduresPatient ? filterProcedures.page : ''}
                        {' de '}
                        {Math.ceil(proceduresPatient.rowTotal / filterProcedures.perpage)
                          ? Math.ceil(proceduresPatient.rowTotal / filterProcedures.perpage)
                          : ''}{' '}
                        ({proceduresPatient.rowTotal} encontrados)
                      </p>
                      <Pagination
                        activePage={filterProcedures.page}
                        itemsCountPerPage={filterProcedures.perpage}
                        totalItemsCount={consultsPatient.rowTotal}
                        pageRangeDisplayed={5}
                        onChange={e => {
                          setFilterProcedures({ ...filterProcedures, page: e });
                          setTriggerProcedures(triggerProcedures + 1);
                        }}
                        itemClassPrev={stylePagination.itemClassPrev}
                        itemClassNext={stylePagination.itemClassNext}
                        itemClassFirst={stylePagination.itemClassFirst}
                        itemClassLast={stylePagination.itemClassLast}
                        itemClass={stylePagination.itemClass}
                      />
                    </div>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </Row>
        </TabContainer>
      </div>
    </>
  );
};
