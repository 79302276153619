import Select from 'react-select';
import { Col, Form, Row } from 'react-bootstrap';
import { Box } from '@mui/material';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import cardCredit from '../../../assets/img/icons/cardCredit.svg';
import close from '../../../assets/img/icons/close-blue.svg';
import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';
import NumberFormat from 'react-number-format';
import { useTheme } from 'styled-components';
import { Text } from '../../../components/UI/atoms';
import { formatNumberWithoutDecimals, getLocaleSeparators } from '../../../helpers/numberFormatting';

const localeSeparators = getLocaleSeparators()

export const CardCollectionTc = ({
  activeEvent,
  setActiveEvent,
  handleRemoveDeposit,
  listFranchise,
}) => {
  const { colors } = useTheme();

  return (
    <Row className=' mt-2' style={{ width: '970px' }}>
      <Col xs={10} className=''>
        <div className={`${tableStyles.addCardWhite} d-flex my-3 py-3 px-2`}>
          <Col xs={2} className='align-self-center'>
            <div className='d-flex align-self-center justify-content-center'>
              <div
                className={`${tableStyles.addCardWhite} d-flex justify-content-center align-self-center m-0 my-0`}
                style={{ width: '100px', height: '72px', alignItems: 'center', display: 'flex' }}
              >
                <img alt='arrowIcon' height='40px' src={cardCredit} className='' />
              </div>
            </div>
          </Col>

          <Col xs={5} className='px-2'>
            <Form.Group className=' text-start mb-2' controlId=''>
              <Text
                title={'Tipo de recaudo'}
                color={colors.ordDarkBlueText}
                fontSize={'14px'}
                required
              />

              <Form.Control
                className={`ord-roundInput `}
                type='text'
                placeholder='Tarjeta de crédito'
                disabled
              />
            </Form.Group>

            <div className='d-flex'>
              <Col xs={12}>
                <Form.Group className=' text-start ml-2' controlId=''>
                  <Text
                    title={'Franquicia'}
                    color={colors.ordDarkBlueText}
                    fontSize={'14px'}
                    required
                  />

                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={listFranchise?.results?.map(e => ({
                      value: e.fraId,
                      label: e.fraName,
                    }))}
                    className='text-secondary '
                    placeholder={'Seleccionar...'}
                    styles={ordCustomSelect}
                    value={
                      activeEvent?.depositValues?.tc?.franchise
                        ? {
                            label: listFranchise?.results?.find(
                              e => e?.fraId === activeEvent?.depositValues?.tc?.franchise,
                            )?.fraName,
                            value: listFranchise?.results?.find(
                              e => e?.fraId === activeEvent?.depositValues?.tc?.franchise,
                            )?.fraId,
                          }
                        : ''
                    }
                    onChange={e => {
                      setActiveEvent({
                        ...activeEvent,
                        depositValues: {
                          ...activeEvent?.depositValues,
                          tc: { ...activeEvent?.depositValues?.tc, franchise: e.value },
                        },
                      });
                    }}
                  ></Select>
                </Form.Group>
              </Col>
            </div>
          </Col>

          <Box display='flex' flexDirection={'column'} className='w-100'>
            <Col xs={12}>
              <Form.Group className=' text-start mr-2 mb-2' controlId=''>
                <Text
                  title={'Monto pagado'}
                  color={colors.ordDarkBlueText}
                  fontSize={'14px'}
                  required
                />

                <NumberFormat
                  allowNegative={false}
                  className={`register-inputs`}
                  placeholder='Escribe...'
                  style={{
                    maxHeight: '32px',
                    height: '32px',
                    fontSize: '14px',
                    borderColor: '#a3bad1',
                  }}
                  isNumericString={true}
                  value={activeEvent?.depositValues?.tc?.amount}
                  thousandSeparator={localeSeparators.groupSeparator}
                  decimalSeparator={localeSeparators.decimalSeparator}
                  format={(valueStr) => {
                    if (!valueStr) return ''

                    return formatNumberWithoutDecimals(valueStr)
                  }}
                  allowLeadingZeros={false}
                  onValueChange={({ value }) => {
                    setActiveEvent({
                      ...activeEvent,
                      depositValues: {
                        ...activeEvent?.depositValues,
                        tc: { ...activeEvent?.depositValues?.tc, amount: value },
                      },
                    });
                  }}
                />
              </Form.Group>
            </Col>

            <Col xs={12}>
              <Form.Group className=' text-start mr-2' controlId=''>
                <Text
                  title={'No. referencia'}
                  color={colors.ordDarkBlueText}
                  fontSize={'14px'}
                  required
                />

                <input
                  className={`register-inputs`}
                  style={{
                    maxHeight: '32px',
                    height: '32px',
                    fontSize: '14px',
                    borderColor: '#a3bad1',
                  }}
                  type='number'
                  placeholder='Escribe...'
                  value={activeEvent?.depositValues?.tc?.referenceNumber}
                  onChange={e => {
                    setActiveEvent({
                      ...activeEvent,
                      depositValues: {
                        ...activeEvent?.depositValues,
                        tc: { ...activeEvent?.depositValues?.tc, referenceNumber: e.target.value },
                      },
                    });
                  }}
                />
              </Form.Group>
            </Col>
          </Box>
        </div>
      </Col>

      <Col xs={2} style={{ display: 'flex', alignItems: 'center' }}>
        <span
          className={tableStyles.hoverClose}
          onClick={() => handleRemoveDeposit('tarjetaCredito')}
        >
          <img
            src={close}
            alt='cerrar'
            style={{
              padding: '5px',
              boxShadow: '0px 0px 2px #ccc',
              background: 'white',
              borderRadius: '100%',
              cursor: 'pointer',
            }}
          />
        </span>
      </Col>
    </Row>
  );
};
