export const customSelectStyle = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#003F80' : '#fff',
  }),
  control: provided => ({
    ...provided,
    border: '3px solid #01A2F5',
    borderRadius: 5,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: '#003F80',
  }),
  clearIndicator: provided => ({
    ...provided,
    color: '#003F80',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },

  multiValueRemove: provided => ({
    ...provided,
    color: '#003F80',
  }),
};

export const customSelectMulti = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    backgroundColor: state.isDisabled
      ? '#58595B'
      : state.isSelected
        ? '#7FADDE'
        : state.isFocused
          ? 'rgba(0, 93, 191, 0.05)'
          : '#fff',
    color: state.isDisabled || state.isSelected ? 'white' : 'black',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
  }),
  control: provided => ({
    ...provided,
    border: '1px solid #7FADDE',
    borderRadius: 5,
    fontSize: 13,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 6px',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
};

export const StyleNiif = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#003F80' : '#fff',
  }),
  control: provided => ({
    ...provided,
    border: '3px solid #1C6EA4',
    borderRadius: 5,
    width: 175,
    // none of react-select's styles are passed to <Control />
    //width: 200,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: '#003F80',
  }),
  clearIndicator: provided => ({
    ...provided,
    color: '#003F80',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },

  multiValueRemove: provided => ({
    ...provided,
    color: '#003F80',
  }),
};

export const customSelectStyleSmall = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#003F80' : '#fff',
  }),
  control: provided => ({
    ...provided,
    border: '3px solid #01A2F5',
    borderRadius: 5,
    // none of react-select's styles are passed to <Control />
    width: 240,
    marginBottom: 18,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: '#003F80',
  }),
  clearIndicator: provided => ({
    ...provided,
    color: '#003F80',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
};

export const customSelectNew = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,

    backgroundColor: state.isDisabled
      ? '#58595B'
      : state.isSelected
        ? '#7FADDE'
        : state.isFocused
          ? 'rgba(0, 93, 191, 0.05)'
          : '#fff',
    color: state.isDisabled || state.isSelected ? 'white' : 'black',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
    overflowX: 'hidden',
  }),
  control: provided => ({
    ...provided,
    border: '1px solid #7FADDE',
    borderRadius: 5,
    height: 24,
    minHeight: 16,
    outerHeight: 26,
    innerHeight: 26,

    fontSize: 12,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#005DBF',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#005DBF',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '24px',
    padding: '0 6px',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '24px',
  }),

  singleValue: provided => {
    const transition = 'opacity 300ms';
    const color = '#58595B';
    return { ...provided, transition, color };
  },
};

export const customSelectNew3 = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,

    backgroundColor: state.isDisabled
      ? '#58595B'
      : state.isSelected
        ? '#7FADDE'
        : state.isFocused
          ? 'rgba(0, 93, 191, 0.05)'
          : '#fff',
    color: state.isDisabled || state.isSelected ? 'white' : 'black',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
  }),
  control: provided => ({
    ...provided,
    border: '1px solid #7FADDE',
    borderRadius: 5,
    height: 27,
    minHeight: 16,
    outerHeight: 26,
    innerHeight: 26,

    fontSize: 12,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#005DBF',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#005DBF',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '27px',
    padding: '0 6px',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '24px',
  }),

  singleValue: provided => {
    const transition = 'opacity 300ms';
    const color = '#58595B';
    return { ...provided, transition, color };
  },
};

export const customSelectNew2 = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,

    backgroundColor: state.isDisabled
      ? '#58595B'
      : state.isSelected
        ? '#003F80'
        : state.isFocused
          ? '#ccdce4'
          : '#fff',
    color: state.isDisabled || state.isSelected ? 'white' : '#58595B',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
  }),
  control: provided => ({
    ...provided,
    border: '1.5px solid #005dbf',
    borderRadius: 5,
    height: 27,
    minHeight: 16,
    outerHeight: 26,
    innerHeight: 26,

    fontSize: 13,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '27px',
    padding: '0 6px',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '27px',
  }),

  singleValue: provided => {
    const transition = 'opacity 300ms';
    const color = '#58595B';
    return { ...provided, transition, color };
  },
};

export const ordCustomSelectGreen = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    backgroundColor: state.isDisabled
      ? '#58595B'
      : state.isSelected
        ? '#003F80'
        : state.isFocused
          ? '#ccdce4'
          : '#fff',
    color: state.isDisabled || state.isSelected ? 'white' : '#58595B',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
  }),
  control: provided => ({
    ...provided,
    border: '1.5px solid #9bdee7',
    borderRadius: 5,
    height: 32,
    minHeight: 16,
    outerHeight: 26,
    innerHeight: 26,

    fontSize: 13,
  }),
  menu: provided => ({
    ...provided,
    width: '100%',
    textAlign: 'center',
    fontSize: '17px',
    marginTop: '4.8px',
    padding: 0,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '27px',
    padding: '0 6px',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '27px',
  }),

  singleValue: provided => {
    const transition = 'opacity 300ms';
    const color = '#58595B';
    return { ...provided, transition, color };
  },
};

export const ordCustomSelect = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    overflowX: 'hidden',
    backgroundColor: state.isDisabled
      ? '#58595B'
      : state.isSelected
        ? '#003F80'
        : state.isFocused
          ? '#ccdce4'
          : '#fff',
    color: state.isDisabled || state.isSelected ? 'white' : '#58595B',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
    zIndex: 1000000,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  control: provided => ({
    ...provided,
    border: '1.5px solid #A3BAD1',
    borderRadius: 5,
    height: 32,
    minHeight: 16,
    outerHeight: 26,
    innerHeight: 26,

    fontSize: 13,
  }),
  menu: provided => ({
    ...provided,
    width: '100%',
    fontSize: '17px',
    marginTop: '4.8px',
    padding: 0,
    zIndex: 9000,
  }),

  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '27px',
    padding: '0 6px',
    // zIndex:10000
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '27px',
  }),

  singleValue: provided => {
    const transition = 'opacity 300ms';
    const color = '#58595B';
    return { ...provided, transition, color };
  },
};

export const ordCustomSelect2 = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    backgroundColor: state.isDisabled
      ? '#58595B'
      : state.isSelected
        ? '#003F80'
        : state.isFocused
          ? '#ccdce4'
          : '#fff',
    color: state.isDisabled || state.isSelected ? 'white' : '#58595B',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
  }),
  control: provided => ({
    ...provided,
    border: '1.5px solid #A3BAD1',
    borderRadius: 0,
    height: 32,
    minHeight: 16,
    outerHeight: 26,
    innerHeight: 26,
    fontSize: 13,
    transition: 'all 0.4s',
    borderTopRightRadius: '5px !important',
    borderBottomRightRadius: '5px !important',

    '&:hover': {
      backgroundColor: '#003F80',
      color: 'white',
    },

    '&:hover .css-1vookos-placeholder': {
      color: 'white',
    },

    '&:hover .css-pva82x-singleValue': {
      color: 'white',
    },

    '&:hover .css-1v68m0g-indicatorContainer': {
      color: 'white',
    },

    '&:hover .css-1qws1nf-indicatorContainer': {
      color: 'white',
    },
  }),
  menu: provided => ({
    ...provided,
    width: '100%',
    fontSize: '17px',
    marginTop: '4.8px',
    padding: 0,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '27px',
    padding: '0 6px',
    justifyContent: 'center',
    '&:hover': {
      color: 'white',
    },
  }),
  placeholder: provided => ({
    ...provided,
    '&:hover': {
      color: 'white',
    },
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '27px',
    '&:hover': {
      color: 'white',
    },
  }),

  indicatorContainer: provided => ({
    ...provided,
    '&:hover': {
      color: 'white',
    },
  }),

  singleValue: provided => {
    const transition = 'opacity 300ms';
    const color = '#58595B';
    return { ...provided, transition, color };
  },
};

export const customSelectNewDarkMulti = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    backgroundColor: state.isDisabled
      ? '#58595B'
      : state.isSelected
        ? '#7FADDE'
        : state.isFocused
          ? 'rgba(0, 93, 191, 0.05)'
          : '#fff',
    color: state.isDisabled || state.isSelected ? 'white' : 'black',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
  }),
  menu: provided => ({
    ...provided,
    zIndex: 9,
    fontSize: 12,
  }),
  control: provided => ({
    ...provided,
    border: '1px solid #7FADDE',
    borderRadius: 5,
    height: 'auto',
    minHeight: 16,
    outerHeight: 26,
    innerHeight: 26,

    fontSize: 12,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#005DBF',
    marginBottom: '0.2rem',
  }),
  valueContainer: provided => ({
    ...provided,
    height: 'auto',
    padding: '0 6px',
    marginBottom: '0.2rem',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '27px',
  }),
  singleValue: provided => {
    const transition = 'opacity 300ms';
    const color = '#58595B';
    const fontSize = '12px';
    return { ...provided, transition, color, fontSize };
  },
  noOptionsMessage: provided => ({
    ...provided,
    color: '#58595B',
  }),
};

export const customSelectNewDark = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    backgroundColor: state.isDisabled
      ? '#58595B'
      : state.isSelected
        ? '#7FADDE'
        : state.isFocused
          ? 'rgba(0, 93, 191, 0.05)'
          : '#fff',
    color: state.isDisabled || state.isSelected ? 'white' : 'black',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
  }),
  menu: provided => ({
    ...provided,
    zIndex: 9,
    fontSize: 12,
  }),
  control: provided => ({
    ...provided,
    border: '1px solid #7FADDE',
    borderRadius: 5,
    minHeight: 16,
    innerHeight: 26,
    fontSize: 12
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#005DBF',
    marginBottom: '0.2rem',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 6px',
    marginBottom: '0.2rem',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    // display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
  }),
  singleValue: provided => {
    const transition = 'opacity 300ms';
    const color = '#58595B';
    const fontSize = '12px';
    return { ...provided, transition, color, fontSize };
  },
  noOptionsMessage: provided => ({
    ...provided,
    color: '#58595B',
  }),
};

export const customSelectMultiNewDark = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,

    backgroundColor: state.isDisabled
      ? '#58595B'
      : state.isSelected
        ? '#7FADDE'
        : state.isFocused
          ? 'rgba(0, 93, 191, 0.05)'
          : '#fff',
    color: state.isDisabled || state.isSelected ? 'white' : 'black',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
  }),
  menu: provided => ({
    ...provided,
    zIndex: 9,
    fontSize: 12,
  }),
  control: provided => ({
    ...provided,
    border: '1px solid #7FADDE',
    borderRadius: 5,
    outerHeight: 26,
    innerHeight: 26,
    fontSize: 12,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#005DBF',
    marginBottom: '0.2rem',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 6px',
    marginBottom: '0.2rem',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '27px',
  }),
  singleValue: provided => {
    const transition = 'opacity 300ms';
    const color = '#58595B';
    const fontSize = '12px';
    return { ...provided, transition, color, fontSize };
  },
  noOptionsMessage: provided => ({
    ...provided,
    color: '#58595B',
  }),
};

export const customSelectNewDark2 = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,

    backgroundColor: state.isDisabled
      ? '#58595B'
      : state.isSelected
        ? '#003F80'
        : state.isFocused
          ? '#ccdce4'
          : '#fff',
    color: state.isDisabled || state.isSelected ? 'white' : 'black',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
  }),
  menu: provided => ({ ...provided, zIndex: 9 }),
  control: provided => ({
    ...provided,
    border: '1.5px solid #005DBF',
    borderRadius: 5,
    height: 27,
    minHeight: 16,
    outerHeight: 26,
    innerHeight: 26,
    maxHeight: 27,
    fontSize: 13,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '27px',
    padding: '0 6px',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '27px',
  }),

  singleValue: provided => {
    const transition = 'opacity 300ms';
    const color = '#58595B';
    const fontSize = '16px';
    return { ...provided, transition, color, fontSize };
  },
};

export const customSelectGray = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,

    backgroundColor: state.isDisabled
      ? '#58595B'
      : state.isSelected
        ? '#003F80'
        : state.isFocused
          ? '#ccdce4'
          : '#fff',
    color: state.isDisabled || state.isSelected ? 'white' : 'black',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
  }),
  control: provided => ({
    ...provided,
    border: '1.5px solid #005DBF',
    borderRadius: 5,
    height: 27,
    minHeight: 16,
    outerHeight: 26,
    innerHeight: 26,
    maxHeight: 27,
    fontSize: 13,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#808080',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '27px',
    padding: '0 6px',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '27px',
  }),

  singleValue: provided => {
    const transition = 'opacity 300ms';
    const color = '';
    const fontSize = '16px';
    return { ...provided, transition, color, fontSize };
  },
};

export const customSelectSpecialClear = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    backgroundColor: state.isSelected ? '#003F80' : '#fff',
  }),
  control: provided => ({
    ...provided,
    border: '#fff',
    borderRadius: 30,
    minHeight: 16,
    outerHeight: 26,
    innerHeight: 26,
    fontSize: 13,
    backgroundColor: '#01A0F60D',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#01A0F6',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: '27px',
    padding: '0 6px',
    backgroundColor: state.isDisabled ? '#F5F7FA' : '#fff',
    borderRadius: '5px',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '27px',
    backgroundColor: state.isDisabled ? '#F5F7FA' : '#fff',
    borderRadius: '5px',
  }),

  singleValue: (provided, state) => {
    const color = '#58595B';
    const transition = 'opacity 300ms';
    const opacity = state.isDisabled ? 0.5 : 1;
    return { ...provided, opacity, transition, color };
  },
};
export const customSelectNewGray = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    backgroundColor: state.isSelected ? '#003F80' : '#fff',
  }),
  menu: provided => ({ ...provided, zIndex: 9 }),
  control: (provided, state) => ({
    ...provided,
    border: '0.7px solid #CECECE',
    borderRadius: 5,
    height: 27,
    minHeight: 16,
    outerHeight: 26,
    innerHeight: 26,
    backgroundColor: state.isDisabled ? '#fff' : '#fff',
    fontSize: 13,
  }),
  dropdown: provided => ({
    ...provided,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#CECECE',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '27px',
    padding: '0 6px',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '27px',
  }),

  singleValue: provided => {
    const color = '#58595B';
    const transition = 'opacity 300ms';
    return { ...provided, transition, color };
  },
};
export const customSelectMonth = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    backgroundColor: state.isSelected ? '#003F80' : '#fff',
  }),
  control: provided => ({
    ...provided,
    border: '1.5px solid #005DBF',
    borderRadius: 5,
    backgroundColor: '#005DBF',

    minHeight: 16,
    outerHeight: 26,
    innerHeight: 26,

    fontSize: 13,
  }),
  placeholder: provided => ({
    ...provided,
    color: '#fff',
    width: '100%',
    textAlign: 'center',
    fontSize: '17px',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '27px',
    padding: '0 6px',
  }),
  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '27px',
  }),
  menu: provided => ({
    ...provided,
    width: '100%',
    textAlign: 'center',
    fontSize: '17px',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    const color = '#fff';
    const width = '100%';
    const textAlign = 'center';
    const fontSize = '17px';
    return {
      ...provided,
      opacity,
      transition,
      color,
      width,
      textAlign,
      fontSize,
    };
  },
};

export const customSelectTransparent = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    backgroundColor: state.isSelected ? '#003F80' : '#fff',
  }),
  control: provided => ({
    ...provided,
    border: 'none',
    borderRadius: 5,
    background: 'border-box',
    height: 27,
    minHeight: 16,
    outerHeight: 26,
    innerHeight: 26,
    maxHeight: 27,
    fontSize: 13,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '27px',
    padding: '0 6px',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '27px',
  }),

  singleValue: provided => {
    const transition = 'opacity 300ms';
    const color = '#58595B';
    const fontSize = '16px';
    return { ...provided, transition, color, fontSize };
  },
};

export const customSelectEd = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    backgroundColor: state.isSelected ? '#003F80' : '#fff',
  }),
  control: provided => ({
    ...provided,
    border: '1.5px solid #005DBF',
    borderRadius: 5,

    minHeight: 16,
    outerHeight: 26,
    innerHeight: 26,

    fontSize: 13,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: '27px',
    padding: '0 6px',
    backgroundColor: state.isDisabled ? '#F5F7FA' : '#fff',
    borderRadius: '5px',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '27px',
    backgroundColor: state.isDisabled ? '#F5F7FA' : '#fff',
    borderRadius: '5px',
  }),

  singleValue: provided => {
    const transition = 'opacity 300ms';
    const color = '#58595B';
    const fontSize = '16px';
    return { ...provided, transition, color, fontSize };
  },
};

export const customSelectMultiColor1 = {
  option: provided => ({
    ...provided,
    fontSize: 13,
    backgroundColor: 'yellow',
    color: 'red',
  }),
  control: provided => ({
    ...provided,
    border: '1.5px solid #01A2F5',
    borderRadius: 5,
    fontSize: 13,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 6px',
    backgroundColor: 'yellow',
    color: 'blue',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
    border: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
};

export const customSelectBockedYellow = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: styles => {
    return {
      ...styles,
      backgroundColor: '#FCF1E1', // Clear yellow
      color: '#6E6F7C', //Gray
      ':active': {
        ...styles[':active'],
        backgroundColor: '#FCF1E1',
      },
    };
  },
  multiValue: styles => {
    return {
      ...styles,
      backgroundColor: '#FCF1E1',
    };
  },
  multiValueLabel: styles => ({
    ...styles,
    color: '#6E6F7C',
  }),
  multiValueRemove: styles => ({
    ...styles,
    display: 'none',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    display: 'none',
  }),
};
export const tlnCustomSelect = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    backgroundColor: state.isDisabled
      ? '#58595B'
      : state.isSelected
        ? '#003F80'
        : state.isFocused
          ? '#CCDCE4'
          : '#fff',
    color: state.isDisabled || state.isSelected ? 'white' : '#58595B',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
  }),
  control: provided => ({
    ...provided,
    border: '1.5px solid #A3BAD1',
    borderRadius: 5,
    height: 32,
    minHeight: 16,
    outerHeight: 26,
    innerHeight: 26,
    fontSize: 13,
  }),
  menu: provided => ({
    ...provided,
    width: '100%',
    textAlign: 'start',
    fontSize: '17px',
    marginTop: '4.8px',
    padding: 0,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '27px',
    padding: '0 6px',
  }),
  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '27px',
  }),
  singleValue: provided => {
    const transition = 'opacity 300ms';
    const color = '#58595B';
    return { ...provided, transition, color };
  },
};

/* Select appointment */

export const appCustomSelect = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    backgroundColor: '#ffff',
    color: state.isDisabled || state.isSelected ? '#58595B' : '#58595B',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
  }),
  control: provided => ({
    ...provided,
    border: 'none',
    borderRadius: 6,
    height: 34,
    minHeight: 16,
    outerHeight: 26,
    innerHeight: 26,
    fontSize: 13,
    backgroundColor: '#f5f7fa',
  }),
  menu: provided => ({
    ...provided,
    width: '100%',
    fontSize: '17px',
    padding: 0,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '27px',
    padding: '0 6px',
  }),
  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '27px',
  }),
  singleValue: provided => {
    const transition = 'opacity 300ms';
    const color = '#58595B';
    return { ...provided, transition, color };
  },
};

export const appCustomSelectNew = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    backgroundColor: state.isDisabled
      ? '#D6E0EB'
      : state.isSelected
        ? '#D6E0EB'
        : state.isFocused
          ? '#ccdce4'
          : '#fff',
    color: state.isDisabled || state.isSelected ? '#58595B' : '#58595B',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
  }),
  control: (provided, state) => ({
    ...provided,
    border: '0.5px solid #5C84AD',
    borderRadius: 6,
    height: 36,
    minHeight: 16,
    outerHeight: 26,
    innerHeight: 26,
    fontSize: 13,
    backgroundColor: state.isDisabled ? '#e6e6e6a3' : '#FFFFFF',
  }),
  menu: provided => ({
    ...provided,
    width: '100%',
    fontSize: '17px',
    padding: 0,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#6E6F7C',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#6E6F7C',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '27px',
    padding: '0 6px',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '27px',
  }),

  singleValue: provided => {
    const transition = 'opacity 300ms';
    const color = '#58595B';
    return { ...provided, transition, color };
  },
};

export const appDarkCustomSelect = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 12,
    backgroundColor: state.isDisabled
      ? '#58595B'
      : state.isSelected
        ? '#003F80'
        : state.isFocused
          ? '#ccdce4'
          : '#F5FCFD',
    color: state.isDisabled || state.isSelected ? 'white' : '#58595B',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
  }),
  control: provided => ({
    ...provided,
    border: '1.5px solid #F5FCFD',
    borderRadius: 6,
    height: 32,
    minHeight: 16,
    outerHeight: 26,
    innerHeight: 26,
    backgroundColor: '#F5FCFD',
    color: '#A3BAD1',
    fontSize: 12,
  }),
  menu: provided => ({
    ...provided,
    width: '100%',
    fontSize: '17px',
    marginTop: '4.8px',
    padding: 0,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '27px',
    padding: '0 6px',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
    color: '#A3BAD1',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '27px',
  }),
  placeholder: provided => ({
    ...provided,
    color: '#A3BAD1',
  }),

  singleValue: provided => {
    const transition = 'opacity 300ms';
    const color = '#A3BAD1';
    return { ...provided, transition, color };
  },
};

export const appBlueSelect = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    backgroundColor: state.isDisabled
      ? '#58595B'
      : state.isSelected
        ? '#003F80'
        : state.isFocused
          ? '#ccdce4'
          : '#fff',
    color: state.isDisabled || state.isSelected ? 'white' : '#58595B',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
  }),
  control: provided => ({
    ...provided,
    border: '1.5px solid #00B4CC70',
    borderRadius: 5,
    height: 32,
    minHeight: 16,
    outerHeight: 26,
    innerHeight: 26,

    fontSize: 13,
    '&:hover': {
      borderColor: '#00B4CC70',
    },
  }),
  menu: provided => ({
    ...provided,
    width: '100%',
    fontSize: '17px',
    marginTop: '4.8px',
    padding: 0,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '27px',
    padding: '0 6px',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '27px',
  }),

  placeholder: provided => ({
    ...provided,
    color: '#A3BAD1',
  }),
  singleValue: provided => {
    const transition = 'opacity 300ms';
    const color = '#58595B';
    return { ...provided, transition, color };
  },
};

export const ordCustomSelectFranquicias = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    backgroundColor: state.isDisabled
      ? '#58595B'
      : state.isSelected
        ? '#003F80'
        : state.isFocused
          ? '#ccdce4'
          : '#fff',
    color: state.isDisabled || state.isSelected ? 'white' : '#58595B',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
  }),
  control: provided => ({
    ...provided,
    border: '0.7px solid rgba(1, 160, 246, 0.5)',
    borderRadius: 6,
    height: 30,
    minHeight: 16,
    outerHeight: 26,
    innerHeight: 26,
    fontSize: 13,
  }),
  menu: provided => ({
    ...provided,
    width: '100%',
    fontSize: '17px',
    marginTop: '4.8px',
    padding: 0,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '27px',
    padding: '0 6px',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '30px',
  }),

  singleValue: provided => {
    const transition = 'opacity 300ms';
    const color = '#58595B';
    return { ...provided, transition, color };
  },
};

export const customSelectNewDarkNewNom = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,

    backgroundColor: state.isDisabled
      ? '#58595B'
      : state.isSelected
        ? '#7FADDE'
        : state.isFocused
          ? 'rgba(0, 93, 191, 0.05)'
          : '#fff',
    color: state.isDisabled || state.isSelected ? 'white' : 'black',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
  }),
  menu: provided => ({
    ...provided,
    zIndex: 9000,
    position: 'sticky',
    overflow: 'visible',
  }),
  menuList: base => ({
    ...base,
    maxHeight: '200px', // your desired height
  }),
  control: provided => ({
    ...provided,
    border: '1px solid #7FADDE',
    borderRadius: 5,
    height: 24,
    minHeight: 16,
    outerHeight: 26,
    innerHeight: 26,
    maxHeight: 24,
    fontSize: 12,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#005DBF',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '24px',
    padding: '0 6px',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '24px',
  }),

  singleValue: provided => {
    const transition = 'opacity 300ms';
    const color = '#58595B';
    const fontSize = '13px';
    return { ...provided, transition, color, fontSize };
  },
};

export const customSelectNew4 = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,

    backgroundColor: state.isDisabled
      ? '#58595B'
      : state.isSelected
        ? '#7FADDE'
        : state.isFocused
          ? 'rgba(0, 93, 191, 0.05)'
          : '#fff',
    color: state.isDisabled || state.isSelected ? 'white' : 'black',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
  }),
  control: provided => ({
    ...provided,
    border: '1px solid #7FADDE',
    borderRadius: 5,
    height: 32,
    minHeight: 16,
    outerHeight: 26,
    innerHeight: 26,

    fontSize: 12,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#005DBF',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#005DBF',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '32px',
    padding: '0 6px',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '32px',
  }),

  singleValue: provided => {
    const transition = 'opacity 300ms';
    const color = '#58595B';
    return { ...provided, transition, color };
  },
};

export const customSelectNewGrayNew = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    backgroundColor: state.isSelected ? '#003F80' : '#fff',
  }),
  menu: provided => ({ ...provided, zIndex: 9 }),
  control: (provided, state) => ({
    ...provided,
    border: '0.7px solid #CECECE',
    borderRadius: 5,
    height: 24,
    minHeight: 16,
    outerHeight: 26,
    innerHeight: 26,
    backgroundColor: state.isDisabled ? '#fff' : '#fff',
    fontSize: 12,
  }),
  dropdown: provided => ({
    ...provided,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#CECECE',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '24px',
    padding: '0 6px',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '24px',
  }),

  singleValue: provided => {
    const color = '#58595B';
    const transition = 'opacity 300ms';
    return { ...provided, transition, color };
  },
};

export const customSelectMultiGeneral = {
  option: provided => ({
    ...provided,
    fontSize: 13,
    minHeight: 32,
  }),
  control: provided => ({
    ...provided,
    border: '1px solid #7FADDE',
    borderRadius: 5,
    fontSize: 13,
    minHeight: 32,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 6px',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
};

export const customSelectMultiBig = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    overflowX: 'hidden',
    backgroundColor: state.isDisabled
      ? '#58595B'
      : state.isSelected
        ? '#7FADDE'
        : state.isFocused
          ? 'rgba(0, 93, 191, 0.05)'
          : '#fff',
    color: state.isDisabled || state.isSelected ? 'white' : '#58595B',
  }),
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #7FADDE',
    borderRadius: 5,
    fontSize: 13,
    minHeight: 32,
    background: state.isDisabled && '#ebf1f7',
  }),
  placeholder: provided => ({
    ...provided,
    marginTop: 2,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 6px',
    minHeight: 24,
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
  }),

  singleValue: provided => {
    const transition = 'opacity 300ms';
    const color = '#58595B';
    return { ...provided, transition, color };
  },
};

export const customSelectAdmin = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    backgroundColor: state.isDisabled
      ? '#58595B'
      : state.isSelected
        ? '#7FADDE'
        : state.isFocused
          ? 'rgba(0, 93, 191, 0.05)'
          : '#fff',
    color: state.isDisabled || state.isSelected ? 'white' : '#58595B',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
  }),
  control: (provided, state) => ({
    ...provided,
    border: '1.5px solid #A3BAD1',
    borderRadius: 5,
    height: 32,
    minHeight: 16,
    outerHeight: 26,
    innerHeight: 26,
    fontSize: 13,
    background: state.isDisabled && '#ebf1f7',
  }),
  menu: provided => ({
    ...provided,
    width: '100%',
    fontSize: '17px',
    marginTop: '4.8px',
    padding: 0,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '27px',
    padding: '0 6px',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '27px',
  }),

  singleValue: provided => {
    const transition = 'opacity 300ms';
    const color = '#58595B';
    return { ...provided, transition, color };
  },
};

export const selectMultiple = {
  option: (provided, { isSelected, isDisabled }) => ({
    ...provided,
    fontSize: 13,
    color: '#58595b',
    cursor: 'pointer',
    overflowX: 'hidden',
    backgroundColor: isDisabled ? undefined : isSelected ? '' : '',
    '&:hover': {
      backgroundColor: '#cccccc6b',
    },
  }),
  menu: provided => ({
    ...provided,
    width: '100%',
    fontSize: '17px',
    marginTop: '4.8px',
    padding: 0,
    overflow: 'auto',
  }),
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #7FADDE',
    background: state.isDisabled ? '#fafafa' : '',
    borderRadius: 5,
    fontSize: 13,
    height: '33px',
    minHeight: '33px',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 6px',
    overflow: 'auto',
    height: '30px',
    minHeight: '30px',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
};

export const selectMultipleSmall = {
  option: (provided, { isSelected, isDisabled }) => ({
    ...provided,
    fontSize: 13,
    color: '#58595b',
    cursor: 'pointer',
    overflowX: 'hidden',
    backgroundColor: isDisabled ? undefined : isSelected ? '' : '',
    '&:hover': {
      backgroundColor: '#cccccc6b',
    },
  }),
  menu: provided => ({
    ...provided,
    width: '100%',
    fontSize: '17px',
    marginTop: '4.8px',
    padding: 0,
  }),
  control: provided => ({
    ...provided,
    border: '1px solid #7FADDE',
    borderRadius: 5,
    fontSize: 13,
    minHeight: '31px',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 6px',
    minHeight: '27px',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
};

export const selectMultipleVerySmall = {
  option: (provided, { isSelected, isDisabled }) => ({
    ...provided,
    fontSize: 13,
    // backgroundColor: 'white',
    color: '#58595b',
    cursor: 'pointer',
    overflowX: 'hidden',
    backgroundColor: isDisabled ? undefined : isSelected ? '' : '',

    '&:hover': {
      backgroundColor: '#cccccc6b',
    },
  }),
  menu: provided => ({
    ...provided,
    width: '100%',
    fontSize: '17px',
    marginTop: '4.8px',
    padding: 0,
  }),
  control: provided => ({
    ...provided,
    border: '1px solid #7FADDE',
    borderRadius: 5,
    fontSize: 13,
    minHeight: '24px',
    height: '24px',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  clearIndicator: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#003F80',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 6px',
    minHeight: '24px',
    height: '24px',
  }),

  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: provided => ({
    ...provided,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  },
};
