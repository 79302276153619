import { useCallback, useEffect, useState } from 'react';
import { API_VERSION, URL_GATEWAY } from '../helpers/constants';
import { convertFilterToString } from '../helpers/convertToFilter';
import { isEmptyOrUndefined, message } from '../helpers/helpers';
import { customSwaltAlert } from '../helpers';

export const useGetMethod = () => {
  const [results, setResults] = useState({});
  const [load, setLoad] = useState(false);

  const fetchGet = async configJson => {
    let { url, objFilters, array, token, doAfterSuccess, succesAction, doAfterException, completeURL } =
      configJson;
    const filters = array ? array : convertFilterToString(objFilters);
    if (isEmptyOrUndefined(url) && isEmptyOrUndefined(completeURL)) {
      setLoad(false);
      return message(
        'warning',
        'Parámetro url no encontrado',
        'El parámetro url es obligatorio en el objeto de configuración',
      );
    }
    if (isEmptyOrUndefined(token)) {
      setLoad(false);
      return message(
        'warning',
        'Parámetro token no encontrado',
        'El parámetro token es obligatorio en el objeto de configuración',
      );
    }
    setLoad(true);
    try {
      const newURL = completeURL ?? `${URL_GATEWAY}${API_VERSION}${url}?${filters}`
      const query = await fetch(newURL, {
        method: 'GET',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      });
      let results = await query.json();
      if (results?.success || results?.data?.length || results?.results?.length) {
        setLoad(false);
        setResults(results);
        !!doAfterSuccess && doAfterSuccess(results);
        !!succesAction && succesAction(results);
        return results;
      } else {
        setLoad(false);
        doAfterException
          ? doAfterException(results)
          : console.log('warning', results.title || 'Advertencia', results.message);
        return results;
      }
    } catch (error) {
      setLoad(false);
      console.error(error.message);
      return message('error', 'Error', error?.message ? error?.message : 'Ha ocurrido un error');
    }
  };
  const trigger = useCallback(configJson => {
    return fetchGet(configJson);
  }, []);
  return { load, results, trigger };
};

export const usePostMethod = () => {
  const [results, setResults] = useState({});
  const [load, setLoad] = useState(false);
  useEffect(() => {
    setResults({});
  }, []);

  const fetchPost = async fetchProps => {
    let {
      url,
      token,
      method,
      body,
      succesAction,
      doAfterException,
      doAfterSuccess,
      noAlert = false,
      noErrorAlert = false,
    } = fetchProps;
    if (isEmptyOrUndefined(url)) {
      setLoad(false);
      return message(
        'warning',
        'Parámetro url no encontrado',
        'El parámetro url es obligatorio en el objeto de configuración',
      );
    }
    if (isEmptyOrUndefined(token)) {
      setLoad(false);
      return message(
        'warning',
        'Parámetro token no encontrado',
        'El parámetro token es obligatorio en el objeto de configuración',
      );
    }
    setLoad(true);
    try {
      const query = await fetch(`${URL_GATEWAY}${API_VERSION}${url}`, {
        method: method,
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(fetchProps.body ? fetchProps.body : body),
      });
      let results = await query.json();
      if (results?.success || results.status === 200) {
        setLoad(false);
        setResults(results);
        !!doAfterSuccess && doAfterSuccess(results);
        return succesAction
          ? succesAction(results)
          : !noAlert &&
              customSwaltAlert({
                showCancelButton: false,
                icon: 'success',
                title: results.title ?  results.title : results.message,
                text: results.title ? results.message : '',
                confirmButtonText: 'Aceptar',
              });
      } else {
        setLoad(false);
        return doAfterException
          ? doAfterException(results)
          : !noErrorAlert &&
              customSwaltAlert({
                showCancelButton: false,
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: results.message,
                confirmButtonText: 'Aceptar',
              });
      }
    } catch (error) {
      setLoad(false);
      console.log('error: ', error);
      return message('error', 'Error', 'Ha ocurrido un error POST');
    }
  };

  const trigger = useCallback(fetchProps => {
    fetchPost(fetchProps);
  }, []);
  return { load, results, trigger };
};

export const useMultiGetMethod = () => {
  const [results, setResults] = useState({});
  const [load, setLoad] = useState(false);

  const fetchMultiGet = configJson => {
    let { multipleGet, token } = configJson;
    if (multipleGet.length <= 0) {
      setLoad(false);
      return message(
        'warning',
        'Parámetro multipleGet no encontrado',
        'El parámetro url es obligatorio en el objeto de configuración',
      );
    }
    if (isEmptyOrUndefined(token)) {
      setLoad(false);
      return message(
        'warning',
        'Parámetro token no encontrado',
        'El parámetro token es obligatorio en el objeto de configuración',
      );
    }

    let uniqueResult = {};
    multipleGet?.forEach((e, i) => {
      const uniqueGet = async e => {
        setLoad(true);
        try {
          const filters = convertFilterToString(e.objFilters);
          const query = await fetch(`${URL_GATEWAY}${API_VERSION}${e.url}?${filters}`, {
            method: 'GET',
            headers: {
              Authorization: token,
              'Content-Type': 'application/json',
            },
          });
          let results = await query.json();

          if (e?.requestName) {
            uniqueResult[e?.requestName] = results;
          } else {
            uniqueResult[e?.url + `  -#${i}`] = results;
          }
          !!e?.doAfterSuccess && e?.doAfterSuccess(uniqueResult[e?.requestName]);
          setLoad(false);
        } catch (error) {
          setLoad(false);
          console.error(error.message);
          return message('error', 'Error', 'Ha ocurrido un error');
        }
      };
      uniqueGet(e);
    });
    setResults(uniqueResult);
    return uniqueResult;
  };
  const trigger = useCallback(configJson => {
    return fetchMultiGet(configJson);
  }, []);
  return { load, results, trigger };
};
