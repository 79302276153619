import * as deepcopy from 'deepcopy';
import { Component } from 'react';
import ReactCodeInput from 'react-code-input';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { connect } from 'react-redux';
import Select from 'react-select';
import 'reactjs-popup/dist/index.css';
import {
  changeStatus,
  cleanNiifAccountLinked,
  createPuc,
  getAllBanks,
  getAllPUC,
  getAllPUCAccounts,
  getLocalAccounts,
  getNiifAccounts,
  getNiifByNumber,
  updatePuc,
} from '../../actions/pucActions';
import Add from '../../assets/img/icons/CrearCuentaOscuro.svg';
import Export from '../../assets/img/icons/excel.svg';
import SearchIcon from '../../assets/img/icons/lupa.svg';
import SearchFileIcon from '../../assets/img/icons/SearchLocal.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';
import { customSwaltAlert, isEmptyOrUndefined } from '../../helpers';
import { PAGE, PERPAGE } from '../../helpers/constants';
import GenericTableNew from '../Layouts/GenericTableNew';
import ModalNew from '../Layouts/ModalNew';
import paginationStyles from '../Layouts/pagination.module.scss';
import { ordCustomSelect } from '../Layouts/react-select-custom';
import tableStyles from '../Layouts/tableStyle.module.scss';
import CustomPopup from '../Popup/customPopUpExtends';
import './customReactCodeInput.scss';
import PucStyles from './Puc_CSS.module.scss';

const INITIAL_PUC_STATE = {
  id: '',
  code: '',
  newCode: '',
  description: '',
  niifLinked: '',
  niifDescription: '',
  uses_third: false,
  uses_cost_center: false,
  type: '',
  check_format: '',
  account_type: '',
  name_bank: '',
  num_count: '',
  check_info_bank: false,
  detail: false,
};

class TablePuc extends Component {
  constructor(props) {
    super(props);

    this.state = {
      titleModal: 'Crear cuenta contable',
      modal: false,
      editRecord: false,
      addPuc: { ...INITIAL_PUC_STATE, entity_account: this.props.currentAccount.id },
      totalIndex: 0,
      filters: {
        search: '',
        active: false,
        perpage: PERPAGE,
        page: PAGE,
        eaccount: this.props.currentAccount.id,
      },
      headerState: [
        {
          tittle: 'No. de cuenta',
          className: 'text-start px-2',
        },
        {
          tittle: 'Nombre',
          className: 'text-start',
        },
        {
          tittle: 'Tipo',
          className: 'text-center',
        },
        {
          tittle: 'No. Bancario',
          className: 'text-center',
        },
        {
          tittle: 'Estado',
          className: 'text-center w100',
        },
      ],
    };
  }

  myPermission = () =>
    this.props.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === 'PUC');
  componentDidMount() {
    this.props.getAllBanks({ eaccount: this.props.currentAccount.id });
    const { history } = this.props;
    if (!this.myPermission()?.read) {
      history?.push('/compras/inicio');
    }
    this.props.getAllPUC(this.state.filters);
    this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.allAccounts !== prevProps.allAccounts) {
      this.setState({
        allAccounts: this.props.allAccounts,
      });
    }
  }

  getInitialState = () => {
    this.setState({
      titleModal: 'Crear cuenta contable',
      modal: false,
      editRecord: false,
      addPuc: {
        id: '',
        code: '',
        newCode: '',
        description: '',
        niifLinked: '',
        niifDescription: '',
        uses_third: false,
        uses_cost_center: false,
        type: '',
        check_format: '',
        account_type: '',
        name_bank: '',
        num_count: '',
        check_info_bank: false,
        entity_account: this.props.currentAccount.id,
        detail: false,
      },
      totalIndex: 0,
      filters: {
        search: '',
        active: '',
        perpage: PERPAGE,
        page: PAGE,
        eaccount: this.props.currentAccount.id,
      },
      headerState: [
        {
          tittle: 'No. de cuenta',
          className: 'text-start px-2',
        },
        {
          tittle: 'Nombre',
          className: 'text-start',
        },
        {
          tittle: 'Tipo',
          className: 'text-center',
        },
        {
          tittle: 'No. Bancario',
          className: 'text-center',
        },
        {
          tittle: 'Estado',
          className: 'text-center w100',
        },
      ],
    });
  };

  handlePageChange(pageNumber) {
    let { filters } = this.state;
    filters.page = pageNumber;
    this.setState({ filters });
    this.props.getAllPUC(this.state.filters);
  }

  handleEditRecord = puc => {
    let { modal, addPuc, titleModal } = this.state;
    modal = !this.state.modal;
    addPuc = deepcopy(puc);
    addPuc.code = addPuc.complete_account;
    if (addPuc.niifAccount) {
      addPuc.niifLinked = addPuc.niifAccount;
      this.props.getNiifByNumber(addPuc.niifLinked);
    }
    titleModal = 'Editar cuenta contable';
    this.setState({ modal, addPuc, editRecord: true, titleModal });
    if (addPuc.type === 'local') {
      if (addPuc.niifAccount) {
        setTimeout(async () => {
          let niif = this.props.niifAccounts;
          addPuc.niifDescription = niif.description;
          await this.setState({
            addPuc,
          });
        }, 500);
      }
    }
  };

  handleShowDetails = puc => {
    let { modal, addPuc, titleModal } = this.state;
    modal = !this.state.modal;
    addPuc = deepcopy(puc);
    addPuc.code = addPuc.complete_account;
    addPuc['detail'] = true;
    if (addPuc?.niifAccount) {
      addPuc.niifLinked = addPuc.niifAccount;
      this.props.getNiifByNumber(addPuc.niifLinked);
    }
    titleModal = 'Detalle de cuenta contable';
    this.setState({ modal, addPuc, titleModal });
    if (addPuc.type === 'local') {
      if (addPuc.niifAccount) {
        setTimeout(async () => {
          let niif = this.props.niifAccounts;
          addPuc.niifDescription = niif.description;
          await this.setState({
            addPuc,
          });
        }, 500);
      }
    }
  };

  toggleStatus = account => {
    this.props.changeStatus(account);
  };

  onValueSelectBank = bankLabel => {
    if (bankLabel) {
      return this.props.allBank
        .map(el => ({ ...el, label: el.description, value: el.value }))
        .find(bank => bank.description === bankLabel);
    }
    return undefined;
  };

  // Search puc when user was typing
  onKeyUpSearch = e => {
    const value = e.target.value;
    let { filters } = this.state;
    filters.search = value;
    if (e.keyCode === 13) {
      filters.page = 1;
      this.setState({ filters });
      this.props.getAllPUC(this.state.filters);
    } else {
      e.preventDefault();
    }
    this.setState({ filters });
  };

  //Method to search when click in magnify lens
  handleSearch = () => {
    let { filters } = this.state;
    filters.page = 1;
    this.props.getAllPUC(this.state.filters);
  };

  // Search niff linked
  handleSearchNiifAccount = () => {
    this.props.getNiifByNumber(this.state.addPuc.niifLinked);
    let { addPuc } = this.state;
    setTimeout(() => {
      let niif = this.props.niifAccounts;
      if (niif.id !== null) {
        addPuc.niifDescription = niif.description;
        if (addPuc.niifDescription !== niif.description) {
          return this.handleSearchNiifAccount();
        } else {
          this.setState({ addPuc });
        }
      } else {
        addPuc.niifDescription = 'NO SE ENCONTRÓ LA CUENTA NIIF';
        this.setState({ addPuc });
      }
    }, 1000);
  };

  toggleModal = () => {
    let { modal } = this.state;
    modal = !modal;
    this.props.cleanNiifAccountLinked();
    if (this.state.editRecord) {
      this.setState({ modal, addPuc: { ...INITIAL_PUC_STATE, entity_account: this.props.currentAccount.id } });
    }
    this.setState({
      modal,
      titleModal: 'Crear cuenta contable',
      addPuc: { ...INITIAL_PUC_STATE, entity_account: this.props.currentAccount.id },
    });
  };

  onChangeForm = e => {
    var { name, value } = e.target;
    let { addPuc } = this.state;
    if (name === 'niifLinked') {
      var regex = /^[A-Za-z]+$/;
      if (String(value).match(regex) || isNaN(value)) {
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'El campo solo acepta texto',
          showCancelButton: false,
        });
      }
    }
    addPuc[name] = value;
    this.setState({ addPuc });
  };

  onChangeCheckBox = e => {
    var { name } = e.target;
    let { addPuc } = this.state;

    if (name === 'check_info_bank') {
      addPuc[name] = !addPuc[name];
      addPuc['name_bank'] = '';
      addPuc['bankId'] = '';
      addPuc['num_count'] = '';
      addPuc['account_type'] = '';
      this.setState({ addPuc });
    } else {
      addPuc[name] = !addPuc[name];
      this.setState({ addPuc });
    }
  };

  onChangeAccountNumber = value => {
    let { addPuc } = this.state;
    if (this.state.editRecord) {
      addPuc.newCode = value;
      addPuc.code = value; 
    } else {
      addPuc.code = value;
    }
    this.setState({ addPuc });
  };

  handleSubmit() {
    if (this.state.editRecord) {
      if (this.state.addPuc.check_info_bank) {
        delete this.state.addPuc.active;
        return this.props.updatePuc(this.state.addPuc,this.toggleModal);
      } else {
        let { addPuc } = this.state;
        addPuc.account_type = '';
        addPuc.name_bank = '';
        addPuc.num_count = '';
        addPuc.entity_account = this.props.currentAccount.id;
        this.setState({ addPuc });
        delete this.state.addPuc.active;
        return this.props.updatePuc(this.state.addPuc,this.toggleModal);
      }
    }
    this.props.createPuc(this.state.addPuc, this.toggleModal);
  }

  renderAccounts(account) {
    return (
      <tr key={`${account.id}${account.type}`} className='hover-table-row text-center align-middle'>
        <td className='col-md-2 text-start px-2'>{account.complete_account} </td>
        <td className='col-md-6 text-start'>{account.description}</td>
        <td className='col-md-1 text-uppercase'>{account.type}</td>
        <td className='col-md-2 text-center'>
          {!!account.num_count && account.num_count !== 'None' ? account.num_count : '-'}
        </td>
        <td className='col-md-1'>
          <div className={`${tableStyles.groupElems}`}>
            <div className={account.active ? tableStyles.greenState : tableStyles.redState}>
              {account.active ? 'Habilitado' : 'Inhabilitado'}
            </div>
            <td className='position-relative mx-2' style={{ paddingLeft: '0.5rem' }}>
              <CustomPopup
                triggerSrc={threeDots}
                showEdit={this.myPermission()?.edit}
                editClickEvent={() => this.handleEditRecord(account)}
                showDetailsEvent={() => this.handleShowDetails(account)}
                showDetails={this.myPermission()?.read}
                showEnable={this.myPermission()?.edit}
                isEnabled={account.active}
                enableClickEvent={() => this.toggleStatus(account)}
                noHover
              />
            </td>
          </div>
        </td>
      </tr>
    );
  }
  validateBoton() {
    let disableModalButton = false;
    if (this.state.addPuc?.check_info_bank) {
      if (
        isEmptyOrUndefined(this.state.addPuc.code) ||
        isEmptyOrUndefined(this.state.addPuc.account_type) ||
        isEmptyOrUndefined(this.state.addPuc.num_count) ||
        isEmptyOrUndefined(this.state.addPuc.name_bank) ||
        isEmptyOrUndefined(this.state.addPuc.description) ||
        isEmptyOrUndefined(this.state.addPuc.type)
      ) {
        disableModalButton = true;
      }
    } else {
      if (
        isEmptyOrUndefined(this.state.addPuc.code) ||
        isEmptyOrUndefined(this.state.addPuc.type) ||
        isEmptyOrUndefined(this.state.addPuc.description)
      ) {
        disableModalButton = true;
      }
    }

    return disableModalButton;
  }
  render() {
    let renderHeaders = this.state.headerState.map((header, key) => {
      return (
        <th key={key}>
          <div
            className={`${header === 'est.' || header === '' ? tableStyles.groupElems : ''} ${header.className}`}
          >
            {header.tittle}
          </div>
        </th>
      );
    });
    let renderAccounts = this.props.allAccounts
      ? this.props.allAccounts.map(account => {
          return this.renderAccounts(account);
        })
      : '';

    const handleSumit = e => {
      e.preventDefault();
      let { filters } = this.state;
      filters.page = 1;
      this.props.getAllPUC(this.state.filters);
    };
    return (
      <div className={tableStyles.container}>
        {this.props.loading && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Plan único de cuenta</h1>
          <div className=''>
            <div className=' flex-direction-row widtDiv align-middle'>
              {this.myPermission()?.read && (
                <form onSubmit={handleSumit} style={{ display: 'contents' }}>
                  <input
                    className={tableStyles.searchUsersAlt}
                    type='text'
                    placeholder='  Buscar...'
                    value={this.state.filters.search}
                    onChange={e => this.onKeyUpSearch(e)}
                  />
                  <img
                    src={SearchIcon}
                    alt='buscar'
                    className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
                    onClick={this.handleSearch}
                  />
                </form>
              )}

              {this.myPermission()?.read && (
                <img
                  src={Export}
                  alt='exportar'
                  className={`float-right ${PucStyles.buttonExcel} ${PucStyles.btnNewHover} ${PucStyles.imagePadding} align-self-center`}
                  style={{ marginTop: '7px' }}
                />
              )}

              {this.myPermission()?.create && (
                <div
                  className={`mx-3 ${PucStyles.buttonCreatCount} align-items-center`}
                  onClick={this.toggleModal}
                >
                  <p
                    className={`${PucStyles.labelNew}`}
                    style={{ fontWeight: '900px', fontSize: '16px', paddingRight: '0.2rem' }}
                  >
                    Crear cuenta{' '}
                  </p>
                  <img
                    src={Add}
                    alt='añadir'
                    className={`${PucStyles.btnNewHover} ${PucStyles.imagePadding}`}
                  />
                </div>
              )}
            </div>
          </div>

          <ModalNew
            hideFooter={
              this.state.editRecord ? !this.myPermission()?.edit : !this.myPermission()?.create
            }
            title={this.state.titleModal}
            bodyStyles={{ maxHeight: '525px', overflow: 'scroll' }}
            show={this.state.modal}
            btnYesEvent={!this.state.addPuc.detail ? () => this.handleSubmit() : null}
            btnNoName={!this.state.addPuc.detail ? 'Cancelar' : 'Cancelar'}
            btnYesName='Aceptar'
            btnYesDisabled={this.validateBoton()}
            onHide={() => this.toggleModal(false)}
          >
            <div className='row justify-content-center'>
              <div className=' justify-content-center mx-auto'>
                <p className={`float-left labelNumberAccount ${PucStyles.label_inputs_new}`}>
                  No. de cuenta<span className={'text-warning'}>*</span>
                </p>
                <ReactCodeInput
                  type='number'
                  fields={8}
                  value={this.state.addPuc.code}
                  name='code'
                  disabled={!!this.state.addPuc.detail}
                  onChange={this.onChangeAccountNumber}
                  inputStyle={{
                    backgroundColor: 'rgba(0, 93, 191, 0.1)',
                    border: '1px solid rgba(0,0,0,0.05)',
                    borderRadius: '6px',
                    padding: '1rem 0.5rem',
                    margin: '0.1rem',
                    fontSize: '1.5rem',
                    textAlign: 'center',
                  }}
                />

                <div className={`d-flex justify-content-araund ${PucStyles.divAjuste}`}>
                  <p className={`${PucStyles.divAjuste2} ${PucStyles.label_inputs_new2}`}>Clase</p>
                  <p className={`${PucStyles.divAjuste2} ${PucStyles.label_inputs_new2}`}>Grupo</p>
                  <p className={`${PucStyles.divAjuste3} ${PucStyles.label_inputs_new2}`}>Cuenta</p>
                  <p className={`${PucStyles.divAjuste3} ${PucStyles.label_inputs_new2}`}>
                    Subcuenta
                  </p>
                  <p className={`${PucStyles.divAjuste3} ${PucStyles.label_inputs_new2}`}>
                    Auxiliar
                  </p>
                </div>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='mx-auto'>
                <p className={`${PucStyles.label_inputs_new}`}>
                  Nombre<span className={'text-warning'}>*</span>
                </p>
                <textarea
                  rows='10'
                  placeholder='Escribir...'
                  cols='10'
                  className={`${PucStyles.register_inputs_new} p-1 ${tableStyles.outlineNone} px-2`}
                  style={{ color: '#58595b' }}
                  name='description'
                  type='text'
                  onChange={this.onChangeForm}
                  disabled={!!this.state.addPuc.detail}
                  value={this.state.addPuc.description || ''}
                />
              </div>
              <div className={` mt-3 mx-5 p-3`}>
                <p className={`${PucStyles.titulo}`}>Tipo de cuenta</p>
                <div className='d-flex justify-content-flex-start'>
                  <div className='form-check mx-1'>
                    <input
                      className='form-check-input styleCheckInputRatio'
                      type='radio'
                      name='type'
                      id='radioNiifSelect'
                      value='niif'
                      onChange={this.onChangeForm}
                      checked={this.state.addPuc.type === 'niif' ? true : false}
                      disabled={!!this.state.addPuc.detail}
                    />
                    <label
                      className={`form-check-label ${PucStyles.label_inputs_new3}`}
                      htmlFor='radioNiifSelect'
                    >
                      NIIF
                    </label>
                  </div>
                  <div className='form-check mx-3'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='type'
                      id='radioLocalSelect'
                      value='local'
                      onChange={this.onChangeForm}
                      checked={this.state.addPuc.type === 'local' ? true : false}
                      disabled={!!this.state.addPuc.detail}
                    />
                    <label
                      className={`form-check-label ${PucStyles.label_inputs_new3}`}
                      htmlFor='radioLocalSelect'
                    >
                      Local
                    </label>
                  </div>
                </div>
                {this.state.addPuc.type === 'local' && (
                  <div className='d-flex mx-auto justify-content-center'>
                    <div className='col-md-4   mt-3 mx-1 align-items-center'>
                      <p className={` ${PucStyles.label_inputs_new1}`}>Núm. de cuenta</p>
                      <input
                        className={` ${PucStyles.registerInputs}`}
                        name='niifLinked'
                        placeholder='Escribir...'
                        type='text'
                        maxLength={8}
                        onChange={this.onChangeForm}
                        value={this.state.addPuc.niifLinked || ''}
                        disabled={!!this.state.addPuc.detail}
                      />
                    </div>
                    {this.state.addPuc.detail ? (
                      <></>
                    ) : (
                      <img
                        src={SearchFileIcon}
                        alt='buscar cuenta'
                        className='icons-svg-top-table float-left hoverPointer mr-5'
                        onClick={
                          this.state.addPuc.niifLinked !== '' ? this.handleSearchNiifAccount : null
                        }
                        style={{
                          marginTop: 'auto',
                          maxWidth: '1.7rem',
                          cursor: this.state.addPuc.niifLinked !== '' ? 'pointer' : 'not-allowed',
                          filter: this.state.addPuc.niifLinked !== '' ? 'none' : 'grayscale(80%)',
                        }}
                      />
                    )}
                    <div className='mt-3 mx-1'>
                      <p className={` ${PucStyles.label_inputs_new1}`}>
                        Cuenta de Homologación NIIF<span className={'text-warning'}>*</span>
                      </p>
                      <input
                        className={` ${PucStyles.registerInputs}`}
                        name='niifDescription'
                        type='text'
                        placeholder='...'
                        disabled={true}
                        value={this.state.addPuc.niifDescription || ''}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className='d-flex  mt-3'>
                <div className='form-check mx-2'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    name='uses_third'
                    id='uses_third'
                    onChange={this.onChangeCheckBox}
                    checked={this.state.addPuc.uses_third}
                    disabled={!!this.state.addPuc.detail}
                  />
                  <label
                    className={`form-check-label ${PucStyles.label_inputs_new3}`}
                    htmlFor='uses_third'
                  >
                    Utiliza terceros
                  </label>
                </div>
                <div className='form-check mx-3'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    name='uses_cost_center'
                    id='usesCenterCost'
                    onChange={this.onChangeCheckBox}
                    checked={this.state.addPuc.uses_cost_center}
                    disabled={!!this.state.addPuc.detail}
                  />
                  <label
                    className={`form-check-label ${PucStyles.label_inputs_new3}`}
                    htmlFor='usesCenterCost'
                  >
                    Exige centro de costo
                  </label>
                </div>
              </div>
              <div className='d-flex  mt-3'>
                <div className='form-check mx-2'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    name='check_info_bank'
                    id='usesInfoBank'
                    onChange={this.onChangeCheckBox}
                    checked={this.state.addPuc.check_info_bank}
                    disabled={!!this.state.addPuc.detail}
                  />
                  <label
                    className={`form-check-label ${PucStyles.label_inputs_new3}`}
                    htmlFor='usesInfoBank'
                  >
                    Contiene Información bancaria
                  </label>
                </div>
              </div>

              {this.state.addPuc.check_info_bank ? (
                <div className={'mt-3'}>
                  <p className={` ${PucStyles.titulo}`}> Información Bancaria</p>
                  <div className='mx-auto' style={{ width: '104%' }}>
                    <p className={` ${PucStyles.label_inputs_new}`}>
                      Nombre del banco<span className={'text-warning'}>*</span>
                    </p>

                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      className='text-secondary w-100'
                      placeholder={'Seleccionar...'}
                      styles={ordCustomSelect}
                      isDisabled={this.state.addPuc.detail}
                      value={this.onValueSelectBank(this.state.addPuc.name_bank)}
                      options={this.props?.allBank?.map(bank => ({
                        ...bank,
                        label: bank.description,
                        value: bank.id,
                      }))}
                      onChange={e => {
                        if (e.value) {
                          let { label, value } = e;
                          let { addPuc } = this.state;
                          addPuc['name_bank'] = label;
                          addPuc['bankId'] = value;
                          this.setState({ addPuc });
                        } else {
                          let { addPuc } = this.state;
                          addPuc['name_bank'] = '';
                          addPuc['bankId'] = '';
                          this.setState({ addPuc });
                        }
                      }}
                    />
                  </div>
                  <div className={`d-flex ${PucStyles.justify} gap-2 mt-2`}>
                    <div className={` ${PucStyles.flexDiv}  ${PucStyles.flexDiv_margin}`}>
                      <p className={` ${PucStyles.label_inputs_new}`}>Número de Cta.</p>
                      <input
                        className={` flexDiv__flexSelectInput ${PucStyles.register_inputs_new} px-2`}
                        name='num_count'
                        type='number'
                        placeholder='Escribir...'
                        maxLength={11}
                        onChange={this.onChangeForm}
                        value={this.state.addPuc.num_count}
                        disabled={!!this.state.addPuc.detail}
                      />
                    </div>

                    <div className='flexDiv'>
                      <p className={`${PucStyles.label_inputs_new}`}>Tipo de Cta.</p>

                      <select
                        name='account_type'
                        className={` flexDiv__flexSelectInput ${PucStyles.register_inputs_new} px-2`}
                        onChange={this.onChangeForm}
                        disabled={!!this.state.addPuc.detail}
                        value={this.state.addPuc.account_type}
                      >
                        <option value=''>Seleccionar...</option>
                        <option value='Corriente'>Corriente</option>
                        <option value='Fondos_de_inversión'>Fondos de inversión</option>
                        <option value='Ahorros'>Ahorros</option>
                        <option value='Fiduciarias'>Fiduciarias</option>
                      </select>
                    </div>
                  </div>
                </div>
              ) : (
                <span></span>
              )}
            </div>
          </ModalNew>

          {this.myPermission()?.read && (
            <GenericTableNew
              fontFamilyTable={'fontFamilyTable'}
              dark={true}
              headers={renderHeaders}
            >
              {renderAccounts}
            </GenericTableNew>
          )}

          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {this.props.totalIndexPuc ? this.state.filters.page : ''}
              {' de '}
              {Math.ceil(this.props.totalIndexPuc / this.state.filters.perpage)
                ? Math.ceil(this.props.totalIndexPuc / this.state.filters.perpage)
                : ''}{' '}
              ({this.props.totalIndexPuc} encontrados)
            </p>
            <Pagination
              activePage={this.state.filters.page}
              itemsCountPerPage={10}
              totalItemsCount={this.props.totalIndexPuc}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange.bind(this)}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuth: state.loginReducer.Authenticated,
  allBank: state.bankReducer,
  currentAccount: state.loginReducer.currentAccount,
  allAccounts: state.pucReducer.allAccounts,
  niifAccounts: state.pucReducer.niifAccounts,
  localAccounts: state.pucReducer.localAccounts,
  totalIndexAccount: state.pucReducer.totalIndexAccount,
  createSucces: state.pucReducer.createSucces,
  totalIndexPuc: state.pucReducer.totalIndexPuc,
  loading: state.pucReducer.puc_loading,
});

const mapDispatchToProps = dispatch => ({
  getAllPUCAccounts: filters => {
    dispatch(getAllPUCAccounts(filters));
  },
  getAllBanks: filters => {
    dispatch(getAllBanks(filters));
  },
  getNiifAccounts: async filters => {
    await dispatch(getNiifAccounts(filters));
  },
  getLocalAccounts: async filters => {
    await dispatch(getLocalAccounts(filters));
  },
  changeStatus: status => {
    dispatch(changeStatus(status));
  },
  createPuc: (objPuc, toggleModal) => {
    dispatch(createPuc(objPuc, toggleModal));
  },
  updatePuc: (objPuc, doAfter) => {
    dispatch(updatePuc(objPuc, doAfter));
  },
  getNiifByNumber: number => {
    dispatch(getNiifByNumber(number));
  },
  cleanNiifAccountLinked: () => {
    dispatch(cleanNiifAccountLinked());
  },
  getAllPUC: filters => {
    dispatch(getAllPUC(filters));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(TablePuc);
