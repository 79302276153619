export const getExternalUrl = ({loginReducer, category, myPayroll}) =>{
    const newModules = ['Tesorería'];

    const {Authorization, user_data, currentAccount} = loginReducer;

    const fullName = `${loginReducer?.user_data?.first_name || ''} ${loginReducer?.user_data?.first_surname || ''} ${loginReducer?.user_data?.second_surname || ''}`;
    const currentCategory = loginReducer?.all_categories?.find(
        cat => cat?.name === (category?.title || category?.name),
    );

    const userData = loginReducer.user_data;
    const hasMyPayroll = `myPayroll=${myPayroll ? 1 : 0}`;
    const idModule = `idModule=${currentCategory?.id}`;
    const nameModule = `nameModule=${currentCategory?.name}`;
    const token = `token=${Authorization}`;
    const idAccount = `idAccount=${currentAccount?.id}`;
    const idUser = `idUser=${user_data?.id}`;
    const idProfile = `idProfile=${currentAccount.profile.id}`;
    const userImage = `userImage=${userData?.image}`;
    const fullNameUser = `fullNameUser=${fullName}`;
    const url = category?.url;
    const urlBase = `${url}${url?.charAt(url.length - 1) === '/' ? '' : '/'}${newModules.includes(currentCategory?.name) ? '' : 'validate'}`;

    return `${urlBase}?${idModule}&${nameModule}&${hasMyPayroll}&${idUser}&${idAccount}&${idProfile}&${userImage}&${fullNameUser}&${token}`
}