import moment from 'moment';
import { useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RenderMedicalFormulasPdf } from '../../AsistanceModule/RenderMedicalFormulasPdf';
import { usePostMethod } from '../../Hooks/useFetch';
import alertCircleRed from '../../assets/img/icons/alertCircleRed.svg';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import {
  day,
  getPermission,
  isEmptyOrUndefined,
  loader,
  message,
  swalConfirm,
  today,
  validateEmptyString,
} from '../../helpers/helpers';
import FullScreenDialog from '../OrderingComponents/FullScreenDialog';
import OrdModal from '../OrderingComponents/OrdModal';
import { customSwaltAlertAsistencial } from './../../helpers/customSwaltAlertAsistencial';

export const InhabilitiesTab = patientData => {
  const store = useSelector(state => state);
  const loginReducer = store?.loginReducer;
  const doctorFullNameStore = `${loginReducer?.user_data?.first_name} ‍‍‍‍‍${loginReducer?.user_data?.first_surname} ${loginReducer?.user_data?.second_surname}`;
  const doctorName = `${patientData?.patientData?.medFirstName}`;
  const doctorSurname = `${patientData?.patientData?.medFirstSurname}`;
  const doctorFullName = `${doctorName} ‍‍‍‍‍${doctorSurname} `;
  const signatureDoctor = patientData?.patientData?.medSignature;
  const token = store.loginReducer.Authorization;
  const listPermission = store.loginReducer.currentAccount?.profile?.permission;
  const myPermission = getPermission({ prefix: 'order', listPermission });
  const { load: createInhabilitieLoading, trigger: createInhabilitie } = usePostMethod();
  const [fullDialogScreen, setFullDialogScreen] = useState({
    show: false,
    id: '',
  });
  const [data, setData] = useState({
    observations: '',
    startDate: moment(new Date()).format('YYYY-MM-DD'),
    accumulatedDays: '',
    //Opcionales
    justification: '',
  });
  const [justification, setJustification] = useState({
    showModal: false,
    justification: '',
  });
  const [daysPassed, setDaysPassed] = useState({
    statusdaysPassed: false,
    errorMessageDaysPassed: '',
  });
  const [inhabiliteActive, setInhabiliteActive] = useState({
    statusInhabilite: false,
    errorMessageInhabilite: '',
  });
  const finalDate = () => {
    let inputDate = parseInt(data.accumulatedDays);
    let startDay = new Date(data.startDate);
    let dayIq = startDay.setDate(startDay.getDate() + inputDate);
    let superDate = new Date(dayIq).toLocaleDateString('sv');
    return superDate;
  };
  function myMinDate() {
    let minDay = today().split('-');
    let todayDay = minDay[2];

    minDay[2] = String(todayDay - 1);
    if (minDay[2] < 10) {
      minDay[2] = '0' + minDay[2];
    }
    let lastDate = minDay.join('-');
    return lastDate;
  }
  const validationsGenerateButton = () => {
    if (isEmptyOrUndefined(data?.startDate)) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `El campo fecha de inicio es obligatorio`,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }

    const selectedDate = new Date(data?.startDate).getTime();
    const minDate = new Date(myMinDate()).getTime();

    if (selectedDate < minDate) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `El campo fecha de inicio no puede ser menor al permitido por el sistema`,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }

    let validations = validateEmptyString([
      { field: data.startDate, whithOutAlert: true },
      { field: data?.accumulatedDays, whithOutAlert: true },
      { field: data?.observations, whithOutAlert: true },
    ]);
    const numberOfDaysValidation = () => {
      let inputDate = parseInt(data.accumulatedDays);
      if (inputDate > 30 || inputDate <= 0) {
        return false;
      } else {
        return true;
      }
    };
    const testingTime = () => {
      let final = false;
      let userDay = data.startDate.split('-');
      let splittedDay = Number(userDay[2]);
      let today = Number(day());
      let calculatedDay = Math.abs(today - splittedDay);

      if (calculatedDay >= 3) {
        final = false;
      } else {
        final = true;
      }
      return final;
    };
    if (validations.valid) {
      if (testingTime()) {
        if (inhabiliteActive.statusInhabilite) {
          message(
            'warning',
            'No se puede registrar la incapacidad',
            'El paciente cuenta con una incapacidad activa',
          );
        } else if (daysPassed.statusdaysPassed) {
          swalConfirm({
            title: `<span class=${tableStyles.ordClearBlueText} >Supera los 150 días acumulados</span>`,
            text: `Las próximas incapacidades deben ser autorizadas por dirección médica.`,
            confirmButtonText: 'Si, continuar',
            doAfterConfirm: () => {
              setJustification({ ...justification, showModal: true });
            },
          });
        } else if (!numberOfDaysValidation()) {
          message(
            'info',
            'Días de incapacidad fuera de alcance',
            'Los días de incapacidad que se le puede otorgar al usuario deben ser mayor a 0 días y menor que 30 días',
          );
        } else {
          swalConfirm({
            title: `<span class=${tableStyles.ordClearBlueText} >¿Está seguro?</span>`,
            text: `Se creará incapacidad para ${patientData.patientData.firstName} ${patientData.patientData.lastName}`,
            confirmButtonText: 'Si, continuar',
            doAfterConfirm: () => handleSubmit(),
          });
        }
      } else {
        message(
          'info',
          'Verifique datos ingresados',
          'Debe seleccionar únicamente las fechas que se encuentran activas en el calendario',
        );
      }
    } else {
      message('info', 'Debe llenar todos los campos');
    }
  };
  function handleSubmit() {
    createInhabilitie({
      url: '/medical/medicalinhabilities/',
      token: token,
      method: 'POST',
      noAlert: true,
      body: {
        clinicalHistoryId: patientData?.patientData?.clhId,
        observations: data?.observations,
        startDate: data?.startDate,
        accumulatedDays: data?.accumulatedDays,
        // Opcionales
        authComment: data.justification,
      },

      succesAction: results => {
        if (!daysPassed.statusdaysPassed) {
          setFullDialogScreen({
            show: true,
            id: results.results,
          });
        }
      },
      doAfterException: error => {
        if (error?.error === 'reqAuth') {
          swalConfirm({
            title: `<span class=${tableStyles.ordClearBlueText} >Supera los 150 días acumulados</span>`,
            text: `Las próximas incapacidades deben ser autorizadas por dirección médica.`,
            confirmButtonText: 'Si, continuar',
            doAfterConfirm: () => {
              setJustification({ ...justification, showModal: true });
              setDaysPassed({
                ...justification,
                statusdaysPassed: true,
                errorMessageDaysPassed: error?.error,
              });
            },
          });
        } else {
          message('warning', 'No se puede registrar la incapacidad', error?.message);
          setInhabiliteActive({
            ...justification,
            statusInhabilite: true,
            errorMessageInhabilite: error?.error,
          });
          setDaysPassed({ statusdaysPassed: false });
        }
      },
    });
  }

  return (
    <>
      <FullScreenDialog onHide={() => setFullDialogScreen(false)} isOpen={fullDialogScreen.show}>
        <RenderMedicalFormulasPdf
          pdfParams={{
            url: '/medical/pdfMedicalinhabilities/', //ESPERAR URL
            id: fullDialogScreen.id,
          }}
          title={'Incapacidad '}
          finalize={{
            title: 'Finalizar',
            finalizeAction: () => {
              setData({
                observations: '',
                startDate: '',
                accumulatedDays: '',
              });
              message(
                'success',
                `<span class=${tableStyles.ordClearBlueText} >Certificado de incapacidad generado </span>`,
              );
              setFullDialogScreen(false);
            },
          }}
          backAction={() => {
            setData({
              observations: '',
              startDate: '',
              accumulatedDays: '',
            });
            setFullDialogScreen(false);
          }}
        />
      </FullScreenDialog>
      <div className={` ${tableStyles.ordContainer}  mt-3 p-3`}>
        {createInhabilitieLoading && loader}
        <OrdModal
          title={'Justificación'}
          show={justification.showModal}
          btnYesName='Aceptar'
          hideCancelButton
          size='500'
          btnYesEvent={() => {
            setJustification({ ...justification, showModal: false });
            handleSubmit();
            setData({
              ...data,
              justification: '',
              accumulatedDays: '',
              observations: '',
              startDate: '',
            });
            customSwaltAlertAsistencial({
              icon: 'success',
              title: 'Incapacidad enviada',
              text: `Se ha enviado a dirección médica para ser autorizada`,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          }}
          onHide={() => {
            setJustification({ ...justification, showModal: false });
          }}
        >
          <Col xs={12}>
            <Form.Group className='mb-3' controlId='fName'>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                <b className='text-start'>&nbsp;Justifique la razón porque envía la incapacidad</b>
              </Form.Label>
              <textarea
                placeholder={'Escribir...'}
                onChange={e => setData({ ...data, justification: e.target.value })}
                value={data.justification}
                rows='25'
                cols='55'
                style={{ height: '7rem' }}
                className={`text-secondary ord-roundInput w-100`}
              ></textarea>
            </Form.Group>
          </Col>
        </OrdModal>

        {/* -------------------------FORM------------------------- */}
        {daysPassed.statusdaysPassed ? (
          <Row className={` mx-5 text-center pb-2`}>
            <Col xs={10} className={`${tableStyles.ordAlertCustom} p-2 `}>
              <strong>
                Última incapacidad que se puede generar, supera los 150 días acumulados
              </strong>
            </Col>
            <Col xs={2} className={`text-start align-self-center`}>
              <img src={alertCircleRed} alt={'Lupa'} />
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {inhabiliteActive.statusInhabilite ? (
          <Row className={` mx-5 text-center pb-2`}>
            <Col xs={10} className={`${tableStyles.ordAlertCustom} p-2 `}>
              <strong>{`${inhabiliteActive.errorMessageInhabilite}`}</strong>
            </Col>
            <Col xs={2} className={`text-start align-self-center`}>
              <img src={alertCircleRed} alt={'Lupa'} />
            </Col>
          </Row>
        ) : (
          <></>
        )}
        <Row
          style={{ marginBottom: '28%' }}
          className={`${tableStyles.ordSearchSections} mx-5 pt-2 p-2 text-start`}
        >
          <Col xs={12} className={`text-center `}>
            <h3 className={`${tableStyles.ordDarkBlueText}`}>
              <b>Incapacidad</b>
            </h3>
          </Col>
          <Col xs={12}>
            <Row>
              <Col xs={2}>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                  <b className='text-start'>&nbsp;No. de días</b>
                </Form.Label>
                <Form.Control
                  className={`ord-roundInput`}
                  type='number'
                  placeholder='Escribe...'
                  onChange={e => setData({ ...data, accumulatedDays: e.target.value })}
                  value={data.accumulatedDays}
                />
              </Col>
              <Col xs={5}>
                <Form.Group controlId='startDate'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                    <b className='text-start'>&nbsp;Fecha de inicio</b>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput ${tableStyles.ordGrayOrdersInputs}`}
                    type='date'
                    placeholder=''
                    value={data.startDate}
                    onChange={e => {
                      setData({ ...data, startDate: e.target.value });
                    }}
                    max={today()}
                    min={myMinDate()}
                    // min={lastDate}
                  />
                </Form.Group>
              </Col>
              <Col xs={5}>
                <Form.Group controlId='endDate'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                    <b className='text-start'>&nbsp;Fecha de fin</b>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput ${tableStyles.ordGrayOrdersInputs}`}
                    type='date'
                    placeholder=''
                    value={finalDate()}
                    readOnly
                    min={today()}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <Form.Group className='mb-3' controlId='fName'>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                <b className='text-start'>&nbsp;Observaciones</b>
              </Form.Label>
              <textarea
                onChange={e => setData({ ...data, observations: e.target.value })}
                value={data.observations}
                placeholder={'Escribir...'}
                rows='25'
                cols='55'
                style={{ height: '3rem' }}
                className={`text-secondary ord-roundInput w-100`}
              ></textarea>
            </Form.Group>
          </Col>
          <Col xs={5}>
            <Card
              className={`bordered ${tableStyles.ordBordersemiCard} d-flex align-items-center `}
            >
              {!isEmptyOrUndefined(signatureDoctor) ? (
                <img
                  src={signatureDoctor}
                  alt='firma'
                  className='p-2'
                  style={{ width: '15rem', height: '5rem' }}
                />
              ) : (
                <div className='d-flex justify-content-center align-content-center h-100 align-items-center'>
                  <div
                    className={`${tableStyles.tlnTextGray} ${tableStyles.f14} px-4 py-2 text-center `}
                  >
                    <span className='align-self-center'>No hay firma disponible</span>
                  </div>
                </div>
              )}
            </Card>
          </Col>
          <Col xs={12} className='mt-3' style={{ marginLeft: '4px' }}>
            <b>Médico: </b>
            {doctorFullName?.includes('null') || doctorFullName?.length <= 0
              ? doctorFullNameStore
              : doctorFullName}
          </Col>
        </Row>
        <Row className={`${tableStyles.ordBorderTop} mt-2`}>
          <Col xs={12} className='d-flex justify-content-end pt-3 mb-3'>
            <button className={`mr-2 btn ${tableStyles.ordBtnSecondary}`}>Cancelar</button>
            {myPermission?.create && (
              <button
                onClick={() => {
                  validationsGenerateButton();
                }}
                className={`${tableStyles.ordBtnPrimary} btn`}
              >
                Generar
              </button>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
