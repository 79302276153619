import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import add from '../assets/img/icons/addDocument.svg';
import clearIcon from '../assets/img/icons/close.svg';
import ordBlueSearch from '../assets/img/icons/ordBlueSearch.svg';
import icon_search from '../assets/img/icons/ordSearchClearBlue.svg';

import { formatToRcSelect, loader, swalConfirm, validateEmptyString } from '../helpers/helpers';
import { useGetMethod, usePostMethod } from '../Hooks/useFetch';

import { OrdGenericTemplate } from '../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdModal from '../OrderingModule/OrderingComponents/OrdModal';
import OrdTable from '../OrderingModule/OrderingComponents/OrdTable';

import { appDarkCustomSelect, ordCustomSelect } from '../components/Layouts/react-select-custom';
import tableStyles from '../components/Layouts/tableStyle.module.scss';
import { customSwaltAlertAsistencial, formatteDocument, getEnvs } from '../helpers';
import { formatNumberWithoutDecimals, getLocaleSeparators } from '../helpers/numberFormatting';

const RequestDiscount = () => {
  const store = useSelector(state => state);
  const history = useHistory();
  const CURRENCY = getEnvs().CURRENCY ?? 'COP'

  const token = store.loginReducer.Authorization;
  const idAccount = store.loginReducer.currentAccount.id;
  const userId = store.loginReducer.user_data.id;

  const localeSeparators = useRef(getLocaleSeparators())

  const [triggerTable, setTriggerTable] = useState(0);
  const [triggerAppointment, setTriggerAppointment] = useState(0);
  const [data, setData] = useState({
    contractId: '',
    srvId: '',
    patientId: '',
    typeDiscount: '',
    discountQty: '',
  });
  const [requestInfo, setRequestInfo] = useState({
    idUser: userId,
    eaccount: idAccount,
    contractId: '',
    srvId: '',
    patientId: '',
    typeDiscount: '',
    srvPrice: '',
  });
  const {
    results: listPatientResults,
    load: listPatientLoader,
    trigger: getListPatient,
  } = useGetMethod();
  const {
    results: listAppointmentResults,
    load: listAppointmentLoader,
    trigger: getListAppointment,
  } = useGetMethod();
  const {
    results: insurancesResults,
    load: insurancesLoader,
    trigger: getInsurancesOptions,
  } = useGetMethod();
  const {
    results: servicesResults,
    load: servicesLoader,
    trigger: getServicesOptions,
  } = useGetMethod();
  const {
    results: servicesAppResults,
    load: servicesApp,
    trigger: getServicesApp,
  } = useGetMethod();
  const { load: sendDiscountLoader, trigger: sendDiscount } = usePostMethod();
  const insurancesOptions = formatToRcSelect(
    insurancesResults?.results?.contracts,
    'corporateClientId',
    'corporateClient',
    '',
    '',
    '',
  );
  const servicesOptions = formatToRcSelect(
    servicesResults?.results,
    'srvId',
    'srvName',
    'srvPrice',
    '',
    '',
  );
  const servicesAppOptions = formatToRcSelect(
    servicesAppResults?.results,
    'id',
    'name',
    '',
    '',
    '',
  );
  const typeOptions = [
    { value: '', label: '-Seleccione' },
    { value: 'Porcentaje', label: 'Porcentaje' },
    { value: 'Valor', label: 'Valor' },
  ];
  const [modalPatient, setModalPatient] = useState({
    eaccount: idAccount,
    discountFilter: true,
    page: 1,
    perpage: 6,
    show: false,
    state: false,
    id: 0,
  });
  const [filterAppointment, setFilterAppointment] = useState({
    eaccount: idAccount,
    page: 1,
    perpage: 10,
    search: '',
    modality: 'on_site',
    status: 'pending_payment',
    startDate: moment().format('YYYY-MM-DD')
  });
  const [modalAppointment, setModalAppointment] = useState({
    perpage: 10,
    show: false,
    eaccount: idAccount,
    page: 1,
    state: false,
    id: '',
    companyTypeTag: 'particular',
    app: false,
  });
  useEffect(() => {
    getListPatient({
      url: '/medical/patient/listPatient/',
      objFilters: { ...modalPatient },
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListPatient, token, triggerTable]);

  useEffect(() => {
    getListAppointment({
      url: '/medical/generals/appointments/',
      objFilters: filterAppointment,
      token: token,
    });
    getServicesApp({
      url: '/medical/generals/services/',
      objFilters: { eaccount: idAccount },
      token: token,
    });
  }, [filterAppointment, getListAppointment, getServicesApp, idAccount, token, triggerAppointment]);

  useEffect(() => {
    if (modalAppointment.app) {
      setData(state => ({ ...state, isDisabled: true }));
    } else {
      setData(state => ({ ...state, isDisabled: false }));
    }
  }, [modalAppointment.app]);

  const headers = [
    {
      title: 'Paciente',
      className: 'px-2 col-6 text-start',
    },
    {
      title: 'No. identificación',
      className: 'px-2 col-4 text-start',
    },
  ];
  const headersAppointment = [
    {
      title: 'Paciente',
      className: 'px-2 col-5 text-start',
    },
    {
      title: 'Servicio',
      className: 'px-2 col-5 text-start',
    },
    {
      title: 'Fecha atención',
      className: 'px-2 col-2 text-center',
    },
  ];
  const closePatientModal = () => {
    setModalPatient({ ...modalPatient, show: false, filterPatient: '', state: true, page: 1 });
    setTriggerTable(triggerTable + 1);
  };
  const closeAppointmentModal = () => {
    setModalAppointment({ ...modalAppointment, show: false, search: '', state: true });
    setFilterAppointment({
      ...filterAppointment,
      search: '',
      srvId: '',      
      endDate: '',
    });
    setTriggerAppointment(triggerAppointment + 1);    
  };

  const backHistory = () => {
    history.goBack();
  };

  const selectedPatient = () => {    
    setData({
      ...data,
      patientId: modalPatient.id,
      document: formatteDocument(modalPatient.document).value,
      name: modalPatient.name,
      insurance: '',
      contract: '',
      srvId: '',
      srvPrice: '',
      typeDiscount: '',
      amount: '',
      finalValue: '',
      comments: '',
    });
    getInsurances();
    closePatientModal();
  };
  const selectedAppointment = () => {
    setData({
      ...data,
      amount: null,
      typeDiscount: '',
      finalValue: '',
      patientId: modalAppointment.id,
      document: modalAppointment.document,
      name: modalAppointment.name,
      contractId: modalAppointment.contractId,
      srvPrice: modalAppointment.srvPrice,
      srvId: modalAppointment.srvId,
      insurance: modalAppointment.companyId,
      idApp: modalAppointment.idApp,
      applicationDateApp: modalAppointment.dateAttentionApp,
      dateAttentionApp:modalAppointment.dateAttentionApp,
      contract: modalAppointment.contract,
      company: modalAppointment.company,
      serviceName: modalAppointment.serviceName,
      siteName: modalAppointment.siteName,
      cityName: modalAppointment.cityName
    });
    closeAppointmentModal();    
  };
  //  ANCHOR TABLE INFO
  const formatDataAppointment = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach(item => {
        let orange =
          modalAppointment.state === true && modalAppointment.idApp === item.idApp
            ? tableStyles.ordBgFileTableYellow
            : '';
        tempList.push(
          <tr
            key={item?.srvId}
            className={`hover-table-row ${orange} hoverPointer`}
            onClick={() => {
              setModalAppointment({
                ...modalAppointment,
                state: true,
                id: item.idPatient,
                idApp: item.idApp,
                document: formatteDocument(item.documentPatient).value,
                name: item.fullNamePatient,
                contractId: item.idContractCorporateClient,
                contract: item.contractCorporateClient,
                srvPrice: item.copayAmount || item.price,
                srvId: item.idService,
                applicationDateApp: item.applicationDateApp,
                dateAttentionApp: item.dateAttentionApp,
                insurance: item.companyId,
                company: item.company,
                serviceName: item.serviceName,
                siteName: item.siteName,
                cityName: item.cityName,
              });
            }}
          >
            <td className={`text-start px-2 ${orange} `}>{item.fullNamePatient}</td>
            <td className={`text-start px-2 ${orange} `}>{item?.serviceName}</td>
            <td className={`text-center px-2 ${orange} `}>{item?.dateApp}</td>
          </tr>,
        );
      });
    }
    return tempList;
  };
  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach(item => {
        let orange =
          modalPatient.state === true && modalPatient.id === item.id
            ? tableStyles.ordBgFileTableYellow
            : '';
        let fullName = `${item?.first_name} ${item?.other_names} ${item?.last_name} ${item?.second_surname}`;
        tempList.push(
          <tr
            key={item?.srvId}
            className={`hover-table-row hoverPointer ${orange}`}
            onClick={() => {
              setModalPatient({
                ...modalPatient,
                state: true,
                id: item.id,
                document: formatteDocument(item.document).value,
                name: fullName,
              });
            }}
          >
            <td className={`text-start px-2 ${orange} `}>{fullName}</td>
            <td className={`text-start px-2 ${orange} `}>{formatteDocument(item?.document).format}</td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const getInsurances = () => {
    getInsurancesOptions({
      url: '/medical/patient/',
      objFilters: {
        id: modalAppointment.app ? modalAppointment.id : modalPatient.id,
        companyTypeTag: 'particular',
        eaccount: idAccount,
        isMain: false,
      },
      token: token,
    });
  };
  const getContract = insurance => {
    let item = insurancesResults?.results?.contracts.filter(
      contract => contract.corporateClientId === insurance,
    );
    setData({
      ...data,
      contractId: item && item[0]?.contractId,
      contract: item && item[0]?.contractDescription ? item[0]?.contractDescription : '-',
      insurance: insurance,
      srvId: '',
      typeDiscount: '',
      amount: '',
      finalValue: '',
      srvPrice: '',
    });
    getServices(item[0]?.contractId ? item[0]?.contractId : '');
  };
  const getServices = id => {
    getServicesOptions({
      url: '/medical/generals/serviceByContract/',
      objFilters: { contractId: id, eaccount: idAccount },
      token: token,
    });
  };
  // -----------------------------
  const amountOnChangeInput = e => {
    setData({ ...data, amount: e });
    getFinalValue(e);
  };
  const getFinalValue = e => {
    let finalValue;
    let qtyDiscount = e
    let valorInicial = data?.srvPrice ? parseFloat(String(data.srvPrice).replace(',', '')) : 0;
    if (data.typeDiscount) {
      if (data.typeDiscount === 'Porcentaje') {
        qtyDiscount = qtyDiscount / 100;
        let percentage = valorInicial - valorInicial * qtyDiscount;
        finalValue = percentage ? percentage : '';
      } else {
        let value = valorInicial - e;
        finalValue = value ? value : '';
      }
      setData({ ...data, qtyDiscount: qtyDiscount, finalValue: finalValue, amount: e });
    }
  };
  //  SUBMIT FORM
  const validationsRequest = () => {
    let validations;
    if (modalAppointment.app) {
      validations = validateEmptyString([
        { field: data.amount, message: 'La cantidad es obligatoria' },
        { field: data.typeDiscount, message: 'El tipo es obligatorio' },
      ]);
    } else {
      validations = validateEmptyString([
        { field: data.amount, message: 'La cantidad es obligatoria' },
        { field: data.srvId, message: 'El servicio es obligatorio' },
        { field: data.typeDiscount, message: 'El tipo es obligatorio' },
        { field: data.insurance, message: 'La aseguradora es obligatoria' },
        { field: data.name, message: 'El paciente es obligatorio' },
      ]);
    }
    if (validations.valid) {
      setRequestInfo({
        ...requestInfo,
        contractId: data.contractId,
        srvId: data.srvId,
        patientId: data.patientId,
        typeDiscount: data.typeDiscount,
        qtyDiscount: data.qtyDiscount,
        srvPrice: data.srvPrice,
      });
      if (data.srvPrice <= 0) {
        return customSwaltAlertAsistencial({
          showCancelButton: false,
          icon: 'warning',
          title: 'Cita no válida',
          text: 'No se puede solicitar descuento para citas con copago menor o igual a 0',
          confirmButtonText: 'Aceptar',
        });
      }
      modalAppointment.app ? sendRequestApp() : sendRequestDiscount();
    }
  };
  const sendRequestDiscount = () => {
    let symbol = data.typeDiscount === 'Porcentaje' ? '%' : '$';
    let textData = symbol === '%' ? `${data.amount}${symbol}` : `${formatNumberWithoutDecimals (data.amount)}`;

    swalConfirm({
      title: `<span class=${tableStyles.ordClearBlueText} >¿Está seguro?</span>`,
      text: `Se solicitará un descuento de ${textData} para  el paciente: ${data.name}`,
      confirmButtonText: 'Si, continuar',
      doAfterConfirm: () => {
        sendDiscount({
          url: '/medical/appointment/requestDiscountWithOutApp/',
          token: token,
          method: 'POST',
          body: {
            idUser: userId,
            eaccount: idAccount,
            contractId: data.contractId,
            srvId: data.srvId,
            patientId: data.patientId,
            typeDiscount: data.typeDiscount,
            qtyDiscount: data.qtyDiscount,
            discountQty: data.qtyDiscount,
            srvPrice: data.srvPrice,
            comments: data.comments,
          },
          doAfterSuccess: () => {
            clearData();
            setTriggerTable(triggerTable + 1);
          },
          doAfterException: results => {
            customSwaltAlertAsistencial({
              showCancelButton: false,
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: results.message,
              confirmButtonText: 'Aceptar',
            });
          },
        });
      },
    });
  };
  const sendRequestApp = () => {
    let symbol = data.typeDiscount === 'Porcentaje' ? '%' : '$';
    let textData = symbol === '%' ? `${data.amount}${symbol}` : `${formatNumberWithoutDecimals(data.amount)}`;

    swalConfirm({
      title: `<span class=${tableStyles.ordClearBlueText} >¿Está seguro?</span>`,
      text: `Se solicitará un descuento de ${textData} para  el paciente: ${data.name}`,
      confirmButtonText: 'Si, continuar',
      doAfterConfirm: () => {
        sendDiscount({
          url: '/medical/appointment/discountOfOneAppointment/',
          token: token,
          method: 'POST',
          body: {
            idUser: userId,
            eaccount: idAccount,
            typeDiscount: data.typeDiscount,
            qtyDiscount: data.qtyDiscount,
            comments: data.comments,
            idApp: data.idApp,
          },
          doAfterSuccess: () => {
            setTriggerAppointment(triggerAppointment + 1);
            clearData();
          },
          doAfterException: results => {
            customSwaltAlertAsistencial({
              showCancelButton: false,
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: results.message,
              confirmButtonText: 'Aceptar',
            });
          },
        });
      },
    });
  };
  const clearData = () => {
    setData({
      ...data,
      name: '',
      document: '',
      insurance: '',
      contract: '',
      srvId: '',
      srvPrice: '',
      typeDiscount: '',
      amount: '',
      comments: '',
      applicationDateApp: '',
      dateAttentionApp: '',
      siteName: '',
      cityName: '',
      company: '',
      serviceName: '',
      finalValue: '',
    });
    setModalAppointment({ ...modalAppointment, idApp: '' });
  };

  const handleClickPatient = () => {
    setModalAppointment({ ...modalAppointment, app: false });
    setModalPatient({ ...modalPatient, show: true });
  };
// ANCHOR: RETURN JSX COMPONENT
  return (
    <>
      {(listPatientLoader ||
        insurancesLoader ||
        servicesLoader ||
        sendDiscountLoader ||
        servicesApp ||
        listAppointmentLoader) &&
        loader}
      <OrdModal
        title={'Seleccionar cita'}
        show={modalAppointment.show}
        btnYesName={'Seleccionar'}
        btnNoName={'Cancelar'}
        size={800}
        onHide={() => closeAppointmentModal()}
        btnYesEvent={() => selectedAppointment()}
        btnNoEvent={() => closeAppointmentModal()}
        sizeBody={`${tableStyles.modalHeight}`}
      >
        <div>
          <Row>
            <Col xs={3}>
              <Form.Group className=' text-start' controlId='fName'>
                <Form.Label
                  className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14} d-flex flex-column w-100`}
                >
                  <b> &nbsp;</b>
                </Form.Label>
                <div className={`d-flex ${tableStyles.appGrayInputSearch} ${tableStyles.f12}`}>
                  <Form.Control
                    style={{ backGroundColor: 'red' }}
                    className={`ord-roundInput    ${tableStyles.appInputDate}`}
                    type='text'
                    placeholder='Nombre o ID del paciente'
                    autoComplete='off'
                    onChange={e => {
                      setFilterAppointment({ ...filterAppointment, search: e.target.value });
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        setTriggerAppointment(triggerAppointment + 1);
                        setFilterAppointment({ ...filterAppointment, page: 1 });
                      }
                    }}
                  />
                  <img src={ordBlueSearch} className='' alt='buscar' />
                </div>
              </Form.Group>
            </Col>
            <Col xs={3}>
              <div className='w-100'>
                <Form.Label
                  className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14} d-flex flex-column w-100`}
                >
                  <b> &nbsp;</b>
                </Form.Label>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  options={servicesAppOptions}
                  className='text-secondary '
                  placeholder={'Servicios'}
                  styles={appDarkCustomSelect}
                  value={servicesAppOptions.find(x => x.value === filterAppointment.id)}
                  onChange={e => {
                    setFilterAppointment({ ...filterAppointment, srvId: e.value });
                  }}
                />
              </div>
            </Col>
            <Col xs={6}>
              <div className='d-flex'>
                <Col xs={5}>
                  <Form.Group className='mb-3 text-start' controlId='startName'>
                    <Form.Label
                      className={`m-0 ${tableStyles.ordAquaMarineText} ${tableStyles.f14} d-flex flex-column w-100`}
                    >
                      <span>&nbsp; &nbsp;Fecha inicio</span>
                    </Form.Label>
                    <input
                      className={` ${tableStyles.appInputDate}`}
                      placeholder='Date'
                      type='date'
                      value={filterAppointment.startDate}
                      onChange={e => {
                        setFilterAppointment({ ...filterAppointment, startDate: e.target.value });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={5} className='mx-3'>
                  <Form.Group className='mb-3 text-start ' controlId='endDat'>
                    <Form.Label
                      className={`m-0 ${tableStyles.ordAquaMarineText} ${tableStyles.f14} d-flex flex-column w-100`}
                    >
                      <span>&nbsp; &nbsp;Fecha fin</span>
                    </Form.Label>
                    <input
                      className={` ${tableStyles.appInputDate} `}
                      type='date'
                      placeholder=''
                      value={filterAppointment.endDate}
                      onChange={e => {
                        setFilterAppointment({ ...filterAppointment, endDate: e.target.value });
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col xs={2} className=' justify-content-start align-items-center  mb-2'>
                  <Form.Label
                    className={`m-0 ${tableStyles.ordClearBlueText} ${tableStyles.f14} d-flex flex-column w-100`}
                  >
                    <b> &nbsp;</b>
                  </Form.Label>
                  <img
                    className='hoverPointer'
                    height='27px'
                    alt='search'
                    src={icon_search}
                    onClick={() => {
                      setTriggerAppointment(triggerAppointment + 1);
                      setFilterAppointment({ ...filterAppointment, page: 1 });
                    }}
                  />
                </Col>
              </div>
            </Col>
          </Row>
          <div>
            <OrdTable
              headers={headersAppointment}
              hasChildren={true}
              oneHeadDarkBlue={false}
              lowerCase={false}
              fTdClearBlue={true}
              paginate={{
                activePage: filterAppointment.page,
                totalPages: listAppointmentResults?.rowTotal,
                perPage: filterAppointment.perpage,
                pageRangeDisplayed: 1,
                onChangePage: async e => {
                  const result = await getListAppointment({
                    url: '/medical/generals/appointments/',
                    objFilters: { ...filterAppointment, page: e },
                    token: token,
                  });
                  setFilterAppointment({ ...filterAppointment, page: e });
                  formatDataAppointment(result?.results);
                },
                showTextDetails: true,
              }}
            >
              {formatDataAppointment(listAppointmentResults?.results)}
            </OrdTable>
          </div>
        </div>
      </OrdModal>
      <OrdModal
        title={'Seleccionar paciente'}
        show={modalPatient.show}
        btnYesName={'Seleccionar'}
        btnNoName={'Cancelar'}
        size={500}
        onHide={() => closePatientModal()}
        btnYesEvent={() => selectedPatient()}
        btnNoEvent={() => {
          closePatientModal();
        }}
        sizeBody={`${tableStyles.modalHeight}`}
      >
        <div>
          <div>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`} />

              <div className={`d-flex ${tableStyles.ordGrayInputSearch}`}>
                <Form.Control
                  style={{ backGroundColor: 'red' }}
                  className={`ord-roundInput`}
                  type='text'
                  placeholder='Escribe aquí para buscar'
                  autoComplete='off'
                  onChange={e => {
                    setModalPatient({ ...modalPatient, filterPatient: e.target.value });
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      setModalPatient({ ...modalPatient, page: 1 });
                      setTriggerTable(triggerTable + 1);
                    }
                  }}
                />
                <img src={ordBlueSearch} className='p-2' alt='buscar' />
              </div>
            </Form.Group>
          </div>
          <div>
            <OrdTable
              headers={headers}
              hasChildren={true}
              oneHeadDarkBlue={false}
              lowerCase={false}
              fTdClearBlue={true}
              paginate={{
                activePage: modalPatient.page,
                totalPages: listPatientResults?.rowTotal,
                perPage: modalPatient.perpage,
                pageRangeDisplayed: 1,
                onChangePage: async e => {
                  const result = await getListPatient({
                    url: '/medical/patient/listPatient/',
                    objFilters: { ...modalPatient, page: e },
                    token: token,
                  });
                  setModalPatient({ ...modalPatient, page: e });
                  formatData(result?.results);
                },
                showTextDetails: true,
              }}
            >
              {formatData(listPatientResults?.results)}
            </OrdTable>
          </div>
        </div>
      </OrdModal>
      <OrdGenericTemplate
        className='w-90 my-4'
        showBottomBorder={false}
        title={'Solicitar descuento'}
        titleSize={12}
      >
        <div className='w-90 mx-1 my-3'>
          <Col xs={2} className={`${tableStyles.ordClearBlueCard} my-3`}>
            <div className='w-100 p-2 d-flex align-items-center '>
              <button
                onClick={() => {
                  setModalAppointment({ ...modalAppointment, show: true, app: true });
                }}
              >
                <img src={add} alt='seleccionarCita' height='15px' />
                <b className={`${tableStyles.ordClearBlueText}`}> Seleccionar cita</b>
              </button>
            </div>
          </Col>

          <div className={`${tableStyles.ordClearBlueCard} `}>
            <Row className={`p-4 `}>
              <Col xs={3}>
                <div className='d-flex flex-column w-100'>
                  <Form.Group className='mb-3 text-start' controlId='fPatient'>
                    <Form.Label
                      className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14} d-flex flex-column w-100`}
                    >
                      <b> &nbsp;Paciente</b>
                    </Form.Label>
                    <div className='d-flex w-100'>
                      <button className='cursorPointer w-100' onClick={() => handleClickPatient()}>
                        <Form.Control
                          className={`ord-roundInput  ${tableStyles.appSearchPatientButton}  cursorPointer`}
                          autoComplete='off'
                          type='text'
                          placeholder='Escriba...'
                          value={data.name}
                          onChange={() => {
                            setTriggerTable(triggerTable + 1);
                            setData({ ...data, insurance: '' });
                          }}
                        />
                      </button>
                    </div>
                  </Form.Group>
                </div>
              </Col>
              <Col xs={3}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                    <span> &nbsp;Identificación</span>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput `}
                    disabled
                    type='text'
                    placeholder=''
                    value={formatteDocument(data.document).format}
                    onChange={() => {
                      setTriggerTable(triggerTable + 1);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                    <span> &nbsp;Aseguradora</span>
                  </Form.Label>

                  {modalAppointment.app ? (
                    <Form.Control
                      className={`ord-roundInput `}
                      disabled
                      type='text'
                      placeholder=''
                      value={data.company}
                    />
                  ) : (
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      options={insurancesOptions}
                      isDisabled={data.isDisabled}
                      className='text-secondary '
                      placeholder={'Seleccione'}
                      styles={ordCustomSelect}
                      value={insurancesOptions.find(x => x.value === data.insurance)}
                      onChange={e => {
                        setData({
                          ...data,
                          insurance: e.value,
                          srvId: '',
                          typeDiscount: '',
                          amount: '',
                          finalValue: '',
                        });
                        getContract(e.value);
                      }}
                    />
                  )}
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label
                    className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14} d-flex justify-content-between`}
                  >
                    <span> &nbsp;Contrato</span>
                    <div className='d-flex justify-content-end '>
                      {modalAppointment.app && (
                        <img
                          src={clearIcon}
                          width={15}
                          className={'cursorPointer'}
                          alt='closeIcon'
                          style={{ marginRight: '-10px' }}
                          onClick={() => {
                            clearData();
                          }}
                        />
                      )}
                    </div>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput `}
                    disabled
                    type='text'
                    placeholder=''
                    value={data.contract}
                  />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                    <span> &nbsp;Servicio</span>
                  </Form.Label>
                  {modalAppointment.app ? (
                    <Form.Control
                      disabled
                      className={`ord-roundInput`}
                      type='text'
                      placeholder='Escribe...'
                      value={data.serviceName}
                    />
                  ) : (
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      options={servicesOptions}
                      isDisabled={data.isDisabled}
                      className='text-secondary '
                      placeholder={'Seleccione'}
                      styles={ordCustomSelect}
                      value={servicesOptions.find(x => x.value === data.srvId)}
                      onChange={e => {
                        setData({
                          ...data,
                          srvId: e.value,
                          srvPrice: e.extra,
                          typeDiscount: '',
                          amount: '',
                          finalValue: '',
                        });
                      }}
                    />
                  )}
                </Form.Group>
              </Col>
              {modalAppointment.app && (
                <>
                  <Col xs={3}>
                    <Form.Group className='mb-3 text-start' controlId='fName'>
                      <Form.Label
                        className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}
                      >
                        <span> &nbsp;Ubicación</span>
                      </Form.Label>
                      <Form.Control
                        className={`ord-roundInput `}
                        disabled
                        type='text'
                        placeholder=''
                        value={data.cityName}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className='mb-3 text-start' controlId='fName'>
                      <Form.Label
                        className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}
                      >
                        <span> &nbsp;Sede</span>
                      </Form.Label>
                      <Form.Control
                        className={`ord-roundInput `}
                        disabled
                        type='text'
                        placeholder=''
                        value={data.siteName}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group className='mb-3 text-start' controlId='fName'>
                      <Form.Label
                        className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}
                      >
                        <span> &nbsp;Fecha y hora de atención</span>
                      </Form.Label>
                      <Form.Control
                        className={`ord-roundInput ${tableStyles.inputDateServiceReports}`}
                        disabled
                        type='text'
                        value={data.dateAttentionApp ?? modalAppointment.dateAttentionApp}
                      />
                    </Form.Group>
                  </Col>{' '}
                </>
              )}
            </Row>
          </div>

          <div className={``}>
            <Row className={`py-4`}>
              <Col xs={3}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                    <span> &nbsp;Copago / Cuota moderadora</span>
                  </Form.Label>

                  <NumberFormat
                    allowNegative={false}
                    decimalScale={0}
                    className={'form-control ord-roundInput'}
                    decimalSeparator={localeSeparators.current.decimalSeparator}
                    disabled
                    id='srvPrice'
                    isNumericString={true}
                    maxLength={12}
                    placeholder={formatNumberWithoutDecimals(0)}
										format={valueStr => {
											if (!valueStr) return '';

											return formatNumberWithoutDecimals(Number(valueStr));
										}}
                    thousandSeparator={localeSeparators.current.groupSeparator}
                    thousandsGroupStyle='thousand'
                    value={data.srvPrice}
                    onValueChange={({ value }) => {
                      setData({ ...data, startDate: Number(value) });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                    <span> &nbsp;Tipo</span>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={typeOptions}
                    className='text-secondary '
                    placeholder={'Seleccione'}
                    value={typeOptions.find(x => x.value === data.typeDiscount)}
                    styles={ordCustomSelect}
                    onChange={e => {
                      setData({ ...data, typeDiscount: e.value, amount: '', finalValue: '' });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className='mbf-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                    <span> &nbsp;Cantidad</span>
                  </Form.Label>

                  {data.typeDiscount === 'Valor' ? (
                    <div className='input-group '>
                      <NumberFormat
                        className={'form-control ord-roundInput'}
                        allowNegative={false}
                        id='amount'
                        thousandsGroupStyle='thousand'
                        thousandSeparator={localeSeparators.current.groupSeparator}
                        decimalSeparator={localeSeparators.current.decimalSeparator}
                        decimalScale={0}
                        value={data.amount}
                        onValueChange={e => {
                          amountOnChangeInput(e.value);
                        }}
                        maxLength={12}
                      />
                      <div className='input-group-prepend'>
                        <span className='input-group-text ord-roundInput'>
                        {CURRENCY}
                        </span>
                      </div>
                    </div>
                  ) : data.typeDiscount === 'Porcentaje' ? (
                    <div className='input-group '>
                      <NumberFormat
                        className={'form-control ord-roundInput'}
                        allowNegative={false}
                        id='percentage'
                        thousandsGroupStyle='thousand'
                        thousandSeparator
                        value={data.amount}
                        onValueChange={e => {
                          amountOnChangeInput(e.value);
                        }}
                        decimalScale={2}
                        maxLength={5}
                        isAllowed={(values) => values.floatValue <= 100 || !values.floatValue}
                      />
                      <div className='input-group-prepend'>
                        <span className='input-group-text ord-roundInput'> &nbsp;%</span>
                      </div>
                    </div>
                  ) : (
                    <NumberFormat
                      className={'form-control ord-roundInput'}
                      allowNegative={false}
                      id='amount'
                      thousandsGroupStyle='thousand'
                      placeholder={formatNumberWithoutDecimals(0)}
                      isNumericString={typeof data.amount === 'string'}
                      thousandSeparator={localeSeparators.groupSeparator}
                      decimalSeparator={localeSeparators.decimalSeparator}
                      format={valueStr => {
                        if (!valueStr) return '';

                        return formatNumberWithoutDecimals(Number(valueStr));
                      }}
                      decimalScale={0}
                      value={data.amount}
                      onValueChange={e => {
                        amountOnChangeInput(e.value);
                      }}
                      maxLength={12}
                    />
                  )}
                </Form.Group>
              </Col>

              <Col xs={3}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                    <span> &nbsp;Valor final</span>
                  </Form.Label>

                  <NumberFormat
                    fixedDecimalScale
                    decimalScale={0}
                    className={'form-control ord-roundInput'}
                    decimalSeparator={localeSeparators.current.decimalSeparator}
                    disabled
                    id='finalValue'
                    isNumericString={true}
                    maxLength={12}
                    placeholder={formatNumberWithoutDecimals(0)}
										format={valueStr => {
											if (!valueStr) return '';

											return formatNumberWithoutDecimals(Number(valueStr));
										}}
                    thousandSeparator={localeSeparators.current.groupSeparator}
                    thousandsGroupStyle='thousand'
                    value={data.finalValue}
                    onValueChange={({ value }) => {
                      setData({ ...data, finalValue: Number(value) });
                    }}
                  />
                </Form.Group>
              </Col>
              <div>
                <Form.Group className='mb-3' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                    <span className='text-start'>&nbsp;Comentarios</span>
                  </Form.Label>
                  <textarea
                    value={data?.comments}
                    placeholder={'Escribir...'}
                    rows='40'
                    cols='50'
                    style={{ height: '4rem' }}
                    className={`text-secondary ord-roundInput w-100 ${tableStyles.outlineNone}`}
                    key={triggerTable + 'observations'}
                    onChange={e =>
                      setData({
                        ...data,
                        comments: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </div>
            </Row>
          </div>
          <div className='d-flex w-full justify-content-end'>
            <div className='mx-4'>
              <button
                onClick={() => backHistory()}
                className={`${tableStyles.ordBtnSecondary} p-2`}
              >
                Cancelar
              </button>
            </div>
            <div>
              <button
                type='submit'
                className={`${tableStyles.ordBtnPrimary}  p-2`}                
                onClick={() => {
                  validationsRequest();
                }}
              >
                Enviar solicitud{' '}
              </button>
            </div>
          </div>
        </div>
      </OrdGenericTemplate>
    </>
  );
};

export default RequestDiscount;
