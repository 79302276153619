import { loader, message } from '../../../helpers/helpers';
import { useEffect } from 'react';
import { OrdGenericTemplate } from '../../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import { Box } from '@mui/material';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import { useTheme } from 'styled-components';
import { Button, Text } from '../../../components/UI/atoms';
import next from '../../../assets/img/icons/ordRightArrow.svg';
import { ProgressBar } from '../../../components/ProgressBar/ProgressBar';
import usePatientAdmissionStore from '../../../Hooks/usePatientAdmissionStore';
import { useHistory } from 'react-router-dom';
import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';
import Select from 'react-select';
import { useState } from 'react';
import { useGetMethod } from '../../../Hooks/useFetch';
import { useSelector } from 'react-redux';
import moment from 'moment';
import camera from '../../../assets/img/icons/aaa.svg';
import alerta from '../../../assets/img/icons/alert-circle-orange.svg';
import { Drawer } from '@material-ui/core';
import { Title } from './../../../components/UI/atoms/title/Title';
import Camera from '../../../components/Camera/Camera';
import { handleChangeValidation } from '../../../helpers/handleChangeValidation';
import { DrawerAndAlert } from '../components';
import { customSwaltAlertAsistencial, formatteDocument } from '../../../helpers';

export const PatientBasicInfo = () => {
  //  ---------------------------Hooks && LocalStorage-------------------------

  const { activeEvent, setActiveEvent, image, setImage } = usePatientAdmissionStore();
  const { patient } = activeEvent;
  const history = useHistory();

  const convertBase64 = archives => {
    if (!['jpg', 'svg', 'png', 'jpeg'].includes(archives[0].type.split('/')[1])) {
      message('error', 'Error', 'Tipo de formato de imagen incorrecto');
      return;
    }
    Array.from(archives).forEach(archive => {
      let reader = new FileReader();
      reader.readAsDataURL(archive);
      reader.onload = function () {
        let rst = reader.result;
        setImage(rst);
        setActiveEvent({ ...activeEvent, patient: { ...activeEvent.patient, photo: rst } });
      };
    });
    // return result
  };

  //  ---------------------------States && Store -------------------------
  const store = useSelector(state => state);

  const [isHaveCity, setIsHaveCity] = useState({ status: false, city: '' });

  const [modal, setModal] = useState(false);

  const token = store.loginReducer.Authorization;

  const [formError, setFormError] = useState({});

  const [listCitiesFormat, setListCitiesFormat] = useState(null);

  //  ---------------------------Theme -------------------------
  const { colors } = useTheme();

  //  --------------------------- Validations Form -----------------------

  const validForm = () => {
    return Object?.values(formError)?.length || validSelects()  ? true : false;
  };

  const validSelects = () => {
    if (
      patient.docType === '' ||
      patient.docType === null ||
      patient.gender === '' ||
      patient.gender === null ||
      patient.docType === '' ||
      patient.docType === null ||
      patient.province === '' ||
      patient.province === null
    ) {
      return true;
    }
    return false;
  };

  //  --------------------------- Petitions -------------------------
  const { results: listDocTypes, load: loaderDocTypes, trigger: getListDocTypes } = useGetMethod();

  const { results: listCities, load: loaderListCities, trigger: getListCities } = useGetMethod();

  const {
    results: listDepartaments,
    load: loaderListDepartaments,
    trigger: getListDepartaments,
  } = useGetMethod();

  //  --------------------------- Actions -------------------------

  const messagePhotoSave = () => {
    customSwaltAlertAsistencial({
      icon: 'success',
      title: 'Foto guardada exitosamente',
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });

    setModal(false);
  };
  //  ---------------------------Effects -------------------------

  useEffect(() => {
    if (patient.province !== null) {
      setListCitiesFormat(listCities?.data?.filter(el => el.province === patient.province));
      setActiveEvent({ ...activeEvent, patient: { ...activeEvent.patient, city: '' } });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient?.province]);

  useEffect(() => {
    if (patient.province !== null) {
      setListCitiesFormat(listCities?.data?.filter(el => el.province === patient.province));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCities]);

  useEffect(() => {
    if (isHaveCity.status) {
      setActiveEvent({
        ...activeEvent,
        patient: { ...activeEvent.patient, city: isHaveCity.city },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHaveCity?.status]);

  useEffect(() => {
    if (activeEvent?.patient?.photo) {
      setImage(activeEvent?.patient?.photo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeEvent?.patient?.photo]);

  useEffect(() => {
    getListDocTypes({
      url: '/payroll/document_types/',
      token: token,
    });

    getListCities({
      url: '/admin/cities/',
      token: token,
    });

    getListDepartaments({
      url: '/admin/provinces/',
      token: token,
    });

    if (typeof patient.city === 'number') {
      setIsHaveCity({ status: true, city: patient.city });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //  ---------------------------Component -------------------------
  return (
    <>
      {(loaderDocTypes || loaderListCities || loaderListDepartaments) && loader}

      <Drawer
        anchor={'bottom'}
        open={modal}
        style={{ zIndex: 900 }}
        // onClose={() => setServices({ ...services, showDrawer: false })}
      >
        <div style={{ height: '92vh' }}>
          <Box padding='20px'>
            <Title
              title={'Tomar foto'}
              fontSize='2rem'
              textAlign={'center'}
              color={colors.ordAquaMarine}
            />
          </Box>

          <Box
            display='grid'
            gridTemplateColumns='50px 60% 1fr'
            height={'90%'}
            borderTop={`1px solid ${colors.gray}`}
          >
            <div></div>

            <Box height={'100%'} width='100%' className='mt-4 '>
              <Camera />
            </Box>

            <Box className='mt-4 mx-3 '>
              <Box>
                <Title title={'Requisitos'} fontSize='1.5rem' />
                <div className='mt-3 mb-2'>
                  <Text
                    classText={tableStyles.f14}
                    iconAlt={'alerta'}
                    iconPosition='0'
                    iconPath={alerta}
                    title='No utilizar gorra'
                    color={colors.ordDarkGray}
                    textPosition='1'
                  />
                </div>

                <div className='mb-2'>
                  <Text
                    classText={tableStyles.f14}
                    iconAlt={'alerta'}
                    iconPosition='0'
                    iconPath={alerta}
                    title='No utilizar gafas'
                    color={colors.ordDarkGray}
                    textPosition='1'
                  />
                </div>

                <div className='mb-2'>
                  <Text
                    classText={tableStyles.f14}
                    iconAlt={'alerta'}
                    iconPosition='0'
                    iconPath={alerta}
                    title='No utilizar tapabocas'
                    color={colors.ordDarkGray}
                    textPosition='1'
                  />
                </div>

                <div className='mb-2'>
                  <Text
                    classText={tableStyles.f14}
                    iconAlt={'alerta'}
                    iconPosition='0'
                    iconPath={alerta}
                    title='Mantener los ojos abiertos'
                    color={colors.ordDarkGray}
                    textPosition='1'
                  />
                </div>

                <div className='mb-2'>
                  <Text
                    classText={tableStyles.f14}
                    iconAlt={'alerta'}
                    iconPosition='0'
                    iconPath={alerta}
                    title='Mirar hacia la cámara'
                    color={colors.ordDarkGray}
                    textPosition='1'
                  />
                </div>

                <div className='file-select' id='src-file1'>
                  <input
                    type='file'
                    name='src-file1'
                    aria-label='Archivo'
                    accept='.jpg, .jpeg, .png .svg'
                    onChange={e => {
                      convertBase64(e.target.files);
                    }}
                  />
                </div>

                <Box display='flex' columnGap='10px' className='mt-4'>
                  <Button
                    id='xx'
                    className={`${tableStyles}.ordBtnPrimary} btn`}
                    // disabled={validForm()}
                    width='100px'
                    padding='8px'
                    fontSize='14px'
                    border={`2px solid ${colors.ordAquaMarine}`}
                    backgroundColor={'white'}
                    backgroundColorHover={colors.ordAquaMarine}
                    colorHover={'white'}
                    color={colors.ordAquaMarine}
                    onClick={() => {
                      setModal(false);
                      setImage(image || activeEvent?.patient?.photo || '');
                    }}
                  >
                    Cancelar
                  </Button>

                  <Button
                    disabled={image ? false : true}
                    className={`${tableStyles}.ordBtnPrimary} btn`}
                    width='100px'
                    padding='8px'
                    fontSize='14px'
                    border='none'
                    backgroundColor={colors.ordAquaMarine}
                    backgroundColorHover={colors.ordAquaMarine + '90'}
                    onClick={() => {
                      messagePhotoSave();
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>

              {image && (
                <div>
                  <img
                    height={'200px'}
                    style={{ marginTop: '30px' }}
                    src={image}
                    alt='Screenshot'
                  />
                </div>
              )}
            </Box>
          </Box>
        </div>
      </Drawer>

      <OrdGenericTemplate
        rowStyle={{ alignItems: 'center' }}
        extraHeader={<DrawerAndAlert patientDocument={formatteDocument(patient?.document).format} patientId={patient?.id} />}
        className={`w-90 mt-4 mx-2`}
        showBottomBorder={false}
        title={'Admisión del paciente'}
        titleSize={6}
        showBackArrow
        backArrowAction={() => {
          setActiveEvent({ ...activeEvent, progress: 0 });
          history.push({ pathname: '/admision/admitirPacientes' });
        }}
      >
        <ProgressBar className={'mt-4 mb-3'} />

        <div
          className='my-4'
          style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', alignItems: 'start' }}
        >
          <Text
            classText={tableStyles.f16}
            title={'Información personal'}
            color={colors.ordAquaMarine}
            fontWeight='bold'
          />
          <div style={{ display: 'grid', gridTemplateColumns: '280px 1fr 1fr', gap: '20px' }}>
            <div>
              <Text
                classText={tableStyles.f14}
                title={'Fotografía'}
                color={colors.darkerBlue}
                fontWeight='bold'
              />

              {image ? (
                <Box
                  display='grid'
                  onClick={() => {
                    setModal(true);
                    setImage(image || activeEvent?.patient?.photo || '');
                  }}
                  sx={{
                    height: '89%',
                    borderRadius: '5px',
                    transition: 'all 0.3s',
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <img
                    style={{ transition: 'all 0.3s', width: '100%' }}
                    height={'230px'}
                    src={image}
                    alt=''
                  />

                  <Box
                    style={{ cursor: 'pointer' }}
                    display='flex'
                    alignItems={'end'}
                    justifyContent='end'
                    padding={'5px'}
                    onClick={() => {
                      setModal(true);
                      setImage(image || activeEvent?.patient?.photo || '');
                    }}
                  >
                    <img style={{ transition: 'all 0.3s' }} src={camera} alt='' />
                  </Box>
                </Box>
              ) : (
                <Box
                  display='grid'
                  sx={{
                    height: '89%',
                    borderRadius: '5px',
                    transition: 'all 0.3s',
                    border: '1px solid #00b4cc',
                    '&:hover': {
                      background: '#a3e4ec24',
                      cursor: 'pointer',
                    },
                    '&:hover img': {
                      transform: 'scale(1.2)',
                    },
                  }}
                >
                  <Box
                    display='flex'
                    alignItems={'end'}
                    justifyContent='center'
                    padding={'5px'}
                    onClick={() => {
                      setModal(true);
                      setImage(image || activeEvent?.patient?.photo || '');
                    }}
                  >
                    <Text
                      classText={tableStyles.f14}
                      title='Tomar foto o subir archivo'
                      color={colors.gray}
                      textAlign='center'
                    />
                  </Box>

                  <Box
                    display='flex'
                    alignItems={'end'}
                    justifyContent='end'
                    padding={'5px'}
                    onClick={() => {
                      setModal(true);
                      setImage(image || activeEvent?.patient?.photo || '');
                    }}
                  >
                    <img style={{ transition: 'all 0.3s' }} src={camera} alt='' />
                  </Box>
                </Box>
              )}
            </div>

            <div
              style={{
                display: 'flex',
                gap: '5px',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div style={{ height: '80px' }}>
                <Text
                  classText={tableStyles.f14}
                  title={'Tipo identificación'}
                  color={colors.darkBlue}
                  required
                />
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  options={listDocTypes?.results?.map(state => ({
                    label: state.name,
                    value: state.id,
                  }))}
                  value={{
                    label: listDocTypes?.results?.find(state => state?.id === patient?.docType)
                      ?.name,
                    value: listDocTypes?.results?.find(state => state?.id === patient?.docType)?.id,
                  }}
                  className='text-secondary w-100 '
                  placeholder={'Seleccionar...'}
                  styles={ordCustomSelect}
                  onChange={e => {
                    setActiveEvent({
                      ...activeEvent,
                      patient: { ...activeEvent.patient, docType: e.value },
                    });
                  }}
                ></Select>
              </div>

              <div style={{ height: '80px' }}>
                <div>
                  <Text
                    classText={tableStyles.f14}
                    title={'Primer Nombre'}
                    color={colors.darkBlue}
                    required
                  />

                  <input
                    type='text'
                    placeholder='Escribir...'
                    className={`register-inputs-gray text-secondary ${formError['firstName'] !== undefined && formError['firstName'].className}`}
                    defaultValue={patient?.firstName || ''}
                    value={patient?.firstName}
                    onChange={({ target }) => {
                      handleChangeValidation(
                        {
                          type: target.type,
                          value: target.value,
                          name: 'firstName',
                          min: 2,
                          max: 20,
                          messageMin: 'Mínimo 2 caracteres',
                          messageMax: 'Máximo 20 caracteres',
                          messageFirstLetter: 'Invalido',
                        },
                        { setFormError, formError },
                      );

                      setActiveEvent({
                        ...activeEvent,
                        patient: { ...activeEvent.patient, firstName: target.value },
                      });
                    }}
                  />
                </div>
                {formError['firstName'] !== undefined ? formError['firstName'].component : ''}
              </div>

              <div style={{ height: '80px' }}>
                <Text
                  classText={tableStyles.f14}
                  title={'Primer Apellido'}
                  color={colors.darkBlue}
                  required
                />

                <input
                  type='text'
                  placeholder='Escribir...'
                  className={`register-inputs-gray text-secondary ${formError['lastName'] !== undefined && formError['lastName'].className}`}
                  defaultValue={patient?.lastName || ''}
                  value={patient?.lastName}
                  onChange={({ target }) => {
                    handleChangeValidation(
                      {
                        type: target.type,
                        value: target.value,
                        name: 'lastName',
                        min: 2,
                        max: 20,
                        messageMin: 'Mínimo 2 caracteres',
                        messageMax: 'Máximo 20 caracteres',
                        messageFirstLetter: 'Invalido',
                      },
                      { setFormError, formError },
                    );
                    setActiveEvent({
                      ...activeEvent,
                      patient: { ...activeEvent.patient, lastName: target.value },
                    });
                  }}
                />
                {formError['lastName'] !== undefined ? formError['lastName'].component : ''}
              </div>

              <div style={{ height: '80px' }}>
                <Text
                  classText={tableStyles.f14}
                  title={'Fecha de nacimiento'}
                  color={colors.darkBlue}
                  required
                />

                <input
                  type='date'
                  value={patient?.birthdate}
                  className={`register-inputs-gray text-secondary ${formError['birthdate'] !== undefined && formError['birthdate'].className}`}
                  onChange={({ target }) => {
                    handleChangeValidation(
                      {
                        type: target.type,
                        value: target.value,
                        name: 'birthdate',
                        maxDate: moment(new Date()).format('YYYY-MM-DD'),
                        messageMax: 'La fecha no puede ser mayor a la actual',
                        message: 'Digite una fecha correcta',
                      },
                      { setFormError, formError },
                    );

                    setActiveEvent({
                      ...activeEvent,
                      patient: { ...activeEvent.patient, birthdate: target.value },
                    });
                  }}
                />
                {formError['birthdate'] !== undefined ? formError['birthdate'].component : ''}
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                gap: '5px',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div style={{ height: '80px' }}>
                <Text
                  classText={tableStyles.f14}
                  title={'Identificación'}
                  color={colors.darkBlue}
                  required
                />

                <input
                  type='text'
                  placeholder='Escribir...'
                  className={`register-inputs-gray text-secondary ${formError['document'] !== undefined && formError['document'].className}`}
                  defaultValue={formatteDocument(patient?.document).format || ''}
                  value={formatteDocument(patient?.document).format}
                  onChange={({ target }) => {
                    handleChangeValidation(
                      {
                        type: 'number',
                        value: formatteDocument(target.value).value,
                        name: 'document',
                        min: 8,
                        max: 15,
                        messageMin: 'Mínimo 8 caracteres',
                        messageMax: 'Máximo 15 caracteres',
                      },
                      { setFormError, formError },
                    );

                    setActiveEvent({
                      ...activeEvent,
                      patient: { ...activeEvent.patient, document: target.value },
                    });
                  }}
                />
                {formError['document'] !== undefined ? formError['document'].component : ''}
              </div>

              <div style={{ height: '80px' }}>
                <Text
                  classText={tableStyles.f14}
                  title={'Segundo nombre'}
                  color={colors.darkBlue}
                />

                <input
                  type='text'
                  placeholder='Escribir...'
                  className={`register-inputs-gray text-secondary ${formError['otherNames'] !== undefined && formError['otherNames'].className}`}
                  defaultValue={patient?.otherNames || ''}
                  value={patient?.otherNames}
                  onChange={({ target }) => {
                    handleChangeValidation(
                      {
                        type: target.type,
                        value: target.value,
                        name: 'otherNames',
                        min: 0,
                        max: 20,
                        messageMin: 'Mínimo 0 caracteres',
                        messageMax: 'Máximo 20 caracteres',
                        messageFirstLetter: 'Invalido',
                      },
                      { setFormError, formError },
                    );

                    setActiveEvent({
                      ...activeEvent,
                      patient: { ...activeEvent.patient, otherNames: target.value },
                    });
                  }}
                />
                {formError['otherNames'] !== undefined ? formError['otherNames'].component : ''}
              </div>

              <div style={{ height: '80px' }}>
                <Text
                  classText={tableStyles.f14}
                  title={'Segundo apellido'}
                  color={colors.darkBlue}
                />

                <input
                  type='text'
                  placeholder='Escribir...'
                  className={`register-inputs-gray text-secondary ${formError['secondSurname'] !== undefined && formError['secondSurname'].className}`}
                  defaultValue={patient?.secondSurname || ''}
                  value={patient?.secondSurname}
                  onChange={({ target }) => {
                    handleChangeValidation(
                      {
                        type: target.type,
                        value: target.value,
                        name: 'secondSurname',
                        min: 0,
                        max: 20,
                        messageMin: 'Mínimo 0 caracteres',
                        messageMax: 'Máximo 20 caracteres',
                        messageFirstLetter: 'Invalido',
                      },
                      { setFormError, formError },
                    );

                    setActiveEvent({
                      ...activeEvent,
                      patient: { ...activeEvent.patient, secondSurname: target.value },
                    });
                  }}
                />
                {formError['secondSurname'] !== undefined
                  ? formError['secondSurname'].component
                  : ''}
              </div>

              <div style={{ height: '80px' }}>
                <Text
                  classText={tableStyles.f14}
                  title={'Genero'}
                  color={colors.darkBlue}
                  required
                />
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  options={[
                    { label: 'Masculino', value: 'male' },
                    { label: 'Femenino', value: 'female' },
                  ]}
                  className='text-secondary w-100 '
                  placeholder={'Seleccionar...'}
                  styles={ordCustomSelect}
                  defaultValue={{
                    label: patient?.gender === 'male' ? 'Masculino' : 'Femenino',
                    value: patient?.gender,
                  }}
                  onChange={e => {
                    setActiveEvent({
                      ...activeEvent,
                      patient: { ...activeEvent.patient, gender: e.value },
                    });
                  }}
                ></Select>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div
          className='my-4'
          style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', alignItems: 'start' }}
        >
          <Text
            classText={tableStyles.f16}
            title={'Información de contacto'}
            color={colors.ordAquaMarine}
            fontWeight='bold'
          />
          <div style={{ display: 'grid', gap: '10px', gridTemplateColumns: '1fr' }}>
            <div style={{ display: 'grid', gap: '20px', gridTemplateColumns: '1fr 1fr 1fr' }}>
              <div>
                <Text classText={tableStyles.f14} title={'Teléfono'} color={colors.darkBlue} />

                <input
                  type='number'
                  placeholder='Escribir...'
                  className={`register-inputs-gray text-secondary ${formError['homephone'] !== undefined && formError['homephone'].className}`}
                  defaultValue={patient?.homephone || ''}
                  value={patient?.homephone}
                  onChange={({ target }) => {
                    handleChangeValidation(
                      {
                        type: target.type,
                        value: target.value,
                        name: 'homephone',
                        min: 0,
                        max: 7,
                        messageMin: 'Mínimo 0 caracteres',
                        messageMax: 'Máximo 7 caracteres',
                      },
                      { setFormError, formError },
                    );

                    setActiveEvent({
                      ...activeEvent,
                      patient: { ...activeEvent.patient, homephone: target.value },
                    });
                  }}
                />
                {formError['homephone'] !== undefined ? formError['homephone'].component : ''}
              </div>

              <div>
                <Text
                  classText={tableStyles.f14}
                  title={'Celular'}
                  color={colors.darkBlue}
                  required
                />

                <input
                  type='number'
                  placeholder='Escribir...'
                  className={`register-inputs-gray text-secondary ${formError['cellphone'] !== undefined && formError['cellphone'].className}`}
                  defaultValue={patient?.cellphone || ''}
                  value={patient?.cellphone}
                  onChange={({ target }) => {
                    handleChangeValidation(
                      {
                        type: target.type,
                        value: target.value,
                        name: 'cellphone',
                        min: 10,
                        max: 10,
                        messageMin: 'Mínimo 10 números',
                        messageMax: 'Máximo 10 números',
                      },
                      { setFormError, formError },
                    );

                    setActiveEvent({
                      ...activeEvent,
                      patient: { ...activeEvent.patient, cellphone: target.value },
                    });
                  }}
                />
                {formError['cellphone'] !== undefined ? formError['cellphone'].component : ''}
              </div>

              <div>
                <Text
                  classText={tableStyles.f14}
                  title={'Email'}
                  color={colors.darkBlue}
                  required
                />

                <input
                  type='email'
                  placeholder='Escribir...'
                  className={`register-inputs-gray text-secondary ${formError['email'] !== undefined && formError['email'].className}`}
                  defaultValue={patient?.email || ''}
                  value={patient?.email}
                  onChange={({ target }) => {
                    handleChangeValidation(
                      {
                        type: target.type,
                        value: target.value,
                        name: 'email',
                        min: 7,
                        max: 50,
                        messageInvalidEmail: 'Digite un correo correcto',
                        messageMin: 'Mínimo 7 caracteres',
                        messageMax: 'Máximo 50 caracteres',
                      },
                      { setFormError, formError },
                    );

                    setActiveEvent({
                      ...activeEvent,
                      patient: { ...activeEvent.patient, email: target.value },
                    });
                  }}
                />
                {formError['email'] !== undefined ? formError['email'].component : ''}
              </div>
            </div>

            <div style={{ display: 'grid', gap: '20px', gridTemplateColumns: '1fr 1fr 1fr' }}>
              <div>
                <Text
                  classText={tableStyles.f14}
                  title={'Whatsapp'}
                  color={colors.darkBlue}
                  required
                />

                <input
                  type='number'
                  placeholder='Escribir...'
                  className={`register-inputs-gray text-secondary ${formError['whatsapp'] !== undefined && formError['whatsapp'].className}`}
                  defaultValue={patient?.whatsapp || ''}
                  value={patient?.whatsapp}
                  onChange={({ target }) => {
                    handleChangeValidation(
                      {
                        type: target.type,
                        value: target.value,
                        name: 'whatsapp',
                        min: 10,
                        max: 10,
                        messageMin: 'Mínimo 10 números',
                        messageMax: 'Máximo 10 números',
                      },
                      { setFormError, formError },
                    );

                    setActiveEvent({
                      ...activeEvent,
                      patient: { ...activeEvent.patient, whatsapp: target.value },
                    });
                  }}
                />
                {formError['whatsapp'] !== undefined ? formError['whatsapp'].component : ''}
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div
          className='my-4'
          style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', alignItems: 'start' }}
        >
          <Text
            classText={tableStyles.f16}
            title={'Información de residencia'}
            color={colors.ordAquaMarine}
            fontWeight='bold'
          />
          <div style={{ display: 'grid', gap: '10px', gridTemplateColumns: '1fr' }}>
            <div style={{ display: 'grid', gap: '20px', gridTemplateColumns: '1fr 1fr 1fr' }}>
              <div>
                <Text
                  classText={tableStyles.f14}
                  title={'Departamento'}
                  color={colors.darkBlue}
                  required
                />
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  options={listDepartaments?.data?.map(state => ({
                    label: state.name,
                    value: state.id,
                  }))}
                  value={{
                    label: listDepartaments?.data?.find(state => state.id === patient?.province)
                      ?.name,
                    value: patient?.province,
                  }}
                  className='text-secondary w-100 '
                  placeholder={'Seleccionar...'}
                  styles={ordCustomSelect}
                  onChange={e => {
                    // setActiveEvent(state => ({
                    //     ...state,
                    //     province: e.value,
                    //     departamentLabel: e.label
                    // }))

                    setActiveEvent({
                      ...activeEvent,
                      patient: { ...activeEvent.patient, province: e.value },
                    });
                  }}
                ></Select>
              </div>

              <div>
                <Text classText={tableStyles.f14} title={'Municipio'} color={colors.darkBlue} />
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  // options={listCitiesFormat?.map(state => ({ label: state.name, value: state.id }))}
                  options={listCitiesFormat?.map(state => ({ label: state.name, value: state.id }))}
                  value={{
                    label: listCities?.data?.find(state => state.id === patient?.city)?.name,
                    value: patient?.city,
                  }}
                  className='text-secondary w-100 '
                  placeholder={'Seleccionar...'}
                  styles={ordCustomSelect}
                  onChange={e => {
                    // setActiveEvent(state => ({
                    //     ...state,
                    //     city: e.value,
                    //     cityLabel: e.label
                    // }))

                    setActiveEvent({
                      ...activeEvent,
                      patient: { ...activeEvent.patient, city: e.value },
                    });
                  }}
                ></Select>
              </div>

              <div>
                <Text classText={tableStyles.f14} title={'Zona'} color={colors.darkBlue} />
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  options={[
                    { label: 'Urbana', value: 'Urbana' },
                    { label: 'Rural', value: 'Rural' },
                  ]}
                  className='text-secondary w-100 '
                  placeholder={'Seleccionar...'}
                  styles={ordCustomSelect}
                  defaultValue={{ label: patient?.addressZone, value: patient?.addressZone }}
                  onChange={e => {
                    setActiveEvent({
                      ...activeEvent,
                      patient: { ...activeEvent.patient, addressZone: e.value },
                    });
                  }}
                ></Select>
              </div>
            </div>

            <div style={{ display: 'grid', gap: '20px', gridTemplateColumns: '1fr 1fr 1fr' }}>
              <div>
                <Text classText={tableStyles.f14} title={'Dirección'} color={colors.darkBlue} />

                <input
                  type='text'
                  placeholder='Escribir...'
                  className={`register-inputs-gray text-secondary ${formError['homeAddress'] !== undefined && formError['homeAddress'].className}`}
                  defaultValue={patient?.homeAddress || ''}
                  value={patient?.homeAddress}
                  onChange={({ target }) => {
                    handleChangeValidation(
                      {
                        type: target.type,
                        value: target.value,
                        name: 'homeAddress',
                        min: 2,
                        max: 20,
                        messageMin: 'Mínimo 2 caracteres',
                        messageMax: 'Máximo 20 caracteres',
                        messageFirstLetter: 'Invalido',
                      },
                      { setFormError, formError },
                    );

                    setActiveEvent({
                      ...activeEvent,
                      patient: { ...activeEvent.patient, homeAddress: target.value },
                    });
                  }}
                />
                {formError['homeAddress'] !== undefined ? formError['homeAddress'].component : ''}
              </div>

              <div>
                <Text classText={tableStyles.f14} title={'Barrio'} color={colors.darkBlue} />

                <input
                  type='text'
                  placeholder='Escribir...'
                  className={`register-inputs-gray text-secondary ${formError['addressNeighborhood'] !== undefined && formError['addressNeighborhood'].className}`}
                  defaultValue={patient?.addressNeighborhood || ''}
                  value={patient?.addressNeighborhood}
                  onChange={({ target }) => {
                    handleChangeValidation(
                      {
                        type: target.type,
                        value: target.value,
                        name: 'addressNeighborhood',
                        min: 2,
                        max: 50,
                        messageMin: 'Mínimo 2 caracteres',
                        messageMax: 'Máximo 50 caracteres',
                        messageFirstLetter: 'Invalido',
                      },
                      { setFormError, formError },
                    );

                    setActiveEvent({
                      ...activeEvent,
                      patient: { ...activeEvent.patient, addressNeighborhood: target.value },
                    });
                  }}
                />
                {formError['addressNeighborhood'] !== undefined
                  ? formError['addressNeighborhood'].component
                  : ''}
              </div>
            </div>
          </div>
        </div>

        <Box className='d-flex justify-content-end my-5'>
          <Button
            className={`${tableStyles}.ordBtnPrimary} btn`}
            disabled={validForm()}
            width='130px'
            padding='8px'
            fontSize='14px'
            border='none'
            backgroundColor={colors.ordAquaMarine}
            backgroundColorHover={colors.ordAquaMarine + '90'}
            onClick={() => {
              setActiveEvent({ ...activeEvent, progress: 2 });
              history.push({ pathname: '/admision/admitirPacientes/informacionAdicional' });
            }}
          >
            Siguiente
            <img className={`${tableStyles.iconWhite} mx-2`} src={next} alt='next' />
          </Button>
        </Box>
      </OrdGenericTemplate>
    </>
  );
};
