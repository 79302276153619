import { API_VERSION, URL_GATEWAY } from '../helpers/constants';
import { convertFilterToString } from '../helpers/convertToFilter';
import { customSwaltAlert } from '../helpers/customSwaltAlert';
import {
  CREATE_PUC_ACCOUNT_SUCCESS,
  ERROR_CREATE_PUC_ACCOUNT,
  GET_ALL_ACCOUNTS,
  GET_ALL_BANK,
  GET_LOCAL_ACCOUNTS,
  GET_NIIF_ACCOUNTS,
  PUC_LOADING,
  SET_INDEX_LOCAL_ACCOUNTS,
  SET_INDEX_NIIF,
  SET_INDEX_PUC,
  SET_TOTAL_INDEX_PUC,
} from './actionTypes';

// ANCHOR GET ALL ACCOUNTS
export const getAllPUCAccounts = objFilters => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/puc/accounts?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      var { results, row_total, totalIndex } = res; // Obtenemos la propiedad data de la petición que contiene un array de objetos
      dispatch({
        type: GET_ALL_ACCOUNTS,
        payload: results,
      });
      dispatch({
        type: SET_INDEX_PUC,
        payload: row_total,
      });
      dispatch({
        type: SET_TOTAL_INDEX_PUC,
        payload: totalIndex,
      });
    })
    .catch(err => {
      console.error(err);
      dispatch({
        type: GET_ALL_ACCOUNTS,
        payload: [],
      });

      customSwaltAlert({
        icon: 'error',
        title: 'Error al traer las cuentas',
        text: 'Ocurrió un error al intentar traer la información de las cuentas.',
        footer: 'Si el problema persiste comunícate con un asesor.',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    });
};

export const getAllBanks = objFilters => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/bankingEntities?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      if(res.success){
        dispatch({
          type: GET_ALL_BANK,
          payload: res.results,
        });
      }
    })
    .catch(err => {
      console.error(err);
      dispatch({
        type: GET_ALL_BANK,
        payload: [],
      });

      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Ocurrió un error al intentar traer la información de los bancos.',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    });
};

// ANCHOR GET METHOD FOR NIIF ACCOUNTS
export const getNiifAccounts = objFilters => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/niif?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res; // Obtenemos la propiedad data de la petición que contiene un array de objetos
      dispatch({
        type: GET_NIIF_ACCOUNTS,
        payload: results,
      });
      dispatch({
        type: SET_INDEX_NIIF,
        payload: row_total,
      });
    })
    .catch(error => {
      console.error(error);

      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Ocurrió un error al intentar traer la información de las cuentas.',
        footer: 'Si el problema persiste conmuníquese con un asesor.',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    });
};

// ANCHOR GET NIIF BY NUMBER
export const getNiifByNumber = number => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const eaccount = getState().loginReducer.currentAccount.id;
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/niif/${number}?eaccount=${eaccount}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: GET_NIIF_ACCOUNTS,
        payload: res,
      });
      return res;
    })
    .catch(error => {
      console.error(error);

      customSwaltAlert({
        icon: 'error',
        title: 'Error al traer la cuenta',
        text: 'Ocurrió un error al intentar traer la información de la cuenta.',
        footer: 'Si el problema persiste conmuníquese con un asesor.',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    });
};

// ANCHOR GET LOCAL ACCOUNTS
export const getLocalAccounts = objFilters => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/localaccount?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res; // Obtenemos la propiedad data de la petición que contiene un array de objetos
      dispatch({
        type: GET_LOCAL_ACCOUNTS,
        payload: results,
      });
      dispatch({
        type: SET_INDEX_LOCAL_ACCOUNTS,
        payload: row_total,
      });
    })
    .catch(error => {
      console.error(error);

      customSwaltAlert({
        icon: 'error',
        title: 'Error al traer las cuentas',
        text: 'Ocurrió un error al intentar traer la información de las cuentas.',
        footer: 'Si el problema persiste conmuníquese con un asesor.',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    });
};

// ANCHOR CHANGE STATUS ACCOUNT
export const changeStatus = objPuc => (dispatch, getState) => {
  const updated_at = Date.now();
  const IDCurrent = getState().loginReducer.currentAccount.id;
  const defaultFiltersPuc = {
    perpage: 10,
    page: 1,
    search: '',
    eaccount: IDCurrent,
  };
  objPuc['updated_at'] = updated_at;
  const token = getState().loginReducer.Authorization;
  let status = objPuc.active ? 'deshabilitar' : 'habilitar';

  customSwaltAlert({
    icon: 'warning',
    title: '¿Está seguro?',
    text: `Se va ${status} la cuenta: ${objPuc.description}<br>
      ${
        objPuc.type === 'niif' && objPuc.localAccount && objPuc.active === true
          ? 'Al deshabilitar, también deshabilitará la cuenta local asociada.'
          : ''
      }`,
    showCancelButton: true,
  }).then(result => {
    if (result.isConfirmed) {
      dispatch({
        type: PUC_LOADING,
        payload: true,
      });
      objPuc.active = !objPuc.active;
      objPuc.active = objPuc.active !== false ? 1 : 0;
      let type = objPuc.type === 'local' ? 'localaccount' : objPuc.type;
      objPuc.code = objPuc.complete_account;
      if (objPuc.reserved) {
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Esta cuenta es reservada y no se puede editar.',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
      fetch(`${URL_GATEWAY}${API_VERSION}/admin/${type}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(objPuc),
      })
        .then(response => response.json())
        .then(res => {
          dispatch({
            type: PUC_LOADING,
            payload: false,
          });
          if (res.success) {
            setTimeout(() => {
              customSwaltAlert({
                icon: 'success',
                title: `Actualizada exitosamente`,
                text: res.message,
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
              });

              dispatch(getAllPUCAccounts(defaultFiltersPuc));
            }, 500);
          } else {
            customSwaltAlert({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: `${res.message}`,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          }
        })
        .catch(() => {
          customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: 'No se pudo cambiar el estado',
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        });
    }
  });
};

// ANCHOR CREATE PUC
export const createPuc = (objPuc, toggleModal) => (dispatch, getState) => {
  const created_at = Date.now();
  const updated_at = Date.now();
  const IDCurrent = getState().loginReducer.currentAccount.id;
  const defaultFiltersPuc = {
    perpage: 10,
    page: 1,
    search: '',
    eaccount: IDCurrent,
  };
  objPuc['created_at'] = created_at;
  objPuc['updated_at'] = updated_at;
  const token = getState().loginReducer.Authorization;

  dispatch({
    type: PUC_LOADING,
    payload: true,
  });

  let type = objPuc.type === 'local' ? 'localaccount' : objPuc.type;
  if (type === 'niif') {
    delete objPuc['niifLinked'];
    delete objPuc['niifDescription'];
  }
  if (type === 'localaccount') {
    if (objPuc.niifLinked !== '' && objPuc.niifLinked !== null) {
      dispatch(getNiifByNumber(objPuc.niifLinked));
    } else {
      dispatch({
        type: PUC_LOADING,
        payload: false,
      });
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe llenar todos los campos para agregar una cuenta o subcuenta',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }
  }
  setTimeout(async () => {
    objPuc.uses_third = objPuc.uses_third != null ? objPuc.uses_third : false;
    objPuc.uses_cost_center = objPuc.uses_cost_center != null ? objPuc.uses_cost_center : false;
    objPuc.check_info_bank = objPuc.check_info_bank != null ? objPuc.check_info_bank : false;
    if (type === 'localaccount') {
      var niifLink = getState().pucReducer.niifAccounts;
      if (
        objPuc.uses_cost_center !== niifLink.uses_cost_center ||
        objPuc.uses_third !== niifLink.uses_third
      ) {
        if (niifLink !== undefined && niifLink !== null) {
          if (typeof niifLink === 'object') {
            let complete_account = niifLink.complete_account;
            let code = objPuc.code;
            if (complete_account && code) {
              if (
                complete_account.length !== code.length ||
                complete_account === null ||
                complete_account === undefined
              ) {
                customSwaltAlert({
                  icon: 'warning',
                  title: 'Intenta de nuevo',
                  text: 'Error, debe elegir una cuenta NIIF válida',
                  confirmButtonText: 'Aceptar',
                  showCancelButton: false,
                });

                dispatch({
                  type: PUC_LOADING,
                  payload: false,
                });
              }
            }
          } else {
            let code = objPuc.code;
            if (niifLink.length !== code.length) {
              dispatch({
                type: PUC_LOADING,
                payload: false,
              });

              return customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
              });
            }
          }
        } else {
          dispatch({
            type: PUC_LOADING,
            payload: false,
          });
          return customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: 'Error, debe elegir una cuenta NIIF válida',
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        }
      }
      fetch(`${URL_GATEWAY}${API_VERSION}/admin/${type}`, {
        method: 'POST',
        body: JSON.stringify(objPuc),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
      })
        .then(response => response.json())
        .then(res => {
          if (res.success) {
            toggleModal();
            customSwaltAlert({
              icon: 'success',
              title: `Creada exitosamente`,
              text: res.message,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });

            dispatch({
              type: CREATE_PUC_ACCOUNT_SUCCESS,
              payload: true,
            });

            dispatch(getAllPUC(defaultFiltersPuc));

            dispatch({
              type: PUC_LOADING,
              payload: false,
            });
          } else {
            customSwaltAlert({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: `${res.message}`,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });

            dispatch({
              type: PUC_LOADING,
              payload: false,
            });
          }
        })
        .catch(err => {
          console.error(err);

          dispatch({
            type: PUC_LOADING,
            payload: false,
          });

          dispatch({
            type: ERROR_CREATE_PUC_ACCOUNT,
            payload: err,
          });
        });
    } else {
      fetch(`${URL_GATEWAY}${API_VERSION}/admin/${type}`, {
        method: 'POST',
        body: JSON.stringify(objPuc),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          token: token,
        },
      })
        .then(response => response.json())
        .then(res => {
          if (res.success) {
            toggleModal();
            customSwaltAlert({
              icon: 'success',
              title: `Cuenta creada exitosamente`,
              text: res.message,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });

            dispatch({
              type: CREATE_PUC_ACCOUNT_SUCCESS,
              payload: true,
            });
            dispatch(getAllPUC(defaultFiltersPuc));

            dispatch({
              type: PUC_LOADING,
              payload: false,
            });
          } else {
            customSwaltAlert({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: res.message,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });

            dispatch({
              type: PUC_LOADING,
              payload: false,
            });
          }
        })
        .catch(err => {
          customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: `Ocurrió un error al tratar de crear la cuenta`,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });

          console.error(err);
          dispatch({
            type: ERROR_CREATE_PUC_ACCOUNT,
            payload: err,
          });

          dispatch({
            type: PUC_LOADING,
            payload: false,
          });
        });
    }
  }, 500);
};

// ANCHOR UPDATE PUC
export const updatePuc = (objPuc, doAfter) => (dispatch, getState) => {
  dispatch({
    type: GET_ALL_ACCOUNTS,
    loading: true,
  });
  const updated_at = Date.now();
  objPuc['updated_at'] = updated_at;
  const token = getState().loginReducer.Authorization;
  const IDCurrent = getState().loginReducer.currentAccount.id;
  const defaultFiltersPuc = {
    perpage: 10,
    page: 1,
    search: '',
    eaccount: IDCurrent,
  };
  let type = objPuc.type === 'local' ? 'localaccount' : objPuc.type;
  if (type === 'niif') delete objPuc['niifLinked'];
  if (type === 'localaccount' && !objPuc.niifLinked) {
    dispatch({
      type: GET_ALL_ACCOUNTS,
      loading: false,
    });
    dispatch(getAllPUC(defaultFiltersPuc));
    return customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: 'Para las cuentas locales es obligatorio una homologación NIIF.',
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
  }
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/${type}`, {
    method: 'PUT',
    body: JSON.stringify(objPuc),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      if (res.success) {
        dispatch({
          type: GET_ALL_ACCOUNTS,
          loading: false,
        });
        doAfter()

        customSwaltAlert({
          icon: 'success',
          title: `Actualizado exitosamente`,
          text: `Se ha actualizado el plan único de cuenta: ${objPuc.description}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });

        dispatch({
          type: CREATE_PUC_ACCOUNT_SUCCESS,
          payload: true,
        });
        dispatch(getAllPUC(defaultFiltersPuc));
      } else {
        dispatch({
          type: GET_ALL_ACCOUNTS,
          loading: false,
        });
        dispatch(getAllPUC(defaultFiltersPuc));

        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `${res.message}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
        console.error(res.message);
      }
    })
    .catch(err => {
      console.error(err);
      dispatch(getAllPUC(defaultFiltersPuc));

      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Ocurrió un error al guardar los cambios',
        footer: 'Si el problema persiste comuníquese con un asesor.',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    });
};

export const cleanNiifAccountLinked = () => dispatch => {
  dispatch({
    type: GET_NIIF_ACCOUNTS,
    payload: [],
  });
};

// función renovada para traer el listado de cuentas
export const getAllPUC = params => (dispatch, getState) => {
  delete params.active;
  dispatch({
    type: GET_ALL_ACCOUNTS,
    loading: true,
  });
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(params);
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/puc/accounts?${filters}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      if (res.success) {
        dispatch({
          type: GET_ALL_ACCOUNTS,
          payload: res.results,
          loading: false,
        });
        dispatch({
          type: SET_INDEX_PUC,
          payload: res.row_total,
        });
        dispatch({
          type: SET_TOTAL_INDEX_PUC,
          payload: res.totalIndex,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: PUC_LOADING,
        payload: false,
      });

      customSwaltAlert({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al listar las cuentas',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    });
};
