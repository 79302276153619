//react
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import moment from 'moment';

//icons
import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg';
import checkedGreen from '../../assets/img/icons/CheckGreen.svg';

//utils
import { customSwaltAlertAsistencial, isEmptyOrUndefined, loader, today } from '../../helpers';

//styles
import styles from '../../components/Layouts/tableStyle.module.scss';

//hooks
import { useGetMethod, usePostMethod } from '../../Hooks';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

//components
import { Col, Form, Row } from 'react-bootstrap';
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import Select from 'react-select';
import { ordCustomSelect } from '../../components/Layouts/react-select-custom';
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';
import { formatNumberWithoutDecimals } from '../../helpers/numberFormatting';

const headers = [
  {
    title: 'Fecha',
    className: `px-2 text-center`,
  },

  {
    title: 'Tipo',
    className: `px-2 text-center`,
  },

  {
    title: 'Monto',
    className: `px-2 text-end`,
  },

  {
    title: 'Registrado por',
    className: `px-2 text-center`,
  },

  {
    title: 'Estado',
    className: `px-2 text-center`,
  },
  {
    title: '',
    className: 'px-2',
  },
];

export const AdmissionGeneralBalanceDetail = () => {
  // states
  const history = useHistory();
  const location = useLocation();
  const patientId = location?.state?.patientId;
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const eaccount = store.loginReducer.currentAccount.id;
  const userId = store.loginReducer.user_data.id;

  const initialStateFilters = {
    dateMax: '',
    dateMin: '',
    search: '',
    status: '',
    type: '',
    page: 1,
    perpage: 10,
    eaccount,
  };

  const [data, setData] = useState({
    trigger: 0,
    filters: { ...initialStateFilters },
  });
  const [checkedPendings, setCheckedPendings] = useState([]);

  const { trigger } = data;
  const { dateMax, dateMin, page, perpage } = data.filters;

  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className={`text-center px-2`}>
              {item?.createdAt ? moment(item?.createdAt).format('YYYY-MM-DD') : '-'}
            </td>

            <td className={`text-center px-2`}>{item?.type || '-'}</td>

            <td className={`text-end px-2`}>
              {item?.amount ? formatNumberWithoutDecimals(item?.amount) : '-'}
            </td>

            <td className={`text-center px-2`}>{item?.createdBy || '-'}</td>

            <td className={`text-center px-2`}>
              {item?.status ? (
                <div
                  className={
                    item?.status === 'pending' ? styles.lightRedState : styles.lightGreenState
                  }
                >
                  {item?.status === 'pending' ? 'Pendiente' : 'Entregado'}
                </div>
              ) : (
                '-'
              )}
            </td>
            <td>
              {item?.status === 'pending' ? (
                <input
                  type='checkbox'
                  className={`${styles.checkClearBlue} ${styles.appCheckInput} ${styles.appCheckClear} form-check-input m-0`}
                  onChange={({ target }) => {
                    if (target.checked) {
                      setCheckedPendings([...checkedPendings, item.pwmId]);
                    } else {
                      setCheckedPendings([...checkedPendings.filter(c => c !== item.pwmId)]);
                    }
                  }}
                  checked={checkedPendings.includes(item.pwmId)}
                />
              ) : (
                <img src={checkedGreen} width={20} alt='' />
              )}
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  //request
  const {
    load: loadListGeneralBalance,
    results: listGeneralBalance,
    trigger: getListGeneralBalance,
  } = useGetMethod();

  const { trigger: getListTypes } = useGetMethod();
  const [options, setOptions] = useState([]);

  const { load: generateLoader, trigger: createMovement } = usePostMethod();

  useEffect(() => {
    if (isEmptyOrUndefined(patientId)) {
      customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `Paciente no encontrado`,
        showCancelButton: false,
      }).finally(() => {
        history.push({
          pathname: `/admision/vueltos/`,
        });
      });
    } else {
      getListGeneralBalance({
        token,
        objFilters: { ...data.filters },
        url: `/medical/patient/${patientId}/wallet/movement`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  useEffect(() => {
    if (trigger) {
      getListGeneralBalance({
        token,
        objFilters: { ...data.filters },
        url: `/medical/patient/${patientId}/wallet/movement`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    getListTypes({
      token,
      objFilters: { ...data.filters },
      url: `/medical/patient/movements-types`,
    })
      .then(result => {
        const formattedOptions = result.results.map(item => ({
          value: item.name,
          label: item.description,
        }));
        const allOption = { value: undefined, label: 'Seleccionar...' };
        setOptions([allOption, ...formattedOptions]);      })
      .catch(error => {
        console.error('Error fetching options:', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const updateFilters = newFilters => {
    setData(state => {
      const updatedState = {
        ...state,
        trigger: state.trigger + 1,
        filters: {
          ...state.filters,
          ...newFilters,
        },
      };
      return updatedState;
    });
  };

  const onCreateMovement = () => {
    createMovement({
      method: 'POST',
      url: `/medical/patient/${patientId}/wallet/movement`,
      token,
      body: {
        pwmIds: checkedPendings,
        eaccount: eaccount,
        userId: userId,
      },
      succesAction: results => {
        void customSwaltAlertAsistencial({
          icon: 'success',
          title: 'Entregado exitosamente',
          text: results.message,
          showCancelButton: false,
        });
        setCheckedPendings([]);
        history.goBack();
      },
      doAfterException: () => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Ha ocurrido un error al intentar entregar el vuelto.',
          showCancelButton: false,
        });
      },
    });
  };

  return (
    <>
      {(loadListGeneralBalance || generateLoader) && loader}

      {!isEmptyOrUndefined(patientId) ? (
        <>
          <OrdGenericTemplate
            className='w-92 my-4'
            showBottomBorder={false}
            title={'Detalle de movimiento'}
            titleSize={12}
            showBackArrow={true}
            backArrowAction={() => history.push('/admision/vueltos')}
          >
            <div className='me-4 my-2 asistencialDateColorIcon'>
              <Row className='d-flex px-1'>
                <Col xs={2} className={`px-0 align-self-end`}>
                  <Form.Group className='px-2' controlId='ordType'>
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
                      <span>&nbsp;Estado</span>
                    </Form.Label>

                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      className='text-secondary '
                      placeholder={'Seleccionar...'}
                      styles={ordCustomSelect}
                      options={[
                        { label: 'Seleccionar...', value: '' },
                        { label: 'Entregado', value: 'delivered' },
                        { label: 'Pendiente', value: 'pending' },
                      ]}
                      onChange={({ value }) => {
                        updateFilters({ status: value, page: 1 });
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col xs={2} className={`px-0 align-self-end`}>
                  <Form.Group className='px-2' controlId='ordType'>
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
                      <span>&nbsp;Tipo</span>
                    </Form.Label>

                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      className='text-secondary '
                      placeholder={'Seleccionar...'}
                      styles={ordCustomSelect}
                      options={options}
                      onChange={({ value }) => {
                        updateFilters({ type: value, page: 1 });
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col xs={2} className={`px-0 align-self-end`}>
                  <Form.Group className='px-2' controlId='ordType'>
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
                      <span>&nbsp;Fecha desde</span>
                    </Form.Label>
                    <input
                      className={`ord-roundInput2`}
                      style={dateMin ? { color: '#1a538d' } : {}}
                      type='date'
                      id='date-start'
                      value={dateMin}
                      onChange={e => {
                        setData(state => ({
                          ...state,
                          filters: {
                            ...state.filters,
                            dateMin: e.target.value,
                          },
                        }));
                      }}
                      max={today()}
                    />
                  </Form.Group>
                </Col>

                <Col xs={2} className={`px-0 align-self-end`}>
                  <Form.Group className=' px-2' controlId='ordType'>
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
                      <span>&nbsp;Hasta</span>
                    </Form.Label>
                    <input
                      className={`ord-roundInput2`}
                      style={dateMax ? { color: '#1a538d' } : {}}
                      id='date-end'
                      type='date'
                      value={dateMax}
                      min={dateMin}
                      max={today()}
                      onChange={e => {
                        setData(state => ({
                          ...state,
                          filters: {
                            ...state.filters,
                            dateMax: e.target.value,
                          },
                        }));
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col xs={4} className='align-self-end'>
                  <Form.Group className=' text-start' controlId='fName'>
                    <div
                      className={`d-flex ${styles.appGrayInputSearch} ${styles.f12} align-self-end`}
                    >
                      <input
                        className={`ord-roundInput ${styles.appInputDate} w-100`}
                        type='text'
                        placeholder='Escribe aquí para buscar'
                        autoComplete='off'
                        onChange={e => {
                          setData(state => ({
                            ...state,
                            filters: {
                              ...state.filters,
                              search: e.target.value,
                            },
                          }));
                        }}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            setData(state => ({
                              ...state,
                              trigger: trigger + 1,
                              filters: { ...state.filters, page: 1 },
                            }));
                          }
                        }}
                      />
                      <img
                        src={ordBlueSearch}
                        className='pointer'
                        alt='buscar'
                        onClick={() => {
                          setData(state => ({
                            ...state,
                            trigger: trigger + 1,
                            filters: {
                              ...state.filters,
                              page: 1,
                            },
                          }));
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* SECTION TABLE */}
              <OrdTable
                shadow
                headers={headers}
                hasChildren={true}
                oneHeadDarkBlue={false}
                lowerCase={false}
                paginate={{
                  activePage: page,
                  totalPages: listGeneralBalance?.rowTotal,
                  perPage: perpage,
                  pageRangeDisplayed: 3,
                  showTextDetails: true,
                  onChangePage: async e => {
                    setData(state => ({
                      ...state,
                      filters: { ...state?.filters, page: e },
                      trigger: trigger + 1,
                    }));
                  },
                }}
              >
                {formatData(listGeneralBalance?.results || [])}
              </OrdTable>
            </div>

            <div className='d-flex justify-content-end mb-5'>
              <button
                disabled={checkedPendings.length < 1}
                className={`${styles.tlnBtnPrimary} btn`}
                onClick={onCreateMovement}
              >
                Entregar vuelto
              </button>
            </div>
          </OrdGenericTemplate>
        </>
      ) : (
        ''
      )}
    </>
  );
};
