import { useEffect, useState } from 'react';
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

import warningIcon from '../../../assets/img/icons/alertDangerAnimate.svg';
import app_editar from '../../../assets/img/icons/app_editar.svg';
import searchIconApp from '../../../assets/img/icons/searchIconApp.svg';

import { loader } from '../../../helpers/helpers';
import { useGetMethod } from '../../../Hooks/useFetch';

import { OrdBiTable } from '../../../OrderingModule/OrderingComponents/OrdBiTable';
import OrdTable from '../../../OrderingModule/OrderingComponents/OrdTable';
import OrdModal from './../../../OrderingModule/OrderingComponents/OrdModal';

import styles from '../../../components/Layouts/tableStyle.module.scss';
import { VoluntaryInsuranceInfo } from '../HandlePatient/VoluntaryInsuranceInfo';
import { formatteDocument } from './../../../helpers';

const InfoPatient = ({ token, options, patient, setOptions, getIdComment, detailPatient }) => {
  const [filter, setFilter] = useState({
    page: 1,
    perpage: 10,
  });

  const {
    results: appointmentHistory,
    load: loaderAppointmentHistory,
    trigger: getAppointmentHistory,
  } = useGetMethod();

  const [modalActive, setModalActive] = useState(false);
  const [services, setServices] = useState({
    currentItem: {},
    status: '',
    header: [
      {
        title: 'Fecha',
        className: 'col-2 text-center',
      },
      {
        title: 'Servicio',
        className: 'col-3 text-start',
      },
      {
        title: 'Estado',
        className: 'col-2 text-center',
      },
      {
        title: <>&nbsp;</>,
        className: 'col-1 text-end px-1',
      },
    ],
  });

  useEffect(() => {
    void getAppointmentHistory({
      url: `/medical/appointment/`,
      token: token,
      objFilters: { idPatient: patient.id },
    });
  }, [getAppointmentHistory, patient.id, token]);

  const collectAppointmentInfo = [
    {
      title: 'Efectivo',
      subTitle: '-',
      titleSize: 3,
      subTitleSize: 6,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Total débito',
      subTitle: '-',
      titleSize: 3,
      subTitleSize: 6,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Total',
      subTitle: '-',
      titleSize: 3,
      subTitleSize: 6,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Asesor',
      subTitle: '-',
      titleSize: 3,
      subTitleSize: 6,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Fecha',
      subTitle: '-',
      titleSize: 3,
      subTitleSize: 6,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
  ];

  const appointmentInfo = [
    {
      title: 'Sede',
      subTitle: services?.currentItem?.siteName,
      titleSize: 6,
      subTitleSize: 6,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Médico',
      subTitle: services?.currentItem?.fullNameDoctor,
      titleSize: 6,
      subTitleSize: 6,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Servicio',
      subTitle: services?.currentItem?.serviceName,
      titleSize: 6,
      subTitleSize: 6,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Fecha',
      subTitle: services?.currentItem?.dateApp
        ? services?.currentItem?.dateApp + ' ' + services?.currentItem?.timeApp
        : '-',
      titleSize: 6,
      subTitleSize: 6,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'No. Historia clínica',
      subTitle: services?.currentItem?.clinicalHistoryID,
      titleSize: 6,
      subTitleSize: 6,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Diagnóstico',
      subTitle: services?.currentItem?.dxName,
      titleSize: 6,
      subTitleSize: 6,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
  ];

  const insurenceAppointmentInfo = [
    {
      title: 'Aseguradora',
      subTitle: services?.currentItem?.ccName,
      titleSize: 5,
      subTitleSize: 7,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Contrato',
      subTitle: services?.currentItem?.contractName,
      titleSize: 5,
      subTitleSize: 7,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Población',
      subTitle: services?.currentItem?.popName,
      titleSize: 5,
      subTitleSize: 7,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Grupo de ingreso',
      subTitle: services?.currentItem?.crtName,
      titleSize: 5,
      subTitleSize: 7,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Observaciones',
      subTitle: services?.currentItem?.appObservations,
      titleSize: 5,
      subTitleSize: 7,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
  ];

  const RenderModal = () => {
    return (
      <div className='row'>
        <Col xs={3} style={{ marginTop: '10px' }}>
          <div className={styles.app_container_content_form}>
            <Form.Group controlId='fName'>
              <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                <span className='px-1 fw-bold'>Identificación del paciente</span>
              </Form.Label>
              <Form.Control
                className={styles.app_inputs_detail_patient}
                type='text'
                disabled
                value={formatteDocument(detailPatient?.results?.document).format}
                placeholder='-'
              />
            </Form.Group>
          </div>

          <div className={styles.app_container_content_form}>
            <Form.Group controlId='fName'>
              <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                <span className='px-1 fw-bold'>Primer Nombre </span>
              </Form.Label>
              <Form.Control
                className={styles.app_inputs_detail_patient}
                type='text'
                disabled
                value={detailPatient?.results?.firstName}
                placeholder='-'
              />
            </Form.Group>
          </div>

          <div className={styles.app_container_content_form}>
            <Form.Group controlId='fName'>
              <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                <span className='px-1 fw-bold'>Primer Apellido</span>
              </Form.Label>
              <Form.Control
                className={styles.app_inputs_detail_patient}
                type='text'
                disabled
                value={detailPatient?.results?.lastName}
                placeholder='-'
              />
            </Form.Group>
          </div>

          <div className={styles.app_container_content_form}>
            <Form.Group controlId='fName'>
              <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                <span className='px-1 fw-bold'>Fecha de nacimiento</span>
              </Form.Label>
              <Form.Control
                className={styles.app_inputs_detail_patient}
                type='date'
                disabled
                value={detailPatient?.results?.birthDate}
                placeholder='-'
              />
            </Form.Group>
          </div>

          <div className={styles.app_container_content_form}>
            <Form.Group controlId='fName'>
              <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                <span className='px-1 fw-bold'>WhatsApp</span>
              </Form.Label>
              <Form.Control
                className={styles.app_inputs_detail_patient}
                type='text'
                disabled
                value={detailPatient?.results?.whatsapp}
                placeholder='-'
              />
            </Form.Group>
          </div>

          <div className={styles.app_container_content_form}>
            <Form.Group controlId='fName'>
              <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                <span className='px-1 fw-bold'>Correo electrónico</span>
              </Form.Label>
              <Form.Control
                className={styles.app_inputs_detail_patient}
                type='email'
                disabled
                value={detailPatient?.results?.email}
                placeholder='-'
              />
            </Form.Group>
          </div>
        </Col>

        <Col xs={9} style={{ marginTop: '10px' }}>
          <div>
            <div className='d-flex justify-content-between align-items-center ml-5'>
              <div>
                <span className={styles.app_title_order}>
                  Identificador de la Cita No. {services.currentItem?.id}
                </span>
              </div>
            </div>

            <Row style={{ marginBottom: '20px', marginTop: '20px' }}>
              <Col xs={6}>
                <div>
                  <span className={styles.app_container_biTable_title}>Información de la cita</span>
                  <OrdBiTable data={appointmentInfo} />
                </div>
              </Col>
              <Col xs={6}>
                <div>
                  <span className={styles.app_container_biTable_title}>Aseguradora</span>
                  <OrdBiTable data={insurenceAppointmentInfo} />
                </div>
              </Col>
            </Row>

            <div>
              <span className={styles.app_container_biTable_title}>Recaudo</span>
              <OrdBiTable data={collectAppointmentInfo} />
            </div>
          </div>
        </Col>
      </div>
    );
  };

  const formatDataHistory = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        if (index < 10) {
          tempList.push(
            <tr key={index} className={`hover-table-row`}>
              <td className={`text-center`}>{item.dateApp}</td>
              <td className={`text-start px-2`}>{item.serviceName}</td>
              <td className={`text-center`} style={{ color: `${item.fontColorStatus}` }}>
                <div
                  style={{
                    background: `${item.bgColorStatus}`,
                    borderRadius: '10px',
                    padding: '5px',
                    maxWidth: '200px',
                  }}
                >
                  {item.status}
                </div>
              </td>
              <td className={`text-center px-2`}>
                <img
                  className={'cursorPointer'}
                  alt={'arrow'}
                  src={searchIconApp}
                  width={25}
                  onClick={() => {
                    setOptions({ ...options, showDialogPdf: true });
                    setModalActive(true);
                    setServices({
                      ...services,
                      currentItem: item,
                      show: true,
                      status: item?.status,
                      fontColorStatus: item?.fontColorStatus,
                    });
                  }}
                />
              </td>
            </tr>,
          );
        }
      });
    }
    return tempList;
  };

  const renderToolImportance = props => (
    <Tooltip {...props}>
      <div>
        <span style={{ color: '#c99489' }}>{detailPatient?.results?.alert}</span>
      </div>
    </Tooltip>
  );
  return (
    <>
      {loaderAppointmentHistory && loader}

      <OrdModal
        title='Detalle de cita'
        subtitle={`Estado - ${services?.status ? services?.status : '-'}`}
        colorSubtitle={`${services?.fontColorStatus}`}
        btnNoName='Cerrar'
        show={modalActive}
        size='1340'
        onHide={() => {
          setModalActive(false);
        }}
      >
        <RenderModal />
      </OrdModal>

      <div className={styles.app_container_register_app}>
        <div
          className={styles.app_container_close_app}
          onClick={() => {
            setOptions({
              ...options,
              showInfoPatient: false,
              showDetailPatient: true,
            });
          }}
        >
          <div className={styles.app_close} />
        </div>
        <div className={styles.app_container_register_patient}>
          {/* Detalle del paciente */}
          <div style={{ marginBottom: '10px' }}>
            <div style={{ marginBottom: '10px' }}>
              <span className={styles.app_title_register_app} style={{ fontSize: '40px' }}>
                Detalle del paciente
              </span>
            </div>
            <div className={styles.app_container_icon_detail}>
              <div>
                <img
                  src={app_editar}
                  alt='editar-icon'
                  className={'cursorPointer'}
                  width={23}
                  onClick={() => {
                    setOptions({
                      ...options,
                      showDetailPatient: false,
                      showHandlePatient: true,
                      isEditingPatient: true,
                      // showEditPatient: true,
                    });
                  }}
                />
              </div>
              <div>
                <div
                  className={
                    detailPatient?.results?.notes_num === 0
                      ? styles.app_container_gray
                      : styles.app_container_blue
                  }
                  onClick={() => {
                    getIdComment(detailPatient?.results?.id);
                  }}
                >
                  <span className={styles.app_text_dialog}>
                    {detailPatient?.results?.notes_num === 0 ? (
                      <>
                        <span style={{ color: '#6e6f7c' }}>0</span>
                      </>
                    ) : (
                      detailPatient?.results?.notes_num
                    )}
                  </span>
                </div>
              </div>
              <div>
                {/* {detailPatient?.results?.alert ? ( */}
                <OverlayTrigger
                  placement='top'
                  delay={{ show: 250, hide: 400 }}
                  className={styles.app_container_tool_websc}
                  overlay={renderToolImportance}
                >
                  <div>
                    <img
                      alt={'icon'}
                      width={'22px'}
                      src={warningIcon}
                      className={`cursorPointer`}
                      onClick={() => {
                        setOptions({ ...options, showImportance: true });
                      }}
                    />
                  </div>
                </OverlayTrigger>
                {/* ) : (
                  <div>
                    <img
                      alt={"icon"}
                      width={"22px"}
                      src={warningIcon}
                      className={`cursorPointer`}
                      onClick={() => {
                        setOptions({ ...options, showImportance: true });
                      }}
                    />
                  </div>
                )} */}
              </div>
            </div>
            <div className={styles.app_container_form_register}>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className='px-1'>Tipo de documento </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type='text'
                    placeholder='-'
                    disabled
                    value={detailPatient?.results?.docType}
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className='px-1'>Número de documento </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type='text'
                    disabled
                    placeholder='-'
                    value={formatteDocument(detailPatient?.results?.document).format}
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className='px-1'>Primer Nombre </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type='text'
                    disabled
                    value={detailPatient?.results?.firstName}
                    placeholder='-'
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className='px-1'>Segundo Nombre </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type='text'
                    disabled
                    value={detailPatient?.results?.otherNames}
                    placeholder='-'
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className='px-1'>Primer Apellido </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type='text'
                    disabled
                    value={detailPatient?.results?.lastName}
                    placeholder='-'
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className='px-1'>Segundo Apellido </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type='text'
                    disabled
                    value={detailPatient?.results?.secondSurname}
                    placeholder='-'
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className='px-1'>Fecha de nacimiento </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type='date'
                    disabled
                    value={detailPatient?.results?.birthDate}
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className='px-1'>Edad </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type='text'
                    disabled
                    value={`${detailPatient?.results?.age} años`}
                    placeholder='-'
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className='px-1'>WhatsApp </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type='text'
                    disabled
                    value={detailPatient?.results?.whatsapp}
                    placeholder='-'
                  />
                </Form.Group>
              </div>

              <div className={styles.app_container_content_form}>
                <Form.Group controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className='px-1'>Género </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type='text'
                    disabled
                    value={
                      detailPatient?.results?.gender === 'male'
                        ? 'Masculino'
                        : detailPatient?.results?.gender === 'female'
                          ? 'Femenino'
                          : '-'
                    }
                    placeholder='-'
                  />
                </Form.Group>
              </div>

              <div className={styles.app_container_content_form}>
                <Form.Group controlId='affiliationType'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className='px-1'>Tipo de afiliado </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type='text'
                    disabled
                    value={detailPatient?.results?.affiliationTypeName}
                    placeholder='-'
                  />
                </Form.Group>
              </div>

              <div className={styles.app_container_content_form}>
                <Form.Group controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className='px-1'>Correo electrónico </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type='email'
                    disabled
                    value={detailPatient?.results?.email}
                    placeholder='-'
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className='px-1'>Estado civil </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type='text'
                    disabled
                    value={
                      detailPatient?.results?.maritalStatus === 'single'
                        ? 'Soltero'
                        : detailPatient?.results?.maritalStatus === 'married'
                          ? 'Casado'
                          : detailPatient?.results?.maritalStatus === 'divorced'
                            ? 'Divorciado'
                            : detailPatient?.results?.maritalStatus === 'widowed'
                              ? 'Viudo'
                              : detailPatient?.results?.maritalStatus === 'freeunion'
                                ? 'Unión libre'
                                : '-'
                    }
                    placeholder='-'
                  />
                </Form.Group>
              </div>
            </div>
          </div>
          {/* Información complementaria */}
          <div style={{ marginBottom: '10px' }}>
            <div>
              <span className={styles.app_subtitle_register_app}>Información complementaria</span>
            </div>
            <div className={styles.app_container_form_register}>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className='px-1'>Departamento </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type='text'
                    placeholder='-'
                    disabled
                    value={detailPatient?.results?.provinceName}
                  />
                </Form.Group>
                {/* <Form.Group controlId="fName">
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className="px-1">Departamento </b>
                  </Form.Label>
                  <Select noOptionsMessage={() => 'No hay datos'}
                    options={formatedProvinces}
                    className="text-secondary"
                    placeholder={"Seleccionar..."}
                    styles={appCustomSelectNew}
                    value={formatedProvinces.find(
                      (x) => x.value === newPatient.provinceId
                    )}
                    onChange={(e) => {
                      getSelectCities(e.value);
                      setNewPatient({ ...newPatient, provinceId: e.value });
                    }}
                  />
                </Form.Group> */}
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className='px-1'>Municipio </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type='text'
                    disabled
                    value={detailPatient?.results?.cityName}
                    placeholder='-'
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className='px-1'>Dirección </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type='text'
                    disabled
                    value={detailPatient?.results?.homeAddress}
                    placeholder='-'
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className='px-1'>Grupo sanguíneo </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type='text'
                    disabled
                    value={detailPatient?.results?.bloodType}
                    placeholder='-'
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className='px-1'>Celular</b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type='text'
                    disabled
                    value={detailPatient?.results?.cellphone}
                    placeholder='-'
                  />
                </Form.Group>
              </div>
              <div className={styles.app_container_content_form}>
                <Form.Group controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <b className='px-1'>Teléfono fijo </b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type='text'
                    disabled
                    value={detailPatient?.results?.homephone}
                    placeholder='-'
                  />
                </Form.Group>
              </div>
            </div>
          </div>
          {/* Aseguradora Obligatoria */}
          <div style={{ marginBottom: '10px' }}>
            {detailPatient?.results?.contracts.map((item, index) => {
              if (item.isMain === 1) {
                return (
                  <div className={styles.app_container_register_insurance} key={index}>
                    <div>
                      <span className={styles.app_subtitle_register_app}>
                        Aseguradora obligatoria
                      </span>
                    </div>
                    <div className={styles.app_container_form_register}>
                      <div className={styles.app_container_content_form}>
                        <Form.Group controlId='fName'>
                          <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className='px-1'>Empresa </b>
                          </Form.Label>
                          <Form.Control
                            className={styles.app_inputs_detail_patient}
                            type='text'
                            disabled
                            value={item?.corporateClient}
                            placeholder='-'
                          />
                        </Form.Group>
                      </div>
                      <div className={styles.app_container_content_form}>
                        <Form.Group controlId='fName'>
                          <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className='px-1'>Contrato </b>
                          </Form.Label>
                          <Form.Control
                            className={styles.app_inputs_detail_patient}
                            type='text'
                            disabled
                            value={item?.contractName}
                            placeholder='-'
                          />
                        </Form.Group>
                      </div>
                      <div className={styles.app_container_content_form}>
                        <Form.Group controlId='fName'>
                          <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className='px-1'>Población</b>
                          </Form.Label>
                          <Form.Control
                            className={styles.app_inputs_detail_patient}
                            type='text'
                            disabled
                            value={item?.population}
                            placeholder='-'
                          />
                        </Form.Group>
                      </div>
                      <div className={styles.app_container_content_form}>
                        <Form.Group controlId='fName'>
                          <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className='px-1'>Grupo de ingreso</b>
                          </Form.Label>
                          <Form.Control
                            className={styles.app_inputs_detail_patient}
                            type='text'
                            disabled
                            value={item?.crtName}
                            placeholder='-'
                          />
                        </Form.Group>
                      </div>
                      <div className={styles.app_container_content_form}>
                        <Form.Group controlId='fName'>
                          <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className='px-1'>No. de Póliza</b>
                          </Form.Label>
                          <Form.Control
                            className={styles.app_inputs_detail_patient}
                            type='text'
                            disabled
                            value={item?.policy}
                            placeholder='-'
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                );
              } else return null;
            })}
          </div>

          {/* Aseguradora Voluntaria */}
          {detailPatient?.results?.contracts.map((item) => {
            if (item.isMain) return null

            return <VoluntaryInsuranceInfo key={item.relId} contract={item} />
          })}
          {appointmentHistory.rowTotal > 0 ? (
            <div>
              <span className={styles.app_subtitle_register_app}>Historial de citas</span>
              <OrdTable
                hasChildren={true}
                headers={options.header}
                paginate={{
                  activePage: filter.page,
                  totalPages: appointmentHistory.rowTotal,
                  perPage: filter.perpage,
                  pageRangeDisplayed: 1,
                  onChangePage: async e => {
                    const result = await getAppointmentHistory({
                      url: `/medical/appointment/`,
                      token: token,
                      objFilters: { ...filter, page: e, idPatient: patient?.id },
                    });
                    setFilter({ ...filter, page: e });
                    formatDataHistory(result?.results);
                  },
                  showTextDetails: true,
                }}
              >
                {formatDataHistory(appointmentHistory?.results)}
              </OrdTable>
            </div>
          ) : (
            <div className='d-flex flex-column'>
              <span className={styles.app_subtitle_register_app}>Historial de citas</span>
              <span className={styles.app_subtitle_not_history}>
                El paciente no tiene historial de citas
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default InfoPatient;
