import { combineReducers } from "redux";
import { loginReducer } from "./loginReducer";
import { classificationReducer } from "./classificationReducer";
import { profileReducer } from "./profileReducer";
import { userReducer } from "./userReducer";
import { headerReducer } from "./headerReducer";
import { siteReducer } from "./siteReducer";
import { cietenReducer } from "./cietenReducer";
import { cuentasReducer } from "./cuentasReducer";
import { locationReducer } from "./locationReducer";
import { functionalReducer } from "./functionalReducer";
import { costcenterReducer } from "./costcenterReducer";
import { measurementUnitsReducer } from "./measurementUnitsReducer";
import { storageReducer } from "./storageReducer";
import { pucReducer } from "./pucReducer";
import { consumptionCenterReducer } from "./consumptionCenterReducer";
import { articleReducer } from "./articleReducer";
import { requisitionReducer } from "./requisitionReducer";
import { individualAuctionReducer } from "./individualAuctionReducer";
import { providerReducer } from "./providerReducer";
import { specialitiesReducer } from "./specialityReducer";
import { subspecialitiesReducer } from "./subspecialityReducer";
import { diagnosticReducer } from "./diagnosticReducer";
import { inventoryFamilyReducer } from "./inventoryFamilyReducer";
import { cupsReducer } from "./cupsReducer";
import { corporateclientsReducer } from "./corporateclientsReducer";
import { listNiifReducer } from "./listNiifReducer";

import { invoiceReducer } from "./invoiceReducer";
import { glossReducer } from "./glossReducer";
import { depositBankReducer } from "./depositBankReducer";
import { cashReceipttReducer } from "./cashReceipttReducer";
import { InvoiceReceiptReducer } from "./InvoiceReceiptReducer";
import { billPaymentReducer } from "./billPaymentReducer";
import { consultReducer } from "./consultReducer";
import { noteReducer } from "./noteReducer";
import { salidaConsumoReducer } from "./salidaConsumoReducer";

import { bankNoteReducer } from "./bankNoteReducer";
import { paymentReducer } from "./paymentReducer";

import { statusPOReducer } from "./statusPOReducer";
import { entryWarehouseReducer } from "./entryWarehouseReducer";
import { agreementReducer } from "./agreementReducer";

import { purchaseOrderReducer } from "./purchaseOrderReducer";
import { accountingMovementsReducer } from "./accountingMovementsReducer";
import { inventoryReducer } from "./inventoryReducer";
import { deductionsAccrualsReducer } from "./deductionsAccrualsReducer";
import { notesReducer } from "./notesReducer";
import { billingReducer } from "./billingReducer";
import { PinvoiceReducer } from "./crossingAccountsReducer";
import { accountingReducer } from "./accountingReducer";

import { payrollReducer } from "./payrollReducer";
import { accountingConsultsReducer } from "./accountingConsultsReducer";

import { contractReducer } from "./contractReducer";

import { partnersReducer } from "./partnersReducer";
import { tmPayRollReducer } from "./tmPayRollReducer";
import { positionReducer } from "./positionReducer";
import { ReassignmentChecksReducer } from "./ReassignmentChecksReducer";

import { causationReducer } from "./causationReducer";
import { workEnvReducer } from "./workEnvReducer";
import { movementsReducer } from "./movementsReducer";

import { complaintsReducer } from "./complaintsReducer";
import { DisciplinaryProcessesReducer } from "./DisciplinaryProcessesReducer";
import { PlanningMedicalExamsReducer } from "./PlanningMedicalExamsReducer";
import { TaxCalendarReducer } from "./TaxCalendarReducer";
import { reportsReducer } from "./reportsReducer";
import { approvedNewsReducer } from "./approvedNewsReducer";
import { myPayrollReducer } from "./myPayrollReducer";
import { ripsReducer } from "./ripsReducer";
import { managementReducer } from "./managementReducer";
import { populationReducer } from "./populationReducer";
import { telemedicineReducer } from "./telemedicineReducer";
import { assistanceReducer } from "./assistanceReducer";
import { uiModalReducer } from './uiModalReducer';
import { calendarReducer } from './calendarReducer';
import { blockTextareaReducer } from './blockTextareaReducer';
import { collectionReducer } from './collectionReducer';
import { collectionGetOneReducer } from './collectionGetOneReducer';
import { currencyReducer } from './currencyReducer';
import { patientActiveReducer } from './patientActiveReducer';
import { filterCheckTeleScheduleReducer } from './filterCheckTeleScheduleReducer';
import { companyTypeReducer } from './companyTypeReducer';
import { patientAdmissionReducer } from "./patientAdmissionReducer ";
import { payHistoryReducer } from "./payHistoryReducer";
import { bankReducer } from "./bankReducer";


export default combineReducers({
  payrollReducer,
  patientActiveReducer,
  companyTypeReducer,
  patientAdmissionReducer,
  collectionGetOneReducer,
  salidaConsumoReducer,
  purchaseOrderReducer,
  blockTextareaReducer,
  filterCheckTeleScheduleReducer,
  PinvoiceReducer,
  collectionReducer,
  storageReducer,
  loginReducer,
  headerReducer,
  listNiifReducer,
  userReducer,
  profileReducer,
  siteReducer,
  cuentasReducer,
  cietenReducer,
  functionalReducer,
  costcenterReducer,
  locationReducer,
  measurementUnitsReducer,
  pucReducer,
  inventoryFamilyReducer,
  consumptionCenterReducer,
  articleReducer,
  requisitionReducer,
  cupsReducer,

  individualAuctionReducer,

  providerReducer,
  corporateclientsReducer,
  depositBankReducer,
  cashReceipttReducer,
  InvoiceReceiptReducer,
  billingReducer,
  statusPOReducer,
  agreementReducer,
  entryWarehouseReducer,
  consultReducer,
  accountingMovementsReducer,
  ReassignmentChecksReducer,
  paymentReducer,
  specialitiesReducer,
  subspecialitiesReducer,
  classificationReducer,
  diagnosticReducer,
  invoiceReducer,
  billPaymentReducer,
  glossReducer,
  causationReducer,
  accountingConsultsReducer,
  movementsReducer,
  workEnvReducer,

  complaintsReducer,
  DisciplinaryProcessesReducer,

  PlanningMedicalExamsReducer,
  TaxCalendarReducer,
  inventoryReducer,
  notesReducer,
  noteReducer,
  accountingReducer,
  bankNoteReducer,
  bankReducer,
  contractReducer,
  partnersReducer,
  deductionsAccrualsReducer,
  tmPayRollReducer,
  positionReducer,

  reportsReducer,

  approvedNewsReducer,
  myPayrollReducer,
  ripsReducer,
  managementReducer,
  populationReducer,
  telemedicineReducer,
  assistanceReducer,

  uiModalReducer,
  calendarReducer,
  currencyReducer,

  payHistoryReducer

});
