import { Drawer } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Card, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useGetMethod, usePostMethod } from '../../../Hooks/useFetch';
import OrdModal from '../../../OrderingModule/OrderingComponents/OrdModal';
import arrowRigthBlue from '../../../assets/img/icons/arrowRigthBlue.svg';
import closeIcon from '../../../assets/img/icons/close.svg';
import closeIconBigger from '../../../assets/img/icons/closeIconBigger.svg';
import countNotes from '../../../assets/img/icons/countNotes.svg';
import iconMessage from '../../../assets/img/icons/icon_message.svg';
import toOrderImg from '../../../assets/img/icons/img_appointment.svg';
import iconTrans from '../../../assets/img/icons/trashNotes.svg';
import warningIcon from '../../../assets/img/icons/warningIcon.gif';
import { appCustomSelectNew } from '../../../components/Layouts/react-select-custom';
import styles from '../../../components/Layouts/tableStyle.module.scss';
import { customSwaltAlertAsistencial, formatteDocument } from '../../../helpers';
import {
  formatToRcSelect,
  generateId,
  loader,
  message,
  swalConfirm,
} from '../../../helpers/helpers';
import InfoPatient from './infoPatient';
import Programming from './programming';

const DetailPatient = ({
  options,
  patient,
  filters,
  setPatient,
  setFilters,
  setOptions,
  detailPatient,
  getListPacient,
  getDetailPatient,
  detailLoaderPatient,
  ListPacientLoader,
  procedure,
  setInfoPatient,
  setProcedure,
  postTicketFunction,
  triggerTicket,
  setTriggerTicket,
  setTicketValue,
}) => {
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const idUser = store.loginReducer.user_data.id;
  const [importanceTemp, setImportanceTemp] = useState();

  const fullNameUser =
    store.loginReducer.user_data.first_name + ' ' + store.loginReducer.user_data.first_surname;
  const { trigger: getTicket } = useGetMethod();

  const [comments, setComments] = useState({
    userId: idUser,
    entity: 'userClient',
    comment: '',
    id: '',
  });

  const [order, setOrder] = useState({
    serviceId: '',
    sorId: '',
  });

  const [loading, setLoading] = useState(false);

  const {
    results: generalComments,
    load: loadGeneralComments,
    trigger: getGeneralComments,
  } = useGetMethod();

  const {
    results: importancies,
    load: loadImportancies,
    trigger: getImportancies,
  } = useGetMethod();

  const { results: importanciesAsis, trigger: getImportanciesAssis } = useGetMethod();

  const {
    results: pendingOrders,
    load: loadPendingOrders,
    trigger: getPendingOrders,
  } = useGetMethod();

  const { load: loadCreateMarkPatient, trigger: sendMarkPatient } = usePostMethod();
  const { load: loadCreateComments, trigger: sendComment } = usePostMethod();
  const { load: loadDeleteComments, trigger: deleteComment } = usePostMethod();

  // ANCHOR ORDERS PETITION
  useEffect(() => {
    getImportancies({
      url: '/medical/markups/',
      objFilters: { status: 'enabled' },
      token: token,
    });

    getPendingOrders({
      url: '/medical/patient/service/',
      objFilters: { patient: patient.id, typeList: 'orderPend' },
      token: token,
    });
    setLoading(true);
    procedure?.serviceId && getIdOrder(procedure?.serviceId, procedure?.sorId ?? '');
    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.showCards]);

  useEffect(() => {
    getImportanciesAssis({
      url: '/medical/patient/assistance-risks',
      objFilters: { status: 'enabled' },
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getIdComment = id => {
    const filterComments = {
      id: id,
      entity: 'userClient',
    };
    getGeneralComments({
      url: '/medical/comments/',
      objFilters: filterComments,
      token: token,
    });
    setComments({ ...comments, id: id });
    setOptions({ ...options, showNotes: true });
  };

  const getIdNotes = id => {
    deleteComment({
      url: '/medical/comments/',
      token: token,
      noAlert: true,
      method: 'DELETE',
      body: { id: id },
      doAfterSuccess: () => {
        const filterComments = {
          id: comments.id,
          entity: 'userClient',
        };
        getGeneralComments({
          url: '/medical/comments/',
          objFilters: filterComments,
          token: token,
        });
        getDetailPatient({
          url: '/medical/patient/',
          objFilters: patient,
          token: token,
        });
        setOptions({ ...options, showNotes: false });
      },
    });
  };

  const getIdOrder = (id, orderId) => {
    // setOrder({ ...order, serviceId: procedure?.serviceId ? procedure?.serviceId : id });
    setOrder({ ...order, serviceId: id, sorId: orderId ?? '' });
    setOptions({
      ...options,
      showProgramming: true,
      showCards: false,
    });
  };

  const onSubmitComment = e => {
    e.preventDefault();
    sendComment({
      url: '/medical/comments/',
      token: token,
      noAlert: true,
      method: 'POST',
      body: comments,
      doAfterSuccess: () => {
        const filterComments = {
          id: comments.id,
          entity: 'userClient',
        };
        getGeneralComments({
          url: '/medical/comments/',
          objFilters: filterComments,
          token: token,
        });
        setComments({ ...comments, comment: '' });
        getDetailPatient({
          url: '/medical/patient/',
          objFilters: patient,
          token: token,
        });
        message('success', 'Nota añadida');
      },
    });
  };

  const OnSubmitMarkPatient = () => {
    sendMarkPatient({
      url: '/medical/patient/',
      token: token,
      method: 'PUT',
      body: patient,
      doAfterSuccess: () => {
        getDetailPatient({
          url: '/medical/patient/',
          objFilters: patient,
          token: token,
        });
        getListPacient({
          url: '/medical/patient/listPatient/',
          objFilters: filters,
          token: token,
        });
        setOptions({
          ...options,
          showInfoPatient: false,
          showCards: true,
          showImportance: false,
          showImportanceAsis: false,
        });
      },
    });
  };

  const formatDataComments = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <>
            <div key={index} style={{ paddingLeft: '16px' }}>
              <div className={styles.app_container_tras_notes}>
                <span className={styles.app_name_drawer}>{item.userName}</span>
                {item.userId === idUser && (
                  <img
                    src={iconTrans}
                    width={15}
                    alt='tras-notes'
                    className='cursorPointer'
                    onClick={() => {
                      setOptions({ ...options, showNotes: true });
                      swalConfirm({
                        title: `<span class=${styles.ordClearBlueText} >¿Desea eliminar la nota?</span>`,
                        text: ` `,
                        confirmButtonText: 'Si, continuar',
                        doAfterConfirm: () => {
                          getIdNotes(item.id);
                          message('success', 'Nota eliminada');
                        },
                      });
                    }}
                  />
                )}
              </div>
              <p className={styles.app_description_drawer}>{item.comment}</p>
              <div className={styles.app_container_date_drawer}>
                <span className={styles.app_date_drawer}>{item.date}</span>
              </div>
            </div>
            <div className={styles.app_border_drawer} />
          </>,
        );
      });
    }
    return tempList;
  };

  // ANCHOR LIST  ordenamientos pendientes
  const formDataOrdersPending = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <Card
            className={styles.app_card_custom_ordering}
            key={index}
            onClick={() => {
              postTicketFunction('order_pending');
              getIdOrder(item.serviceId, item.sorId);
            }}
          >
            <div>
              <span className={styles.app_title_order}>{item.offeredService}</span>
            </div>
            <div key={index} className={styles.app_container_card_ord}>
              <div className={styles.app_container_info_card}>
                <div className='d-flex' style={{ gap: '1rem' }}>
                  <span className={styles.app_title_card_order}>Validez</span>
                  <span className={styles.app_subtitle_card_order}>{item.dateValidity}</span>
                </div>
                <div className='d-flex' style={{ gap: '1rem' }}>
                  <span className={styles.app_title_card_order}>Fecha recomendada</span>
                  <span className={styles.app_subtitle_card_order}>{item.dateSuggested}</span>
                </div>
              </div>
            </div>
          </Card>,
        );
      });
    }
    return tempList;
  };

  const formatedImportances = formatToRcSelect(
    importancies.results,
    'id',
    'description',
    'classification',
  );

  const formatedImportancesAsiss = formatToRcSelect(
    importanciesAsis.results,
    'clinicalDiseaseId',
    'clinicalDiseaseName',
    'clinicalDiseaseTag',
  );

  const renderToolImportance = props => (
    <Tooltip {...props}>
      <div className={`${styles.darkerGrayText}`}>Importancias administrativas</div>
    </Tooltip>
  );
  const renderToolImportanceAsis = props => (
    <Tooltip {...props}>
      <div className={`${styles.darkerGrayText}`}>Importancias asistenciales</div>
    </Tooltip>
  );

  const renderTooltipNotes = notes_num => (
    <Tooltip>
      <div className={`${styles.darkerGrayText}`}>
        ({notes_num}) {notes_num > 1 ? 'Notas' : 'Nota'}
      </div>
    </Tooltip>
  );

  return (
    <>
      {(loadImportancies ||
        loading ||
        detailLoaderPatient ||
        ListPacientLoader ||
        loadDeleteComments ||
        loadCreateMarkPatient ||
        loadPendingOrders) &&
        loader}
      <Drawer
        anchor={'right'}
        open={options.showNotes}
        style={{ zIndex: 900 }}
        onClose={() => setOptions({ ...options, showNotes: false })}
      >
        <div className={styles.app_drawer}>
          <div className={styles.app_container_close}>
            <img
              src={closeIcon}
              width={25}
              className={'cursorPointer'}
              alt='closeIcon'
              onClick={() => {
                setComments({ ...comments, id: '' });
                setOptions({ ...options, showNotes: false });
              }}
            />
          </div>
          <div className={styles.app_container_drawer}>
            <span className={styles.app_title_drawer}>Notas</span>
            {loadGeneralComments && loader}
            {generalComments.rowTotal === 0 ? (
              <div className={styles.app_container_not_notes}>
                <span className={styles.app_title_not_notes}>No hay notas aún</span>
              </div>
            ) : (
              <div className={styles.app_container_content_drawer}>
                {formatDataComments(generalComments.results)}
              </div>
            )}

            <div className={styles.app_container_form_drawer}>
              {loadCreateComments && loader}
              <Form.Group style={{ marginBottom: '10px' }}>
                <Form.Label className={styles.app_title_input}>
                  <span>{fullNameUser}</span>
                </Form.Label>
                <Form.Control
                  as='textarea'
                  rows={4}
                  maxLength='170'
                  value={comments.comment}
                  onChange={e => setComments({ ...comments, comment: e.target.value })}
                  placeholder='Escribir...'
                />
              </Form.Group>
              <div className={styles.app_container_date_drawer}>
                <img
                  src={iconMessage}
                  className={'cursorPointer'}
                  alt='iconMessage'
                  onClick={onSubmitComment}
                />
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <OrdModal
        title='Tipo de importancia asistencial'
        show={options.showImportanceAsis}
        btnYesName={'Aceptar'}
        btnNoName={'Cancelar'}
        size={'300'}
        onHide={() => setOptions({ ...options, showImportanceAsis: false })}
        btnYesEvent={() => OnSubmitMarkPatient()}
        btnNoEvent={() => setOptions({ ...options, showImportanceAsis: false })}
      >
        <div>
          <Select
            noOptionsMessage={() => 'No hay datos'}
            options={formatedImportancesAsiss}
            className='text-secondary mb-2'
            value={formatedImportances.filter(x => x.value === patient?.cum_id)}
            placeholder={'Seleccionar...'}
            onChange={e => {
              if (importanceTemp.assistance.some(x => x.id === e.value)) {
                customSwaltAlertAsistencial({
                  icon: 'warning',
                  title: 'Intenta de nuevo',
                  text: 'La importancia ya ha sido añadida, elija otra',
                  showCancelButton: false,
                });
              } else {
                setImportanceTemp(state => ({
                  ...state,
                  assistance: [
                    ...state.assistance,
                    { id: e.value, classification: e.extra, name: e.label },
                  ],
                }));
                setPatient(state => ({
                  ...state,
                  firstName: detailPatient?.results?.firstName,
                  risk: {
                    ...importanceTemp,
                    assistance: [
                      ...(importanceTemp?.assistance ?? []),
                      { id: e.value, classification: e.extra, name: e.label },
                    ],
                  },
                }));
              }
            }}
            styles={appCustomSelectNew}
          />
          {importanceTemp?.assistance?.length > 0 &&
            importanceTemp?.assistance.map(risk => {
              return (
                <div key={generateId()} className='d-flex py-2 align-items-center '>
                  <span className='text-muted'>{risk.name}</span>
                  <div
                    className='px-2 cursorPointer'
                    onClick={() => {
                      const filtered = importanceTemp.assistance.filter(x => x.id !== risk.id);
                      setImportanceTemp(state => ({ ...state, assistance: filtered }));
                      setPatient(state => ({
                        ...state,
                        firstName: detailPatient?.results?.firstName,
                        risk: { ...importanceTemp, assistance: filtered },
                      }));
                    }}
                  >
                    <img alt='icon' src={closeIconBigger} height={30} width={30} />
                  </div>
                </div>
              );
            })}
        </div>
      </OrdModal>
      <OrdModal
        title='Tipo de importancia administrativa'
        show={options.showImportance}
        btnYesName={'Aceptar'}
        btnNoName={'Cancelar'}
        size={'300'}
        onHide={() => setOptions({ ...options, showImportance: false })}
        btnYesEvent={() => OnSubmitMarkPatient()}
        btnNoEvent={() => setOptions({ ...options, showImportance: false })}
      >
        <div>
          <Select
            noOptionsMessage={() => 'No hay datos'}
            options={formatedImportances}
            className='text-secondary mb-2'
            value={formatedImportances.filter(x => x.value === patient?.cum_id)}
            placeholder={'Seleccionar...'}
            onChange={e => {
              if (importanceTemp.administrative.some(x => x.id === e.value)) {
                customSwaltAlertAsistencial({
                  icon: 'warning',
                  title: 'Intenta de nuevo',
                  text: 'La importancia ya ha sido añadida, elija otra',
                  showCancelButton: false,
                });
              } else {
                setImportanceTemp(state => ({
                  ...state,
                  administrative: [
                    ...state.administrative,
                    { id: e.value, classification: e.extra, description: e.label },
                  ],
                }));
                setPatient(state => ({
                  ...state,
                  firstName: detailPatient?.results?.firstName,
                  risk: {
                    ...importanceTemp,
                    administrative: [
                      ...(importanceTemp?.administrative ?? []),
                      { id: e.value, classification: e.extra, description: e.label },
                    ],
                  },
                }));
              }
            }}
            styles={appCustomSelectNew}
          />
          {importanceTemp?.administrative?.length > 0 &&
            importanceTemp?.administrative.map(risk => {
              return (
                <div key={generateId()} className='d-flex py-2 align-items-center '>
                  <span className='text-muted'>{risk.description}</span>
                  <div
                    className='px-2 cursorPointer'
                    onClick={() => {
                      const filtered = importanceTemp.administrative.filter(x => x.id !== risk.id);
                      setImportanceTemp(state => ({ ...state, administrative: filtered }));
                      setPatient(state => ({
                        ...state,
                        firstName: detailPatient?.results?.firstName,
                        risk: { ...importanceTemp, administrative: filtered },
                      }));
                    }}
                  >
                    <img alt='icon' src={closeIconBigger} height={30} width={30} />
                  </div>
                </div>
              );
            })}
        </div>
      </OrdModal>

      {options.showInfoPatient ? (
        <InfoPatient
          token={token}
          filters={filters}
          options={options}
          patient={patient}
          setOptions={setOptions}
          getIdComment={getIdComment}
          detailPatient={detailPatient}
        />
      ) : (
        <>
          <div className={styles.app_container_list_app}>
            <div>
              <div
                style={{
                  margin: '30px auto auto auto',
                  width: '100%',
                  maxWidth: '260px',
                }}
              >
                <div style={{ marginBottom: '20px' }}>
                  <span className={styles.app_title_register_app}>Registro de Citas</span>
                </div>
                <div className={styles.app_container_options_register}>
                  <img
                    src={arrowRigthBlue}
                    alt='arrow'
                    width={13}
                    className={'cursorPointer'}
                    onClick={() => {
                      setPatient({ id: '', cum_id: '' });
                      // setPatient({ id: "" });
                      getTicket({
                        url: '/medical/appointment/concecutive/',
                        objFilters: {
                          idConsec: 23,
                        },
                        token: token,
                        doAfterSuccess: res => {
                          setTicketValue(res.results);
                        },
                      });
                      setTriggerTicket(triggerTicket + 1);
                      setOptions({
                        ...options,
                        showDetailPatient: false,
                        showSearchPatient: true,
                        showProgramming: false,
                        showSchedule: false,
                      });
                      setFilters({
                        filterPatient: '',
                        page: 1,
                        perpage: 100,
                        orderByType: 'creation',
                      });
                      setProcedure('');
                      setInfoPatient('');
                    }}
                  />

                  {/*  */}

                  <div className='d-flex gap-3 align-items-center'>
                    <div>
                      {detailPatient?.results?.notes_num ? (
                        <OverlayTrigger
                          placement='top'
                          delay={{ show: 250, hide: 250 }}
                          overlay={renderTooltipNotes(detailPatient?.results?.notes_num)}
                        >
                          <div
                            className='pointer d-flex position-relative justify-content-center'
                            onClick={() => {
                              getIdComment(detailPatient?.results?.id);
                            }}
                          >
                            <img
                              alt='notes'
                              height={22}
                              src={countNotes}
                              width={22}
                              className={`${styles.filterOrdAquaMarine}`}
                            />
                            <span
                              className={`${styles.textCountNotes2} ${styles.filterWhite}`}
                              style={{ marginLeft: 'auto', marginTop: '-1px' }}
                            >
                              {detailPatient?.results?.notes_num}
                            </span>
                          </div>
                        </OverlayTrigger>
                      ) : (
                        <div
                          className='pointer d-flex position-relative justify-content-center'
                          onClick={() => {
                            getIdComment(detailPatient?.results?.id);
                          }}
                        >
                          <img
                            alt='notes'
                            height={22}
                            src={countNotes}
                            width={22}
                            className={`${styles.filterGrayLight}`}
                          />
                        </div>
                      )}
                    </div>

                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 250, hide: 250 }}
                      className={styles.app_container_tool_websc}
                      overlay={renderToolImportanceAsis}
                    >
                      <div className='d-flex align-items-end'>
                        <img
                          alt={'icon'}
                          width={'38px'}
                          src={warningIcon}
                          className={`cursorPointer`}
                          onClick={() => {
                            setOptions({ ...options, showImportanceAsis: true });
                            getDetailPatient({
                              url: '/medical/patient/',
                              objFilters: patient,
                              token: token,
                              doAfterSuccess: ({ results }) => {
                                if (results.risk) {
                                  setImportanceTemp(results.risk);
                                }
                              },
                            });
                          }}
                        />
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 250, hide: 250 }}
                      className={styles.app_container_tool_websc}
                      overlay={renderToolImportance}
                    >
                      <div className='d-flex align-items-end'>
                        <img
                          alt={'icon'}
                          width={'38px'}
                          src={warningIcon}
                          className={`cursorPointer`}
                          onClick={() => {
                            setOptions({ ...options, showImportance: true });
                            getDetailPatient({
                              url: '/medical/patient/',
                              objFilters: patient,
                              token: token,
                              doAfterSuccess: ({ results }) => {
                                if (results.risk) {
                                  setImportanceTemp(results.risk);
                                }
                              },
                            });
                          }}
                        />
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
              <div className={styles.app_container_appointment_info}>
                <div className={styles.app_container_content_form}>
                  <Form.Group controlId='fName'>
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                      <b className='px-1'>Identificación del paciente</b>
                    </Form.Label>
                    <Form.Control
                      className={styles.app_inputs_detail_patient}
                      type='text'
                      disabled
                      value={formatteDocument(detailPatient?.results?.document).format}
                      placeholder='-'
                    />
                  </Form.Group>
                </div>
                <div className={styles.app_container_content_form}>
                  <Form.Group controlId='fName'>
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                      <b className='px-1'>Primer Nombre</b>
                    </Form.Label>
                    <Form.Control
                      className={styles.app_inputs_detail_patient}
                      type='text'
                      disabled
                      value={detailPatient?.results?.firstName}
                      placeholder='-'
                    />
                  </Form.Group>
                </div>
                <div className={styles.app_container_content_form}>
                  <Form.Group controlId='fName'>
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                      <b className='px-1'>Primer Apellido</b>
                    </Form.Label>
                    <Form.Control
                      className={styles.app_inputs_detail_patient}
                      type='text'
                      disabled
                      value={detailPatient?.results?.lastName}
                      placeholder='-'
                    />
                  </Form.Group>
                </div>
                <div className={styles.app_container_content_form}>
                  <Form.Group controlId='fName'>
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                      <b className='px-1'>Fecha de nacimiento</b>
                    </Form.Label>
                    <Form.Control
                      className={styles.app_inputs_detail_patient}
                      type='date'
                      disabled
                      value={detailPatient?.results?.birthDate}
                      placeholder='-'
                    />
                  </Form.Group>
                </div>
                <div className={styles.app_container_content_form}>
                  <Form.Group controlId='fName'>
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                      <b className='px-1'>WhatsApp</b>
                    </Form.Label>
                    <Form.Control
                      className={styles.app_inputs_detail_patient}
                      type='text'
                      disabled
                      value={detailPatient?.results?.whatsapp}
                      placeholder='-'
                    />
                  </Form.Group>
                </div>
                <div className={styles.app_container_content_form}>
                  <Form.Group controlId='fName'>
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                      <b className='px-1'>Correo electrónico</b>
                    </Form.Label>
                    <Form.Control
                      className={styles.app_inputs_detail_patient}
                      type='email'
                      disabled
                      value={detailPatient?.results?.email}
                      placeholder='-'
                    />
                  </Form.Group>
                </div>
                <div className={styles.app_container_button}>
                  <button
                    className={styles.button_assign_appointment}
                    onClick={() => {
                      postTicketFunction('assign_appointment');
                      setOrder({
                        serviceId: '',
                        sorId: '',
                      });
                      setOptions({
                        ...options,
                        showDetailPatient: true,
                        showInfoPatient: false,
                        showSearchPatient: false,
                        showCards: false,
                        showProgramming: true,
                        showSchedule: false,
                      });
                    }}
                  >
                    Asignar cita
                  </button>
                  <button
                    className={styles.button_detail_patient}
                    onClick={() => {
                      setOptions({
                        ...options,
                        showDetailPatient: true,
                        showInfoPatient: true,
                        showSearchPatient: false,
                      });
                    }}
                  >
                    Detalle del paciente
                  </button>
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-center mb-3'>
              <img alt='order' src={toOrderImg} width='270' />
            </div>
          </div>
        </>
      )}
      {options.showCards === true && !options.showProgramming && (
        <>
          {pendingOrders.results?.length > 0 ? (
            <div
              style={{
                width: '100%',
                backgroundColor: '#F6FEFF',
                height: '100%',
                overflow: 'auto',
                overflowX: 'hidden',
              }}
            >
              <div
                style={{
                  margin: '41px auto',
                  width: '100%',
                  maxWidth: '816px',
                }}
              >
                <div style={{ marginBottom: '1rem' }}>
                  <span className={styles.app_title_orderPed}>Ordenamientos pendientes</span>
                </div>
                <div className={styles.app_container_scroll}>
                  {formDataOrdersPending(pendingOrders.results)}
                </div>
              </div>
            </div>
          ) : (
            <div style={{ width: '100%', backgroundColor: '#F6FEFF' }}>
              <div
                style={{
                  marginLeft: '50px',
                  marginRight: '50px',
                  marginTop: '41px',
                }}
              >
                <div style={{ marginBottom: '1rem' }}>
                  <span className={styles.app_title_orderPed}>
                    El paciente seleccionado no tiene eventos
                  </span>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {/* ANCHOR ruta => progrm */}
      {options.showProgramming === true && (
        <Programming
          order={order}
          token={token}
          patient={patient}
          options={options}
          setOptions={setOptions}
          procedure={procedure}
          detailPatient={detailPatient}
          getDetailPatient={getDetailPatient}
          detailLoaderPatients={detailLoaderPatient}
          ListPacientLoader={ListPacientLoader}
          postTicketFunction={postTicketFunction}
          setTriggerTicket={setTriggerTicket}
          triggerTicket={triggerTicket}
          setTicketValue={setTicketValue}
        />
      )}
    </>
  );
};

export default DetailPatient;
