import { Box, Drawer } from '@mui/material';
import { useEffect, useState } from 'react';
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import Basura from '../../assets/img/icons/Basura.svg';
import Ellipse from '../../assets/img/icons/Ellipse.svg';
import addIcon from '../../assets/img/icons/add-check.svg';
import close from '../../assets/img/icons/appClosePatient.svg';
import arrow from '../../assets/img/icons/arrowDarkGrey.svg';
import blockIcon from '../../assets/img/icons/blockIcon.svg';
import assignedIcon from '../../assets/img/icons/checkOnTable.svg';
import expandIcon from '../../assets/img/icons/expandIcon.svg';
import filterIcon from '../../assets/img/icons/filterIcon.svg';
import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg';
import select from '../../assets/img/icons/ordGrayBack.svg';
import personIcon from '../../assets/img/icons/personAddIcon.svg';

import { customSwaltAlertAsistencial } from '../../helpers';
import { AntSwitchBlue } from '../../helpers/constants';
import { formatToRcSelect, generateId, isEmptyOrUndefined, loader } from '../../helpers/helpers';

import OrdModal from '../../OrderingModule/OrderingComponents/OrdModal';
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';
import CalendarPage from '../../components/Calendar/CalendarPage';
import { MultiSelect } from '../../shared/MultiSelect';

import { useGetMethod, usePostMethod } from '../../Hooks/useFetch';
import { getAllConCenter } from '../../actions/consumptionCenterActions';

import { ordCustomSelect } from '../../components/Layouts/react-select-custom';
import styles from '../../components/Layouts/tableStyle.module.scss';

const Offices = ({ tab }) => {
  const dispatch = useDispatch();

  const store = useSelector(state => state);
  const createdBy = store.loginReducer.user_data.id;
  const token = store.loginReducer.Authorization;
  const eaccount = store.loginReducer.currentAccount.id;
  const consumptionCenter = store.consumptionCenterReducer.consumption_center || {};

  const [filters, setFilters] = useState({
    siteName: null,
    siteId: null,
    search: null,
    status: null,
    statusName: null,
    show: false,
    eaccount,
    createdBy,
  });

  const [modal, setModal] = useState({
    isEditing: false,
    coceId: null,
    coceDescription: null,
    serviceTypes: [],
    siteId: null,
    siteName: null,
    crtyId: null,
    crtyName: null,
    show: false,
    name: null,
    status: 0,
    eaccount,
    createdBy,
  });

  const [servicesList, setServicesList] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);

  const [trigger, setTrigger] = useState(0);

  const { results: ListSites, trigger: getListSites } = useGetMethod();

  const { results: ListConsultingRooms, trigger: getListConsultingRooms } = useGetMethod();

  const { results: ListTypesSchedules, trigger: getListTypesSchedules } = useGetMethod();

  const { trigger: getServicesList } = useGetMethod();

  const { results: daysOptionsResults, trigger: getDaysOptions } = useGetMethod();

  const { load: loaderCreateConsulting, trigger: createConsulting } = usePostMethod();

  const [filtersOffices, setFiltersOffices] = useState({
    siteId: '',
    statusId: '',
    search: '',
    showFilters: false,
  });

  const [showDetail, setShowDetail] = useState({
    show: false,
    state: false,
    idRow: '',
  });

  const [disponibility, setDisponibility] = useState([
    {
      openingTime: undefined,
      closingTime: undefined,
      dow: undefined,
      id: generateId(),
    },
  ]);

  const [openAssignmentDrawer, setOpenAssignmentDrawer] = useState({
    show: false,
    showAssignment: false,
    menuIsOpen: true,
    showBlock: false,
  });

  const onAddNewDisponibility = () => {
    setDisponibility(state => {
      const isValid = Object.values(state)?.every(
        el =>
          !isEmptyOrUndefined(el?.closingTime) &&
          !isEmptyOrUndefined(el?.openingTime) &&
          !isEmptyOrUndefined(el?.dow),
      );

      const newObj = {
        openingTime: undefined,
        closingTime: undefined,
        dow: undefined,
        id: generateId(),
      };

      if (isValid) {
        const newDisponibility = state?.length ? [...state, newObj] : [newObj];
        return newDisponibility;
      } else {
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Debes completar los campos de la disponibilidad',
          showCancelButton: false,
        });
      }
      return state;
    });
  };

  useEffect(() => {
    if (!disponibility?.length) {
      onAddNewDisponibility();
    }
  }, [disponibility]);

  useEffect(() => {
    if (tab !== 'Consultorios') {
      setShowDetail({
        show: false,
        state: false,
        idRow: '',
      });
    }
  }, [tab]);

  useEffect(() => {
    if (modal.siteId) {
      dispatch(
        getAllConCenter({
          eaccount,
          lite: 1,
          active: 1,
          handleResponse: 1,
          siteId: modal.siteId,
        }),
      );
    }
  }, [dispatch, modal.crtyId, modal.siteId, eaccount]);

  useEffect(() => {
    if (trigger) {
      getListConsultingRooms({
        url: `/medical/resources/consultingRooms/`,
        objFilters: filters,
        token: token,
      });
    }
  }, [trigger, getListConsultingRooms, token, filters]);

  useEffect(() => {
    getServicesList({
      token,
      url: '/medical/generals/service-types/',
      objFilters: { eaccount },
    }).then(res => {
      if (res.success) {
        setServicesList(
          res?.results?.map(service => ({ ...service, label: service?.name, value: service?.id })),
        );
      }
    });

    getDaysOptions({
      url: '/medical/schedules/dow/',
      //   objFilters: data,
      token: token,
    });

    getListSites({
      url: `/medical/generals/site/`,
      objFilters: {
        eaccount,
        status: 'enabled',
      },
      token: token,
    });

    getListTypesSchedules({
      url: `/medical/schedules/consultingRoomType/`,
      token: token,
    });

    getListConsultingRooms({
      url: `/medical/resources/consultingRooms/`,
      objFilters: { eaccount },
      token: token,
    });
  }, [
    eaccount,
    getDaysOptions,
    getListConsultingRooms,
    getListSites,
    getListTypesSchedules,
    getServicesList,
    token,
  ]);

  const daysOptions = formatToRcSelect(
    daysOptionsResults?.results,
    'dowId',
    'dowValue',
    '',
    '',
    '',
  );

  const onRemoveDisponibility = idDisponibility => {
    const listDisponibility = disponibility?.filter(el => el.id !== idDisponibility);
    setDisponibility(listDisponibility);
  };

  const closeNewOfficeModal = () => {
    setFilters(state => ({
      ...state,
      siteId: '',
      eaccount,
      createdBy,
      name: '',
      crtyId: '',
      show: false,
    }));

    setModal({
      isEditing: false,
      siteId: null,
      siteName: null,
      crtyId: null,
      crtyName: null,
      show: false,
      name: null,
      eaccount,
      createdBy,
      status: 0,
      serviceTypes: [],
    });

    setSelectedServices([]);

    setDisponibility([
      {
        openingTime: undefined,
        closingTime: undefined,
        dow: undefined,
        id: generateId(),
      },
    ]);
  };

  const validSelectedHour = ({ value, item, type = 'openingTime' }) => {
    let openingTime = undefined;
    let closingTime = undefined;

    if (type === 'openingTime') {
      closingTime = !isEmptyOrUndefined(item?.closingTime)
        ? Number(item?.closingTime?.replaceAll(':', ''))
        : 0;
      openingTime = !isEmptyOrUndefined(value) ? Number(value?.replaceAll(':', '')) : 0;
    } else {
      openingTime = !isEmptyOrUndefined(item?.openingTime)
        ? Number(item?.openingTime?.replaceAll(':', ''))
        : 0;
      closingTime = !isEmptyOrUndefined(value) ? Number(value?.replaceAll(':', '')) : 0;
    }

    if (openingTime > closingTime && item?.openingTime !== undefined && closingTime !== 0) {
      customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'La hora inicial no puede ser mayor a la hora final',
        showCancelButton: false,
      });
    } else {
      const newDisponibility = disponibility?.map(el => {
        if (el?.id === item?.id) {
          const closingTimex = value?.split(':');
          const part1 = closingTimex[0];
          const part2 = Number(closingTimex[1]);

          // Ajuste para PM
          const newPart1 = part1 < 12 ? part1 + 12 : part1;
          const newPart2 = part2 <= 8 ? 0 : part2 + 1;

          return {
            ...el,
            [type]: value,
            [type === 'openingTime' ? 'closingTime' : undefined]:
              `${newPart1}:${newPart2 <= 9 ? '0' : ''}${newPart2}`,
          };
        }
        return el;
      });

      setDisponibility(newDisponibility);
    }
  };

  const closeAssignmentsDrawer = () => {
    setOpenAssignmentDrawer({
      ...openAssignmentDrawer,
      show: false,
      menuIsOpen: true,
      showAssignment: false,
      showBlock: false,
    });
  };

  const validateFields = () => {
    if (modal?.crtyName === 'Quirófano' && !modal?.serviceTypes?.length) {
      return true;
    }

    const isValid = Object.values(disponibility)?.every(
      el =>
        !isEmptyOrUndefined(el?.closingTime) &&
        !isEmptyOrUndefined(el?.openingTime) &&
        !isEmptyOrUndefined(el?.dow),
    );

    if (!isValid) {
      return true;
    }

    if (
      modal.crtyId !== '' &&
      modal.crtyId !== null &&
      modal.name !== '' &&
      modal.name !== null &&
      modal.siteId !== '' &&
      modal.siteId !== null
    ) {
      return false;
    }

    return true;
  };

  const submitAddConsulting = () => {
    createConsulting({
      url: '/medical/resources/consultingRooms/',
      token: token,
      method: modal.isEditing ? 'PUT' : 'POST',
      body: { ...modal, schedulesInfo: disponibility },
      succesAction: () => {
        closeNewOfficeModal();
        setTrigger(state => state + 1);
        customSwaltAlertAsistencial({
          icon: 'success',
          title: modal.isEditing ? 'Actualizado exitosamente' : 'Creado exitosamente',
          text: `Se ha ${modal.isEditing ? 'actualizado' : 'creado'} el consultorio: ${modal?.name}`,
          showCancelButton: false,
        });
      },
      doAfterException: res => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: res.message || 'Ha ocurrido un error',
          showCancelButton: false,
        });
      },
    });
  };

  const headers = [
    {
      title: 'Consultorios',
      className: 'px-2 text-center',
    },
    {
      title: 'Sede',
      className: 'px-2  text-start',
    },
    {
      className: 'px-2 text-start',
    },
    {
      className: 'px-2'
    }
  ];

  const newHeaders = [
    {
      title: 'Día',
      className: 'px-3 text-center col-6',
    },
    {
      title: 'Hora inicial',
      className: 'px-3 text-center col-2',
    },
    {
      title: 'Hora final',
      className: 'px-3 text-center col-2',
    },
    {
      title: '',
      className: '',
    },
  ];

  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach(item => {
        let status = !item.status ? Ellipse : assignedIcon;
        let orange =
          showDetail.state === true && showDetail.idRow === item.id
            ? styles.ordBgFileTableYellow
            : '';
        tempList.push(
          <tr
            style={!item.status ? { opacity: '0.5' } : {}}
            key={item?.srvId}
            className={`hover-table-row ${orange} ${styles.cursorPointer}`}
            onClick={() => {
              const serviceTypes =
                item?.serviceTypes?.map(service => ({
                  ...service,
                  label: service?.name,
                  value: service?.id,
                })) || [];

              setModal(e => ({
                ...e,
                id: item.id,
                isEditing: true,
                show: true,
                coceId: item.coceId,
                coceDescription: item.coceDescription,
                siteId: item.siteId,
                siteName: item.siteName,
                crtyName: item.crtyName,
                crtyId: item.crtyId,
                name: item.name,
                status: item.status,
                serviceTypes,
              }));

              setSelectedServices(serviceTypes);

              setDisponibility(
                item?.schedulesInfo?.map(el => ({ ...el, id: generateId() })) || [
                  {
                    openingTime: undefined,
                    closingTime: undefined,
                    dow: undefined,
                    id: generateId(),
                  },
                ],
              );
            }}
          >
            <td
              style={!item.status ? { background: '#F5F7FA' } : {}}
              className={`text-center px-2 ${orange} `}
            >
              {item.status ? (
                <img alt='statusIcon' src={status} style={{ height: '14px' }} />
              ) : (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div className={styles.iconCircle}></div>
                </div>
              )}
            </td>
            <td
              style={!item.status ? { background: '#F5F7FA' } : {}}
              className={`text-start px-2 ${orange} `}
            >
              {item?.name}
            </td>
            <td
              style={!item.status ? { background: '#F5F7FA' } : {}}
              className={`text-start px-2 ${orange} `}
            >
              {item?.siteName}
            </td>
            <td
              style={!item.status ? { background: '#F5F7FA' } : {}}
              className={`text-start px-2 ${orange} `}
            >
              <img alt='arrowIcon' src={arrow} />
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const newFormatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach(item => {
        tempList.push(
          <tr key={item?.id} className={`hover-table-row`}>
            <td className={`text-center px-3`}>
              <Select
                menuShouldBlockScroll
                menuPortalTarget={document.body}
                noOptionsMessage={() => 'No hay datos'}
                options={daysOptions}
                value={
                  !isEmptyOrUndefined(item?.dow)
                    ? {
                        label: daysOptions?.find(day => day?.value === item.dow)?.label,
                        value: item?.dow,
                      }
                    : ''
                }
                className='text-secondary'
                placeholder={'Seleccionar...'}
                styles={ordCustomSelect}
                onChange={({ value }) => {
                  const newDisponibility = disponibility?.map(el => {
                    if (el?.id === item?.id) {
                      return {
                        ...el,
                        dow: value,
                      };
                    }
                    return el;
                  });
                  setDisponibility(newDisponibility);
                }}
              />
            </td>

            <td className={`text-center px-3 text-center`}>
              <input
                style={{ paddingTop: '3px' }}
                type='time'
                disabled={isEmptyOrUndefined(item?.dow)}
                className={`ord-roundInput2 ${styles.tlnTextGray}`}
                value={item?.openingTime || ''}
                onChange={({ target }) => {
                  if (
                    target.value?.replaceAll(':', '') === item?.closingTime?.replaceAll(':', '')
                  ) {
                    return customSwaltAlertAsistencial({
                      icon: 'warning',
                      title: 'Intenta de nuevo',
                      text: 'La hora de inicio no puede ser igual a la hora final',
                      showCancelButton: false,
                    });
                  }

                  const isExistDay = disponibility?.filter(el => el?.dow === item?.dow);

                  if (isExistDay.length > 1) {
                    const days = isExistDay?.filter(
                      el =>
                        el?.dow === item?.dow &&
                        !isEmptyOrUndefined(el?.closingTime) &&
                        !isEmptyOrUndefined(el?.openingTime),
                    );

                    const myPromise = new Promise(resolved => {
                      days?.forEach(day => {
                        let closingTime = !isEmptyOrUndefined(day?.closingTime)
                          ? Number(day?.closingTime?.replaceAll(':', ''))
                          : 0;
                        let openingTime = !isEmptyOrUndefined(day?.openingTime)
                          ? Number(day?.openingTime?.replaceAll(':', ''))
                          : 0;
                        let value = !isEmptyOrUndefined(target.value)
                          ? Number(target.value?.replaceAll(':', ''))
                          : 0;
                        if (
                          (openingTime < value && closingTime < value) ||
                          (openingTime > value && closingTime > value)
                        ) {
                          resolved({ isContinue: true, value: target.value });
                        } else {
                          resolved({ isContinue: false });
                        }
                      });
                    });

                    myPromise.then(({ isContinue, value }) => {
                      if (isContinue) {
                        validSelectedHour({ item, value, type: 'openingTime' });
                      } else {
                        customSwaltAlertAsistencial({
                          icon: 'warning',
                          title: 'Intenta de nuevo',
                          text: 'La hora se encuentra ocupada para el día seleccionado',
                          showCancelButton: false,
                        });
                      }
                    });
                  } else {
                    validSelectedHour({ item, value: target.value, type: 'openingTime' });
                  }
                }}
              />
            </td>

            <td className={`text-center px-3 text-center`}>
              <input
                style={{ paddingTop: '3px' }}
                disabled={isEmptyOrUndefined(item?.openingTime)}
                type='time'
                className={`ord-roundInput2 ${styles.tlnTextGray}`}
                value={item?.closingTime || ''}
                onChange={({ target }) => {
                  if (
                    target.value?.replaceAll(':', '') === item?.openingTime?.replaceAll(':', '')
                  ) {
                    return customSwaltAlertAsistencial({
                      icon: 'warning',
                      title: 'Intenta de nuevo',
                      text: 'La hora de final no puede ser igual a la hora inicial',
                      showCancelButton: false,
                    });
                  }
                  const isExistDay = disponibility?.filter(el => el?.dow === item?.dow);

                  if (isExistDay.length > 1) {
                    const days = isExistDay?.filter(
                      el =>
                        el?.dow === item?.dow &&
                        !isEmptyOrUndefined(el?.closingTime) &&
                        !isEmptyOrUndefined(el?.openingTime),
                    );

                    const myPromise = new Promise(resolved => {
                      days?.forEach(day => {
                        let closingTime = !isEmptyOrUndefined(day?.closingTime)
                          ? Number(day?.closingTime?.replaceAll(':', ''))
                          : 0;
                        let openingTime = !isEmptyOrUndefined(day?.openingTime)
                          ? Number(day?.openingTime?.replaceAll(':', ''))
                          : 0;
                        let value = !isEmptyOrUndefined(target.value)
                          ? Number(target.value?.replaceAll(':', ''))
                          : 0;
                        if (
                          (openingTime < value && closingTime < value) ||
                          (openingTime > value && closingTime > value)
                        ) {
                          resolved({ isContinue: true, value: target.value });
                        } else {
                          resolved({ isContinue: false });
                        }
                      });
                    });

                    myPromise.then(({ isContinue, value }) => {
                      if (isContinue) {
                        validSelectedHour({ item, value, type: 'closingTime' });
                      } else {
                        customSwaltAlertAsistencial({
                          icon: 'warning',
                          title: 'Intenta de nuevo',
                          text: 'La hora se encuentra ocupada para el día seleccionado',
                          showCancelButton: false,
                        });
                      }
                    });
                  } else {
                    validSelectedHour({ item, value: target.value, type: 'closingTime' });
                  }
                }}
              />
            </td>

            <td
              className={`text-center px-3 text-center pointer`}
              onClick={() => onRemoveDisponibility(item?.id)}
            >
              <img src={Basura} alt='borrar' />
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const renderPersonIcon = () => <Tooltip> Asignar</Tooltip>;
  const renderBlockIcon = () => <Tooltip> Bloquear</Tooltip>;
  const renderExpandTooltip = () => <Tooltip> Vista completa</Tooltip>;

  return (
    <>
      {loaderCreateConsulting && loader}

      <Drawer anchor={'right'} open={openAssignmentDrawer.show} style={{ width: '50%' }}>
        <div style={{ width: '350px', padding: ' 1rem 1rem', height: '100%' }} className=''>
          <div>
            <Row>
              <div className='d-flex justify-content-between'>
                <div className='d-flex justify-content-start'>
                  <button onClick={() => closeAssignmentsDrawer()}>
                    <img alt='close' src={close} />
                  </button>
                </div>
              </div>
            </Row>
            {openAssignmentDrawer.menuIsOpen === true ? (
              <div className='px-2'>
                <div className='d-flex text center align-self-center justify-content-center'>
                  <b
                    className={`${styles.f24} ${styles.tlnTextPrimary} text-center align-self-center d-flex `}
                  >
                    {' '}
                    &nbsp;¿Qué desea hacer?
                  </b>{' '}
                  <br />
                </div>
                <button
                  onClick={() => {
                    setOpenAssignmentDrawer({
                      ...openAssignmentDrawer,
                      showAssignment: true,
                      menuIsOpen: false,
                    });
                  }}
                  style={{ width: '259px', height: '150px' }}
                  className={`${styles.appBackBorderBlue} my-4 ${styles.appButtonHover}  p-4 mx-4 d-flex flex-column align-self-center justify-content-center text-center`}
                >
                  <img
                    alt='personIcon'
                    src={personIcon}
                    height='50px'
                    className={`${styles.AppIconHoverTurquoise} align-self-center`}
                  />
                  <span
                    className={`${styles.tlnTextGray} ${styles.appTextHoverTurquoise} ${styles.f16} align-self-center `}
                  >
                    Asignar
                  </span>
                </button>
                <button
                  onClick={() => {
                    setOpenAssignmentDrawer({
                      ...openAssignmentDrawer,
                      showAssignment: false,
                      showBlock: true,
                      menuIsOpen: false,
                    });
                  }}
                  style={{ width: '259px', height: '150px' }}
                  className={`${styles.appBackBorderBlue} my-4 ${styles.appButtonHover}  p-4 mx-4 d-flex flex-column align-self-center justify-content-center text-center`}
                >
                  <img
                    alt='blockIcon'
                    src={blockIcon}
                    height='50px'
                    className={`${styles.AppIconHoverTurquoise} align-self-center`}
                  />
                  <span
                    className={`${styles.tlnTextGray} ${styles.appTextHoverTurquoise} ${styles.f16} align-self-center `}
                  >
                    Bloquear fecha
                  </span>
                </button>
              </div>
            ) : openAssignmentDrawer.showAssignment ? (
              <div className='px-2'>
                <div className=''>
                  <b className={`${styles.f32} ${styles.tlnTextPrimary} text-center `}>
                    {' '}
                    &nbsp;Asignación
                  </b>{' '}
                  <br />
                </div>
                <div className='my-2'>
                  <Form.Group className='mb-3 text-start' controlId='fName'>
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                      <b> &nbsp;Auxiliar:</b>
                    </Form.Label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      isDisabled={filtersOffices.siteId}
                      className='text-secondary '
                      placeholder={'Seleccione'}
                      styles={ordCustomSelect}
                      onChange={e => {
                        setFiltersOffices({ ...filtersOffices, siteId: e.value });
                      }}
                    />
                  </Form.Group>
                  <Form.Group className='mb-3 text-start' controlId='fName'>
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                      <b> &nbsp;Instrumentador:</b>
                    </Form.Label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      isDisabled={filtersOffices.siteId}
                      className='text-secondary '
                      placeholder={'Seleccione'}
                      styles={ordCustomSelect}
                      onChange={e => {
                        setFiltersOffices({ ...filtersOffices, siteId: e.value });
                      }}
                    />
                  </Form.Group>
                  <Row>
                    <Col xs={6}>
                      <Form.Group className='mb-3 text-start' controlId='fName'>
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                          <b> &nbsp;Desde:</b>
                        </Form.Label>
                        <Form.Control
                          className={`ord-roundInput `}
                          type='time'
                          placeholder='Escriba...'
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group className='mb-3 text-start' controlId='fName'>
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                          <b> &nbsp;Hasta:</b>
                        </Form.Label>
                        <Form.Control
                          className={`ord-roundInput ${styles.tlnTextGray}`}
                          type='time'
                          placeholder='Escriba...'
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className='mb-3 text-start' controlId='fName'>
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                      <b> &nbsp;Fecha de inicio:</b>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput `}
                      type='date'
                      placeholder='Escriba...'
                    />
                  </Form.Group>
                  <Form.Group className='mb-3 text-start' controlId='fName'>
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                      <b> &nbsp;Repetición:</b>
                    </Form.Label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      isDisabled={filtersOffices.siteId}
                      className='text-secondary '
                      placeholder={'Seleccione'}
                      styles={ordCustomSelect}
                      onChange={e => {
                        setFiltersOffices({ ...filtersOffices, siteId: e.value });
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
            ) : openAssignmentDrawer.showBlock ? (
              <div className='px-2'>
                <div className=''>
                  <b className={`${styles.f32} ${styles.tlnTextPrimary} text-center `}>
                    {' '}
                    &nbsp;Bloquear fecha
                  </b>{' '}
                  <br />
                </div>
                <div className='my-2'>
                  <Row>
                    <Col xs={6}>
                      <Form.Group className='mb-3 text-start' controlId='fName'>
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                          <b> &nbsp;Desde:</b>
                        </Form.Label>
                        <Form.Control
                          className={`ord-roundInput `}
                          type='date'
                          placeholder='Escriba...'
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group className='mb-3 text-start' controlId='fName'>
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                          <b> &nbsp;Hora:</b>
                        </Form.Label>
                        <Form.Control
                          className={`ord-roundInput ${styles.tlnTextGray}`}
                          type='time'
                          placeholder='Escriba...'
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <Form.Group className='mb-3 text-start' controlId='fName'>
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                          <b> &nbsp;Hasta:</b>
                        </Form.Label>
                        <Form.Control
                          className={`ord-roundInput `}
                          type='date'
                          placeholder='Escriba...'
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group className='mb-3 text-start' controlId='fName'>
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                          <b> &nbsp;Hora:</b>
                        </Form.Label>
                        <Form.Control
                          className={`ord-roundInput ${styles.tlnTextGray}`}
                          type='time'
                          placeholder='Escriba...'
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className='mb-3 text-start' controlId='fName'>
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                      <b> &nbsp;Repetición:</b>
                    </Form.Label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      isDisabled={filtersOffices.siteId}
                      className='text-secondary '
                      placeholder={'Seleccione'}
                      styles={ordCustomSelect}
                      onChange={e => {
                        setFiltersOffices({ ...filtersOffices, siteId: e.value });
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {!openAssignmentDrawer.menuIsOpen && (
          <div
            style={{ width: '350px', padding: ' 1rem 2rem', height: '100%' }}
            className='d-flex justify-content-end align-items-end align-self-end mb-4 '
          >
            <div className='px-3'>
              <button
                onClick={() => closeAssignmentsDrawer()}
                className={`mr-2 btn ${styles.ordBtnSecondary}`}
              >
                Cancelar
              </button>
              <button className={`${styles.ordBtnPrimary} btn`}>Guardar</button>
            </div>
          </div>
        )}
      </Drawer>

      <OrdModal
        title={modal.isEditing ? 'Actualizar consultorio' : 'Nuevo consultorio'}
        show={modal.show}
        btnYesName={modal.isEditing ? 'Actualizar' : 'Guardar'}
        btnYesDisabled={validateFields()}
        btnNoName={'Cancelar'}
        size={620}
        onHide={() => closeNewOfficeModal()}
        btnYesEvent={() => submitAddConsulting()}
        btnNoEvent={() => closeNewOfficeModal()}
        inLineStyleAdditionalContent={{ height: '50vh', overflow: 'auto' }}
        additionalContent={
          <div>
            <Row>
              <div>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                    <span>
                      {' '}
                      &nbsp;Sede <span className={`${styles.ordOrangeText}`}>*</span>
                    </span>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={ListSites?.results?.map(e => ({ label: e.site, value: e.siteId }))}
                    value={modal.siteId ? { label: modal.siteName, value: modal.siteId } : ''}
                    isDisabled={modal.isEditing}
                    className='text-secondary '
                    placeholder={'Seleccione'}
                    styles={ordCustomSelect}
                    onChange={e => {
                      setModal(state => ({ ...state, siteId: e.value, siteName: e.label }));
                    }}
                  />
                </Form.Group>

                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                    <span>
                      {' '}
                      &nbsp;Tipo de recurso <span className={`${styles.ordOrangeText}`}>*</span>
                    </span>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={ListTypesSchedules?.results?.map(e => ({
                      label: e.name,
                      value: e.id,
                    }))}
                    isDisabled={modal.isEditing}
                    className='text-secondary'
                    placeholder={'Seleccione'}
                    styles={ordCustomSelect}
                    value={modal.crtyId ? { label: modal.crtyName, value: modal.crtyId } : ''}
                    onChange={e => {
                      setModal(state => ({ ...state, crtyId: e.value, crtyName: e.label }));
                    }}
                  />
                </Form.Group>

                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                    <span>
                      {' '}
                      &nbsp;Tipo de servicio(s) <span className={`${styles.ordOrangeText}`}>*</span>
                      {modal?.crtyName === 'Quirófano' && (
                        <span className={`${styles.ordOrangeText}`}>*</span>
                      )}
                    </span>
                  </Form.Label>
                  <MultiSelect
                    key='1'
                    options={servicesList}
                    isSelectAll={true}
                    value={selectedServices}
                    menuPlacement={'bottom'}
                    nameList={'servicio'}
                    accentuation={'o'}
                    onChange={elements => {
                      setSelectedServices(elements);
                      setModal(state => ({ ...state, serviceTypes: elements }));
                    }}
                  />
                </Form.Group>

                {modal.crtyName !== 'Virtual' && (
                  <Form.Group className='mb-3 text-start' controlId='fName'>
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                      <span> &nbsp;Centro de consumo</span>
                    </Form.Label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      options={consumptionCenter?.results?.map(e => ({
                        label: e.description,
                        value: e.id,
                      }))}
                      className='text-secondary'
                      placeholder={'Seleccione'}
                      styles={ordCustomSelect}
                      value={
                        modal.coceId ? { label: modal.coceDescription, value: modal.coceId } : ''
                      }
                      onChange={e => {
                        setModal(state => ({
                          ...state,
                          coceId: e.value,
                          coceDescription: e.label,
                        }));
                      }}
                    />
                  </Form.Group>
                )}
              </div>
              <div>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                    <span>
                      {' '}
                      &nbsp;Nombre <span className={`${styles.ordOrangeText}`}>*</span>
                    </span>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput text-secondary textGrayPlaceholder`}
                    type='text'
                    placeholder='Escribir ...'
                    value={modal.name}
                    onChange={e => {
                      setModal(state => ({ ...state, name: e.target.value }));
                    }}
                  />
                </Form.Group>
              </div>

              {modal.isEditing ? (
                <div>
                  <label
                    htmlFor='material-switch'
                    className='d-flex align-items-center justify-content-start my-2 gap-2'
                  >
                    <AntSwitchBlue
                      checked={modal.status}
                      onChange={e => {
                        setModal(state => ({ ...state, status: e.target.checked }));
                      }}
                      inputProps={{ 'aria-label': 'ant design' }}
                    />
                    <span className={styles.labelFilterSwitch}>
                      {modal.status ? 'Habilitado' : 'Deshabilitado'}
                    </span>
                  </label>
                </div>
              ) : (
                ''
              )}

              <Form.Group className='mb-3 text-start' controlId='fName'>
                <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                  <span>
                    {' '}
                    &nbsp;Disponibilidad<span className={`${styles.ordOrangeText}`}>*</span>
                  </span>
                </Form.Label>
                <OrdTable className='mt-1 mb-2' headers={newHeaders} hasChildren={true} shadow>
                  {newFormatData(disponibility)}
                </OrdTable>
                <button className={`${styles.appButtonHover}`} onClick={onAddNewDisponibility}>
                  <img alt='addIcon' src={addIcon} className={`${styles.AppIconHoverTurquoise}`} />
                  <b
                    className={`${styles.tlnTextGray} ${styles.f14} ${styles.appTextHoverTurquoise} mx-2`}
                  >
                    Agregar disponibilidad
                  </b>
                </button>
              </Form.Group>
            </Row>
          </div>
        }
      />

      <Box display={'grid'} gridTemplateColumns='1fr 1fr'>
        <Box>
          <Row className='pt-3'>
            <Col xs={6}>
              <button
                onClick={() => {
                  setFiltersOffices({
                    ...filtersOffices,
                    showFilters: !filtersOffices.showFilters,
                  });
                  setFilters({
                    siteName: null,
                    siteId: null,
                    search: null,
                    status: null,
                    statusName: null,
                    show: false,
                    eaccount,
                    createdBy,
                  });
                  setTrigger(state => state + 1);
                }}
                className={`${styles.appButtonHover}`}
              >
                <img
                  alt='filterIcon'
                  src={filterIcon}
                  className={`${styles.AppIconHoverTurquoise}`}
                />
                <b
                  className={`${styles.tlnTextGray} ${styles.appTextHoverTurquoise} ${styles.f14} mx-2`}
                >
                  Filtrar
                </b>
              </button>
            </Col>
            <Col xs={6}>
              <button
                onClick={() => setModal(state => ({ ...state, show: true, isEditing: false }))}
                className={`${styles.appButtonHover}`}
              >
                <img alt='addIcon' src={addIcon} className={`${styles.AppIconHoverTurquoise}`} />
                <b
                  className={`${styles.tlnTextGray} ${styles.f14} ${styles.appTextHoverTurquoise} mx-2`}
                >
                  Agregar consultorio{' '}
                </b>
              </button>
            </Col>
            {filtersOffices.showFilters && (
              <>
                <Col xs={6} className='mt-2'>
                  <Form.Group className='mb-3 text-start' controlId='fName'>
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                      <span> &nbsp;Sede</span>
                    </Form.Label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      options={[
                        { label: 'Seleccionar...', value: '' },
                        ...ListSites.results.map(e => ({ value: e.siteId, label: e.site })),
                      ]}
                      className='text-secondary '
                      placeholder={'Seleccionar...'}
                      styles={ordCustomSelect}
                      onChange={e => {
                        setFilters(state => ({ ...state, siteId: e.value, siteName: e.label }));
                        setTrigger(state => state + 1);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={6} className='mt-2'>
                  <Form.Group className='mb-3 text-start' controlId='fName'>
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                      <b> &nbsp;Estado</b>
                    </Form.Label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      options={[
                        { value: '', label: 'Seleccionar...' },
                        { value: 1, label: 'Habilitados' },
                        { value: 0, label: 'Deshabilitados' },
                      ]}
                      className='text-secondary '
                      placeholder={'Seleccionar...'}
                      styles={ordCustomSelect}
                      onChange={e => {
                        setFilters(state => ({ ...state, status: e.value, statusName: e.label }));
                        setTrigger(state => state + 1);
                      }}
                    />
                  </Form.Group>
                </Col>
                <div>
                  <div className={`d-flex ${styles.ordGrayInputSearch} mt-2`}>
                    <Form.Control
                      style={{ backGroundColor: 'red' }}
                      className={`ord-roundInput`}
                      type='text'
                      placeholder='Escribe aquí para buscar'
                      autoComplete='off'
                      onChange={e => {
                        setFilters(state => ({ ...state, search: e.target.value }));
                      }}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          setTrigger(state => state + 1);
                        }
                      }}
                    />
                    <img src={ordBlueSearch} className='p-2' alt='buscar'></img>
                  </div>
                </div>
              </>
            )}
          </Row>
          <Row>
            <div style={{ height: '70vh', overflow: 'auto' }} className='mt-2'>
              <OrdTable
                headers={headers}
                hasChildren={true}
                oneHeadDarkBlue={false}
                lowerCase={false}
              >
                {formatData(ListConsultingRooms?.results)}
              </OrdTable>
            </div>
          </Row>
        </Box>

        <Box className='d-flex flex-column'>
          <div className='d-flex justify-content-end mx-2 pt-3'>
            {showDetail.show && (
              <>
                <div className='d-flex justify-content-end align-self-end mb-1 '>
                  <AntSwitchBlue
                    className='mr-2 '
                    defaultChecked
                    inputProps={{ 'aria-label': 'ant design' }}
                  />
                </div>
                <div>
                  <span className={`${styles.tlnTextGray} ${styles.f12} mr-2`}> Habilitar </span>
                </div>
                <div className='d-flex justify-content-end mr-2 '>
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderPersonIcon()}
                  >
                    <button
                      onClick={() =>
                        setOpenAssignmentDrawer({ ...openAssignmentDrawer, show: true })
                      }
                    >
                      <img
                        alt='personIcon'
                        src={personIcon}
                        className={`${styles.filterHoverBlue}`}
                      />
                    </button>
                  </OverlayTrigger>
                </div>
                <div className='d-flex justify-content-end mr-2'>
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderBlockIcon()}
                  >
                    <button>
                      <img
                        alt='blockIcon'
                        src={blockIcon}
                        className={`${styles.filterHoverBlue}`}
                      />
                    </button>
                  </OverlayTrigger>
                </div>
                <div className='d-flex justify-content-end '>
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderExpandTooltip()}
                  >
                    <button>
                      <img
                        alt='expandIcon'
                        src={expandIcon}
                        className={`${styles.filterHoverBlue}`}
                      />
                    </button>
                  </OverlayTrigger>
                </div>
              </>
            )}
          </div>

          <div className='d-flex justify-content-center w-100 h-100'>
            {showDetail.show ? (
              <div className='pt-3 text-end w-100 ml-5 mb-2'>
                <CalendarPage
                  optionalDoctor={true}
                  views={['week']}
                  container
                  className='w-100 ml-5'
                />
              </div>
            ) : (
              <div className='d-flex align-items-center flex-column align-self-center text-center justify-content-center h-100'>
                <span className={`${styles.tlnTextGray} ${styles.f20}`}>
                  Selecciona un consultorio
                </span>
                <img
                  alt='select'
                  src={select}
                  width='25px'
                  className='align-self-center text-center'
                />
              </div>
            )}
          </div>
        </Box>
      </Box>
    </>
  );
};

export default Offices;
