import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { useGetMethod, usePostMethod } from '../../Hooks/useFetch';
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import deleteIcon from '../../assets/img/icons/close.svg';
import addIcon from '../../assets/img/icons/orangeNoBGArrow.svg';
import {
  customSelectMultiGeneral,
  ordCustomSelect,
} from '../../components/Layouts/react-select-custom';
import styles from '../../components/Layouts/tableStyle.module.scss';
import { AntSwitch } from '../../helpers/constants';
import { customSwaltAlertAsistencial } from '../../helpers/customSwaltAlertAsistencial';
import {
  formatToRcSelect,
  isEmptyOrUndefined,
  loader,
  message,
  today,
  validateEmptyString,
} from '../../helpers/helpers';

const NewSchedule = ({ setTab, tab, idDoctor, isEditingSchedule, setIsEditingSchedule }) => {
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const idAccount = store.loginReducer.currentAccount.id;
  const userId = store.loginReducer.user_data.id;
  const [trigger, setTrigger] = useState(0);
  const [triggerSelect, setTriggerSelect] = useState(0);
  const [doctorOptionsResults, setDoctorOptionsResults] = useState(0);

  const { schId, schName, isEditing } = isEditingSchedule;
  // let history = useHistory();
  // ANCHOR data
  const [data, setData] = useState({
    eaccount: idAccount,
    schName: '',
    schDescription: '',
    schExtraQuota: '',
    allowExtraQuota: false,
    siteId: '',
    typeConRoomId: '',
    conRoomId: '',
    modality: 'on_site',
    beginsAt: '',
    endsAt: '',
    dow: [],
    // attGroup: [],
    doctorId: idDoctor,
    userId: userId,
    schColor: '',
    servicesId: [],
    services: [],
    servicesAttGroup: [],
    assistantId: '',
  });

  const [careGroupRow, setCareGroupRow] = useState({
    attGroupId: '',
    attGroupName: '',
    percentage: '',
    attGroup: [],
  });

  const [servicesRow, setServicesRow] = useState({
    srvIdSe: '',
    srvId: '',
    srvName: '',
    maxTime: '',
    ctypeId: '',
    attGroupId: '',
    srvArr: [],
  });

  const closeTab = () => {
    setTab({ ...tab, page: 'index' });
    setIsEditingSchedule(state => ({ ...state, isEditing: false }));
    setData({ ...data, assistantId: '' });
  };
  const { load: doctorsOptionsLoader, trigger: getDoctorOptions } = useGetMethod();
  const {
    results: siteOptionsResults,
    load: siteOptionsLoader,
    trigger: getSiteOptions,
  } = useGetMethod();
  const {
    results: typeOptionsResults,
    load: typeOptionsLoader,
    trigger: getTypeOptions,
  } = useGetMethod();
  const {
    results: locationOptionsResults,
    load: locationOptionsLoader,
    trigger: getLocationOptions,
  } = useGetMethod();
  const {
    results: careGroupOptionsResults,
    load: careGroupOptionsLoader,
    trigger: getCareGroupOptions,
  } = useGetMethod();
  const {
    results: daysOptionsResults,
    load: dayOptionsLoader,
    trigger: getDaysOptions,
  } = useGetMethod();
  const {
    results: servicesOptionsServices,
    load: servicesOptionsLoader,
    trigger: getServicesOptions,
  } = useGetMethod();
  const { load: detailScheduleLoader, trigger: getDetailSchedule } = useGetMethod();
  const { load: createScheduleLoader, trigger: createScheduleMethod } = usePostMethod();

  const siteOptions = formatToRcSelect(siteOptionsResults?.results, 'siteId', 'site', '', '', '');
  const typeOptions = formatToRcSelect(typeOptionsResults?.results, 'id', 'name', '', '', '');
  const locationOptions = formatToRcSelect(
    locationOptionsResults?.results,
    'id',
    'name',
    '',
    '',
    '',
  );
  var careGroupOptions =
    servicesRow.srvArr.length > 0
      ? formatToRcSelect(careGroupOptionsResults?.results, 'id', 'name', '', '', '')
      : [{ value: '', label: '-Seleccione-' }];
  const daysOptions = formatToRcSelect(
    daysOptionsResults?.results,
    'dowId',
    'dowValue',
    '',
    '',
    '',
  );
  const servicesOptions = formatToRcSelect(
    servicesOptionsServices?.results,
    'srvId',
    'srvName',
    'ctypeId',
    'srvId',
    'ctypeName',
    true,
    'attGroupId',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'ctypeId',
  );

  useEffect(() => {
    if (isEditing) {
      let arrServices = [];
      let srvAttGrp = [];
      let srvIds = [];
      let srvArrState = [];
      let arrIdString = [];
      let attGroupState = [];

      if (schId) {
        getDetailSchedule({
          url: '/medical/schedules/detail/',
          objFilters: { eaccount: idAccount, schId: schId, schName, doctorId: data.doctorId },
          token: token,
          doAfterSuccess: res => {
            if (res?.results?.siteId) {
              // gettingServices(res?.results?.siteId);
              getLocationOptions({
                url: '/medical/schedules/consultingRooms/',
                objFilters: { crtyId: res.results.crtyId, siteId: res.results.siteId },
                token: token,
              });
              getServicesOptions({
                url: '/medical/schedules/serviceSiteCtype/',
                objFilters: {
                  eaccount: idAccount,
                  siteId: res?.results?.siteId,
                  doctorId: data.doctorId,
                  modality: data.modality,
                },
                token: token,
                doAfterSuccess: () => {
                  res.results.services.forEach(e => {
                    arrServices.push({ srvId: e.srvId, ctypeId: e.ctypeId }); //AQUÍ
                    srvAttGrp.push({ srvId: e.srvId, attGroupId: e.attGroupId });
                    srvIds.push(`${e.srvId}${e.ctypeId}`);
                    arrIdString.push(parseInt(e.ctypeId));
                    srvArrState.push({
                      maxTime: e.maxDate,
                      srvId: e.srvId,
                      srvIdSe: `${e.srvId}${e.ctypeId}`,
                      srvName: `${e.srvName} - ${e.ctypeName}`,
                      ctypeId: e.ctypeId,
                      attGroupId: e.attGroupId,
                    });
                  });

                  res.results.attGroup.forEach(e => {
                    attGroupState.push({
                      percentage: e.percentage,
                      attGroupId: e.attGroupId,
                      attGroupName: e.attGroupName,
                    });
                  });

                  setData({
                    eaccount: idAccount,
                    schName: res?.results?.schName,
                    schDescription: res?.results?.schDescription,
                    schExtraQuota: res?.results?.schExtraQuota,
                    allowExtraQuota: res?.results?.allowExtraQuota === 0 ? false : true,
                    siteId: res?.results?.siteId,
                    typeConRoomId: res?.results?.crtyId,
                    conRoomId: res?.results?.conRoomId,
                    modality: res?.results?.modality.modalityTag,
                    beginsAt: res?.results?.beginsAt,
                    endsAt: res?.results?.endsAt,
                    dow: res?.results?.dow,
                    // attGroup: [],
                    doctorId: idDoctor,
                    userId: userId,
                    schColor: res?.results?.schColor,
                    servicesId: srvIds,
                    services: arrServices,
                    servicesAttGroup: srvAttGrp,
                    assistantId: res?.results?.assistantId,
                  });

                  setServicesRow({
                    srvIdSe: '',
                    srvId: '',
                    srvName: '',
                    maxTime: '',
                    ctypeId: '',
                    attGroupId: '',
                    srvArr: srvArrState,
                  });

                  getCareGroupOptions({
                    url: '/medical/attentionGroup/',
                    objFilters: { eaccount: idAccount, status: 'enabled', ctypeId: arrIdString },
                    token: token,
                  });

                  setCareGroupRow({
                    attGroupId: '',
                    attGroupName: '',
                    percentage: '',
                    attGroup: attGroupState,
                  });
                },
              });
            } else {
              return message('warning', 'No se ha podido obtener la información de la agenda', false, false, true);
            }
          },
        });
      }
    }

    getSiteOptions({
      url: '/medical/generals/site/',
      objFilters: { eaccount: idAccount, modality: data.modality, status: 'enabled' },
      token: token,
    });
    getTypeOptions({
      url: '/medical/schedules/consultingRoomType/',
      objFilters: { eaccount: idAccount, status: 'enabled' },
      token: token,
    });

    getDaysOptions({
      url: '/medical/schedules/dow/',
      //   objFilters: data,
      token: token,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, idDoctor]);

  const gettingSite = e => {
    getLocationOptions({
      url: '/medical/schedules/consultingRooms/',
      objFilters: { crtyId: e, siteId: data.siteId, enabled: 1 },
      token: token,
    });
  };

  const gettingServices = e => {
    getServicesOptions({
      url: '/medical/schedules/serviceSiteCtype/',
      objFilters: {
        eaccount: idAccount,
        siteId: e,
        doctorId: data.doctorId,
        modality: data.modality,
      },
      token: token,
    });
  };

  const gettingCareGroup = e => {
    getCareGroupOptions({
      url: '/medical/attentionGroup/',
      objFilters: { eaccount: idAccount, status: 'enabled', ctypeId: e },
      token: token,
    });
  };

  useEffect(() => {
    setData({ ...data, siteId: '', typeConRoomId: '', conRoomId: '' });
    setTrigger(trigger + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.modality]);

  // ANCHOR ADD
  const addCareGroup = () => {
    let validations = validateEmptyString([
      { field: careGroupRow.attGroupId, message: 'El grupo de atención es obligatorio' },
    ], true);
    let careGroupValidation = careGroupRow.attGroup.find(
      care => care.attGroupId === careGroupRow.attGroupId,
    )
      ? message(
        'warning',
        'Intenta de nuevo',
        'El grupo de atención elegido ya se encuentra seleccionado',
        false, false, true
      )
      : false;
    if (validations.valid && careGroupValidation === false) {
      careGroupRow?.attGroup?.push({
        percentage: '',
        attGroupId: careGroupRow.attGroupId,
        attGroupName: careGroupRow.attGroupName,
      });
      setCareGroupRow({ ...careGroupRow, attGroupId: '', attGroupName: '' });
    }
  };
  const addService = () => {
    let validations = validateEmptyString([
      { field: servicesRow.srvIdSe, message: 'El servicio es obligatorio' },
    ], true);
    let servicesValidation = servicesRow.srvArr.find(srv => srv.srvIdSe === servicesRow.srvIdSe)
      ? message('warning', 'Intenta de nuevo', 'El servicio elegido ya se encuentra seleccionado', false, false, true)
      : false;
    if (validations.valid && servicesValidation === false) {
      servicesRow?.srvArr?.push({
        maxTime: '',
        srvId: servicesRow.srvId,
        srvIdSe: servicesRow.srvIdSe,
        srvName: servicesRow.srvName,
        ctypeId: servicesRow.ctypeId,
        attGroupId: servicesRow.attGroupId,
      });
      onChangeServices();
      setServicesRow({ ...servicesRow, srvIdSe: '', srvName: '' });
    }
  };

  // ANCHOR ONCHANGE SERVICES ID
  const onChangeServices = () => {
    // setCurrentAlternatives(e);
    let arrIdEnd = [];
    let arrIdString = [];
    let arrSrv = [];
    let arrIdSrv = [];
    let arrAttGroup = [];

    if (servicesRow.srvArr.length > 0) {
      servicesRow.srvArr.forEach(elem => {
        arrIdEnd.push(elem.srvIdSe);
        arrIdString.push(parseInt(elem.ctypeId));
        arrIdSrv.push(parseInt(elem.srvId));
        arrAttGroup.push({ srvId: parseInt(elem.srvId), attGroupId: parseInt(elem.attGroupId) });
        arrSrv.push({ srvId: parseInt(elem.srvId), ctypeId: parseInt(elem.ctypeId) });
      });
    }
    setData({ ...data, servicesId: arrIdEnd, services: arrSrv, servicesAttGroup: arrAttGroup });
    gettingCareGroup(arrIdString);
  };

  const deleteItem = item => {
    careGroupRow.attGroup.splice(item, 1);
  };
  const deleteService = item => {
    // if (isEditing){
    let dataSrvArr = data?.services;
    let dataAttGrArr = data?.servicesAttGroup;
    dataSrvArr?.splice(item, 1);
    dataAttGrArr?.splice(item, 1);
    setData({ ...data, services: dataSrvArr, servicesAttGroup: dataAttGrArr });
    // }

    let attGroupFilter = careGroupRow.attGroup.find(
      e => e.attGroupId === servicesRow.srvArr[item].attGroupId,
    );
    let attGroupSrv = servicesRow?.srvArr?.filter(e => e.attGroupId === attGroupFilter?.attGroupId);
    let newAttGroup;
    if (attGroupSrv.length === 1) {
      newAttGroup = careGroupRow.attGroup.findIndex(
        e => e.attGroupId === servicesRow.srvArr[item].attGroupId,
      );
      deleteItem(newAttGroup);
    }
    servicesRow.srvArr.splice(item, 1);
    let arrIdString = [];
    if (servicesRow.srvArr.length > 0) {
      servicesRow.srvArr.forEach(elem => {
        arrIdString.push(parseInt(elem.ctypeId));
      });
    }
    gettingCareGroup(arrIdString);

    if (servicesRow.srvArr.length === 0) {
      setCareGroupRow({ ...careGroupRow, attGroup: [], attGroupId: '' });
      setTriggerSelect(triggerSelect + 1);
    }
  };

  const percentageValidation = () => {
    let final = 0;
    let status;
    careGroupRow.attGroup.forEach(item => {
      final = final + parseInt(item.percentage);

      return final;
    });
    if (final === 100) {
      status = true;
    } else {
      message(
        'warning',
        'Intenta de nuevo',
        'Verifique porcentajes de grupos de atención, la sumatoria de los porcentajes debe ser igual a 100%',
        false, false, true
      );
      status = false;
    }

    return status;
  };
  const extraQuotaValidation = () => {
    let validationCareGroup =
      careGroupRow.attGroup.length > 0
        ? true
        : message(
          'warning',
          'Intenta de nuevo',
          'Debe añadir como mínimo un (1) grupo de atención',
          false, false, true
        );

    if (validationCareGroup && percentageValidation() === true) {
      setData({ ...data, allowExtraQuota: true });
    }
  };
  const modalitySwitch = () => {
    if (!isEditing) {
      if (data.modality === 'tmed') {
        setData({ ...data, modality: 'on_site' });
      } else {
        setData({ ...data, modality: 'tmed' });
      }
    } else {
      message('warning', "Intenta de nuevo", 'No es posible cambiar la modalidad', false, false, true);
    }
  };
  const onChangeDays = e => {
    if (isEditing) {
      setData({ ...data, dow: e.map(day => day.value) });
    } else {
      let arrIdEnd = [];
      if (e.length > 0) {
        e.forEach(elem => {
          arrIdEnd.push(parseInt(elem.value));
        });
      }
      setData({ ...data, dow: arrIdEnd });
    }
  };

  // const amountOnChangeInput = (e) => {
  //   setData({ ...data, amount: e });
  //   getFinalValue(e);
  // };

  // ANCHOR POST DATA
  const createSchedule = () => {
    let validations = validateEmptyString([
      { field: data.schColor, message: 'El campo color es obligatorio' },
      { field: data.endsAt, message: 'El campo hasta es obligatorio' },
      { field: data.beginsAt, message: 'El campo horario desde es obligatorio' },
      { field: data.conRoomId, message: 'El campo lugar es obligatorio' },
      { field: data.typeConRoomId, message: 'El campo tipo es obligatorio' },
      { field: data.siteId, message: 'El campo sede es obligatorio' },
      { field: data.schDescription, message: 'La descripción es obligatoria' },
      { field: data.schName, message: 'El nombre de la agenda es obligatorio' },
    ], true);

    let copy = careGroupRow.attGroup;
    careGroupRow.attGroup.forEach((e, i) => {
      copy[i].services = [];
      data.servicesAttGroup.forEach(data => {
        if (data.attGroupId === e.attGroupId) {
          copy[i].services.push(data.srvId);
        }
        setCareGroupRow({ ...careGroupRow, attGroup: copy });
      });
    });

    let arrSrv = [];
    if (servicesRow.srvArr.length > 0) {
      servicesRow.srvArr.forEach(elem => {
        arrSrv.push({
          srvId: parseInt(elem.srvId),
          ctypeId: parseInt(elem.ctypeId),
          maxDate: elem.maxTime,
        });
      });
    }
    setData({ ...data, services: arrSrv });

    // let validationMaxTime;
    // servicesRow.srvArr.forEach((e)=>{
    //   validationMaxTime = e.maxTime === "" ? message("warning", "Intenta de nuevo", "El campo 'Fecha hasta' es obligatorio en la sección de servicios") :  true
    //  })

    let validationDow = isEditing
      ? data.dow !== ''
        ? true
        : message('warning', 'Intenta de nuevo', "El campo 'Repetición' es obligatorio", false, false, true)
      : data.dow.length > 0
        ? true
        : message('warning', 'Intenta de nuevo', "El campo 'Repetición' es obligatorio", false, false, true);

    let validationCareGroup =
      careGroupRow.attGroup.length > 0
        ? true
        : message('warning', 'Intenta de nuevo', 'Los grupos de atención son obligatorios', false, false, true);

    const extraQuotaValidation = (data.allowExtraQuota && !data.schExtraQuota && data.schExtraQuota !== 0)
      ? message("warning", "Intenta de nuevo", "El campo 'No. de cupos' es obligatorio si la agenda permite cupos extras", false, false, true)
      : true

    // if (validations.valid && validationCareGroup === true && validationDow === true && percentageValidation() === true && validationMaxTime ) {
    if (
      validations.valid &&
      validationCareGroup === true &&
      validationDow === true &&
      extraQuotaValidation === true &&
      percentageValidation() === true
    ) {
      customSwaltAlertAsistencial({
        icon: 'warning',
        title: '¿Está seguro?',
        text: isEditing
          ? `Se editará la agenda "${data.schName}"`
          : `Se creará la agenda: ${data.schName}`,
        confirmButtonText: 'Sí, Continuar',
      }).then(result => {
        if (result.isConfirmed) {
          createScheduleMethod({
            url: '/medical/schedules/',
            method: isEditing ? 'PUT' : 'POST',
            // body: { ...data, attGroup: careGroupRow.attGroup },
            body: isEditing
              ? {
                schId: schId,
                eaccount: data.eaccount,
                schName: data.schName,
                schDescription: data.schDescription,
                schExtraQuota: data.schExtraQuota,
                allowExtraQuota: data.allowExtraQuota,
                siteId: data.siteId,
                typeConRoomId: data.typeConRoomId,
                conRoomId: data.conRoomId,
                modality: data.modality,
                beginsAt: data.beginsAt,
                endsAt: data.endsAt,
                dow: data.dow,
                doctorId: data.doctorId,
                userId: data.userId,
                schColor: data.schColor,
                services: arrSrv,
                attGroup: careGroupRow.attGroup,
                assistantId: !isEmptyOrUndefined(data?.assistantId)
                  ? data?.assistantId
                  : undefined,
              }
              : {
                eaccount: data.eaccount,
                schName: data.schName,
                schDescription: data.schDescription,
                schExtraQuota: data.schExtraQuota,
                allowExtraQuota: data.allowExtraQuota,
                siteId: data.siteId,
                typeConRoomId: data.typeConRoomId,
                conRoomId: data.conRoomId,
                modality: data.modality,
                beginsAt: data.beginsAt,
                endsAt: data.endsAt,
                dow: data.dow,
                doctorId: data.doctorId,
                userId: data.userId,
                schColor: data.schColor,
                services: arrSrv,
                attGroup: careGroupRow.attGroup,
                validate_sch: true,
                assistantId: !isEmptyOrUndefined(data?.assistantId)
                  ? data?.assistantId
                  : undefined,
              },

            token: token,
            succesAction: results => {
              customSwaltAlertAsistencial({
                icon: 'success',
                title: 'Creada exitosamente',
                text: results.message,
                showCancelButton: false,
              });
            },
            doAfterSuccess: () => {
              if (isEditing) {
                closeTab();
              } else {
                setData({
                  eaccount: idAccount,
                  schName: '',
                  schDescription: '',
                  schExtraQuota: '',
                  allowExtraQuota: false,
                  siteId: '',
                  typeConRoomId: '',
                  conRoomId: '',
                  modality: 'on_site',
                  beginsAt: '',
                  endsAt: '',
                  dow: [],
                  attGroup: [],
                  doctorId: idDoctor,
                  userId: userId,
                  schColor: '',
                  servicesId: [],
                  services: [],
                  assistantId: '',
                });
                setCareGroupRow({
                  ...careGroupRow,
                  attGroupId: '',
                  attGroupName: '',
                  percentage: '',
                  attGroup: [],
                });
                setServicesRow({
                  srvIdSe: '',
                  srvId: '',
                  srvName: '',
                  maxTime: '',
                  ctypeId: '',
                  attGroupId: '',
                  srvArr: [],
                });
                closeTab();
              }
            },
            doAfterException: error => {
              customSwaltAlertAsistencial({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: error.message,
                showCancelButton: false,
              });
            },
          });
        }
      });
    }
  };

  useEffect(() => {
    getDoctorOptions({
      token,
      url: '/medical/generals/medical/',
      objFilters: { eaccount: idAccount },
    }).then(res => {
      if (res?.success) {
        const format = res?.results?.map(doctor => ({
          ...doctor,
          label: doctor?.mdName,
          value: doctor?.mdId,
        }));
        const defaultValue = { value: '', label: 'seleccionar...' };
        if (format.length) {
          setDoctorOptionsResults([defaultValue, ...format]);
        } else {
          setDoctorOptionsResults([defaultValue]);
        }
      }
    });
  }, [getDoctorOptions, idAccount, token]);

  return (
    <div className='d-flex flex-column h-100 w-100 justify-content-between'>
      {(createScheduleLoader ||
        dayOptionsLoader ||
        locationOptionsLoader ||
        typeOptionsLoader ||
        siteOptionsLoader ||
        doctorsOptionsLoader ||
        servicesOptionsLoader ||
        detailScheduleLoader ||
        careGroupOptionsLoader) &&
        loader}
      <OrdGenericTemplate
        showBackArrow={true}
        backArrowAction={() => {
          closeTab();
        }}
        title={isEditing ? 'Editar agenda' : 'Nueva agenda'}
        className='px-4 my-2 w-90'
        // titleSize={12}
        style={{ overflow: 'auto', height: 'min-content' }}
        titleStyle={{ marginLeft: '1rem', fontSize: '32px' }}
      >
        <div className='mx-4'>
          <div className={`${styles.app_back_search} my-2`}>
            <Row>
              <Col xs={8}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                    <span> &nbsp;Nombre de la agenda</span>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput ${styles.tlnTextGray}`}
                    type='text'
                    disabled={isEditing ? true : false}
                    placeholder='Escriba...'
                    value={data.schName}
                    onChange={e => {
                      setData({ ...data, schName: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={4} className='align-self-end'>
                <div
                  className='d-flex align-self-end mb-3 pb-1'
                // styles={{marginBotton}}
                >
                  <AntSwitch
                    className='mr-2'
                    defaultChecked
                    // key={trigger + "Ant"}
                    checked={data.modality === 'on_site' ? false : true}
                    // onChange={(e) => handleChangeStatus(e.target.checked)}
                    onClick={() => modalitySwitch()}
                    inputProps={{ 'aria-label': 'ant design' }}
                  />
                  <b className={`${styles.f14} ${styles.tlnTextGray}   `}>Telemedicina</b>
                </div>
              </Col>
            </Row>
            <Row>
              <Form.Group className='mb-3' controlId='fName'>
                <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                  <span className='text-start'>&nbsp;Descripción</span>
                </Form.Label>
                <textarea
                  value={data?.schDescription}
                  placeholder={'Escribir...'}
                  rows='40'
                  cols='50'
                  style={{ height: '4rem' }}
                  className={`text-secondary ord-roundInput w-100 ${styles.outlineNone} ${styles.tlnTextGray}`}
                  onChange={e =>
                    setData({
                      ...data,
                      schDescription: e.target.value,
                    })
                  }
                ></textarea>
              </Form.Group>
            </Row>
            <Row>
              <Col xs={3}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                    <span> &nbsp;Sede</span>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={siteOptions}
                    className='text-secondary '
                    // isDisabled={data.modality === "tmed" ? true : false}
                    placeholder={'Seleccione'}
                    value={siteOptions.find(x => x.value === data.siteId)}
                    styles={ordCustomSelect}
                    onChange={e => {
                      setData({
                        ...data,
                        siteId: e.value,
                        typeConRoomId: '',
                        conRoomId: '',
                        services: [],
                        servicesId: [],
                      });
                      setCareGroupRow({ ...careGroupRow, attGroup: [], attGroupId: '' });
                      setServicesRow({ ...servicesRow, srvArr: [], srvIdSe: '' });
                      gettingServices(e.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                    <span> &nbsp;Tipo</span>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={typeOptions}
                    // isDisabled={data.modality === "tmed" ? true : false}
                    className='text-secondary '
                    placeholder={'Seleccione'}
                    value={typeOptions.find(x => x.value === data.typeConRoomId)}
                    styles={ordCustomSelect}
                    onChange={e => {
                      setData({ ...data, typeConRoomId: e.value, conRoomId: ''});
                      gettingSite(e.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                    <span> &nbsp;Lugar</span>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={locationOptions}
                    // isDisabled={data.modality === "tmed" ? true : false}
                    className='text-secondary '
                    placeholder={'Seleccione'}
                    value={locationOptions.find(x => x.value === data.conRoomId) ?? locationOptions[0]}
                    styles={ordCustomSelect}
                    onChange={e => {
                      setData({ ...data, conRoomId: e.value });
                    }}
                  />
                </Form.Group>
              </Col>

              <Col xs={6}>
                <div>
                  {/* ANCHOR SERVICIOS */}
                  <Form.Group className='mb-3 text-start' controlId='fName'>
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                      <span> &nbsp;Servicios</span>
                    </Form.Label>

                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      options={servicesOptions}
                      className='text-secondary '
                      placeholder={'Seleccione'}
                      value={servicesOptions.find(x => x.value === servicesRow.srvIdSe)}
                      styles={ordCustomSelect}
                      onChange={e => {
                        setServicesRow({
                          ...servicesRow,
                          srvIdSe: e.value,
                          srvName: e.label,
                          ctypeId: parseInt(e.extra),
                          srvId: parseInt(e.extra2),
                          attGroupId: parseInt(e.extra3),
                        });
                      }}
                    ></Select>
                  </Form.Group>
                </div>

                <div>
                  <Col xs={2} className='d-flex align-self-end '>
                    <button
                      className={`d-flex align-self-end mb-3 ${styles.appButtonAdd}`}
                      onClick={() => addService()}
                    >
                      <b className={`${styles.f16} ${styles.textOrangeHover}`}>Añadir</b>
                      <img alt='add' src={addIcon} className={`px-2 ${styles.AddIconHover}`} />
                    </button>
                  </Col>
                </div>
              </Col>

              <Col xs={6}>
                <Form.Group className='mb-3 text-start'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                    <span> &nbsp;Ayudante</span>
                  </Form.Label>

                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={doctorOptionsResults}
                    className='text-secondary '
                    placeholder={'Seleccionar...'}
                    styles={ordCustomSelect}
                    value={
                      !isEmptyOrUndefined(data?.assistantId)
                        ? {
                          value: data?.assistantId,
                          label: doctorOptionsResults?.find(
                            doctor => doctor?.value === data?.assistantId,
                          )?.label,
                        }
                        : undefined
                    }
                    onChange={e => {
                      setData({ ...data, assistantId: e.value });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            {servicesRow.srvArr.length > 0 &&
              servicesRow.srvArr.map((srv, index) => {
                return (
                  <Row className='my-2' key={index}>
                    <div>
                      <div
                        className={`bg-white py-2 d-flex rounded ${styles.bdRadius10} align-items-center`}
                      >
                        <Col xs={8} className='d-flex'>
                          <span className={`m-0 ${styles.ordDarkBlueText} ${styles.f16} px-4`}>
                            {' '}
                            {srv.srvName}
                          </span>
                        </Col>
                        <Col xs={3}>
                          <Form.Group className=' text-start' controlId='dateH'>
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                              <span> &nbsp;Fecha hasta:</span>&nbsp;&nbsp;
                            </Form.Label>
                            <input
                              className={` ${styles.appInputLine}  ${styles.tlnTextGray}`}
                              type='date'
                              placeholder=''
                              min={today()}
                              value={servicesRow?.srvArr[index].maxTime}
                              onChange={e => {
                                let srvRow = servicesRow?.srvArr;
                                srvRow[index].maxTime = e.target.value;
                                setServicesRow({ ...servicesRow, srvArr: srvRow });
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={1} className='d-flex justify-content-end'>
                          <button onClick={() => deleteService(index)} className='px-2'>
                            <img alt='delete' src={deleteIcon} width='25px' />
                          </button>
                        </Col>
                      </div>
                    </div>
                  </Row>
                );
              })}
          </div>
          <div className={`${styles.app_back_search} my-4`}>
            <Row>
              <Col xs={3}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                    <span> &nbsp;Grupos de atención</span>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={careGroupOptions}
                    className='text-secondary '
                    placeholder={'Seleccione'}
                    value={careGroupOptions.find(x => x.value === careGroupRow.attGroupId)}
                    styles={ordCustomSelect}
                    key={'attGroup' + triggerSelect}
                    onChange={e => {
                      setCareGroupRow({
                        ...careGroupRow,
                        attGroupId: e.value,
                        attGroupName: e.label,
                      });
                    }}
                  ></Select>
                </Form.Group>
              </Col>
              <Col xs={2} className='d-flex align-self-end '>
                <button
                  className={`d-flex align-self-end mb-3 ${styles.appButtonAdd}`}
                  onClick={() => addCareGroup()}
                >
                  <b className={`${styles.f16} ${styles.textOrangeHover}`}>Añadir</b>
                  <img alt='add' src={addIcon} className={`px-2 ${styles.AddIconHover}`} />
                </button>
              </Col>
              <Col xs={3}></Col>
            </Row>
            {careGroupRow.attGroup.length > 0 &&
              careGroupRow.attGroup.map((care, index) => {
                return (
                  <Row key={index} className='my-2'>
                    <div>
                      <div
                        className={`bg-white py-2 d-flex rounded ${styles.bdRadius10} align-items-center`}
                      >
                        <Col xs={4}>
                          <span className={`m-0 ${styles.ordDarkBlueText} ${styles.f16} px-4`}>
                            {' '}
                            &nbsp;{care.attGroupName}
                          </span>
                        </Col>
                        <Col xs={4}>
                          <Form.Group className=' text-start' controlId='fPercentage'>
                            <div className='input-group '>
                              <NumberFormat
                                className={` ${styles.appInputLine}  ${styles.tlnTextGray}`}
                                allowNegative={false}
                                id='percentage'
                                thousandsGroupStyle='thousand'
                                thousandSeparator
                                value={careGroupRow.attGroup[index].percentage}
                                onValueChange={e => {
                                  // amountOnChangeInput(e.value);
                                  careGroupRow.attGroup[index].percentage = e.value;
                                }}
                                maxLength={12}
                              ></NumberFormat>
                              <div className={` ${styles.appInputLine}  ${styles.tlnTextGray}`}>
                                <span className=''> &nbsp;%</span>
                              </div>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col xs={4} className='d-flex justify-content-end'>
                          <button onClick={() => deleteItem(index)} className='px-2'>
                            <img alt='delete' src={deleteIcon} width='25px' />
                          </button>
                        </Col>
                      </div>
                    </div>
                  </Row>
                );
              })}
          </div>
          <div className={`${styles.app_back_search} my-4`}>
            <Row>
              <Col xs={3}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                    <span> &nbsp;Horario desde</span>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput `}
                    type='time'
                    min='08:00'
                    max='18:00'
                    placeholder='Escriba...'
                    value={data.beginsAt}
                    onChange={e => {
                      setData({ ...data, beginsAt: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                    <span> &nbsp;Hasta</span>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput `}
                    type='time'
                    min='08:00'
                    max='18:00'
                    placeholder='Escriba...'
                    value={data.endsAt}
                    onChange={e => {
                      setData({ ...data, endsAt: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <span> &nbsp;Repetición</span>
                  </Form.Label>
                  {isEditing ? (
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      options={daysOptions}
                      className={` text-secondary ${tableStyles.heightScrollSelectApp} `}
                      // isDisabled={data.modality === "tmed" ? true : false}
                      placeholder={'Seleccione'}
                      value={daysOptions.find(x => x.value === data.dow)}
                      styles={ordCustomSelect}
                      onChange={e => {
                        onChangeDays(e);
                      }}
                    />
                  ) : (
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      isMulti
                      onChange={e => {
                        onChangeDays(e);
                      }}
                      styles={customSelectMultiGeneral}
                      options={daysOptions}
                      placeholder='Seleccione'
                      className='text-sm '
                      value={daysOptions.filter(item => data.dow.includes(item.value))}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className='my-2 '>
              <Col xs={3} className='d-flex align-self-center '>
                <div className='container d-flex flex-column text-start align-self-center w-100'>
                  <label
                    htmlFor='inputTypeColor'
                    className={`${styles.appInputColorExa} ${styles.ordDarkBlueText} ${styles.f14} mb-1`}
                  >
                    Color
                  </label>
                  <input
                    type='color'
                    id='inputTypeColor'
                    value={data.schColor}
                    onChange={e => {
                      setData({ ...data, schColor: e.target.value });
                    }}
                  />
                </div>
              </Col>
              <Col xs={3} className='d-flex align-self-center py-2'></Col>
              <Col xs={4} className='d-flex align-self-end py-2'>
                <div className='d-flex align-items-center'>
                  <div>
                    <button
                      onClick={() => {
                        if (data.allowExtraQuota) {
                          setData(state => ({ ...state, allowExtraQuota: false }));
                        } else {
                          extraQuotaValidation();
                        }
                      }}
                    >
                      <input
                        type='checkbox'
                        name='handleTelemedicine'
                        className={` ${styles.checkClearBlue} ${styles.appCheckInput}  ${styles.appCheckClear}  form-check-input p1 `}
                        checked={data.allowExtraQuota}
                      />
                    </button>
                  </div>
                  <div className=''>
                    <b className={`${styles.f14} ${styles.tlnTextGray}  align-self-end  px-2 `}>
                      Permite cupos extras
                    </b>
                  </div>
                </div>
              </Col>
              {data.allowExtraQuota && (
                <Col xs={2}>
                  <Form.Group className=' text-start' controlId='fName'>
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText} ${styles.f14}`}>
                      <span> &nbsp; No. de cupos</span>
                    </Form.Label>
                    <NumberFormat
                      className={"register-inputs"}
                      allowNegative={false}
                      id='extra_quota'
                      value={data.schExtraQuota}
                      isAllowed={(values) => {
                        const { floatValue } = values;
                        return floatValue ? floatValue < 1000 : true;
                      }}
                      onValueChange={e => {
                        setData({ ...data, schExtraQuota: e.value });
                      }}
                      maxLength={12}
                    ></NumberFormat>
                  </Form.Group>
                </Col>
              )}
            </Row>
          </div>
        </div>
      </OrdGenericTemplate>
      <div
        style={{
          width: '100%',
          padding: ' 1rem 2rem',
          position: 'relative',
          borderTop: '1px solid #',
          background: '#fff',
          marginRight: '1rem',
        }}
        className='d-flex px-4  justify-content-end align-items-end align-self-end border-top bg-white'
      >
        <div style={{ width: '90%' }}>
          <div
            className='px-4 w-90 d-flex justify-content-end align-items-end align-self-end mb-2 '
            style={{ width: '90%', marginRight: '1rem' }}
          >
            <button onClick={() => closeTab()} className={`mr-2 btn ${styles.ordBtnSecondary}`}>
              Cancelar
            </button>
            <button
              onClick={() => {
                setData({ ...data, attGroup: careGroupRow.attGroup });

                createSchedule();
              }}
              className={`${styles.ordBtnPrimary} btn`}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSchedule;
