import { useEffect, useState } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import Swal from 'sweetalert2';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Col, Form, Row } from 'react-bootstrap';
import withReactContent from 'sweetalert2-react-content';

import OrdLupa from '../assets/img/icons/OrdLupa.svg';
import desplegarGris from '../assets/img/icons/desplegarGris.svg';
import ordBlueSearch from '../assets/img/icons/ordBlueSearch.svg';

import { useGetMethod, usePostMethod } from '../Hooks/useFetch';
import { message } from '../helpers/helpers';

import OrdModal from '../OrderingModule/OrderingComponents/OrdModal';
import { OrdCard } from '../OrderingModule/OrderingComponents/OrdCard';
import { OrdGenericTemplate } from '../OrderingModule/OrderingComponents/OrdGenericTemplate';
import { OrdSlideDownButton } from '../OrderingModule/OrderingComponents/OrdSlideDownButton';
import { ColorButton } from '../OrderingModule/OrderingComponents/ColorButton';

import { ordCustomSelect } from '../components/Layouts/react-select-custom';
import tableStyles from '../components/Layouts/tableStyle.module.scss';
import ordComponentStyles from '../OrderingModule/OrderingComponents/ordComponentStyles.module.scss';
import { formatNumberWithoutDecimals } from '../helpers/numberFormatting';
import { formatteDocument } from '../helpers';

export const DiscountAuthorisation = () => {
  const MySwal = withReactContent(Swal);
  const store = useSelector(state => state);
  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState({
    idCorporateClient: '',
    service: '',
    status: '',
    startDate: '',
    endDate: '',
    search: '',
    idAdvisor: '',
  });
  const [trigger, setTrigger] = useState(0);
  const [justification, setJustification] = useState({
    showModal: false,
    justification: '',
  });
  const token = store.loginReducer.Authorization;
  const user = store.loginReducer.user_data.id;
  const eaccount = store.loginReducer.currentAccount.id

  const { results: ListCorporateCLients, trigger: getListCorporateCLients } = useGetMethod();
  const { results: ListServices, trigger: getListServices } = useGetMethod();
  const { results: ListAdvisors, trigger: getListAdvisors } = useGetMethod();
  const {
    results: ListDiscounts,
    load: ListDiscountsLoader,
    trigger: getListDiscounts,
  } = useGetMethod();
  const { trigger: updateQuotas } = usePostMethod();

  const methodUpdate = (idApp, idUser, namePatient, qtyDisc, typeProcess, serviceName) => {
    let formatNumber = formatNumberWithoutDecimals(qtyDisc);
    MySwal.fire({
      icon: 'warning',
      title: '¿Está seguro?',
      html: `Se aprobará un descuento de ${formatNumber} para el paciente: ${namePatient}`,
      confirmButtonText: 'Si, continuar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(result => {
      if (result.isConfirmed) {
        updateQuotas({
          url: '/medical/appointment/appDiscount/',
          token: token,
          noAlert: true,
          method: 'PUT',
          body: {
            idAppDisc: idApp,
            comments: justification.justification,
            idUser: idUser,
            typeProcess: typeProcess,
          },
          doAfterSuccess: () => {
            MySwal.fire({
              icon: 'success',
              title: 'Descuento aprobado',
              html: `${serviceName} <br/> Paciente:  ${namePatient}`,
              confirmButtonText: 'Aceptar',
            });
            setTrigger(trigger + 1);
          },
        });
      }
    });
  };

  const methodRejectedUpdate = (idApp, idUser, namePatient, qtyDisc, typeProcess) => {
    if (justification?.justification.length > 0) {
      updateQuotas({
        url: '/medical/appointment/appDiscount/',
        token: token,
        noAlert: true,
        method: 'PUT',
        body: {
          idAppDisc: idApp,
          comments: justification.justification,
          idUser: idUser,
          typeProcess: typeProcess,
        },
        doAfterSuccess: () => {
          let formatNumber = formatNumberWithoutDecimals(qtyDisc);
          setJustification({ showModal: false, justification: '' });
          MySwal.fire({
            icon: 'success',
            title: 'Descuento rechazado',
            html: ` ${formatNumber} <br/> Paciente:  ${namePatient}`,
            confirmButtonText: 'Aceptar',
          });
          setTrigger(trigger + 1);
        },
      });
    } else {
      message('info', 'La justificación es obligatoria');
    }
  };

  const rejectedMethod = (idApp, idUser, namePatient, qtyDisc, typeProcess, serviceName) => {
    MySwal.fire({
      icon: 'warning',
      title: '¿Está seguro?',
      text: 'Se rechazará la solicitud de descuento del paciente: ' + namePatient,
      confirmButtonText: 'Si, continuar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(result => {
      if (result.isConfirmed) {
        setJustification({
          ...justification,
          idApp: idApp,
          idUser: idUser,
          namePatient: namePatient,
          qtyDisc: qtyDisc,
          typeProcess: typeProcess,
          showModal: true,
          serviceName: serviceName,
        });
      }
    });
  };

  useEffect(() => {
    getListCorporateCLients({
      url: '/medical/client/',
      token: token,
    });
    getListServices({
      url: '/medical/generals/services/',
      token: token,
        objFilters: {
        eaccount: eaccount 
      }
    });
    getListAdvisors({
      url: '/medical/generals/advisors/',
      token: token,
    });
  }, [getListAdvisors, getListCorporateCLients, getListServices, token, eaccount]);

  useEffect(() => {
    getListDiscounts({
      url: '/medical/appointment/appDiscount/',
      token: token,
      objFilters: filters,
    });
  }, [filters, getListDiscounts, token, trigger]);

  let optionsCorpateCLient = [{ key: 'default', value: '', label: 'Todos', id: '' }];
  if (Array.isArray(ListCorporateCLients?.results)) {
    ListCorporateCLients.results.forEach(item => {
      optionsCorpateCLient.push({
        value: item.id,
        label: item.name,
        key: item.id + 'doctor',
      });
    });
  }

  let optionsServices = [{ key: 'default', value: '', label: 'Todos', id: '' }];
  if (Array.isArray(ListServices?.results)) {
    ListServices.results.forEach(item => {
      optionsServices.push({
        value: item.id,
        label: item.name,
        key: item.id + 'service',
      });
    });
  }

  let optionsAdvisor = [{ key: 'default', value: '', label: 'Todos', id: '' }];
  if (Array.isArray(ListAdvisors?.results)) {
    ListAdvisors.results.forEach(item => {
      optionsAdvisor.push({
        value: item.id,
        label: item.name,
        key: item.id + 'advisor',
      });
    });
  }

  const [rotate, setRotate] = useState(false);

  let buttons = [
    {
      button: <img alt='btn' src={OrdLupa}></img>,
    },
    {
      button: (
        <img
          alt='btn'
          style={{
            transform: !rotate && 'rotate(180deg)',
          }}
          width={10}
          src={desplegarGris}
        ></img>
      ),
    },
  ];
  const renderTooltipAccept = props => <Tooltip {...props}>Aceptar</Tooltip>;
  const renderTooltipDeny = props => <Tooltip {...props}>Rechazar</Tooltip>;

  const titleCard = (e, i) => {
    return (
      <Row className={`py-3 ${tableStyles.ordGrayBorderBottom}`} key={i}>
        <Col xs={3} className='align-self-center'>
          <div className='d-flex'>
            <span className='pr-2'>
              <b className={`${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                Solicitante: &nbsp;
              </b>
            </span>
            <span className={`text-secondary fw-normal`}>{e?.fullNameAdvisor}</span>
          </div>
        </Col>
        <Col xs={4} className='align-self-center'>
          <div className='d-flex'>
            <span className='pr-2'>
              <b className={`${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                Fecha de solicitud: &nbsp;
              </b>
            </span>
            <span className={`text-secondary fw-normal`}>{e?.dateOfRequest}</span>
          </div>
        </Col>
        <Col xs className='align-self-center'>
          <div className='d-flex'>
            <span className='pr-2'>
              <b className={`${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                Nuevo valor: &nbsp;
              </b>
            </span>
            <span className={`text-secondary fw-normal`}>
              {formatNumberWithoutDecimals(e?.newValue)}
            </span>
          </div>
        </Col>
        <div className='align-self-center w-auto'>
          <ColorButton
            style={{ width: '100%', fontSize: '15px' }}
            text={
              e?.statusAppDisc === 'pending'
                ? 'Pendiente'
                : e?.statusAppDisc === 'approved'
                  ? 'Aprobado'
                  : 'Rechazado'
            }
            variant={
              e?.statusAppDisc === 'pending'
                ? 'danger'
                : e?.statusAppDisc === 'approved'
                  ? 'success'
                  : 'rejected'
            }
          />
        </div>
        {e?.statusAppDisc === 'pending' && (
          <div className={`w-auto d-flex`} key={i}>
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltipDeny}
            >
              <div
                className={tableStyles.denyCardRed}
                onClick={() =>
                  rejectedMethod(
                    e?.idDisc,
                    user,
                    e?.namePatient,
                    e?.qtyDisc,
                    'rejected',
                    e?.serviceName,
                  )
                }
              >
                {' '}
                &nbsp; &nbsp;
              </div>
            </OverlayTrigger>
            &nbsp; &nbsp;
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltipAccept}
            >
              <div
                className={tableStyles.checkCardGreen}
                onClick={() => {
                  methodUpdate(
                    e?.idDisc,
                    user,
                    e?.namePatient,
                    e?.qtyDisc,
                    'approved',
                    e?.serviceName,
                  );
                }}
              >
                {' '}
                &nbsp; &nbsp;
              </div>
            </OverlayTrigger>
          </div>
        )}
      </Row>
    );
  };

  const bodyCard = (e, i) => {
    return (
      <>
        <Row className={`p-0`} key={i}>
          <Col xs={2} className='col-2 m-0 p-0'>
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>Paciente</b>
          </Col>
          <Col xs={2}>
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>Identificación</b>
          </Col>
          <Col xs={2} className='col-2 m-0 p-0'>
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>Servicio</b>
          </Col>
          <Col xs={2}>
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>Aseguradora</b>
          </Col>
          <Col xs={2}>
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>Estado de cita</b>
          </Col>
          <Col xs={2}>
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>Valor original</b>
          </Col>
        </Row>
        <Row className={`p-0`}>
          <Col xs={2} className='col-2 m-0 p-0'>
            <span className={`${tableStyles.f14} text-secondary fw-normal`}> {e?.namePatient}</span>
          </Col>
          <Col xs={2}>
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>
              {formatteDocument(e?.documentPatient).format}
            </span>
          </Col>
          <Col xs={2} className='col-2 m-0 p-0'>
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>{e?.serviceName}</span>
          </Col>
          <Col xs={2}>
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>
              {e?.nameCorporateClient}
            </span>
          </Col>
          <Col xs={2}>
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>{e?.statusApp}</span>
          </Col>
          <Col xs={2}>
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>
              {formatNumberWithoutDecimals(e?.inititalValue)}
            </span>
          </Col>
        </Row>
        <Row className={`pt-2`}>
          <Col xs={8} className='col-8 m-0 p-0'>
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>Comentarios</b>
          </Col>
          <Col xs={2}>
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>
              Tipo de descuento
            </b>
          </Col>
          <Col xs={2}>
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>Cantidad</b>
          </Col>
        </Row>
        <Row className={`p-0`}>
          <Col xs={8} className='col-8 m-0 p-0'>
            <span className={`${tableStyles.f14} text-secondary fw-normal`}> {e?.comments}</span>
          </Col>
          <Col xs={2}>
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>{e?.typeDisc}</span>
          </Col>
          <Col xs={2}>
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>
              {e?.typeDisc === 'Valor' ? formatNumberWithoutDecimals(e?.qtyDisc) : e?.qtyDisc + '%'}
            </span>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <div className='w-90 mx-1 pt-3'>
        {ListDiscountsLoader && (
          <Loader className='loading' type='Oval' color='#003f80' height={100} width={100} />
        )}
        {/* /* --------------------------- JUSTIFICATION MODAL -------------------------- */}
        <OrdModal
          title={'Justificación'}
          show={justification.showModal}
          btnYesName='Aceptar'
          btnNoName='Cancelar'
          size='500'
          btnYesEvent={() =>
            methodRejectedUpdate(
              justification.idApp,
              justification.idUser,
              justification.namePatient,
              justification.qtyDisc,
              justification.typeProcess,
              justification.serviceName,
            )
          }
          onHide={() => {
            setJustification({ showModal: false, justification: '' });
          }}
          btnNoEvent={() => {
            setJustification({ showModal: false, justification: '' });
          }}
        >
          <Col xs={12}>
            <Form.Group className='mb-3' controlId='fName'>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                <span className='text-start'>&nbsp;Explique la razón del rechazo</span>
              </Form.Label>
              <textarea
                placeholder={'Escribir...'}
                value={justification.justification}
                onChange={e => {
                  setJustification({
                    ...justification,
                    justification: e.target.value,
                  });
                }}
                rows='25'
                cols='55'
                style={{ height: '7rem' }}
                className={`text-secondary ord-roundInput w-100`}
              ></textarea>
            </Form.Group>
          </Col>
        </OrdModal>

        <OrdGenericTemplate
          className='w-100'
          titleSize={6}
          colBtnSize={6}
          colbtnClass={'p-0 m-0'}
          title={'Aprobar Descuentos'}
        >
          <div className='w-100 mx-1'>
            <OrdSlideDownButton
              onToggleClick={() => setRotate(!rotate)}
              buttons={buttons}
              accordionClassName={`${ordComponentStyles.OrdSlideButton} mr-1`}
            >
              <Row className={`px-2`}>
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start' controlId='fName'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b> &nbsp;Fecha solicitud desde</b>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput`}
                      type='date'
                      placeholder=''
                      onChange={e => setFilters({ ...filters, startDate: e.target.value })}
                    />
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start' controlId='fName'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b> &nbsp;Fecha solicitud hasta</b>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput`}
                      type='date'
                      placeholder=''
                      onChange={e => setFilters({ ...filters, endDate: e.target.value })}
                    />
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start' controlId='fName'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b> &nbsp;Servicio</b>
                    </Form.Label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      options={optionsServices}
                      className='text-secondary'
                      placeholder={'Seleccione'}
                      styles={ordCustomSelect}
                      onChange={e => setFilters({ ...filters, service: e.value })}
                    />
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start' controlId='fName'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b> &nbsp;Estado</b>
                    </Form.Label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      options={[
                        { value: '', label: 'Todos' },
                        { value: 'pending', label: 'Pendiente' },
                        { value: 'approved', label: 'Aprobado' },
                        { value: 'rejected', label: 'Rechazado' },
                      ]}
                      className='text-secondary'
                      placeholder={'Seleccione'}
                      styles={ordCustomSelect}
                      onChange={e => setFilters({ ...filters, status: e.value })}
                    />
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start' controlId='fName'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b> &nbsp;Aseguradora</b>
                    </Form.Label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      options={optionsCorpateCLient}
                      className='text-secondary'
                      placeholder={'Seleccione'}
                      styles={ordCustomSelect}
                      onChange={e => setFilters({ ...filters, idCorporateClient: e.value })}
                    />
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start' controlId='fName'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b> &nbsp;Solicitante</b>
                    </Form.Label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      options={optionsAdvisor}
                      className='text-secondary'
                      placeholder={'Seleccione'}
                      styles={ordCustomSelect}
                      onChange={e => setFilters({ ...filters, idAdvisor: e.value })}
                    />
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group className='mb-3 text-start' controlId='fName'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}></Form.Label>
                    <div className={`d-flex`} style={{ gap: '1rem' }}>
                      <Form.Control
                        className={tableStyles.btn_search}
                        type='text'
                        autoComplete='off'
                        placeholder='Nombre o ID del paciente'
                        onChange={e => setSearchValue(e.target.value)}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            setFilters({ ...filters, search: searchValue });
                          }
                        }}
                      />
                      <img
                        src={ordBlueSearch}
                        width={19}
                        alt='buscar'
                        className='cursorPointer'
                        onClick={() => setTrigger(trigger + 1)}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </OrdSlideDownButton>
            {ListDiscounts?.results?.length > 0 &&
              ListDiscounts?.results?.map((e, i) => {
                return (
                  <OrdCard key={i} className='my-3' title={titleCard(e, i)} body={bodyCard(e, i)} />
                );
              })}
          </div>
        </OrdGenericTemplate>
      </div>
    </>
  );
};
export default DiscountAuthorisation;
