import { useState } from 'react';

//components
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import { NewPopover } from '../../shared';

//styles
import styles from '../../components/Layouts/tableStyle.module.scss';
import CustomPopupStyles from '../../components/Popup/customPopup.module.scss';

//icons
import canceled from '../../assets/img/icons/Alert.svg';
import add from '../../assets/img/icons/add-check.svg';
import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg';
import ordDownArrow from '../../assets/img/icons/ordDownArrow.svg';
import ordUpArrow from '../../assets/img/icons/ordUpArrow.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';

//hooks
import { useSelector } from 'react-redux';

// helpers
import { Box } from '@mui/material';
import moment from 'moment';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useGetMethod, usePostMethod } from '../../Hooks';
import OrdModal from '../../OrderingModule/OrderingComponents/OrdModal';
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';
import { ordCustomSelect } from '../../components/Layouts/react-select-custom';
import CustomPopupExtend from '../../components/Popup/customPopUpExtends';
import {
  formatteDocument,
  customSwaltAlertAsistencial,
  generateId,
  isEmptyOrUndefined,
  loader,
  today,
} from '../../helpers';
import { formatNumberWithoutDecimals } from '../../helpers/numberFormatting';

const SubscriptionsPage = () => {
  const store = useSelector(state => state);
  const siteId = store.userReducer.site;
  const token = store.loginReducer.Authorization;
  const eaccount = store.loginReducer.currentAccount.id;
  const idUser = store?.loginReducer?.user_data.id;
  const history = useHistory();

  // states
  const [listProviderPartialPayments, setListProviderPartialPayments] = useState({
    message: '',
    rowTotal: 0,
    status: 0,
    title: '',
    results: [],
  });

  const [currentTargetPopover, setCurrentTargetPopover] = useState(null);
  const [search, setSearch] = useState('');
  const [dataCancellation, setDataCancellation] = useState({
    cancelId: '',
    cancelObservation: '',
    isOpen: false,
  });

  const onClearDataCancellation = () => {
    setDataCancellation({
      cancelId: '',
      cancelObservation: '',
      isOpen: false,
    });
  };

  //////// HTTPS GET ////////////
  const { load: loaderListProviderPartialPayments, trigger: getListProviderPartialPayments } =
    useGetMethod();

  const {
    results: listProviderTypes,
    load: loaderListProviderTypes,
    trigger: getListProviderOrdersTypes,
  } = useGetMethod();

  const {
    results: listCancellationReason,
    load: loaderListCancellationReason,
    trigger: getListCancellationReason,
  } = useGetMethod();

  const { trigger: getCashClosingConfig, load: loadCashClosingConfig } = useGetMethod();

  //////// HTTPS POST ////////////
  const { load: loaderPostOnCancelSubscription, trigger: postOnCancelSubscription } =
    usePostMethod();

  const [data, setData] = useState({
    trigger: 0,
    filters: {
      dateMax: '',
      dateMin: '',
      search: '',
      generalResponse: 0,
      partialPayments: 1,
      page: 1,
      perpage: 10,
      eaccount,
    },
  });

  const { filters, trigger } = data;

  // render data get => admissions/provider-orders
  const onChangeTrigger = () => setData(state => ({ ...state, trigger: state.trigger + 1 }));

  const onCancelSubscription = ({ data: params, fullData }) => {
    if (isEmptyOrUndefined(dataCancellation?.cancelId)) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Seleccione un motivo de anulación valido',
        showCancelButton: false,
      });
    }

    if (isEmptyOrUndefined(dataCancellation?.cancelObservation?.trim())) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Digite una observación valida',
        showCancelButton: false,
      });
    }

    customSwaltAlertAsistencial({
      icon: 'warning',
      title: '¿Esta seguro?',
      text: `Se anulará el abono número ${params?.sequence} al paciente: ${fullData?.patient?.name}`,
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        postOnCancelSubscription({
          url: `/medical/admissions/provider-orders/${fullData?.id}/payments/`,
          token: token,
          method: 'POST',
          noAlert: true,
          body: {
            eaccount,
            userId: idUser,
            sequence: params?.sequence,
            cancelObservation: dataCancellation?.cancelObservation,
            cancelId: dataCancellation?.cancelId,
          },
          doAfterSuccess: response => {
            if (response?.success) {
              customSwaltAlertAsistencial({
                icon: 'success',
                title: 'Actualizado exitosamente',
                text: `Se ha anulado el abono ${params?.sequence} del paciente ${fullData?.patient?.name}`,
                showCancelButton: false,
              });
              setDataCancellation({ isOpen: false });
              setData(state => ({ ...state, trigger: state.trigger + 1 }));
            }
          },
          doAfterException: error => {
            customSwaltAlertAsistencial({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: error.message || 'error x',
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  const handleCancelSubscription = item => {
    if (isEmptyOrUndefined(dataCancellation?.cancelId)) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Seleccione un motivo de anulación valido',
        showCancelButton: false,
      });
    }

    if (isEmptyOrUndefined(dataCancellation?.cancelObservation?.trim())) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Digite una observación valida',
        showCancelButton: false,
      });
    }

    customSwaltAlertAsistencial({
      icon: 'warning',
      title: '¿Esta seguro?',
      text: `Se anulará la orden número ${item?.prefixOrder} al paciente: ${item?.patient?.name}`,
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        postOnCancelSubscription({
          url: `/medical/admissions/provider-orders/${item?.id}/`,
          token: token,
          method: 'POST',
          noAlert: true,
          body: {
            eaccount,
            userId: idUser,
            cancelObservation: dataCancellation?.cancelObservation,
            cancelId: dataCancellation?.cancelId,
          },
          doAfterSuccess: response => {
            if (response?.success) {
              customSwaltAlertAsistencial({
                icon: 'success',
                title: 'Actualizado exitosamente',
                text: `Se ha anulado la orden número ${item?.prefixOrder} del paciente: ${item?.patient?.name}`,
                showCancelButton: false,
              });

              setData(state => ({ ...state, trigger: state.trigger + 1 }));
              onClearDataCancellation();
            }
          },
          doAfterException: error => {
            customSwaltAlertAsistencial({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: error.message || 'error x',
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  const renderAccordion = ({ data, fullData }) => {
    let total = 0;
    return (
      <tr id='tdWhite' className=' animate__animated animate__fadeIn'>
        <td colSpan={12} className='py-4 px-2'>
          <table style={{ fontFamily: 'PT Sans Caption' }}>
            <tr className='tableNewStyle'>
              <th className='text-start px-2'>Transacción</th>
              <th className='text-center px-2'>Fecha</th>
              <th className='text-start px-2'>Tipo de recaudo</th>
              <th className='text-end px-2'>Total</th>
              <th className='text-end'></th>
            </tr>

            {data?.map(item => {
              total += item?.total;
              return (
                <tr key={generateId()} className='tableNewStyle'>
                  <td className='text-start px-2 col-2'>{item?.sequence || '-'}</td>
                  <td className='text-center px-2 col-2'>{item?.date || '-'}</td>

                  <td className='text-start px-2 col-2'>
                    {item?.deposits.map(el => el?.name)?.join() || '-'}
                  </td>
                  <td className='text-end px-2 col-2'>
                  {item?.total ? formatNumberWithoutDecimals(item?.total) : '-'}
                  </td>
                  <td
                    className='text-end col-2'
                    style={item?.isCancelled ? { padding: '0px 14px' } : {}}
                  >
                    {item?.isCancelled ? (
                      <OverlayTrigger
                        placement='top'
                        delay={{ show: 150, hide: 150 }}
                        overlay={<Tooltip>Pago anulado</Tooltip>}
                      >
                        <img src={canceled} alt='pago anulado' height={16} width={16} />
                      </OverlayTrigger>
                    ) : (
                      <CustomPopupExtend
                        position='right center'
                        className={`align-self-end`}
                        triggerClass={`${styles.hoverPopup} `}
                        triggerSrc={threeDots}
                        extraButtons={[
                          {
                            text: 'Anular abono',
                            class: CustomPopupStyles.popUpGeneric,
                            event: () => {
                              setDataCancellation(state => ({
                                ...state,
                                isOpen: true,
                                data: item,
                                fullData,
                              }));
                            },
                          },
                        ]}
                      />
                    )}
                  </td>
                </tr>
              );
            })}

            <tr className={`fw-bold ${styles.f14}`}>
              <td className='text-start px-2 col-2'>{''}</td>
              <td className='text-center px-2 col-2'>{''}</td>
              <td className='text-start px-2 col-2'>{''}</td>
              <td className='text-start px-2 col-2'>{''}</td>
              <td className='text-end px-2 col-2'>
              {total ? formatNumberWithoutDecimals(total) : '-'}
              </td>
              <td className='text-center px-2 col-2'>{''}</td>
            </tr>
          </table>
        </td>
      </tr>
    );
  };

  //table
  const headers = [
    {
      title: <>&nbsp;&nbsp;</>,
      className: `ps-2 `,
    },
    {
      title: 'No. Orden',
      className: `px-2 text-center`,
    },
    {
      title: 'Fecha',
      className: `px-2 text-center`,
    },
    {
      title: 'Tipo',
      className: `px-2 text-center`,
    },
    {
      title: 'Paciente',
      className: `px-2 text-start `,
    },
    {
      title: 'Empresa',
      className: `px-2 text-start col-2`,
    },
    {
      title: `Contrato`,
      className: `px-2 text-start col-2`,
    },
    {
      title: `Admisionista`,
      className: `px-2 text-start col-2`,
    },
    {
      title: `Tipo de lente`,
      className: `text-start col-2`,
    },
    {
      title: `Total`,
      className: `px-2 text-end col-2`,
    },
    {
      title: 'Faltante',
      className: `px-2 text-end col-2`,
    },
    {
      title: 'Estado',
      className: `px-2 text-center`,
    },
    {
      title: <>&nbsp;&nbsp;</>,
      className: 'text-center',
    },
  ];

  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        const percentage = item?.status?.name?.split(' ')[1];
        tempList.push(
          <>
            <tr
              key={item?.id}
              className={`hover-table-row pointer ${item?.toggle ? 'accordionStyles' : ''}`}
              onClick={() => {
                // listProviderOrders?.results[index]?.toggle = !item?.toggle

                let format = listProviderPartialPayments?.results;
                format[index] = { ...format[index], toggle: !format[index]?.toggle };

                setListProviderPartialPayments({
                  ...listProviderPartialPayments,
                  results: format,
                });
              }}
            >
              <td className='ps-2'>
                <img src={!item?.toggle ? ordDownArrow : ordUpArrow} alt='icon' />
              </td>

              <td className={`text-center px-2`}>
                <div style={{ width: '65px' }}>{item?.prefixOrder || '-'}</div>
              </td>

              <td className={`text-center px-2 mx-2`}>
                <div style={{ width: '75px' }}>
                  <span>{moment(item?.date).format('DD/MM/YYYY') || '-'}</span>
                  <br />
                  <span>{moment(item?.date).format('h:mm a') || '-'}</span>
                </div>
              </td>

              <td className={`text-center px-2`}>
                {item?.iconUrl ? (
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 250 }}
                    overlay={
                      <Tooltip>
                        <div style={{ opacity: 1, background: '#fff', color: '#697387' }}>
                          {item?.typeName}
                        </div>
                      </Tooltip>
                    }
                  >
                    <img src={item?.iconUrl} alt='type-order' height={22} width={22} />
                  </OverlayTrigger>
                ) : (
                  '-'
                )}
              </td>

              <td className={`text-start px-2 `}>
                <div style={{ width: '100px' }}>
                  <span>{item?.patient?.name || '-'}</span>
                  <br />
                  <span style={{ color: '#A2A3AB' }}>
                    {`${item?.patient?.docType || '-'} ${ formatteDocument(item?.patient?.doc).format || '-'}`}
                  </span>
                </div>
              </td>

              <td className={`text-start px-2`}>{item?.companyName || '-'}</td>

              <td className={`text-start px-2`}>{item?.contractName || '-'}</td>

              <td className={`text-start px-2`}>{item?.admisionist?.name || '-'}</td>

              <td className={`text-start`}>
                <div style={{ width: '95px' }}>{item?.lensType || '-'}</div>
              </td>

              <td className={`text-end px-2`}>
                {item?.totalAmount ? formatNumberWithoutDecimals(item?.totalAmount) : '-'}
              </td>

              <td className={`text-end px-2`}>
                {item?.remainingAmount ? formatNumberWithoutDecimals(item?.remainingAmount) : '-'}
              </td>

              <td className={`text-center px-2`}>
                <div
                  style={
                    percentage === '100%'
                      ? {
                          width: percentage,
                          backgroundColor: `${item?.status?.fontColor}20`,
                          borderRadius: '10px',
                        }
                      : {
                          width: percentage,
                          backgroundColor: `${item?.status?.fontColor}20`,
                          borderTopLeftRadius: '10px',
                          borderBottomLeftRadius: '10px',
                        }
                  }
                >
                  <div
                    style={{
                      color: `${item?.status?.fontColor}`,
                      backgroundColor: `${item?.status?.fontColor}10`,
                      padding: '4px 10px',
                      width: '100px',
                      borderRadius: '10px',
                    }}
                  >
                    {item?.status?.name || '-'}
                  </div>
                </div>
              </td>

              <td className={`text-center px-1`}>
                <div style={{ minWidth: '50px' }}>
                  <CustomPopupExtend
                    position='right center'
                    className={`align-self-end`}
                    triggerClass={`${styles.hoverPopup} `}
                    triggerSrc={threeDots}
                    extraButtons={[
                      percentage !== '100%'
                        ? {
                            text: 'Abonar',
                            class: `${CustomPopupStyles.popUpGeneric} pe-5`,
                            event: () => {
                              history.push({
                                pathname: '/admision/ordenesProveedores/newOrderProvider',
                                state: {
                                  data: {
                                    ...item,
                                    pot_fields:
                                      'pot_has_lab,pot_has_lens_type,pot_has_discount,pot_has_medical_order,pot_has_partial_payment',
                                    pot_id: 2,
                                    subscription: true,
                                    subscriptionDetail: true,
                                  },
                                },
                              });
                            },
                          }
                        : undefined,
                      {
                        text: 'Anular',
                        class: `${CustomPopupStyles.popUpGeneric} pe-5`,
                        event: () => {
                          setDataCancellation(state => ({
                            ...state,
                            isOpen: true,
                            item,
                          }));
                        },
                      },
                    ]}
                  />
                </div>
              </td>
            </tr>

            {item?.toggle && renderAccordion({ data: item?.bills, fullData: item })}
          </>,
        );
      });
    }
    return tempList;
  };

  useEffect(() => {
    getListProviderPartialPayments({
      objFilters: { ...filters },
      token: token,
      url: '/medical/admissions/provider-orders/',
    }).then(res => {
      if (res?.success) {
        const format = res?.results?.map(el => ({ toggle: false, ...el }));
        setListProviderPartialPayments({
          ...res,
          results: format,
        });
      }
    });
  }, [trigger, filters, token, getListProviderPartialPayments]);

  useEffect(() => {
    getListProviderOrdersTypes({
      token: token,
      url: '/medical/admissions/provider-orders/types',
    });

    getListCancellationReason({
      url: '/medical/generals/cancellationReason/',
      token: token,
    });
  }, [getListCancellationReason, getListProviderOrdersTypes, token]);

  return (
    <>
      {(loaderListProviderPartialPayments ||
        loaderPostOnCancelSubscription ||
        loaderListCancellationReason ||
        loaderListProviderTypes ||
        loadCashClosingConfig) &&
        loader}

      <Row className='d-flex px-1'>
        <Col xs={2} className={`px-0 align-self-end`}>
          <Form.Group className='px-2' controlId='ordType'>
            <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
              <span>&nbsp;Fecha desde aaa</span>
            </Form.Label>
            <input
              className={`ord-roundInput2`}
              style={filters.dateMin ? { color: '#1a538d' } : {}}
              type='date'
              id='date-start'
              max={today()}
              value={filters.dateMin}
              onChange={e => {
                setData(state => ({
                  ...state,
                  filters: { ...state.filters, dateMin: e.target.value, dateMax: '' },
                }));
              }}
            />
          </Form.Group>
        </Col>

        <Col xs={2} className={`px-0 align-self-end`}>
          <Form.Group className=' px-2' controlId='ordType'>
            <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
              <span>&nbsp;Hasta</span>
            </Form.Label>
            <input
              className={`ord-roundInput2`}
              style={filters.dateMax ? { color: '#1a538d' } : {}}
              id='date-end'
              disabled={!filters.dateMin}
              type='date'
              value={filters.dateMax}
              max={today()}
              min={filters.dateMin}
              onChange={e => {
                setData(state => ({
                  ...state,
                  filters: { ...state.filters, dateMax: e.target.value },
                }));
              }}
            />
          </Form.Group>
        </Col>

        <Col xs={4} className='align-self-end'>
          <Form.Group className=' text-start' controlId='fName'>
            <div className={`d-flex ${styles.appGrayInputSearch} ${styles.f12} align-self-end`}>
              <input
                className={`ord-roundInput ${styles.appInputDate} w-100`}
                type='text'
                placeholder='Escribe aquí para buscar'
                autoComplete='off'
                onChange={e => {
                  setSearch(e.target.value);
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setData(state => ({
                      ...state,
                      filters: { ...state.filters, page: 1, search },
                    }));
                    onChangeTrigger();
                  }
                }}
              />
              <img
                src={ordBlueSearch}
                className='pointer'
                alt='buscar'
                onClick={() => {
                  setData(state => ({
                    ...state,
                    filters: { ...state.filters, page: 1, search },
                  }));
                  onChangeTrigger();
                }}
              />
            </div>
          </Form.Group>
        </Col>

        <Col
          className={`flex align-self-end mt-4 justify-content-end d-flex ${styles.colorNewOrderHover} ${styles.colorNewOrderHover2} ${styles.appDarkBlueText}`}
        >
          <div
            className={`d-flex align-self-end ms-auto justify-content-end gap-3 ${styles.colorNewOrderHover}`}
            onClick={event =>
              setCurrentTargetPopover(currentTargetPopover ? null : event.currentTarget)
            }
          >
            <span style={{ fontWeight: 'bold', marginRight: '10px' }}>Nuevo abono</span>
            <img src={add} style={{ marginRight: '5px' }} alt='' />
          </div>
        </Col>
      </Row>

      <OrdTable
        shadow
        headers={headers}
        hasChildren={true}
        oneHeadDarkBlue={false}
        lowerCase={false}
        paginate={{
          activePage: filters.page,
          totalPages: listProviderPartialPayments?.rowTotal,
          perPage: filters.perpage,
          pageRangeDisplayed: 3,
          showTextDetails: true,
          onChangePage: async e => {
            setData(state => ({
              ...state,
              filters: { ...state?.filters, page: e },
              trigger: state.trigger + 1,
            }));
          },
        }}
      >
        {formatData(listProviderPartialPayments?.results || [])}
      </OrdTable>

      <NewPopover
        currentTarget={currentTargetPopover}
        listOptions={listProviderTypes?.results
          ?.filter?.(el => el?.pot_fields?.includes('pot_has_partial_payment'))
          ?.map(e => ({
            text: e?.pot_name,
            onClick: () => {
              getCashClosingConfig({
                url: `/medical/admissions/cashClosingConfig/`,
                objFilters: { userId: idUser, siteId, eaccount },
                token: token,
              }).then(res => {
                if (res?.success) {
                  if (res?.results?.hasPendingCashClosing) {
                    customSwaltAlertAsistencial({
                      icon: 'warning',
                      title: `Intenta de nuevo`,
                      text: 'Para poder seguir generando órdenes, es necesario que realice un cierre parcial',
                      showCancelButton: false,
                      confirmButtonText: 'Aceptar',
                    });
                  } else {
                    history.push({
                      pathname: '/admision/ordenesProveedores/newOrderProvider',
                      state: { data: { ...e, subscription: true } },
                    });
                  }
                } else {
                  customSwaltAlertAsistencial({
                    icon: 'warning',
                    title: `Intenta de nuevo`,
                    text: res?.message,
                    showCancelButton: false,
                    confirmButtonText: 'Aceptar',
                  });
                }
              });

              history.push({
                pathname: '/admision/ordenesProveedores/newOrderProvider',
                state: { data: { ...e, subscription: true } },
              });
            },
          }))}
      />

      <OrdModal
        title={'Anulación'}
        subTitle={'valentina'}
        show={dataCancellation.isOpen}
        btnYesName={'Aceptar'}
        btnYesEvent={() => {
          if (dataCancellation?.item) {
            handleCancelSubscription(dataCancellation?.item);
          } else {
            onCancelSubscription({
              data: dataCancellation?.data,
              fullData: dataCancellation?.fullData,
            });
          }
        }}
        onHide={onClearDataCancellation}
        btnNoEvent={onClearDataCancellation}
      >
        <Box>
          <Box className={`m-0 ${styles.darkerBlueText} fw-bold`}>
            &nbsp;Motivo
            <span className={`${styles.ordOrangeText}`}>*</span>
          </Box>

          <Select
            noOptionsMessage={() => 'No hay datos'}
            options={listCancellationReason?.results?.map(el => ({
              label: el?.canDescription,
              value: el?.canId,
            }))}
            className='text-secondary '
            placeholder={'Seleccionar...'}
            styles={ordCustomSelect}
            onChange={({ value }) => {
              setDataCancellation(state => ({
                ...state,
                cancelId: value,
              }));
            }}
          />
        </Box>

        <Box my={2}>
          <Box className={`m-0 ${styles.darkerBlueText} fw-bold`}>
            &nbsp;Observaciones
            <span className={`${styles.ordOrangeText}`}>*</span>
          </Box>

          <textarea
            placeholder='Escribir...'
            style={{ minHeight: '225px', maxHeight: '225px' }}
            className={`text-secondary ordInputAdmission w-100 p-2`}
            onChange={({ target }) => {
              setDataCancellation(state => ({
                ...state,
                cancelObservation: target?.value,
              }));
            }}
          />
        </Box>
      </OrdModal>
    </>
  );
};

export default SubscriptionsPage;
