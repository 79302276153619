import deepcopy from 'deepcopy';
import { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import ReactSelectAsync from 'react-select/async';
import makeAnimated from 'react-select/animated';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ordCustomSelect } from '../../../src/components/Layouts/react-select-custom';
import CustomPopupExtend from '../../../src/components/Popup/customPopUpExtends';
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';
import GenericTableScroll from '../../components/Layouts/GenericTableScroll';
import ModalNew from '../../components/Layouts/ModalNew';
import { formatToRcSelect, generateId, today } from '../../helpers/helpers';

//-------------- styles ðŸ•   --------------------------------------------
import tableStyles from '../../../src/components/Layouts/tableStyle.module.scss';
import CustomPopupStyles from '../../../src/components/Popup/customPopup.module.scss';

//-------------- icons ðŸ•   --------------------------------------------
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { useGetMethod, usePostMethod } from '../../Hooks/useFetch';
import More from '../../assets/img/icons/AddBlue.svg';
import Close from '../../assets/img/icons/close.svg';
import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';
import { customSwaltAlertAsistencial } from '../../helpers';

function MedicalContracts() {
  //-------------- const   --------------------------
  const [data, setData] = useState([]);
  const history = useHistory();
  const animatedComponents = makeAnimated();
  const MySwal = withReactContent(Swal);
  const store = useSelector(state => state);
  const eaccount = store.loginReducer.currentAccount.id;

  const token = store.loginReducer.Authorization;
  const [loading] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [triggerContract] = useState(0);
  const [triggerPopulation, setTriggerPopulation] = useState(0);
  const [triggerServices, setTriggerServices] = useState(0);
  const [filters, setFilters] = useState({
    perpage: 10,
    page: 1,
    status: '',
    beginsOn: '',
    endsOn: '',
    search: '',
    idAccount: store.loginReducer.currentAccount.id,
  });
  const [infoCreate, setInfoCreate] = useState({
    ctoId: '',
    status: '',
    startDateContract: '',
    endDateContract: '',
    jobTitle: '',
    typeContract: '',
    employee: '',
    user_md: '',
    thirdParty: '',
    contractId: '',
    beginsOn: '',
    endsOn: '',
    idUser: store.loginReducer.user_data.id,
    idAccount: store.loginReducer.currentAccount.id,
  });
  const [infoEmployees, setInfoEmployees] = useState({
    unique: '',
    name_user: '',
    user_id: '',
    job_position: '',
    job_position_id: '',
  });
  const {
    results: ListContracts,
    load: ListContractsLoader,
    trigger: getListContracts,
  } = useGetMethod();
  const { results: ListMedicalCharges, trigger: getListMedicalCharges } = useGetMethod();
  const { results: ListContractOperator, trigger: getListContractOperator } = useGetMethod();
  const { results: ListMedicalUsers, trigger: getListMedicalUsers } = useGetMethod();
  const { results: listExternalUsers, trigger: getListExternalUsers } = useGetMethod();
  const { results: ListHealthProviders, trigger: getListHealthProviders } = useGetMethod();
  const { load: CreateContractLoad, trigger: createMedicalContract } = usePostMethod();

  const { results: ListAccountContracts, trigger: getListAccountContracts } = useGetMethod();
  const { results: ListPopulationsByContract, trigger: getListPopulationsByContract } =
    useGetMethod();
  const { results: ListServicesByRelCP, trigger: getListServicesByRelCP } = useGetMethod();
  const { load: ListDetailLoader, trigger: getListDetail } = useGetMethod();
  const { load: CreateLinkServicesLoad, trigger: CreateLinkServices } = usePostMethod();
  const { results: ListEmployeeWithCard, trigger: getListEmployeeWithCard } = useGetMethod();
  const { load: CloneDatasheetLoad, trigger: CloneDatasheet } = usePostMethod();
  const contractOperatorOptions = formatToRcSelect(
    ListContractOperator?.results,
    'ctoId',
    'ctoName',
  );

  const [listEmployesFormat, setlistEmployesFormat] = useState([]);

  useEffect(() => {
    if (ListMedicalUsers?.results?.length) {
      setlistEmployesFormat(
        ListMedicalUsers?.results?.map(item => ({
          ...item,
          label:
            item.user?.split('-')[1] + ' - ' + item.user?.split('-')[0] + ' - ' + item.jobTitle,
          value: `${item.id}_${generateId()}`,
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ListMedicalUsers?.results?.length]);

  const listExternalUsersFormat = useMemo(() => {
    if (listExternalUsers?.results) {
      return listExternalUsers?.results.map(item => ({
        label: `${item.first_name} ${item.first_surname}`,
        value: item.id,
      }));
    }
    return [];
  }, [listExternalUsers?.results]);

  const [infoRelation, setInfoRelation] = useState({
    arrPop: [],
    idService: '',
    idPop: [],
    idContractServices: [],
    idRelCPS: [],
    idContract: '',
    population: [],
    services: [],
    nameContract: '',
    idRelCP: [],
  });
  const [infoClone, setInfoClone] = useState({ userMdToCopy: '', idContractMdToCopy: '' });

  const [showModal, setShowModal] = useState({
    status: false,
  });
  const [showModalServices, setShowModalServices] = useState({
    status: false,
    idContractMd: '',
    userMd: '',
    employeeName: '',
  });
  const [showModalClone, setShowModalClone] = useState({
    status: false,
    idContractMdToPaste: '',
    userMdToPaste: '',
    doctorName: '',
  });
  const [listEmployees, setListEmployees] = useState([]);
  const [, setListServices] = useState([]);

  const headers = [
    {
      title: 'Empleado',
      className: 'text-start px-2',
    },
    {
      title: 'Tipo de operador',
      className: 'text-start px-2',
    },
    {
      title: 'Nombre del cargo',
      className: 'text-start px-2',
    },
    {
      title: 'Vinculación',
      className: 'text-center px-2',
    },
    {
      title: 'Fecha de inicio',
      className: 'text-center px-2',
    },
    {
      title: 'Estado',
      className: 'text-center px-2',
    },
    {
      title: <>&nbsp;</>,
    },
  ];

  const header = [
    <div key={1} className={`col-6 text-start px-2`} style={{ borderTopLeftRadius: '10px' }}>
      Empleado
    </div>,
    <div key={2} className={`col-5 text-start`}>
      Cargo
    </div>,
    <div key={3} className={`col-1`} style={{ borderTopRightRadius: '10px' }}>
      &nbsp;
    </div>,
  ];

  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach(e => {
        let container = (
          <div
            className='rounded-pill p-2 col-7'
            style={{ backgroundColor: `${e?.bgColor}`, width: 'max-content' }}
          >
            <b style={{ color: `${e?.fontColor}` }}>{e.status}</b>
          </div>
        );
        tempList.push([
          {
            text: e.fullName,
            className: 'text-start px-2 col-3',
          },
          {
            text: e.ctoName ?? '-',
            className: 'text-start px-2 col-2',
          },
          {
            text: e.jobTitle,
            className: 'text-start px-2 col-2',
          },
          {
            text: e.typeContract,
            className: 'text-center px-2 col-2',
          },
          {
            text: e.beginsOn,
            className: 'text-center px-2 col-2',
          },
          {
            text: container,
            className: 'px-2',
            style: { textAlign: '-webkit-center' },
          },
          e.status !== 'Finalizado' && (
            <CustomPopupExtend
              triggerSrc={threeDots}
              extraButtons={[
                {
                  text: 'Asociar servicios',
                  event: () =>
                    setShowModalServices({
                      showModalServices,
                      status: true,
                      idContractMd: e.id,
                      userMd: e.idDoctor,
                      employeeName: e.fullName,
                    }),
                  class: CustomPopupStyles.popUpGeneric,
                },
                {
                  text: 'Clonar ficha',
                  event: () =>
                    setShowModalClone({
                      ...showModalClone,
                      status: true,
                      idContractMdToPaste: e.id,
                      userMdToPaste: e.idDoctor,
                      doctorName: e.fullName,
                    }),
                  class: CustomPopupStyles.popUpGeneric,
                },
                {
                  text: 'Tabla de tiempos',
                  event: () =>
                    history.push({
                      pathname: `/cuentasMedicas/parametrizacionDeTiempos`,
                      state: {
                        name: e.fullName,
                        userMd: e.idDoctor,
                        idContractMd: e.id,
                        jobTitle: e.jobTitle,
                      },
                    }),
                  class: CustomPopupStyles.popUpGeneric,
                },
                {
                  text: 'Asignar Honorarios',
                  class: CustomPopupStyles.popUpGeneric,
                  event: () =>
                    history.push({
                      pathname: `Contratos/asignar-honorarios/${e.id}/${e.idDoctor}`,
                    }),
                },
              ]}
            ></CustomPopupExtend>
          ),
        ]);
      });
    }
    setData(tempList);
    return tempList;
  };

  //-------------- options Selects ðŸ•   --------------------------
  let optionsHealthProviders = [];
  if (Array.isArray(ListHealthProviders?.results)) {
    ListHealthProviders.results.forEach(item => {
      optionsHealthProviders.push({
        value: item.id,
        label: item.provider,
        key: item.id + 'provider',
      });
    });
  }

  let optionsMedicalUsers = [];

  if (Array.isArray(ListMedicalUsers?.results)) {
    ListMedicalUsers.results.forEach(item => {
      if (isNaN(Number(item.idContract)) === false) {
        optionsMedicalUsers.push({
          value: item.id,
          label:
            item.user?.split('-')[1] + ' - ' + item.user?.split('-')[0] + ' - ' + item.jobTitle,
          key: item.id + 'user',
          idContract: item.idContract,
          startDateContract: item.startDateContract,
          endDateContract: item.endDateContract,
          jobTitle: item.user?.split('-')[1] + '-' + item.jobTitle,
          status: item.status,
        });
      }
    });
  }

  let optionsMedicalCharges = [];
  if (Array.isArray(ListMedicalCharges?.results)) {
    ListMedicalCharges.results.forEach(item => {
      optionsMedicalCharges.push({
        value: item.id,
        label: item.job_title,
        key: item.id + 'job_title',
      });
    });
  }

  let optionsAccountContracts = [];
  if (Array.isArray(ListAccountContracts?.results)) {
    ListAccountContracts.results.forEach(item => {
      optionsAccountContracts.push({
        value: item.idContract,
        label: item.contractName,
        key: item.idContract + 'contract',
      });
    });
  }

  let optionsPopulationsByContract = [];
  if (Array.isArray(ListPopulationsByContract?.results)) {
    ListPopulationsByContract.results.forEach(item => {
      optionsPopulationsByContract.push({
        value: item.idClientPopulation,
        label: item.nameClientPopulation,
        key: item.idClientPopulation + 'population',
        idRelCP: item.idRelCP,
      });
    });
  }

  let optionsServicesByRelCP = [];
  if (Array.isArray(ListServicesByRelCP?.results)) {
    ListServicesByRelCP.results.forEach(item => {
      optionsServicesByRelCP.push({
        value: item.idService,
        label: item.nameService,
        idContractService: item.idContractService,
        key: item.idService + 'service',
      });
    });
  }

  let optionsEmployeeWhitCard = [];
  if (Array.isArray(ListEmployeeWithCard?.results)) {
    ListEmployeeWithCard.results.forEach(item => {
      optionsEmployeeWhitCard.push({
        value: item.idContractMd,
        label: item.fullName + '-' + item.jobTitle,
        idRelCPS: item.idRelCPS,
        key: item.idContractMd + 'idContractMd',
        idDoctor: item.idDoctor,
      });
    });
  }

  //-------------- Actions ðŸ•   --------------------------

  useEffect(() => {
    getListContracts({
      url: '/medical/doctor/medicalContracts/',
      objFilters: filters,
      token: token,
      doAfterSuccess: result => {
        formatData(result.results);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters.beginsOn,
    filters.endsOn,
    filters.status,
    filters.typeContract,
    trigger,
    filters.page,
  ]);

  useEffect(() => {
    getListEmployeeWithCard({
      url: '/medical/generals/employeesWhitTechnicalSheet/',
      objFilters: { idAccount: store.loginReducer.currentAccount.id },
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    getListMedicalCharges({
      url: '/medical/generals/medicalCharges/',
      token: token,
    });
    getListContractOperator({
      url: '/medical/doctor/listContractOperator/',
      token: token,
    });
    getListMedicalUsers({
      url: '/medical/generals/medicalUsers/',
      objFilters: { eaccount, status: 'enabled' },
      token: token,
    });

    getListExternalUsers({
      url: '/users/params/',
      objFilters: { page: 1, perpage: 10, eaccount },
      token: token,
    });

    getListHealthProviders({
      url: '/medical/generals/healthProviders/',
      objFilters: {eaccount},
      token: token,
    });
    getListAccountContracts({
      url: '/medical/generals/accountContracts/',
      objFilters: { idAccount: store.loginReducer.currentAccount.id, status: 'enabled' },
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showModalServices.idContractMd !== '' && infoRelation.idContract !== '') {
      getListDetail({
        url: '/medical/doctor/medicalContractDetail/',
        objFilters: {
          idContractMd: showModalServices.idContractMd,
          idContract: infoRelation.idContract,
        },
        token: token,
        doAfterSuccess: results => {
          setInfoRelation({
            ...infoRelation,
            idContractServices: results?.results?.idContractServices
              ? results?.results?.idContractServices
              : [],
            idPop: results?.results?.idPop ? results?.results?.idPop : [],
          });
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoRelation.idContract]);

  useEffect(() => {
    if (infoRelation.idContract !== '') {
      getListPopulationsByContract({
        url: '/medical/generals/populationsByContract/',
        objFilters: infoRelation,
        token: token,
      });
      setTriggerPopulation(triggerPopulation + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoRelation.idContract]);

  useEffect(() => {
    getListServicesByRelCP({
      url: '/medical/generals/servicesByRelCP/',
      array:
        'idPopulations=[' +
        String(infoRelation.idPop) +
        ']&idContract=' +
        String(infoRelation.idContract),
      token: token,
    });
  }, [infoRelation.idPop, infoRelation.idContract, token, getListServicesByRelCP]);

  const onLoadUsers = async (inputValue = '', callback) => {
    const result = await getListExternalUsers({
      url: '/users/params/',
      objFilters: { page: 1, perpage: 10, search: inputValue, eaccount },
      token: token,
    });
    const formattedUserList = result.results?.map(el => ({
      label: `${el.first_name} ${el.first_surname}`,
      value: el.id,
    }));
    callback(formattedUserList);
  };

  const handleSearch = e => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  const createContract = () => {
    if (infoCreate.typeContract === 'Externo' && infoCreate.beginsOn !== '') {
      createMedicalContract({
        url: '/medical/doctor/medicalContracts/',
        token: token,
        method: 'POST',
        body: {
          typeContract: infoCreate.typeContract,
          thirdParty: infoCreate.thirdParty,
          beginsOn: infoCreate.beginsOn,
          endsOn: infoCreate.endsOn,
          employees: listEmployees,
          idUser: infoCreate.idUser,
          idAccount: infoCreate.idAccount,
          ctoId: infoCreate.ctoId,
        },
        succesAction: () => {
          customSwaltAlertAsistencial({
            icon: 'success',
            title: 'Creado exitosamente',
            text: `Se ha creado el contrato externo`,
            showCancelButton: false,
          });
          setInfoCreate({
            ...infoCreate,
            ctoId: '',
            employee: '',
            user_md: '',
            thirdParty: '',
            contractId: '',
            beginsOn: '',
            endsOn: '',
            typeContract: '',
          });
          setListEmployees([]);
          setInfoEmployees({
            infoEmployees,
            name_user: '',
            user_id: '',
            job_position: '',
            job_position_id: '',
          });
          setShowModal({ showModal, status: false, idContractMd: '' });
          setTrigger(trigger + 1);
        },
        doAfterException: results => {
          customSwaltAlertAsistencial({
            showCancelButton: false,
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: results.message,
            confirmButtonText: 'Aceptar',
          });
        },
      });
    } else if (
      infoCreate.typeContract === 'Interno' &&
      infoCreate.beginsOn !== '' &&
      infoCreate.employee
    ) {
      createMedicalContract({
        url: '/medical/doctor/medicalContracts/',
        token: token,
        method: 'POST',
        body: {
          typeContract: infoCreate.typeContract,
          employee: infoCreate.employee,
          beginsOn: infoCreate.beginsOn,
          endsOn: infoCreate.endsOn,
          idUser: infoCreate.idUser,
          idAccount: infoCreate.idAccount,
          idContract: infoCreate.contractId,
          ctoId: infoCreate.ctoId,
        },
        succesAction: () => {
          customSwaltAlertAsistencial({
            icon: 'success',
            title: 'Creado exitosamente',
            text: `Se ha creado el contrato del médico: ${infoCreate?.employeeName}`,
            showCancelButton: false,
          });
          setInfoCreate({
            ...infoCreate,
            employee: '',
            user_md: '',
            thirdParty: '',
            contractId: '',
            beginsOn: '',
            endsOn: '',
            typeContract: '',
          });
          setListEmployees([]);
          setInfoEmployees({
            infoEmployees,
            name_user: '',
            user_id: '',
            job_position: '',
            job_position_id: '',
          });
          setShowModal({ showModal, status: false });
          setTrigger(trigger + 1);
        },
        doAfterException: results => {
          customSwaltAlertAsistencial({
            showCancelButton: false,
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: results.message,
            confirmButtonText: 'Aceptar',
          });
        },
      });
    } else {
      customSwaltAlertAsistencial({
        showCancelButton: false,
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Por favor diligenciar los campos obligatorios',
        confirmButtonText: 'Aceptar',
      });
    }
  };

  const deleteEmployee = item => {
    let tempObject = deepcopy(listEmployees);
    let found = tempObject.filter(x => x.user_id !== item.user_id);
    setListEmployees(found);
  };
  const renderListEmployees = () => {
    let tempList = [];
    if (Array.isArray(listEmployees)) {
      listEmployees.forEach(item => {
        tempList.push(
          <section2 className={`d-flex`}>
            <div className='col-6 text-start px-2'>{item.name_user}</div>
            <div className='col-5 text-start px-2'>{item.job_position}</div>
            <div className='col-1 text-start px-2'>
              <img
                src={Close}
                alt='Close'
                className='px-2 cursorPointer'
                onClick={() => deleteEmployee(item)}
              />
            </div>
          </section2>,
        );
      });
    }
    return tempList;
  };

  const addEmpleoyee = () => {
    if (infoEmployees.user_id !== '' && infoEmployees.job_position_id !== '') {
      let tempObject = listEmployees;
      let tempObject2 = tempObject.filter(x => x.user_id === infoEmployees.user_id);
      if (tempObject2.length <= 0) {
        tempObject.push({
          name_user: infoEmployees.name_user,
          job_position: infoEmployees.job_position,
          user_id: infoEmployees.user_id,
          job_position_id: infoEmployees.job_position_id,
        });
        setListEmployees(tempObject);
      } else {
        MySwal.fire({
          icon: 'warning',
          title: 'Empleado duplicado',
          text: 'Señor usuario, no es posible agregar dos veces el mismo empleado',
          confirmButtonText: 'Aceptar',
          customClass: {
            confirmButton: `buttonSweetAlert`,
          },
        });
      }
    } else {
      customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Por favor diligenciar los campos obligatorios',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }
  };

  //-------------- Service partnership process ðŸ•   --------------------------

  const createLink = () => {
    CreateLinkServices({
      url: '/medical/doctor/linkServicesToMedicalContract/',
      token: token,
      method: 'POST',
      body: {
        idContractMd: showModalServices.idContractMd,
        idContractServices: infoRelation.idContractServices,
        idPopulations: infoRelation.idPop,
      },
      doAfterSuccess: () => {
        setListServices([]);
        setInfoRelation({
          arrPop: [],
          idService: '',
          idPop: [],
          idContractServices: [],
          idRelCPS: [],
          idContract: '',
          population: [],
          services: [],
          nameContract: '',
          idRelCP: [],
        });
        setShowModalServices({ showModalServices, status: false, idContractMd: '', userMd: '' });
        setTrigger(trigger + 1);
      },
      doAfterException: results => {
        customSwaltAlertAsistencial({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const cloneCard = () => {
    if (infoClone.idContractMdToCopy !== '') {
      CloneDatasheet({
        url: '/medical/doctor/cloneDatasheet/',
        token: token,
        method: 'POST',
        body: {
          idContractMdToCopy: infoClone.idContractMdToCopy,
          idContractMdToPaste: showModalClone.idContractMdToPaste,
          userMdToCopy: infoClone.userMdToCopy,
          userMdToPaste: showModalClone.userMdToPaste,
        },
        succesAction: () => {
          customSwaltAlertAsistencial({
            showCancelButton: true,
            icon: 'warning',
            title: '¿Está seguro?',
            text: 'Señor usuario, si el médico cuenta con una ficha técnica, esta se reemplazará en su totalidad',
            confirmButtonText: 'Si, continuar',
          }).then(result => {
            if (result.isConfirmed) {
              setInfoClone({ ...infoClone, userMdToCopy: '', idContractMdToCopy: '' });
              setTrigger(trigger + 1);
              customSwaltAlertAsistencial({
                showCancelButton: false,
                icon: 'success',
                title: 'Ficha técnica clonada exitosamente',
                text: `Se ha clonado la ficha técnica del contrato médico: ${infoClone.doctorName}`,
                confirmButtonText: 'Aceptar',
              }).finally(
                setShowModalClone({
                  ...showModalClone,
                  status: false,
                  idContractMdToPaste: '',
                  userMdToPaste: '',
                })
              )
            }
          });
        },
        doAfterException: results => {
          customSwaltAlertAsistencial({
            showCancelButton: false,
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: results.message,
            confirmButtonText: 'Aceptar',
          });
        },
      });
    } else {
      customSwaltAlertAsistencial({
        showCancelButton: false,
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Se deben diligenciar todos los campos',
        confirmButtonText: 'Aceptar',
      });
    }
  };

  //-------------- Principal return ðŸ•   --------------------------
  return (
    <div className={`${tableStyles.tlnContainer} mt-4`}>
      <ModalNew
        show={showModal.status}
        title='Nuevo contrato'
        size={700}
        btnYesName={'Guardar'}
        btnYesEvent={() => createContract()}
        btnNoEvent={() => {
          setShowModal({ ...showModal, status: false });
          setInfoCreate({
            ...infoCreate,
            employee: '',
            user_md: '',
            thirdParty: '',
            contractId: '',
            beginsOn: '',
            endsOn: '',
            typeContract: '',
          });
          setListEmployees([]);
        }}
        onHide={() => {
          setShowModal({ ...showModal, status: false });
          setInfoCreate({
            ...infoCreate,
            employee: '',
            user_md: '',
            thirdParty: '',
            contractId: '',
            beginsOn: '',
            endsOn: '',
            typeContract: '',
          });
          setListEmployees([]);
        }}
      >
        <Row key={trigger}>
          <Col xs={6}>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label
                className={`m-0 ${tableStyles.ordDarkBlueText}`}
                style={{ paddingLeft: '3px' }}
              >
                <b>
                  {' '}
                  Tipo de operador <span className={'text-warning'}>*</span>
                </b>
              </Form.Label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                options={contractOperatorOptions}
                className='text-secondary'
                placeholder={'Seleccionar...'}
                styles={ordCustomSelect}
                onChange={e => {
                  setInfoCreate({ ...infoCreate, ctoId: e.value });
                }}
              ></Select>
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label
                className={`m-0 ${tableStyles.ordDarkBlueText}`}
                style={{ paddingLeft: '3px' }}
              >
                <b>
                  Tipo o vinculación <span className={'text-warning'}>*</span>
                </b>
              </Form.Label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                options={[
                  { value: 'Interno', label: 'Interno' },
                  { value: 'Externo', label: 'Externo' },
                ]}
                className='text-secondary'
                placeholder={'Seleccione'}
                styles={ordCustomSelect}
                onChange={e => {
                  setInfoCreate({
                    ...infoCreate,
                    typeContract: e.value,
                    third_party: '',
                    contractId: '',
                    startDateContract: '',
                    endDateContract: '',
                  });
                  setInfoEmployees({ ...infoEmployees, name_user: '', user_id: '' });
                  setListEmployees([]);
                }}
              ></Select>
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label
                className={`m-0 ${tableStyles.ordDarkBlueText}`}
                style={{ paddingLeft: '3px' }}
              >
                <b>
                  Fecha de inicio <span className={'text-warning'}>*</span>
                </b>
              </Form.Label>
              <Form.Control
                className={`ord-roundInput`}
                type='date'
                placeholder=''
                value={
                  infoCreate.startDateContract ? infoCreate.startDateContract : infoCreate.beginsOn
                }
                min={today()}
                onChange={e => setInfoCreate({ ...infoCreate, beginsOn: e.target.value })}
                disabled={infoCreate.startDateContract ? true : false}
              />
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label
                className={`m-0 ${tableStyles.ordDarkBlueText}`}
                style={{ paddingLeft: '3px' }}
              >
                <b>Fecha de fin</b>
              </Form.Label>
              <Form.Control
                className={`ord-roundInput`}
                type='date'
                placeholder=''
                value={infoCreate.endDateContract ? infoCreate.endDateContract : infoCreate.endsOn}
                min={infoCreate.beginsOn}
                onChange={e => setInfoCreate({ ...infoCreate, endsOn: e.target.value })}
                disabled={infoCreate.startDateContract ? true : false}
              />
            </Form.Group>
          </Col>
          {infoCreate?.typeContract === 'Externo' ? (
            <Col xs={6}>
              <Form.Group className='mb-3 text-start' controlId='fName'>
                <Form.Label
                  className={`m-0 ${tableStyles.ordDarkBlueText}`}
                  style={{ paddingLeft: '3px' }}
                >
                  <b>
                    Tercero <span className={'text-warning'}>*</span>
                  </b>
                </Form.Label>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  options={optionsHealthProviders}
                  value={
                    infoCreate?.thirdParty
                      ? {
                          label: ListHealthProviders?.results?.find(
                            e => e.id === infoCreate.thirdParty,
                          )?.provider,
                          value: infoCreate.thirdParty,
                        }
                      : ''
                  }
                  className='text-secondary'
                  placeholder={'Seleccione'}
                  styles={ordCustomSelect}
                  onChange={e => setInfoCreate({ ...infoCreate, thirdParty: e.value })}
                ></Select>
              </Form.Group>
            </Col>
          ) : (
            infoCreate?.typeContract === 'Interno' && (
              <Col xs={6}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label
                    className={`m-0 ${tableStyles.ordDarkBlueText}`}
                    style={{ paddingLeft: '3px' }}
                  >
                    <b>
                      {' '}
                      Empleado <span className={'text-warning'}>*</span>
                    </b>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={listEmployesFormat || []}
                    className='text-secondary'
                    value={
                      infoEmployees?.name_user
                        ? {
                            label: infoEmployees.name_user,
                            value: listEmployesFormat.find(
                              state => state.value === infoEmployees.unique,
                            )?.value,
                          }
                        : ''
                    }
                    placeholder={'Seleccione'}
                    styles={ordCustomSelect}
                    onChange={e => {
                      setInfoCreate({
                        ...infoCreate,
                        employee: e.value.split('_')[0],
                        employeeName: e.label,
                        contractId: e.idContract,
                        startDateContract: e.startDateContract,
                        endDateContract: e.endDateContract,
                        jobTitle: e.jobTitle,
                        status: e.status,
                      });
                      setInfoEmployees({
                        ...infoEmployees,
                        name_user: e.label,
                        unique: e.value,
                        user_id: e.value.split('_')[0],
                      });
                    }}
                  ></Select>
                </Form.Group>
              </Col>
            )
          )}
          {infoCreate?.typeContract === 'Externo' && (
            <>
              <Col xs={6}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label
                    className={`m-0 ${tableStyles.ordDarkBlueText}`}
                    style={{ paddingLeft: '3px' }}
                  >
                    <b>
                      Usuario <span className={'text-warning'}>*</span>
                    </b>
                  </Form.Label>
                  <ReactSelectAsync
                    noOptionsMessage={() => 'No hay datos'}
                    loadOptions={onLoadUsers}
                    defaultOptions={[
                      { value: '', label: 'Seleccionar...' },
                      ...listExternalUsersFormat,
                    ]}
                    className='text-secondary'
                    value={
                      infoEmployees?.name_user
                        ? {
                            label: infoEmployees.name_user,
                            value: infoEmployees.user_id,
                          }
                        : ''
                    }
                    placeholder={'Seleccionar...'}
                    styles={ordCustomSelect}
                    onChange={e => {
                      setInfoEmployees({
                        ...infoEmployees,
                        name_user: e.label,
                        unique: e.value,
                        user_id: e.value,
                      });
                    }}
                  ></ReactSelectAsync>
                </Form.Group>
              </Col>
              <Col xs={5}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label
                    className={`m-0 ${tableStyles.ordDarkBlueText}`}
                    style={{ paddingLeft: '3px' }}
                  >
                    <b>
                      Cargo <span className={'text-warning'}>*</span>
                    </b>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={optionsMedicalCharges}
                    className='text-secondary'
                    placeholder={'Seleccione'}
                    styles={ordCustomSelect}
                    onChange={e =>
                      setInfoEmployees({
                        ...infoEmployees,
                        job_position: e.label,
                        job_position_id: e.value,
                      })
                    }
                  ></Select>
                </Form.Group>
              </Col>
              <Col xs={1} className='d-flex ' style={{ paddingTop: '14px' }}>
                <div className='align-self-center'>
                  <img
                    src={More}
                    className='cursorPointer'
                    alt='add'
                    onClick={() => addEmpleoyee()}
                    style={{ height: '30px' }}
                  ></img>
                </div>
              </Col>
              <GenericTableScroll headers={header} body={renderListEmployees()} typeHead={'4'} />
            </>
          )}
        </Row>
      </ModalNew>
      <ModalNew
        show={showModalServices.status}
        title='Asociar servicios'
        size={700}
        btnYesName={'Guardar'}
        btnYesEvent={() => createLink()}
        btnNoEvent={() => {
          setShowModalServices({
            ...showModalServices,
            status: false,
            idContractMd: '',
            userMd: '',
          });
          setInfoRelation({
            arrPop: [],
            idService: '',
            idPop: [],
            idContractServices: [],
            idRelCPS: [],
            idContract: '',
            population: [],
            services: [],
            nameContract: '',
            idRelCP: [],
          });
        }}
        onHide={() => {
          setShowModalServices({
            ...showModalServices,
            status: false,
            idContractMd: '',
            userMd: '',
          });
          setInfoRelation({
            arrPop: [],
            idService: '',
            idPop: [],
            idContractServices: [],
            idRelCPS: [],
            idContract: '',
            population: [],
            services: [],
            nameContract: '',
            idRelCP: [],
          });
        }}
      >
        <Row>
          <Col xs={4}>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label
                className={`m-0 ${tableStyles.ordDarkBlueText}`}
                style={{ paddingLeft: '3px' }}
              >
                <b>
                  Contrato <span className={'text-warning'}>*</span>
                </b>
              </Form.Label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={triggerContract}
                options={optionsAccountContracts}
                className='text-secondary'
                placeholder={'Seleccione'}
                styles={ordCustomSelect}
                onChange={e => {
                  setInfoRelation({ ...infoRelation, idContract: e.value, nameContract: e.label });
                  setTriggerServices(state => state + 1);
                }}
              ></Select>
            </Form.Group>
          </Col>

          <Col xs={12}>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label
                className={`m-0 ${tableStyles.ordDarkBlueText}`}
                style={{ paddingLeft: '3px' }}
              >
                <b>
                  Poblaciones <span className={'text-warning'}>*</span>
                </b>
              </Form.Label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={triggerPopulation}
                isMulti
                options={infoRelation?.idContract === '' ? [] : optionsPopulationsByContract}
                className={`text-secondary `}
                name='optionsPopulationsByContract'
                components={animatedComponents}
                closeMenuOnSelect={false}
                placeholder={'Seleccione'}
                styles={ordCustomSelect}
                value={optionsPopulationsByContract?.filter(item =>
                  infoRelation?.idPop?.includes(item.value),
                )}
                onChange={e => {
                  let arrIdEnd = [];
                  let arrIdPopulation = [];
                  e.forEach(elem => {
                    arrIdEnd?.push({
                      nameClientPopulation: elem.label,
                      idClientPopulation: elem.value,
                      idRelCP: elem.idRelCP,
                    });
                    arrIdPopulation?.push(elem.value);
                  });
                  setInfoRelation({
                    ...infoRelation,
                    population: arrIdEnd,
                    idPop: arrIdPopulation,
                  });
                  setTriggerServices(triggerServices + 1);
                }}
              ></Select>
            </Form.Group>
          </Col>

          <Col xs={12}>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label
                className={`m-0 ${tableStyles.ordDarkBlueText}`}
                style={{ paddingLeft: '3px' }}
              >
                <b>
                  Servicios <span className={'text-warning'}>*</span>
                </b>
              </Form.Label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={triggerServices}
                isMulti
                options={optionsServicesByRelCP}
                className={`text-secondary ${tableStyles.heightScrollSelectApp}`}
                name='optionsServicesByRelCP'
                components={animatedComponents}
                closeMenuOnSelect={false}
                placeholder={'Seleccione'}
                styles={ordCustomSelect}
                value={optionsServicesByRelCP?.filter(item =>
                  infoRelation?.idContractServices?.includes(item.idContractService),
                )}
                onChange={e => {
                  let arrIdEnd = [];
                  e.forEach(elem => {
                    arrIdEnd?.push(elem.idContractService);
                  });
                  setInfoRelation({
                    ...infoRelation,
                    idContractServices: arrIdEnd,
                  });
                }}
              ></Select>
            </Form.Group>
          </Col>
        </Row>
      </ModalNew>
      <ModalNew
        show={showModalClone.status}
        title='Clonar ficha técnica'
        size={400}
        btnYesName={'Guardar'}
        btnYesEvent={() => cloneCard()}
        btnNoEvent={() => {
          setShowModalClone({
            showModalClone,
            status: false,
            idContractMdToPaste: '',
            userMdToPaste: '',
          });
          setInfoClone({ ...infoClone, idContractMdToCopy: '', userMdToCopy: '' });
        }}
        onHide={() => {
          setShowModalClone({
            showModalClone,
            status: false,
            idContractMdToPaste: '',
            userMdToPaste: '',
          });
          setInfoClone({ ...infoClone, idContractMdToCopy: '', userMdToCopy: '' });
        }}
      >
        <Row>
          <Col xs={12}>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label
                className={`m-0 ${tableStyles.ordDarkBlueText}`}
                style={{ paddingLeft: '3px' }}
              >
                <b>
                  Ficha técnica del empleado <span className={'text-warning'}>*</span>
                </b>
              </Form.Label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={triggerContract}
                options={optionsEmployeeWhitCard}
                className='text-secondary'
                placeholder={'Seleccione...'}
                styles={ordCustomSelect}
                onChange={e => {
                  setInfoClone({
                    ...infoClone,
                    idContractMdToCopy: e.value,
                    userMdToCopy: e.idDoctor,
                    doctorName: e.label,
                  });
                }}
              ></Select>
            </Form.Group>
          </Col>
        </Row>
      </ModalNew>
      {(!!loading ||
        ListContractsLoader ||
        CreateLinkServicesLoad ||
        CreateContractLoad ||
        ListDetailLoader ||
        CloneDatasheetLoad) && (
        <Loader className='loading' type='Oval' color='#003f80' height={100} width={100} />
      )}
      <OrdGenericTemplate showBottomBorder={false} title={'Contratos médicos'}>
        <Row>
          <Col xs={2}>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                <b className='px-1'>Fecha desde</b>
              </Form.Label>
              <Form.Control
                className={`ord-roundInput pleaceholderDate`}
                type='date'
                placeholder=''
                onChange={e => setFilters({ ...filters, beginsOn: e.target.value })}
              />
            </Form.Group>
          </Col>
          <Col xs={2}>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                <b className='px-1'>Hasta</b>
              </Form.Label>
              <Form.Control
                className={`ord-roundInput pleaceholderDate`}
                type='date'
                placeholder=''
                onChange={e => setFilters({ ...filters, endsOn: e.target.value })}
              />
            </Form.Group>
          </Col>
          <Col xs={2}>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                <b className='px-1'>Estado</b>
              </Form.Label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                options={[
                  { value: '', label: 'Todos' },
                  { value: 'Vigente', label: 'Vigente' },
                  { value: 'Finalizado', label: 'Finalizado' },
                ]}
                className='text-secondary'
                placeholder={'Seleccione'}
                styles={ordCustomSelect}
                onChange={e => setFilters({ ...filters, status: e.value })}
              ></Select>
            </Form.Group>
          </Col>
          <Col xs={2}>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                <b className='px-1'>Vinculación</b>
              </Form.Label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                options={[
                  { value: '', label: 'Todos' },
                  { value: 'Interno', label: 'Interno' },
                  { value: 'Externo', label: 'Externo' },
                ]}
                className='text-secondary'
                placeholder={'Seleccione'}
                styles={ordCustomSelect}
                onChange={e => setFilters({ ...filters, typeContract: e.value })}
              ></Select>
            </Form.Group>
          </Col>
          <Col xs>
            <form onSubmit={e => handleSearch(e)}>
              <Form.Group className='mb-3 text-start' controlId='fName'>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}></Form.Label>
                <div className={`d-flex ${tableStyles.tlnBlueInputSearch}`}>
                  <Form.Control
                    className={`${tableStyles.tlnRoundInputSearch}`}
                    type='text'
                    placeholder='Escribe aquí para buscar'
                    onChange={e => setFilters({ ...filters, search: e.target.value })}
                  />
                  <img className='cursorPointer' src={ordBlueSearch} alt={'search'}></img>
                </div>
              </Form.Group>
            </form>
          </Col>
          <Col xs={1} className='align-self-center' style={{ width: 'max-content' }}>
            <div className='d-flex  justify-content-end pt-2'>
              <button
                type='button'
                className={`${tableStyles.btnGenerateRep}`}
                onClick={() => setShowModal({ ...showModal, status: true })}
              >
                Crear
              </button>
            </div>
          </Col>
        </Row>
        <OrdTable
          headers={headers}
          body={data}
          className={tableStyles.ordTableShadow}
          paginate={{
            activePage: filters.page,
            totalPages: ListContracts?.rowTotal,
            perPage: filters.perpage,
            pageRangeDisplayed: 3,
            onChangePage: async e => {
              setFilters({ ...filters, page: e });
            },
            showTextDetails: true,
          }}
        ></OrdTable>
      </OrdGenericTemplate>
    </div>
  );
}
export default MedicalContracts;
