import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useGetMethod, usePostMethod } from '../../Hooks/useFetch';
import arrayRight from '../../assets/img/icons/Arrow-gray.svg';
import alertCircleRed from '../../assets/img/icons/alertCircleRed.svg';
import toOrderImg from '../../assets/img/icons/img_appointment.svg';
import icon_search from '../../assets/img/icons/ordSearchClearBlue.svg';
import { loader } from '../../helpers/helpers';

import styles from '../../components/Layouts/tableStyle.module.scss';
import DetailPatient from './DetailPatient';
import HandlePatient from './HandlePatient/HandlePatient';
import { formatteDocument } from './../../helpers';

export const RegisterAppointment = () => {
  const [ticketValue, setTicketValue] = useState();
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const userId = store.loginReducer.user_data.id;
  const idAccount = store.loginReducer.currentAccount.id;
  const location = useLocation();
  const [infoPatient, setInfoPatient] = useState(
    location?.state?.infoPatient && location?.state.infoPatient,
  );
  const [procedure, setProcedure] = useState(
    location?.state?.procedure && location?.state.procedure,
  );
  const [trigger, setTrigger] = useState(1);
  const [search, setSearch] = useState(infoPatient?.document ?? '');
  const [filters, setFilters] = useState({
    filterPatient: '',
    page: 1,
    perpage: 100,
    orderByType: 'creation',
  });

  const [patient, setPatient] = useState({
    eaccount: idAccount,
  });

  const [options, setOptions] = useState({
    isEditingPatient: false,
    showHandlePatient: false,
    showCards: false,
    showImportance: false,
    showInfoPatient: false,
    showSearchPatient: true,
    showDetailPatient: false,
    showEditPatient: false,
    showProgramming: false,
    showSchedule: false,
    showRegisterPatient: false,
    header: [
      {
        title: 'Fecha',
        className: 'col-2 text-center',
      },
      {
        title: 'Servicio',
        className: 'col-3 text-start',
      },
      {
        title: 'Estado',
        className: 'col-2 text-center',
      },
      {
        title: <>&nbsp;</>,
        className: 'col-1 text-end px-1',
      },
    ],
  });
  const { trigger: getTicket } = useGetMethod();
  const { trigger: postTicket } = usePostMethod();
  const [triggerTicket, setTriggerTicket] = useState();

  const {
    results: listPatients,
    load: ListPacientLoader,
    trigger: getListPatient,
  } = useGetMethod();

  const {
    results: detailPatient,
    loader: detailLoaderPatient,
    trigger: getDetailPatient,
  } = useGetMethod();

  const getIdPatient = useCallback(
    (id, alert) => {
      const filters = {
        id: id,
      };
      getDetailPatient({
        url: '/medical/patient/',
        objFilters: { id: filters.id },
        token: token,
      });
      setPatient(state => ({ ...state, id: id, cum_id: alert }));
      setFilters(state => ({ ...state, idPatient: id, patient: id }));
      setOptions({
        ...options,
        showCards: true,
        showDetailPatient: true,
        showSearchPatient: false,
      });
      setSearch('');
    },
    [getDetailPatient, options, token],
  );

  useEffect(() => {
    getTicket({
      url: '/medical/appointment/concecutive/',
      objFilters: {
        idConsec: 23,
      },
      token: token,
      doAfterSuccess: res => {
        setTicketValue(res.results);
      },
    });
  }, [getTicket, token, triggerTicket]);

  useEffect(() => {
    void getListPatient({
      url: '/medical/patient/listPatient/',
      objFilters: filters,
      token: token,
    });
  }, [filters, getIdPatient, getListPatient, infoPatient, token]);

  useEffect(() => {
    if (infoPatient?.id && infoPatient?.alertId) getIdPatient(infoPatient.id, infoPatient.alertId);
  }, [infoPatient?.id, infoPatient?.alertId, getIdPatient]);

  const postTicketFunction = (tag, idApp) => {
    postTicket({
      url: '/medical/appointment/saveStepProcess/',
      method: 'POST',
      body: {
        consecutiveTicket: ticketValue,
        userId: userId,
        aleEvent: tag,
        appId: idApp ? idApp : [],
      },
      token: token,
      noAlert: true,
    });
  };

  const renderList = () => {
    let tempList = [];
    if (Array.isArray(listPatients.results)) {
      listPatients.results.forEach((item, index) => {
        tempList.push(
          <>
            <div
              className={styles.app_container_card_list_patient}
              key={index}
              onClick={() => {
                getIdPatient(item.id, item.alertId);
              }}
            >
              <div className='d-flex flex-column m-0'>
                <span className={styles.app_title_list}>
                  {item.first_name} {item?.other_names} {item.last_name} {item?.second_surname}
                </span>
                <span className={styles.app_text_list}>
                  {item.docTypeDesc} { formatteDocument(item.document).format}
                </span>
              </div>
              <img
                src={arrayRight}
                alt='right'
                width='10'
                className={`cursorPointer ${styles.appColorArrow}`}
              />
            </div>
          </>,
        );
      });
    }
    return tempList;
  };

  return (
    <>
      {ListPacientLoader && loader}
      {options.showDetailPatient && (
        <>
          {detailLoaderPatient && loader}
          <DetailPatient
            token={token}
            filters={filters}
            patient={patient}
            options={options}
            setOptions={setOptions}
            setPatient={setPatient}
            setFilters={setFilters}
            setSearch={setSearch}
            detailPatient={detailPatient}
            getListPacient={getListPatient}
            getDetailPatient={getDetailPatient}
            detailLoaderPatient={detailLoaderPatient}
            ListPacientLoader={ListPacientLoader}
            procedure={procedure}
            setInfoPatient={setInfoPatient}
            setProcedure={setProcedure}
            postTicketFunction={postTicketFunction}
            triggerTicket={triggerTicket}
            setTriggerTicket={setTriggerTicket}
            setTicketValue={setTicketValue}
          />
        </>
      )}

      {options.showHandlePatient && (
        <HandlePatient
          filters={filters}
          options={options}
          trigger={trigger}
          setFilters={setFilters}
          setOptions={setOptions}
          setTrigger={setTrigger}
          getListPacient={getListPatient}
          getDetailPatient={getDetailPatient}
          postTicketFunction={postTicketFunction}
          setPatient={setPatient}
          patient={patient}
          detailPatientData={detailPatient}
          detailLoaderPatient={detailLoaderPatient}
        />
      )}

      {options.showSearchPatient && (
        <>
          <div className={styles.app_container_list_app}>
            <div style={{ marginTop: '30px' }}>
              <div className={styles.app_container_search_app}>
                <div style={{ marginBottom: '20px' }}>
                  <span className={styles.app_title_register_app}>Registro de Citas</span>
                </div>
                <div className={styles.app_back_search}>
                  <span className={styles.app_title_search}>Buscar</span>
                  <div className={styles.app_container_icon_search}>
                    <form
                      onSubmit={e => {
                        e.preventDefault();
                        setTrigger(trigger + 1);
                        setFilters({ ...filters, filterPatient: search });
                      }}
                    >
                      <input
                        className={`${styles.app_input_search} p-2`}
                        type='text'
                        value={search}
                        onChange={({ target }) => {
                          setSearch(target.value);
                        }}
                      />
                    </form>
                    <img
                      className='hoverPointer'
                      width='27px'
                      alt='search'
                      src={icon_search}
                      onClick={() => {
                        setTrigger(trigger + 1);
                        setFilters({ ...filters, filterPatient: search });
                      }}
                    />
                  </div>
                </div>
                <div
                  className={styles.app_container_icon_new}
                  onClick={() => {
                    postTicketFunction('register_new_patient');
                    setOptions({
                      ...options,
                      showSearchPatient: false,
                      showHandlePatient: true,
                    });
                  }}
                >
                  <div className={styles.tlnBtnNew} />
                  <span>Inscribir Nuevo Paciente</span>
                </div>
              </div>
              {listPatients.results?.length <= 0 ? (
                <div className={styles.app_container_alert_patient}>
                  <img src={alertCircleRed} alt='alert-cicle' />
                  <div className={styles.app_alert_patient}>
                    <span>Paciente no encontrado</span>
                  </div>
                </div>
              ) : (
                <>
                  <div>
                    <div className={styles.app_container_list_patient}>{renderList()}</div>
                    {listPatients.results?.length > 0 && (
                      <span className={`${styles.app_text_list} px-3 `}>
                        ({listPatients.rowTotal}) resultados
                      </span>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className='d-flex justify-content-center mb-3'>
              <img alt='order' src={toOrderImg} width='270' />
            </div>
          </div>
          <div style={{ width: '100%', backgroundColor: '#F6FEFF' }} />
        </>
      )}
    </>
  );
};
