import { Col, Row } from 'react-bootstrap';
import ordBackArrow from '../../assets/img/icons/ordBackArrow.svg';
import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { AntSwitchBlue } from '../../helpers/constants';
import '../orderingStyles.css';
import ordComponentStyles from './ordComponentStyles.module.scss';
import Select from 'react-select';
import { ordCustomSelect } from '../../components/Layouts/react-select-custom';

export const OrdGenericTemplate = props => {
  return (
    <>
      <div
        style={props.style && props.style}
        className={`${props.reduced === true ? tableStyles.reducedContainer : tableStyles.ordContainer} m-0 ${props.className && props.className} `}
      >
        <Row
          className={`${props?.showBottomBorder && tableStyles.ordClearBlueBorder} mt-4`}
          style={props.rowStyle ? props.rowStyle : { marginBottom: '1rem' }}
        >
          <Col
            xs={props?.reduced ? props?.titleSize || 6 : props?.titleSize ? props?.titleSize : 5}
            className='d-flex align-items-center'
            style={props.titleStyle}
          >
            {props?.showBackArrow && (
              <img
                alt={'backArrow'}
                width={props?.backArrowWidth}
                height='30px'
                onClick={() => props?.backArrowAction()}
                src={props?.backIcon ? props?.backIcon : ordBackArrow}
                className={`mr-2  cursorPointer ${props?.classIcon ?? ''} ${props.backIconColor} `}
              ></img>
            )}
            <h1
              style={{ fontSize: '40px' }}
              className={` mx-1 my-0  ${tableStyles.ordDarkBlueText} ${props?.titleClass} ${tableStyles.fontFamiTitle}`}
            >
              {props?.title && props?.title}{' '}
            </h1>
            {props?.titleIcon && <>{props.titleIcon?.icon}</>}
          </Col>
          {props?.filterSwitch?.active && (
            <Col
              className={`d-flex align-items-center justify-content-end`}
              xs={props.filterSwitch.size}
            >
              <label
                htmlFor='material-switch'
                className='d-flex align-items-center justify-content-end gap-2'
              >
                <AntSwitchBlue
                  checked={props.filterSwitch.checked}
                  onClick={props.filterSwitch.onChange}
                  inputProps={{ 'aria-label': 'ant design' }}
                />
                <span className={tableStyles.labelFilterSwitch}>{props.filterSwitch.label}</span>
              </label>
            </Col>
          )}

          {props?.extraHeader ? (
            <Col style={{ justifyContent: 'end', display: 'flex' }}>{props?.extraHeader}</Col>
          ) : (
            ''
          )}

          {props?.buttons?.length > 0 && (
            <Col xs={props?.colBtnSize || 7} className={''}>
              <Row className={`justify-content-end mt-4 ${props.btnRowClassName}`}>
                {props?.buttons?.length > 0 &&
                  props?.buttons.map((e, i) => {
                    return (
                      <>
                        <Col
                          onClick={e.action}
                          key={i}
                          xs={e.btnSize || 3}
                          className={`
                                                    ${props?.colbtnClass}
                                                    ${
                                                      e.btnVariant === 'gray'
                                                        ? tableStyles.ordGrayVariant
                                                        : e.btnVariant === 'darkBlue'
                                                          ? tableStyles.ordDarkBlueVariant
                                                          : e.btnVariant === 'clearBlue' &&
                                                            tableStyles.ordClearBlueVariant
                                                    } `}
                        >
                          <span>
                            <p
                              style={e.btnTitleStyle}
                              className={!e.btnTitle && 'd-flex justify-content-end p-0'}
                            >
                              {e.btnTitle || ''}
                              &nbsp;
                              {e.btnIcon && e.btnIcon}
                            </p>
                          </span>
                        </Col>
                      </>
                    );
                  })}
              </Row>
            </Col>
          )}

          {props?.searchWithNew && (
            <Col
              xs={props.searchWithNew?.colSize || 12}
              style={{ marginTop: '20px' }}
              className={`d-flex align-items-center justify-content-between ${props.filterCheck ? props?.searchWithNew?.searchWith : props?.searchWithNew?.WSize ? 'w-' + props?.searchWithNew?.WSize : ''}`}
            >
              {props?.selectComponent && (
                <Col xs={2} style={{ marginRight: '5px' }}>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={props?.selectComponent?.options}
                    className={'text-secondary'}
                    placeholder={'Seleccionar médico'}
                    styles={ordCustomSelect}
                    onChange={props?.selectComponent?.onChange}
                    value={props?.selectComponent?.value}
                  />
                </Col>
              )}
              <form
                style={
                  props.filterCheck
                    ? {
                        display: 'grid',
                        columnGap: '25px',
                        alignItems: 'center',
                        gridTemplateColumns: '1fr 1fr',
                        width: props?.filterCheck?.formWidth,
                      }
                    : {
                        display: 'flex',
                        columnGap: '25px',
                        alignItems: 'center',
                        marginBottom: '0',
                      }
                }
                onSubmit={e => props?.searchWithNew?.onSubmit(e)}
                className={`d-flex align-items-center ${props.searchWithNew.searchWith ? `w-${props.searchWithNew.searchWith}` : 'w-80'}`}
              >
                <div className='position-relative d-flex align-items-center' style={{ flex: 1 }}>
                  <input
                    style={{ width: '100%', marginBottom: '0' }}
                    placeholder='Escribe aquí para buscar'
                    onChange={e => props?.searchWithNew.onChange(e)}
                    className={
                      props?.searchWithNew?.searchIcon
                        ? `${ordComponentStyles.backgroundOutImage} pl-2`
                        : `${ordComponentStyles.backgroundImage} ${tableStyles.appInputDate} ${!props.filterCheck ? '' : ''} pl-2`
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        props?.searchWithNew.onChange(e);
                      }
                    }}
                  />

                  {props?.searchWithNew?.searchIcon && (
                    <img
                      className={ordComponentStyles.searchIcon2}
                      onClick={props?.searchWithNew?.onClickSearchIcon}
                      src={ordBlueSearch}
                      alt='buscar...'
                    />
                  )}
                </div>

                {props?.filterCheck && (
                  <div
                    className='d-flex align-items-center'
                    style={{ gap: '10px', marginBottom: '0' }}
                  >
                    <label
                      className={`${props.filterCheck.classNameLabel && props.filterCheck.classNameLabel}`}
                    >
                      {props?.filterCheck?.label}
                    </label>
                    <input
                      className={`form-check-input styleCheckInputRatio cursorPointer ${props.filterCheck.classNameInput && props.filterCheck.classNameInput}`}
                      style={{ border: '2px solid #1a538d', marginLeft: '10px' }}
                      onChange={e => props.filterCheck.onChange(e)}
                      type='checkbox'
                    ></input>
                  </div>
                )}
              </form>

              {props.searchWithNew?.buttonNewLabel && (
                <div
                  className={`d-flex justify-content-end align-items-center ${props.searchWithNew.className ? props.searchWithNew.className : 'ordGroupAddButton'}`}
                  onClick={() =>
                    props?.searchWithNew?.buttonNewAction
                      ? props?.searchWithNew?.buttonNewAction()
                      : () => alert('No Action defined')
                  }
                >
                  <label
                    style={props?.searchWithNew.style ? props?.searchWithNew.style : {}}
                    htmlFor='newAccident'
                    className='ordGrayText fw-bold'
                  >
                    {props?.searchWithNew?.buttonNewLabel}
                  </label>
                  <button className='addCheckButton mx-2' />
                </div>
              )}

              {props.filterDate && props.filterDate.component}
            </Col>
          )}
        </Row>
        <div>{props?.children}</div>
      </div>
    </>
  );
};
/* ------------------------------- Example use ------------------------------ */
// let buttons = [
//     {
//         btnTitle: "Cargar excel",
//         btnVariant: "gray",
//         btnSize: 3,
//         btnIcon: <img src={add} ></img>,
//         action: () => alert('Cargar excel')
//     },
//     {
//         btnTitle: "Segundo botón",
//         btnVariant: "darkBlue",
//         btnSize: 3,
//         btnIcon: <img src={add} ></img>,
//         action: () => alert('Cargar excel')
//     },
//     {
//         btnTitle: "tercer botón",
//         btnVariant: "clearBlue",
//         // btnSize: 3,
//         btnIcon: <img src={add} ></img>,
//         action: () => alert('Cargar excel')
//     },
// ]
// eslint-disable-next-line no-lone-blocks
{
  /* <OrdGenericTemplate
    className="w-90"
    titleSize={12}
    showBottomBorder={false}
    title={"Justificaciones medicamentos No POS"}
    searchWithNew={{
        onChange: (e) => {
            setFilters({ ...filters, search: e.target.value })
        },
        colSize: "10",
        onSubmit: (e) => {
            e.preventDefault();
            getMedicalJustResults({
                url: "/medical/justification",
                objFilters: { idAccount: idEnterprise, search: filters.search },
                token: token,
            })
            setTrigger(trigger + 1);
        },
        buttonNewLabel: "Nueva justificación",
        buttonNewAction: () => {
            setJustification({ ...justification, showModal: true })
        }
    }}
    titleIcon={{
        icon:
            noJustMedicines?.results?.length > 0 ? (<>
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                >

                    <span>
                        < img
                            alt={"icon"}
                            onClick={() => {
                                formatData(noJustMedicines.results)
                                setNoJustifyMedicines({ ...noJustifyMedicines, showModal: true })
                            }}
                            width={'55px'}
                            src={warningIcon}
                            className={`mr-2 mt-2 mb-2 cursorPointer`}
                        ></img>
                    </span>
                </OverlayTrigger>
            </>) : () => message('info', 'Sin resultados', ' No se encontraron datos para mostrar')
    }}
></OrdGenericTemplate> */
}
