//styles
import styles from '../styles.module.scss';
import { appCustomSelectNew } from '../../../../components/Layouts/react-select-custom';

//components
import { Box } from '@mui/material';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

//icons
import searchCalendar from '../../../../assets/img/icons/searchCalendar.svg';
import { isEmptyOrUndefined } from '../../../../helpers';
import { useState } from 'react';

export const SearchAppointment = ({
  listServices,
  listLocations,
  listDoctors,
  onChangeSelectOfService,
  onChangeSelectOfLocation,
  onChangeSelectOfSite,
  onChangeSelectOfDoctor,
  onSearchSchedules,
  service,
  location,
  site,
  doctor,
  data,
  order,
}) => {
  const validationSearch =
    !isEmptyOrUndefined(service?.value) && !isEmptyOrUndefined(location?.value);

  const [listSitesReal, setListSitesReal] = useState([]);
  return (
    <Box display={'grid'} columnGap='20px' gridTemplateColumns='1fr 1fr 1fr 1fr 40px'>
      <div>
        <Form.Group controlId='servicio'>
          <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
            <b className={`${styles.app_title} px-1`}>Servicio</b>
          </Form.Label>

          <Select
            noOptionsMessage={() => 'No hay datos'}
            className='text-secondary'
            placeholder={'Seleccionar...'}
            menuPortalTarget={data?.length === 1 ? document.body : undefined}
            styles={appCustomSelectNew}
            value={service?.value ? service : { label: 'seleccionar...', value: '' }}
            options={[{ label: 'Seleccionar...', value: '' }, ...listServices]}
            onChange={({ value, label }) => {
              if (value !== order.serviceId) {
                onChangeSelectOfService({ serviceName: label, serviceId: value, sorId: '' });
              } else {
                onChangeSelectOfService({
                  serviceName: label,
                  serviceId: value,
                  sorId: order.sorId,
                });
              }
            }}
          />
        </Form.Group>
      </div>

      <div>
        <Form.Group controlId='Ubicación'>
          <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
            <b className={`${styles.app_title} px-1`}>Ubicación</b>
          </Form.Label>
          <Select
            value={location?.label ? location : ''}
            isDisabled={isEmptyOrUndefined(service?.value)}
            noOptionsMessage={() => 'No hay datos'}
            className='text-secondary'
            placeholder={'Seleccionar...'}
            menuPortalTarget={data?.length === 1 ? document.body : undefined}
            styles={appCustomSelectNew}
            options={[{ label: 'Seleccionar...', value: '' }, ...listLocations]}
            onChange={({ value, label }) => {
              const selectedLocation = listLocations?.find(x => x.value === value);
              if (selectedLocation) {
                const newTemp = selectedLocation.sites;
                const newSites = newTemp?.map(site => ({ label: site.siteName, value: site.id }));
                newSites.unshift({ label: 'Seleccionar...', value: '' });
                setListSitesReal(newSites);
              }
              onChangeSelectOfLocation({ locationId: value, locationName: label });
            }}
          />
        </Form.Group>
      </div>

      <div>
        <Form.Group controlId='Sede'>
          <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
            <b className={`${styles.app_title} px-1`}>Sede</b>
          </Form.Label>
          <Select
            value={site?.label ? site : ''}
            isDisabled={isEmptyOrUndefined(location?.value)}
            noOptionsMessage={() => 'No hay datos'}
            className='text-secondary'
            placeholder={'Seleccionar...'}
            menuPortalTarget={data?.length === 1 ? document.body : undefined}
            styles={appCustomSelectNew}
            options={listSitesReal}
            onChange={({ value, label }) => {
              onChangeSelectOfSite({ siteId: value, siteName: label });
            }}
          />
        </Form.Group>
      </div>

      <div>
        <Form.Group controlId='medicoSearch'>
          <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
            <b className={`${styles.app_title} px-1`}>Médico</b>
          </Form.Label>
          <Select
            value={doctor?.label ? doctor : ''}
            isDisabled={isEmptyOrUndefined(site?.value)}
            noOptionsMessage={() => 'No hay datos'}
            className='text-secondary'
            placeholder={'Seleccionar...'}
            menuPortalTarget={data?.length === 1 ? document.body : undefined}
            styles={appCustomSelectNew}
            options={listDoctors}
            onChange={({ value, label }) => {
              onChangeSelectOfDoctor({ doctorId: value, doctorName: label });
            }}
          />
        </Form.Group>
      </div>

      <div
        className={`col-1 d-flex justify-content-center align-items-center p-0 w-100 ${validationSearch ? 'styleSearchCite' : 'styleSearchCiteInactive'}`}
        onClick={() => (validationSearch ? onSearchSchedules() : '')}
      >
        <img src={searchCalendar} alt='search-icon' />
      </div>
    </Box>
  );
};
