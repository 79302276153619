// espera un array de json con los siguientes keys
// amount , discount, advance
export const calculateCollectionOfAdmissionPatient = (
  services = [],
  totalBalance = 0,
  amountTransfer = 0
) => {
  if (!Array.isArray(services) || !services?.length) {
    return { message: "Digite un array correcto" };
  }

  const result = {
    amount: 0,
    discount: 0,
    advance: 0,
    price: 0
  };

  let amountTotal, amountTotalWithoutTotalBalance,amountTotalFixed;

  for (let index = 0; index < services?.length; index++) {
    const service = services[index];
    if (service["amount"] !== undefined && service["amount"] !== null) {
      result.amount += Number(service.amount);
    }

    if (service["discount"] !== undefined && service["discount"] !== null) {
      result.discount += Number(service.discount);
    }

    if (service["price"] !== undefined && service["price"] !== null) {
      result.price += Number(service.price);
    }

    // if (service["advance"] !== undefined && service["advance"] !== null) {
    //   result.advance += Number(service.advance);
    // }
  }

  result.advance = Number(amountTransfer)

  const totalDiscount = result.discount;
  const totalAdvance = result.advance;

  if (Number(result.amount) <= Number(totalDiscount)) {
    amountTotalFixed = 0;
  }else {
    amountTotalFixed = result.amount - totalDiscount;
  }

  if (Number(result.amount) <= (Number(totalDiscount) + Number(totalBalance) + Number(totalAdvance))) {
    amountTotal = 0;
  } else {
    amountTotal = result.amount - (totalDiscount + Number(totalBalance) + Number(totalAdvance));
  }

  if (Number(result.amount) <= (Number(totalDiscount) + Number(totalAdvance))) {
    amountTotalWithoutTotalBalance = 0;
  } else {
    amountTotalWithoutTotalBalance = amountTotalWithoutTotalBalance =
      result.amount - (totalDiscount + Number(totalAdvance));
  }

  return { ...result, amountTotal, amountTotalWithoutTotalBalance, amountTotalFixed };
};
