import {
  GET_ALL_BANK,
} from "../actions/actionTypes";

const initialState = {
  results: [],
};

export const bankReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_BANK:
      return action.payload;
    default:
      return state;
  }
};
