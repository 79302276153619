import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useGetMethod } from '../../Hooks';
import {
  generateCaption,
  getListCompanies,
  getListContracts,
  getOneListCompanies,
  getOneListContract,
} from '../../actions/billingActions';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import { customSwaltAlert, isEmptyOrUndefined, optionsSelect, today, valueSelect } from '../../helpers';
import { Title } from '../../shared';


function CapitationInvoice() {
  const storage = useSelector(state => state);
  const dispatch = useDispatch();
  const [triggerSelect, setTriggerSelect] = useState(0);

  const [info, setInfo] = useState({
    client: '',
    contract: '',
    due_date: today(),
    observations: '',
    eaccount: storage.loginReducer.currentAccount.id,
    site: '',
    user: storage.loginReducer.user_data.id,
    file64: '',
    fileName: '',
    copayment: 0,
    type_doc: '',
    contractType: 1,
    ungroup: true,
    start_date: '',
    end_date: '',
    resolution_id: undefined
  });

  const { trigger: getResSites, results: resultResSites, load: resolutionsLoad } = useGetMethod();
  const { trigger: getTotalValue, results: resultTotalValue, load: totalValueLoad } = useGetMethod();

  const numberWithCommas = x => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };

  useEffect(() => {
    getResSites({
      url: '/medical/admissions/sites/',
      objFilters: {
        eaccount: info.eaccount,
        userId: info.user
      },
      token: storage.loginReducer.Authorization
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (info.site &&
      info.client &&
      info.contract &&
      info.end_date &&
      info.start_date
    ) {
      getTotalValue({
        url: '/invoicing/invoice/getTotalValueAppointments/',
        objFilters: {
          site_id: info.site,
          contract_id: info.contract,
          client_id: info.client,
          start_date: info.start_date,
          end_date: info.end_date,
          eaccount: info.eaccount
        },
        token: storage.loginReducer.Authorization,
        doAfterSuccess: result => {
          if (result.success) {
            if (result.results.length === 0) {
              return customSwaltAlert({
                showCancelButton: false,
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: result.message,
                confirmButtonText: 'Aceptar'
              });
            }
          }
        }
      });
    }
  }, [getTotalValue,
    info.client,
    info.contract,
    info.end_date,
    info.site,
    info.eaccount,
    info.start_date,
    storage.loginReducer.Authorization]);

  useEffect(() => {
    dispatch(getListContracts(info));
    dispatch(getOneListCompanies(info));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.client]);

  useEffect(() => {
    if (info.contract) {
      dispatch(getOneListContract(info));
    }
    dispatch(getListCompanies({ eaccount: storage.loginReducer.currentAccount.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.contract]);

  let optionsCompanies = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.billingReducer.companies)) {
    storage.billingReducer.companies.forEach(item => {
      optionsCompanies.push({
        value: item.id,
        label: item.copyName,
        key: item.id + 'clients',
      });
    });
  }

  let optionsContracts = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.billingReducer.contracts)) {
    storage.billingReducer.contracts.forEach(item => {
      optionsContracts.push({
        value: item.id,
        label: item.contract_name,
        key: item.id + 'contracts',
      });
    });
  }

  let optionsSites = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.billingReducer.sites)) {
    storage.billingReducer.sites.forEach(item => {
      optionsSites.push({
        value: item.id,
        label: item.description,
        key: item.id + 'sites',
      });
    });
  }

  const buttonGenerate = () => {
    dispatch(
      generateCaption(
        {
          client_id: info.client,
          contract_id: info.contract,
          site_id: info.site,
          resolution_id: info.resolution_id,
          observations: info.observations,
          user_id: info.user,
          start_date: info.start_date,
          end_date: info.end_date,
          due_date: info.due_date,
          eaccount: info.eaccount,
          discount: info.copayment
        },
        () => {
          setInfo({
            client: '',
            contract: '',
            observations: '',
            eaccount: storage.loginReducer.currentAccount.id,
            site: '',
            user: storage.loginReducer.user_data.id,
            file64: '',
            fileName: '',
            copayment: 0,
            type_doc: '',
            contractType: 1,
            ungroup: true,
            start_date: '',
            end_date: '',
            resolution_id: undefined
          });
          setTriggerSelect(triggerSelect + 1);
        }),
    );
  };

  return (
    <>
      <div
        className={` ml-5 ${tableStyles.container}`}
        style={{ marginRight: '3rem', paddingTop: '30px' }}
      >
        {(storage.billingReducer.loading_generate_caption || resolutionsLoad || totalValueLoad) && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}

        <div className='div justify-content-between' style={{ maxWidth: '1065px' }}>
          <Title
            title='Generación de facturas'
            backIconNone
            className={'mb-2'}
          />


        </div>

        <div>
          <div className="d-flex gap-3 align-items-end">
            <div className={tableStyles.containerSelect} style={{ width: "5rem" }}>
              &nbsp;<label className={tableStyles.stylesLabel}>Factura N°</label>
              <input
                className={IndividualStyles.registerInputs}
                type='text'
                style={{ background: '#F5F7FA', color: '#58595B' }}
                readOnly
              />
            </div>

            <div className='div gap-3 justify-content-end' style={{ maxWidth: '1065px' }}>
              <div className={tableStyles.containerSelect}>
                &nbsp;<label className={tableStyles.stylesLabel}>Fecha de expedición</label>
                <input
                  className={`${IndividualStyles.registerInputs} register-inputs`}
                  style={{ background: '#F5F7FA', color: '#005DBF', fontWeight: 'bold' }}
                  type='date'
                  value={new Date().toISOString().substr(0, 10)}
                  readOnly
                />
              </div>
              <div className={tableStyles.containerSelect}>
                &nbsp;<label className={tableStyles.stylesLabel}>Fecha de vencimiento<span className='text-danger'>*</span> </label>
                <input
                  className={`${IndividualStyles.registerInputs} register-inputs`}
                  style={{ color: '#005DBF', fontWeight: 'bold' }}
                  type='date'
                  value={info.due_date}
                  onChange={e =>
                    setInfo(state => ({
                      ...state,
                      due_date: e.target.value
                    }))
                  }
                />
              </div>

            </div>
          </div>
          <div className='div gap-3 align-items-end'>
            <div className={tableStyles.containerSelect}>
              &nbsp;<label className={tableStyles.stylesLabel}>Empresa<span className='text-danger'>*</span> </label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={'client' + triggerSelect}
                placeholder='Seleccionar...'
                options={optionsCompanies}
                onChange={e =>
                  setInfo({
                    ...info,
                    client: e.value,
                    contract: undefined,
                    address: '',
                    site: undefined,
                    resolution_id: undefined
                  })
                }
                styles={customSelectNewDark}
              />
            </div>

            <div className={tableStyles.containerSelect}>
              &nbsp;<label className={tableStyles.stylesLabel}>Contrato<span className='text-danger'>*</span> </label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={'contract' + triggerSelect}
                placeholder='Seleccionar...'
                options={optionsContracts}
                value={valueSelect({
                  list: optionsContracts,
                  findId: 'value',
                  findLabel: 'label',
                  value: info.contract
                })}
                onChange={e =>
                  setInfo({
                    ...info,
                    contract: e.value,
                  })
                }
                styles={customSelectNewDark}
              />
            </div>

            <div className={tableStyles.containerSelect}>
              &nbsp;<label className={tableStyles.stylesLabel}>Dirección</label>
              <input
                className={IndividualStyles.registerInputs}
                type='text'
                style={{ background: '#F5F7FA', color: '#58595B' }}
                placeholder='Seleccionar...'
                defaultValue={storage.billingReducer.one_company?.address}
                readOnly
              />
            </div>

            <div className={tableStyles.containerSelect}>
              &nbsp;<label className={tableStyles.stylesLabel}>Sede<span className='text-danger'>*</span> </label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={'site' + triggerSelect}
                options={optionsSelect({
                  list: resultResSites?.results?.sites,
                  label: 'siteName',
                  value: 'siteId'
                })}
                placeholder='Seleccionar...'
                onChange={e =>
                  setInfo({
                    ...info,
                    site: e.value,
                  })
                }
                styles={customSelectNewDark}
                value={valueSelect({
                  list: resultResSites?.results?.sites,
                  findId: 'siteId',
                  findLabel: 'siteName',
                  value: info.site
                })}
              />
            </div>

            <div className={tableStyles.containerSelect}>
              &nbsp;<label className={tableStyles.stylesLabel}>Resolución<span className='text-danger'>*</span> </label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={'site' + triggerSelect}
                options={optionsSelect({
                  list: resultResSites?.results?.sites?.find(el => el.siteId === info.site)?.resolutions,
                  value: 'id',
                  label: 'name'
                })}
                placeholder='Seleccionar...'
                onChange={e =>
                  setInfo({
                    ...info,
                    resolution_id: e.value,
                  })
                }
                styles={customSelectNewDark}
                value={valueSelect({
                  list: resultResSites?.results?.sites?.find(el => el.siteId === info.site)?.resolutions,
                  findId: 'id',
                  findLabel: 'name',
                  value: info.resolution_id
                })}
              />
            </div>
          </div>

          <div className='div gap-3 align-items-end'>
            <div className={tableStyles.containerSelect}>
              &nbsp;<label className={tableStyles.stylesLabel}>Fecha desde<span className='text-danger'>*</span> </label>
              <input
                className={`${IndividualStyles.registerInputs} register-inputs`}
                style={{ color: '#005DBF', fontWeight: 'bold' }}
                type='date'
                value={info.start_date}
                onChange={e =>
                  setInfo(state => ({
                    ...state,
                    start_date: e.target.value
                  }))
                }
              />
            </div>

            <div className={tableStyles.containerSelect}>
              &nbsp;<label className={tableStyles.stylesLabel}>Fecha hasta<span className='text-danger'>*</span> </label>
              <input
                className={`${IndividualStyles.registerInputs} register-inputs`}
                style={{ color: '#005DBF', fontWeight: 'bold' }}
                type='date'
                value={info.end_date}
                onChange={e =>
                  setInfo(state => ({
                    ...state,
                    end_date: e.target.value
                  }))
                }
              />
            </div>

            <div className={tableStyles.containerSelect}>
              &nbsp;<label className={tableStyles.stylesLabel}>Valor</label>
              <input
                className={IndividualStyles.registerInputs}
                type='text'
                style={{ background: '#F5F7FA', color: '#58595B' }}
                value={
                  !isEmptyOrUndefined(resultTotalValue?.results)
                    ? '$' + numberWithCommas(resultTotalValue?.results)
                    : '$'
                }
                readOnly
              />
            </div>

            <div className={tableStyles.containerSelect}>
              &nbsp;<label className={tableStyles.stylesLabel}>Descuento</label>
              <NumberFormat
                allowNegative={false}
                style={{ padding: 0, color: '#58595B' }}
                className={IndividualStyles.registerInputs}
                name='valueNote'
                thousandsGroupStyle='thousand'
                thousandSeparator={'.'}
                decimalSeparator={','}
                prefix={'$'}
                value={info.copayment}
                isAllowed={e => {
                  const { value } = e;
                  return value <= (resultTotalValue.results || 0);

                }}
                onValueChange={values => {
                  const { value } = values;
                  setInfo({
                    ...info,
                    copayment: value,
                  });
                }}
                min={1}
              ></NumberFormat>
            </div>

            <div className={tableStyles.containerSelect}>
              &nbsp;<label className={tableStyles.stylesLabel}>Valor a pagar</label>
              <input
                className={IndividualStyles.registerInputs}
                type='text'
                style={{ background: '#F5F7FA', color: '#58595B' }}
                value={
                  !isEmptyOrUndefined(resultTotalValue?.results) && !!(info.copayment >= 0)
                    ? '$' +
                    numberWithCommas(
                      Number(resultTotalValue?.results) -
                      Number(info.copayment),
                    )
                    : '$'
                }
                readOnly
              />
            </div>
          </div>

          <div style={{ maxWidth: '1065px' }}>
            <div className='mt-4'>
              &nbsp;<label className={tableStyles.stylesLabel}>Observaciones<span className='text-danger'>*</span> </label>
              <textarea
                className={IndividualStyles.textArea_new3}
                rows='6'
                cols=''
                value={info.observations}
                placeholder='Escribir...'
                onChange={e =>
                  setInfo({
                    ...info,
                    observations: e.target.value,
                  })
                }
              ></textarea>
            </div>
            <div className={IndividualStyles.bottomRow}>
              <Button
                className={`${tableStyles.buttonExtra3} d-block`}
                style={{ padding: '8px', border: '0px' }}
                onClick={() => buttonGenerate()}
              >
                Generar factura
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CapitationInvoice;
