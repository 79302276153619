import { useState } from 'react';
import { Col } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import TabContainer from 'react-bootstrap/TabContainer';
import TabContent from 'react-bootstrap/TabContent';
import TabPane from 'react-bootstrap/TabPane';
import '../../OrderingModule/orderingStyles.css';
import ordBackArrow from '../../assets/img/icons/ordBackArrow.svg';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { AttendanceCertTab } from './AttendanceCertTab';
import { CombosTab } from './CombosTab';
import { InhabilitiesTab } from './InhabilitiesTab';
import { MedicineTab } from './MedicineTab';
import { ReferenceTab } from './ReferenceTab';
import { ServicesTab } from './ServicesTab';

export const MultiTabForm = props => {
  let { goBack, event, clone, documentPatient } = props;
  const [profile] = useState(clone?.isCloningServices ? 3 : 1);
  const [activeTab, setActiveTab] = useState(clone?.isCloningServices ? 3 : 1);
  return (
    <div className={`simpleTabs__Container m-0 ${tableStyles.multiTabScroll}`}>
      <div className={` ${tableStyles.ordBgClearBlue}`}>
        <Col className={`${tableStyles.ordBgAquaMBlueOpacity}`} xs={2}></Col>
        <Col className={`pl-5`} xs={10}>
          <h2 className={`${tableStyles.ordDarkBlueText} mx-5 px-3`}>
            <img
              onClick={() => goBack()}
              src={ordBackArrow}
              className={`mr-2 mb-2 cursorPointer`}
              alt='Atrás'
            ></img>
            <b>{`Evento #${event.clhId}`}</b>
          </h2>
        </Col>
      </div>
      <TabContainer defaultActiveKey={profile}>
        <Nav className='flex-row simpleTabs'>
          <Col
            xs={1}
            className={` ${tableStyles.ordBgClearBlue}`}
            style={{
              borderBottom: '1px solid #cecece',
            }}
          ></Col>
          <Nav.Item className={`${tableStyles.ordBgClearBlue}`} key={1}>
            <Nav.Link
              onClick={() => setActiveTab(1)}
              className={`${activeTab === 1 && 'bg-white'} ${tableStyles.ordDarkBlueText}`}
              eventKey={1}
            >
              Medicamentos
            </Nav.Link>
          </Nav.Item>

          <Nav.Item className={`${tableStyles.ordBgClearBlue}`} key={2}>
            <Nav.Link
              onClick={() => setActiveTab(2)}
              className={`${activeTab === 2 && 'bg-white'} ${tableStyles.ordDarkBlueText}`}
              eventKey={2}
            >
              Combos
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className={`${tableStyles.ordBgClearBlue}`} key={3}>
            <Nav.Link
              onClick={() => setActiveTab(3)}
              className={`${activeTab === 3 && 'bg-white'} ${tableStyles.ordDarkBlueText}`}
              eventKey={3}
            >
              Servicios
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className={`${tableStyles.ordBgClearBlue}`} key={4}>
            <Nav.Link
              onClick={() => setActiveTab(4)}
              className={`${activeTab === 4 && 'bg-white'} ${tableStyles.ordDarkBlueText}`}
              eventKey={4}
            >
              Referencia
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className={`${tableStyles.ordBgClearBlue}`} key={5}>
            <Nav.Link
              onClick={() => setActiveTab(5)}
              className={`${activeTab === 5 && 'bg-white'} ${tableStyles.ordDarkBlueText}`}
              eventKey={5}
            >
              Cert. de asistencia
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className={`${tableStyles.ordBgClearBlue}`} key={6}>
            <Nav.Link
              onClick={() => setActiveTab(6)}
              className={`${activeTab === 6 && 'bg-white'} ${tableStyles.ordDarkBlueText}`}
              eventKey={6}
            >
              Incapacidad
            </Nav.Link>
          </Nav.Item>
          <div
            style={{ flex: 1 }}
            className={`simpleTabs__filler ${tableStyles.ordBgClearBlue}`}
          ></div>
        </Nav>
        <TabContent className='simpleTabs__relative' style={{ overflowY: 'unset' }}>
          <TabPane key={'panelTab_1'} eventKey={1} className='simpleTabs__Area'>
            <MedicineTab clone={clone} event={event} documentPatient={documentPatient} />
          </TabPane>
          <TabPane key={'panelTab_2'} eventKey={2} className='simpleTabs__Area'>
            <CombosTab event={event} />
          </TabPane>
          <TabPane key={'panelTab_3'} eventKey={3} className='simpleTabs__Area'>
            <ServicesTab clone={clone} event={event} />
          </TabPane>
          <TabPane key={'panelTab_4'} eventKey={4} className='simpleTabs__Area'>
            <ReferenceTab event={event} />
          </TabPane>
          <TabPane key={'panelTab_5'} eventKey={5} className='simpleTabs__Area'>
            <AttendanceCertTab patientData={event} />
          </TabPane>
          <TabPane key={'panelTab_6'} eventKey={6} className='simpleTabs__Area'>
            <InhabilitiesTab patientData={event} />
          </TabPane>
        </TabContent>
      </TabContainer>
    </div>
  );
};
