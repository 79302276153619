import { Form } from 'react-bootstrap';
import ReactSelect from 'react-select';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';

import { useGetMethod, usePostMethod } from '../../../Hooks';
import { customSwaltAlertAsistencial, loader } from '../../../helpers';

import ordBlueSearch from '../../../assets/img/icons/ordBlueSearch.svg';

import {
  ordCustomSelect,
  selectMultipleSmall,
} from '../../../components/Layouts/react-select-custom';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import OrdTable from '../../../OrderingModule/OrderingComponents/OrdTable';
import { formatNumberWithoutDecimals } from '../../../helpers/numberFormatting';

const header = [
  { title: 'Fecha hora', className: 'px-2' },
  { title: 'Factura', className: 'px-2 text-start' },
  { title: 'Paciente', className: 'px-2 text-start' },
  { title: 'Servicio', className: 'px-2 text-center' },
  { title: 'Monto', className: 'px-2 text-end' },
  { title: '', className: 'px-2' },
];

export default function OnCredit() {
  const token = useSelector(store => store.loginReducer.Authorization);
  const userId = useSelector(store => store.loginReducer.user_data.id);
  const accountId = useSelector(store => store.loginReducer.currentAccount.id);

  const [serviceTypes, setServiceTypes] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [doctor, setDoctor] = useState(null);
  const [invoice, setInvoice] = useState(null);

  const { trigger: createReport, load: createReportLoader } = usePostMethod();

  const {
    results: pendingRemunerationsResult,
    trigger: getPendingRemunerations,
    load: pendingRemunerationsLoader,
  } = useGetMethod();

  const { trigger: getDoctors, results: doctorsResult, load: doctorsLoader } = useGetMethod();

  const {
    trigger: getServiceTypes,
    results: serviceTypesResult,
    load: serviceTypesLoader,
  } = useGetMethod();

  useEffect(() => {
    void getDoctors({
      url: '/medical/generals/doctors/',
      token: token,
      objFilters: { idAccount: accountId },
    });

    void getServiceTypes({
      url: '/medical/generals/serviceType/',
      objFilters: { listAll: 1 },
      token: token,
    });
  }, [getDoctors, getServiceTypes, token, accountId]);

  const availableInvoiceAmount = useMemo(() => {
    if (pendingRemunerationsResult?.results && pendingRemunerationsResult.results[0]) {
      const value = pendingRemunerationsResult.results[0];
      return value.availableInvoiceAmount;
    }
    return 0;
  }, [pendingRemunerationsResult?.results]);

  const totalPending = useMemo(() => {
    if (selectedServices.length) {
      return selectedServices.reduce((acc, el) => {
        return acc + el.total;
      }, 0);
    }
    return 0;
  }, [selectedServices]);

  const doctorsList = useMemo(() => {
    if (doctorsResult?.results) {
      return [
        { label: 'Seleccionar...', value: null },
        ...doctorsResult.results.map(doc => ({
          label: doc.nameDoctor,
          value: doc.idDoctor,
        })),
      ];
    }
    return [];
  }, [doctorsResult?.results]);

  const serviceTypesList = useMemo(() => {
    if (serviceTypesResult?.results) {
      return serviceTypesResult.results.map(doc => ({
        label: doc.serviceType,
        value: doc.serviceTypeId,
      }));
    }
    return [];
  }, [serviceTypesResult?.results]);

  const onSubmitForm = event => {
    event.preventDefault();

    const {
      sequence: { value: sequenceValue },
      medic: { value: medicValue },
    } = event.target;

    if (!sequenceValue.length || !medicValue.length) {
      void customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Asegúrese de llenar los filtros obligatorios.',
        showCancelButton: false,
      });
      return;
    }

    setDoctor(medicValue);
    setInvoice(sequenceValue);
    void getPendingRemunerations({
      url: `/medical/doctors/${medicValue}/pending-remunerations`,
      token: token,
      objFilters: {
        userId: userId,
        invoicingNumber: sequenceValue,
        serviceTypes: serviceTypes.join(','),
        type: 'on_credit',
      },
    });
  };

  const onCreateReport = () => {
    if (!selectedServices.length) {
      void customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Asegúrese de seleccionar al menos una cita',
        showCancelButton: false,
      });
      return;
    }

    void createReport({
      url: `/medical/doctors/${doctor}/remuneration-reports`,
      method: 'POST',
      token: token,
      body: {
        userId: userId,
        type: 'on_credit',
        invoicingNumber: invoice,
        serviceTypes: serviceTypes.map(s => s.value).join(','),
        selectedAppointments: selectedServices.map(s => s.id),
      },
      doAfterException: results => {
        customSwaltAlertAsistencial({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
        setSelectedServices([]);
      },
    });
  };

  const renderComponent = () => {
    return (
      <>
        {(doctorsLoader ||
          serviceTypesLoader ||
          pendingRemunerationsLoader ||
          createReportLoader) &&
          loader}
        <form onSubmit={onSubmitForm} className='row d-flex align-items-end'>
          <div className='col'>
            <div className={`mx-1 ${tableStyles?.ordDarkBlueText}`}>
              No. de factura<span className='text-danger'>*</span>
            </div>
            <Form.Control
              type='text'
              style={{ width: '100%' }}
              className='ord-roundInput'
              placeholder='Escribe...'
              name='sequence'
            />
          </div>
          <div className='col'>
            <div className={`mx-1 ${tableStyles?.ordDarkBlueText}`}>
              Médico<span className='text-danger'>*</span>
            </div>
            <ReactSelect
              noOptionsMessage={() => 'No hay datos'}
              className='text-secondary'
              styles={ordCustomSelect}
              options={doctorsList}
              name='medic'
              placeholder={'Seleccionar...'}
            />
          </div>
          <div className='col'>
            <div className={`mx-1 ${tableStyles?.ordDarkBlueText}`}>Tipos de servicio</div>
            <ReactSelect
              noOptionsMessage={() => 'No hay datos'}
              options={serviceTypesList}
              isMulti
              isSearchable
              styles={selectMultipleSmall}
              onChange={option => setServiceTypes(option)}
              placeholder={'Seleccionar...'}
              hideSelectedValues={false}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
            />
          </div>
          <div className='col-lg-1'>
            <button type='submit' className='mb-2'>
              <img src={ordBlueSearch} width={16} alt='' />
            </button>
          </div>
        </form>

        {pendingRemunerationsResult?.results &&
          pendingRemunerationsResult.results.map(item => (
            <div key={item.id}>
              <h5 className={`fw-bold ${tableStyles?.ordDarkBlueText} mt-4`}>{item.name}</h5>
              <OrdTable
                shadow
                headers={header}
                hasChildren={true}
                oneHeadDarkBlue={false}
                lowerCase={false}
              >
                {item.services.map(service => (
                  <tr key={service.id}>
                    <td align='left' className='px-2'>
                      {moment(service.attentionDateTime).format('DD/MM/YYYY HH:MM')}
                    </td>
                    <td align='left' className='px-2'>
                      {service.resolution}
                    </td>
                    <td align='left' className='px-2'>
                      {service.patient.name}
                    </td>
                    <td align='center' className='px-2'>
                      {service.service.name}
                    </td>
                    <td align='right' className='px-2'>
                      {formatNumberWithoutDecimals(service.total)}
                    </td>
                    <td width={40}>
                      <input
                        type='checkbox'
                        className={`${tableStyles.checkClearBlue} ${tableStyles.appCheckInput} ${tableStyles.appCheckClear} form-check-input m-0`}
                        checked={selectedServices.map(s => s.id).includes(service.id)}
                        onClick={event => {
                          if (event.target.checked) {
                            setSelectedServices([...selectedServices, service]);
                          } else {
                            setSelectedServices(state => state.filter(s => s.id !== service.id));
                          }
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </OrdTable>
            </div>
          ))}

        <div className='d-flex justify-content-between align-items-center my-3'>
          <div className='d-flex'>
            {pendingRemunerationsResult?.results && (
              <p className={`${tableStyles.ordDarkBlueText} fw-bold mr-3`}>
                Total disponible: {formatNumberWithoutDecimals(availableInvoiceAmount)}
              </p>
            )}
            {selectedServices.length > 0 && (
              <p className={`${tableStyles.ordDarkBlueText} fw-bold`}>
                Total a liquidar: {formatNumberWithoutDecimals(totalPending)}
              </p>
            )}
          </div>
          <button
            className={`${tableStyles.tlnBtnPrimary} btn`}
            onClick={onCreateReport}
            disabled={!selectedServices.length}
          >
            Generar liquidación
          </button>
        </div>
      </>
    );
  };

  return renderComponent();
}
