//import SASS && Mui material && icons
import { Drawer, Box } from '@mui/material';
import tableStyle from '../../../components/Layouts/tableStyle.module.scss';

//import Icons
import iconClose from '../../../assets/img/icons/modalClose.svg';
import iconSend from '../../../assets/img/icons/iconSend.svg';

export const DrawerHistorialChat = ({
  anchor = 'right',
  open,
  title = '',
  chatList = [],
  onClose,
}) => {
  const RenderChat = () => {
    return (
      <Box height={'60vh'} overflow={'scroll'} padding={3} pb={0}>
        {chatList?.map((chat, i) => (
          <Box key={i} mb={2} width={230}>
            <Box className={`${tableStyle.ordAquaMarineText} ${tableStyle.f14}`}>{chat?.name}</Box>
            <Box className={`${tableStyle.ordDarkGrayText} ${tableStyle.f12}`}>{chat?.message}</Box>
            <Box className={`${tableStyle.ordAquaMarineText} ${tableStyle.f14} text-end`}>
              {chat?.date}
            </Box>
            <hr className='mt-0' />
          </Box>
        ))}
      </Box>
    );
  };
  const RenderTextarea = () => {
    return (
      <Box padding={3} pt={0} width={282}>
        <Box className={`${tableStyle.boxTextarea}`}>
          <Box className={`${tableStyle.ordAquaMarineText}`}>Camila</Box>

          <textarea
            // value={}
            placeholder={'Escribir...'}
            rows='70'
            cols='60'
            style={{ height: '120px', borderRadius: '5px', padding: '5px' }}
            className={`w-100 ${tableStyle.outlineNone} ${tableStyle.ordDarkGrayText} ${tableStyle.textareaStyle}`}
          />
          <Box textAlign={'right'}>
            <img
              src={iconSend}
              alt='submit'
              width={14}
              className={`${tableStyle.filterOrdAquaMarine} pointer`}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Drawer anchor={anchor} open={open} style={{ zIndex: 111111 }} onClose={() => onClose()}>
      <Box padding={3}>
        <Box mb={3}>
          <img
            className={`${tableStyle.closeDrawer} pointer`}
            src={iconClose}
            alt='close'
            onClick={() => onClose()}
            height={'28px'}
            width={'28px'}
          />
        </Box>

        <Box className={`${tableStyle.f24} ${tableStyle.ordAquaMarineText} fw-bold`} mb={2}>
          {title}
        </Box>

        <RenderChat />

        <RenderTextarea />
      </Box>
    </Drawer>
  );
};
